body {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: inherit;
}

#root {
  height: 100%;
  overflow: hidden;
}

header {
  transition: all ease-out 0.5s;
  will-change: transform;
  overflow: hidden;
  &.header-hidden {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    transform: translateY(-150%);
  }
  &.isDemoPage {
    overflow: hidden;
    display: block;
  }
}

main {
  transition: all ease-out 0.5s;
  aside {
    transition: all ease-out 0.5s;
    will-change: transform;
    .ds-box {
      .box-content {
        > *:not(.animate-pulse) {
          transform: translate3d(-60px, 0, 0);
          opacity: 0;
          transition: all ease-out 0.4s;
          will-change: transform, opacity;
        }
      }
      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          .box-content {
            > *:not(.animate-pulse) {
              transition-delay: #{($i * 50) + 0}ms;
            }
          }
        }
      }
    }
  }
  section.lp-section {
    will-change: max-width;
    transition: all ease-out 0.5s;
    .loader-wrapper {
      clip-path: inset(0 0 0 0);
      transition: all ease-out 0.6s 0.4s;
      will-change: clip-path;
    }
  }

  &.full-size {
    min-height: 100vh;
    aside {
      transform: translate3d(-300px, 0px, 0px);
      width: 0;
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
      white-space: nowrap;
    }
    section.lp-section {
      width: 100%;
    }
  }
  &.initialized {
    aside {
      .ds-box {
        .box-content {
          .animate-pulse {
            opacity: 0;
            animation: none;
            pointer-events: none;
            user-select: none;
          }
          > *:not(.animate-pulse) {
            transform: translate3d(0, 0, 0);
            opacity: 1;
          }
        }
      }
    }

    section.lp-section {
      .loader-wrapper {
        pointer-events: none;
        clip-path: inset(0 0 100% 0);
      }
    }
  }
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #1d2939;
}

.rs-picker-toggle {
  font-size: 1rem;
  border-radius: 8px;
}
.rs-picker-default .rs-picker-toggle {
  border-color: #eaecf0;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 11px;
  padding-bottom: 11px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 10px;
}
