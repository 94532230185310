.ck {
  &.ck-editor {
  }
  &.ck-icon,
  &.ck-icon * {
    color: #d0d5dd !important;
  }
  &.ck-button {
    &.ck-on {
      .ck.ck-icon,
      .ck.ck-icon * {
        color: #3b82f6 !important;
      }
    }
  }
  &.ck-toolbar {
    background: #fff;
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: #eaecf0;
    border-radius: 8px 8px 0 0 !important;
  }
  &.ck-editor__main {
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: #eaecf0;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    > .ck-editor__editable {
      &:not(.ck-focused) {
        border-color: transparent;
      }
      &.ck-focused {
        &:not(.ck-editor__nested-editable) {
          box-shadow: none;
          border-color: transparent;
        }
      }
    }
  }
  &.ck-content:not(.ck-comment__input *) {
    min-height: 112px;
  }

  ul,
  ol {
    padding-left: 20px;
    li {
    }
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }

  a {
    color: #3b82f6;
  }
}
