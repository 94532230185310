.lp-container {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
}

.lp-header {
  &.sticky {
    position: sticky;
    top: 0;
    animation: 0.6s transform ease;
  }
}

.lp-body {
  &:not(.mobile) > * {
    min-width: 1170px;
  }

  &.mobile {
    .sub-header {
      nav {
        ul {
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
}

@keyframes transform {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
