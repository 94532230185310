@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans/MuseoSans-900.eot');
  src: url('../fonts/MuseoSans/MuseoSans-900.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSans/MuseoSans-900.woff2') format('woff2'),
  url('../fonts/MuseoSans/MuseoSans-900.woff') format('woff'),
  url('../fonts/MuseoSans/MuseoSans-900.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans/MuseoSans-500.eot');
  src: url('../fonts/MuseoSans/MuseoSans-500.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSans/MuseoSans-500.woff2') format('woff2'),
  url('../fonts/MuseoSans/MuseoSans-500.woff') format('woff'),
  url('../fonts/MuseoSans/MuseoSans-500.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans/MuseoSans-700.eot');
  src: url('../fonts/MuseoSans/MuseoSans-700.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSans/MuseoSans-700.woff2') format('woff2'),
  url('../fonts/MuseoSans/MuseoSans-700.woff') format('woff'),
  url('../fonts/MuseoSans/MuseoSans-700.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans/MuseoSans-100.eot');
  src: url('../fonts/MuseoSans/MuseoSans-100.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSans/MuseoSans-100.woff2') format('woff2'),
  url('../fonts/MuseoSans/MuseoSans-100.woff') format('woff'),
  url('../fonts/MuseoSans/MuseoSans-100.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans/MuseoSans-300.eot');
  src: url('../fonts/MuseoSans/MuseoSans-300.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSans/MuseoSans-300.woff2') format('woff2'),
  url('../fonts/MuseoSans/MuseoSans-300.woff') format('woff'),
  url('../fonts/MuseoSans/MuseoSans-300.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

