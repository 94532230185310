$icons: (
  "2-layers",
  "3-layers",
  "airplay",
  "alert-octagon",
  "align-center",
  "align-right",
  "anchor",
  "aperture",
  "archive",
  "arrow-down-circle",
  "arrow-down-left",
  "arrow-down-right",
  "arrow-down",
  "arrow-left",
  "arrow-right-circle",
  "arrow-right",
  "arrow-up-circle",
  "arrow-up-left",
  "arrow-up-right",
  "arrow-up",
  "at-sign",
  "award",
  "battery-charging",
  "battery",
  "bell-off",
  "bell",
  "bluetooth",
  "bold",
  "book-open",
  "book",
  "box",
  "briefcase",
  "calendar",
  "camera-off",
  "camera",
  "cast",
  "check-circle",
  "check-square",
  "check",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "chevrons-down",
  "chevrons-left",
  "chevrons-right",
  "chevrons-up",
  "chrome",
  "circle",
  "clock",
  "close",
  "cloud-drizzle",
  "cloud-lightning",
  "cloud-off",
  "cloud-rain",
  "cloud-snow",
  "cloud",
  "code",
  "codepen",
  "columns",
  "command",
  "compass",
  "copy",
  "corner-down-left",
  "corner-down-right",
  "corner-left-down",
  "corner-left-up",
  "corner-up-left",
  "corner-up-right",
  "cpu",
  "credit-card",
  "crop",
  "crosshair",
  "database",
  "delete",
  "divide-square",
  "divide",
  "dollar-sign",
  "dot",
  "download-cloud",
  "download",
  "drag",
  "dribbble",
  "droplet",
  "edit-2",
  "edit-3",
  "edit",
  "external-link",
  "eye-off",
  "eye",
  "facebook",
  "fast-forward",
  "feather",
  "figma",
  "file-minus",
  "file",
  "film",
  "filter",
  "filters-lines",
  "flag",
  "folder-minus",
  "folder-plus",
  "folder",
  "framer",
  "git-branch",
  "git-commit",
  "git-merge",
  "git-pull-request",
  "github",
  "gitlab",
  "globe",
  "grid",
  "headphones",
  "heart",
  "help-circle",
  "hexagon",
  "home",
  "image",
  "inbox",
  "info",
  "instagram",
  "key",
  "layout",
  "life-buoy",
  "link-2",
  "link",
  "linkedin",
  "list",
  "lock",
  "log-in",
  "log-out",
  "mail",
  "map-pin",
  "map",
  "marketplace",
  "maximize-2",
  "maximize",
  "meh",
  "menu",
  "message-square",
  "mic-off",
  "mic",
  "minimize-2",
  "minimize",
  "minus-circle",
  "minus-square",
  "minus",
  "monitor",
  "more-horizontal",
  "more-vertical",
  "mouse-pointer",
  "move",
  "music",
  "navigation-2",
  "navigation",
  "octagon",
  "pause-circle",
  "pause",
  "pen-tool",
  "percent",
  "phone-call",
  "phone-forwarded",
  "phone-incoming",
  "phone-missed",
  "phone",
  "play-circle",
  "play",
  "plus-circle",
  "plus-square",
  "plus",
  "pocket",
  "printer",
  "radio",
  "refresh-cw",
  "repeat",
  "rewind",
  "rotate-ccw",
  "rotate-cw",
  "rss",
  "save",
  "search",
  "server",
  "settings",
  "share-2",
  "share",
  "shield-off",
  "shield",
  "shuffle",
  "skip-back",
  "skip-forward",
  "slack",
  "slash-divider",
  "slash",
  "sliders",
  "smartphone",
  "square",
  "stop-circle",
  "sun",
  "sunrise",
  "sunset",
  "tablet",
  "tag",
  "thermometer",
  "thumbs-down",
  "thumbs-up",
  "toggle-left",
  "toggle-right",
  "tool",
  "trash-2",
  "trash",
  "trending-up",
  "truck",
  "tv",
  "twitch",
  "twitter",
  "type",
  "umbrella",
  "unlock",
  "upload-cloud",
  "user-check",
  "user-minus",
  "user-plus",
  "user-x",
  "user",
  "users",
  "video",
  "voicemail",
  "volume-2",
  "volume-x",
  "volume",
  "watch",
  "wifi-off",
  "wifi",
  "x-circle",
  "x-octagon",
  "x",
  "youtube",
  "zap-off",
  "zap",
  "zoom-in",
  "zoom-out",
  "baby",
  "best-price",
  "discount",
  "double_bed_new",
  "expand",
  "mastercard",
  "no-smoking",
  "non_refundable_new",
  "palette-solid",
  "protect",
  "size",
  "meal",
  "arrow-up-right-from-square",
  "alert-circle",
  "brush",
  "pie-chart",
  "move-right",
  "plane",
  "ellipsis",
  "black-white",
  "colored",
  "tiktok",
  "pinterest"
);
.icon {
  display: inline-block;
  mask-size: contain;
  mask-position: center center;
  -webkit-mask-position: center center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  @each $name in $icons {
    &.icon-#{$name} {
      -webkit-mask-image: url("../svg/#{$name}.svg");
      mask-image: url("../svg/#{$name}.svg");
    }
  }
}
