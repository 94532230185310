

.dx {
  &:not(.account-management-container) {
    &-widget {
      .dx-datagrid {
        overflow: hidden;

        .dx-row > td {
        }

        .dx-datagrid-content-fixed {
          .dx-datagrid-table {
            .dx-row {
              td.dx-pointer-events-none {
              }
            }
          }
        }
      }

      &.draggable-active {
        .dx-datagrid-headers {
          .dx-datagrid-table {
            .dx-row > td {
              border-width: 0 1px;
            }
          }
        }

        .dx-row > td {
          border-color: #2e90fa;
          border-style: dashed;
          border-width: 1px;
        }

        .dx-datagrid-rowsview {
          .dx-row {
            & > td {
              position: relative;

              &:after {
                content: "-";
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: #ffffff;
                border-bottom: 1px solid #eaecf0;
                z-index: 1;
                animation: hideTableValues 0.5s 0s ease;
              }

              @keyframes hideTableValues {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }

  }
  &-widget {
    font-family: "Inter", serif;
    font-size: 12px;
    border-color: #eaecf0;
    
    .dx-datagrid {
      color: #667085;
      background-color: #f9fafb;

      .dx-row > td {
        padding: 13px 8px;
        border-width: 1px 0 0;
        border-color: #eaecf0;
        border-style: solid;
        background-color: white;
        line-height: 18px;
      }

      .dx-datagrid-content-fixed {
        .dx-datagrid-table {
          .dx-row {
            td.dx-pointer-events-none {
              border-width: 0;
            }
          }
        }
      }
    }

    &.draggable-active {
      .dx-datagrid-headers {
        .dx-datagrid-table {
          .dx-row > td {
            border-width: 0 1px;
          }
        }
      }

      .dx-row > td {
        border-color: #2e90fa;
        border-style: dashed;
        border-width: 1px;
      }

      .dx-datagrid-rowsview {
        .dx-row {
          & > td {
            position: relative;

            &:after {
              content: "-";
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: #ffffff;
              border-bottom: 1px solid #eaecf0;
              z-index: 1;
              animation: hideTableValues 0.5s 0s ease;
            }

            @keyframes hideTableValues {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  &-datagrid-filter-row {
    .dx-editor-cell .dx-editor-with-menu .dx-menu {
      background-color: white;
    }

    .dx-menu {
      margin-left: 0;
    }

    .dx-placeholder::before {
      color: #1d2939;
      font-weight: 400;
      font-size: 12px;
    }
  }

  &-datagrid-header-panel {
    .dx-toolbar {
      margin-bottom: 0;
      border: 1px solid #eaecf0;
      border-bottom: 0;
      border-radius: 8px 8px 0 0;
      padding: 16px;
    }
  }

  &-searchbox .dx-icon-search::before,
  &-show-clear-button .dx-icon-clear {
    color: #667085;
  }

  &-datagrid-headers {
    color: #667085;
    font-weight: 600;
    font-size: 12px;
    border-bottom-width: 0;

    .dx-datagrid-table .dx-row > td {
      background-color: #f9fafb;
      border-width: 0;
    }

    .dx-datagrid-focus-overlay {
      border-width: 0;
    }
  }

  &-datagrid-content {
    .dx-datagrid-table {
      .dx-row {
        .dx-command-select {
          width: 40px;
          min-width: 40px;
          max-width: 40px;
        }
      }
    }
  }

  &-datagrid-borders .dx-datagrid-headers .dx-datagrid-table {
    border-bottom-width: 0;
  }

  &-datagrid-borders .dx-datagrid-rowsview,
  .dx-datagrid-headers + .dx-datagrid-rowsview,
  .dx-datagrid-rowsview.dx-datagrid-after-headers {
    border-top: 0;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    box-shadow: 0px 4px 8px -2px rgba(31, 76, 149, 0.1), 0px 2px 4px -2px rgba(31, 76, 149, 0.06);
  }

  &-datagrid-borders > .dx-datagrid-filter-panel,
  .dx-datagrid-borders > .dx-datagrid-headers {
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }

  &-datagrid-borders > .dx-datagrid-pager {
    position: relative;
    background: #f9fafb;
    padding: 17px 0;
    margin-top: 1rem;
  }

  &-pager {
    .dx-page-sizes {
      .dx-page-size {
        margin-left: 1px;
        margin-right: 1px;
      }
    }

    .dx-pages {
      .dx-info {
        display: inline-block;
        margin-right: 0;
        opacity: 1;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #344054;
        font-weight: 500;
        font-size: 14px;
        top: 50%;
      }

      .dx-page,
      .dx-separator {
        display: none;
      }

      .dx-navigate-button {
        width: auto;
        height: auto;
        padding: 8px 14px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;

        &.dx-button-disable {
          opacity: 0.8;
        }
      }

      .dx-next-button,
      .dx-prev-button {
        &::before {
          position: relative;
          display: block;
          width: auto;
          top: auto;
          margin-top: auto;
          left: auto;
          margin-left: auto;
          font-family: "Inter";
          font-weight: 500;
          font-size: 14px;
          color: #667085;
        }
      }

      .dx-prev-button {
        &::before {
          content: "Önceki sayfa";
        }
      }

      .dx-next-button {
        margin-left: 12px;

        &::before {
          content: "Sonraki sayfa";
        }
      }
    }
  }

  &-datagrid-borders > .dx-datagrid-rowsview {
    border-width: 0 1px 0;
    border-color: #eaecf0;
  }

  &-datagrid-borders > .dx-datagrid-header-panel {
    margin-left: 1px;
  }

  &-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed {
    border-left: 1px solid #eaecf0;
  }

  &-datagrid-borders > .dx-datagrid-headers,
  .dx-datagrid-borders > .dx-datagrid-rowsview,
  .dx-datagrid-borders > .dx-datagrid-total-footer {
    border-color: #eaecf0;
  }

  &-datagrid-focus-overlay {
    border-color: #2e90fa;
  }

  &-datagrid-pager {
    border-top: 0;
  }

  &-datagrid-rowsview {
    position: relative;
    z-index: 1;
  }

  &-datagrid-search-panel {
    margin: 0;
  }

  &-toolbar {
    &-after .dx-toolbar-item {
    }

    .dx-toolbar-items-container {
      height: 46px;
    }
    .dx-toolbar-item {
      padding: 0 24px 0 0;
    }
  }

  &-datagrid-header-panel .dx-toolbar {
    .dx-toolbar-text-auto-hide .dx-button .dx-button-content {
    }

    .dx-toolbar-after {
      height: 100%;
      display: flex;

      .dx-toolbar-item-content,
      .dx-widget.dx-button.dx-button-mode-contained {
        height: 100%;
      }

      .dx-button {
        width: 44px;
        height: 44px;
        background: #eff8ff;
        border: 1px solid #eff8ff;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;

        .dx-button-content {
          display: flex;
          align-items: center;
          justify-content: center;

          .dx-icon {
            background-color: #2e90fa;
          }
        }
      }

      .dx-item.dx-toolbar-item.dx-toolbar-button:not(.dx-toolbar-item-auto-hide) {
      }
    }
  }

  &-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
  .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
  .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
  .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
    background: #f5faff !important;
    border-top: 1px solid #2e90fa !important;
    border-bottom: 1px solid #2e90fa !important;
  }

  &-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines) > td,
  .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > td {
    border-top: 1px solid #2e90fa;
    border-bottom: 1px solid #2e90fa;
  }

  &-datagrid-borders > .dx-datagrid-headers,
  .dx-datagrid-borders > .dx-datagrid-rowsview,
  .dx-datagrid-borders > .dx-datagrid-total-footer {
    border-left: 1px solid #eaecf0;
    border-right: 1px solid #eaecf0;
  }

  &-datagrid-borders .dx-datagrid-rowsview,
  .dx-datagrid-headers + .dx-datagrid-rowsview,
  .dx-datagrid-rowsview.dx-datagrid-after-headers {
    border-top: 1px solid #eaecf0;
  }

  &-datagrid-rowsview.dx-empty {
    height: 220px;
  }

  &-datagrid-nodata {
    background-position: center calc(100% - 84px);
    background-repeat: no-repeat;
    background-size: 96px;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 18px;
    color: black;
    font-weight: 600;
    padding-bottom: 40px;
    background-image: url("../svg/table-no-data.svg");

  }

  &-datagrid-headers .dx-datagrid-table .dx-row > td[id$="-fixed"],
  &-datagrid .dx-row > td[aria-describedby$="-fixed"] {
    position: relative !important;
    overflow: visible !important;
    // border-left: 1px solid #eaecf0 !important; 

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      right: 100%;
      width: 40px;
      top: 0;
      z-index: -1;
      pointer-events: none;
      user-select: none;
      background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }
  }

  &-scrollbar-horizontal {
    &.dx-scrollbar-hoverable,
    .dx-scrollable-scroll {
      height: 4px;
      z-index: 1;
    }

    &.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active,
    &.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll,
    &.dx-scrollbar-hoverable.dx-state-hover,
    &.dx-scrollbar-hoverable.dx-state-hover .dx-scrollable-scroll {
      height: 8px;
    }
  }

  &-scrollbar-hoverable {
    background-color: #eaecf0;

    &.dx-scrollable-scrollbar-active .dx-scrollable-scroll-content {
      background-color: darken(#2e90fa, 10%);
    }
  }

  &-scrollable-scroll {
    padding: 0;

    &-content {
      border-radius: 5px;
      background-color: #2e90fa;
    }
  }

  &--checkbox-icon {
    border-radius: 4px;
    border: 1px solid #d0d5dd;
  }

  &-texteditor {
    .dx-placeholder {
      color: #667085;
    }

    .dx-texteditor-input {
      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }

    &.dx-editor-outlined {
      border: 1px solid #eaecf0;
      box-shadow: 0 1px 2px #1018280d;
      border-radius: 8px;
    }
  }

  &-datagrid-rowsview.dx-datagrid-nowrap.dx-fixed-columns.dx-scrollable,
  .dx-datagrid-content.dx-datagrid-content-fixed {
    overflow: visible !important;
  }

  &-widget .dx-datagrid-rowsview .dx-datagrid-content .dx-checkbox.dx-select-checkbox {
    margin-top: -8px;
  }

  &-datagrid-rowsview.dx-fixed-columns {
    &.dx-scrollable .dx-scrollable-content {
      padding-bottom: 8px;
    }

    .dx-scrollable-scrollbar {
      z-index: 2;
    }
  }

  &-pager .dx-page-sizes .dx-page-size {
    padding: 11px 12px;
  }

  &-pager .dx-page-sizes .dx-selection,
  .dx-pager .dx-pages .dx-selection {
    background: #eff8ff;
    border: 1px solid #2e90fa;
  }

  &-pager .dx-page,
  .dx-pager .dx-page-size {
    border-radius: 8px;
  }

  &-datagrid-filter-row {
    .dx-editor-cell {
      .dx-editor-with-menu {
        .dx-menu {
          display: none;
        }
      }
    }
  }

  &-datagrid-content .dx-datagrid-table .dx-row .dx-editor-container {
    max-width: calc(100% - 16px) !important;
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    input {
      padding: 8px 10px;
      height: auto;
      min-width: auto;
      min-height: auto;
      max-width: initial;
    }
  }

  &-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-placeholder::before,
  .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input {
    padding-left: 10px;
  }

  &-widget.filters-active {
    .dx-datagrid-headers .dx-datagrid-table .dx-row {
      &:not(.dx-datagrid-filter-row) > td {
        position: relative;
        padding: 8px 8px 4px;
        overflow: visible;
      }

      > td[id$="-fixed"] .dx-datagrid-text-content {
        position: relative;
        //top: 24px; I did do this line is commented because it was causing the last title to be invisible
      }
    }

    .dx-row {
      .dx-datagrid-filter-row {
        td {
          padding: 0;
          vertical-align: top;
          padding-bottom: 7px;
        }
      }
    }

    .dx-datagrid {
      .dx-column-indicators {
        position: absolute;
        top: 31px;
        left: 0;
        width: calc(100% - 16px);
        background: white;
        border-radius: 8px;
        border: 1px solid #eaecf0;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      }

      .dx-sort {
        position: absolute;
        right: 0;
        top: -24px;
      }

      .dx-header-filter {
        width: 100%;
        font: 12px/18px "Inter";
        color: #1d2939;
        display: flex;

        &:after {
          position: relative;
          margin: 0;
          content: "Seç";
          padding: 9px 8px;
        }

        &:before {
          content: "";
          position: absolute;
          left: auto;
          right: 1px;
          width: 32px;
          height: calc(100% - 2px);
          top: 50%;
          background: #667085;
          -webkit-mask-repeat: no-repeat;
          -webkit-mask-position: center;
          border-radius: 3px;
          transform: translateY(-50%);
          z-index: 1;
        }

        &:hover {
          &:before {
            background-color: #e6e6e6;
          }
        }

        &:not(.dx-header-filter-empty){
          &:after {
            content: "Seçildi";
          }
        }
      }

      .dx-datagrid-scrollable-simulated {
        height: 76px;
      }
    }
  }

  &-datagrid-content .dx-datagrid-table [class*="column"] + [class*="column"]:last-child {
    height: auto !important;
  }

  &-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu {
    margin-bottom: 4px;
  }

  &-datagrid-column-chooser {
    .dx-overlay-content {
      .dx-popup-title {
        .dx-toolbar-label {
          font-weight: 600;
        }
      }
    }
  }

  &-scrollable-container {
  }
}

.dx-datagrid-rowsview .dx-datagrid-content.dx-datagrid-content-fixed {
  overflow: visible;
}

.dx-datebox-calendar {
  .dx-dropdowneditor-icon {
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      background: #667085;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      -webkit-mask-size: cover;
    }
  }
}

.dx-datagrid-content {
  .dx-datagrid-table {
    .dx-row {
      .dx-editor-cell {
        &:not([aria-label="Filter cell"]):not([aria-label="Select row"]):not([aria-label="Tümünü seç"]),
        & ~ td[aria-describedby$="-fixed"],
        & ~ td[id$="-fixed"] {
          position: relative;
          overflow: visible;
          border-right: 1px solid #eaecf0 !important;
          &:before {
            content: "";
            position: absolute;
            height: 100%;
            left: 100%;
            width: 40px;
            top: 0;
            z-index: -1;
            pointer-events: none;
            user-select: none;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
          }
        }
      }
    }
  }
}

.dx-widget {
  &.filters-active {
    .dx-datagrid {
      &-nodata {
        background-image: url("../svg/table-no-data.svg");
      }
      .dx-header-filter:before {
        -webkit-mask-image: url("../svg/chevron-down.svg");
        mask-image: url("../svg/chevron-down.svg");
      }
      .dx-datebox-calendar .dx-dropdowneditor-icon:before {
        -webkit-mask-image: url("../svg/calendar.svg");
        mask-image: url("../svg/calendar.svg");
      }
    }
  }
}

.dx-overflow-visible-table {
  .dx-datagrid-rowsview, .dx-scrollable-container, .dx-row > td {
    overflow: visible;
  }
}