.tippy-box {
  background-color: #1D2939;
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
}

.tippy-content {
  padding: 8px 12px;
}
