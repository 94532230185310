.inline-selectpicker-wrapper {
    .rs-picker-default {
        .rs-picker-toggle {
            background: transparent !important;
            border: none !important;
            box-shadow: none !important;
            outline: none !important;
            padding: 0;

            input {
                height: auto;
            }

            &.rs-btn .rs-picker-toggle-caret, &.rs-btn .rs-picker-toggle-clean {
                top: 0;
            }
        }
    }
}