$icons: (
  "botique-hotels",
  "building",
  "bungalovs",
  "calendar",
  "car",
  "credit-card",
  "farmhouses",
  "lux-hotels",
  "person",
  "plane",
  "rotate-ccw",
  "search",
  "shield",
  "ski-hotels",
  "special_days",
  "suitcase",
  "villages",
  "maximize-2",
  "help-circle",
  "user"
);
.icon {
  display: inline-block;
  mask-size: contain;
  mask-position: center center;
  -webkit-mask-position: center center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  @each $name in $icons {
    &.icon-#{$name} {
      -webkit-mask-image: url("../../assets/svg/b2c/#{$name}.svg");
      mask-image: url("../../assets/svg/b2c/#{$name}.svg");
    }
  }
}
