.rs-theme-light,
:root {
  --rs-gray-50: #f7f7fa;
  --rs-gray-100: #f2f2f5;
  --rs-gray-200: #EAECF0;
  --rs-gray-300: #d9d9d9;
  --rs-gray-400: #c5c6c7;
  --rs-gray-500: #a6a6a6;
  --rs-gray-600: #8e8e93;
  --rs-gray-700: #7a7a7a;
  --rs-gray-800: #575757;
  --rs-gray-900: #272c36;
  --rs-primary-50: #f2faff;
  --rs-primary-100: #cce9ff;
  --rs-primary-200: #a6d7ff;
  --rs-primary-300: #80c4ff;
  --rs-primary-400: #59afff;
  --rs-primary-500: #3498ff;
  --rs-primary-600: #2589f5;
  --rs-primary-700: #1675e0;
  --rs-primary-800: #0a5dc2;
  --rs-primary-900: #004299;
  --rs-red-50: #fff2f2;
  --rs-red-100: #fccfcf;
  --rs-red-200: #faa9a7;
  --rs-red-300: #fa8682;
  --rs-red-400: #f7635c;
  --rs-red-500: #f44336;
  --rs-red-600: #eb3626;
  --rs-red-700: #d62915;
  --rs-red-800: #b81c07;
  --rs-red-900: #8f1300;
  --rs-orange-50: #fff8f2;
  --rs-orange-100: #ffdfc2;
  --rs-orange-200: #fcc690;
  --rs-orange-300: #fcb160;
  --rs-orange-400: #fa9b2f;
  --rs-orange-500: #fa8900;
  --rs-orange-600: #f08800;
  --rs-orange-700: #db8000;
  --rs-orange-800: #bd7100;
  --rs-orange-900: #945b00;
  --rs-yellow-50: #fffaf2;
  --rs-yellow-100: #ffe9c2;
  --rs-yellow-200: #ffd991;
  --rs-yellow-300: #ffca61;
  --rs-yellow-400: #ffbe30;
  --rs-yellow-500: #ffb300;
  --rs-yellow-600: #f5af00;
  --rs-yellow-700: #e0a500;
  --rs-yellow-800: #c29100;
  --rs-yellow-900: #997500;
  --rs-green-50: #eeffed;
  --rs-green-100: #c8f0c7;
  --rs-green-200: #a5e0a4;
  --rs-green-300: #82cf82;
  --rs-green-400: #65bf67;
  --rs-green-500: #4caf50;
  --rs-green-600: #37ab3c;
  --rs-green-700: #22a12a;
  --rs-green-800: #0f9119;
  --rs-green-900: #007d0c;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #f0f9ff;
  --rs-blue-100: #c5e7fc;
  --rs-blue-200: #9bd4fa;
  --rs-blue-300: #72c0f7;
  --rs-blue-400: #49abf5;
  --rs-blue-500: #2196f3;
  --rs-blue-600: #1787e8;
  --rs-blue-700: #0d73d4;
  --rs-blue-800: #045cb5;
  --rs-blue-900: #00448c;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #4caf50;
  --rs-state-info: #2196f3;
  --rs-state-warning: #ffb300;
  --rs-state-error: #f44336;
  --rs-body: #fff;
  --rs-bg-success: #edfae1;
  --rs-bg-info: #e9f5fe;
  --rs-bg-warning: #fff9e6;
  --rs-bg-error: #fde9ef;
  --rs-text-link: #1675e0;
  --rs-text-link-hover: #0a5dc2;
  --rs-text-link-active: #004299;
  --rs-text-primary: #575757;
  --rs-text-secondary: #8e8e93;
  --rs-text-tertiary: #a6a6a6;
  --rs-text-heading: #272c36;
  --rs-text-inverse: #f7f7fa;
  --rs-text-heading-inverse: #fff;
  --rs-text-active: #1675e0;
  --rs-text-disabled: #c5c6c7;
  --rs-text-error: #f44336;
  --rs-border-primary: #EAECF0;
  --rs-border-secondary: #f2f2f5;
  --rs-bg-card: #fff;
  --rs-bg-overlay: #fff;
  --rs-bg-well: #f7f7fa;
  --rs-bg-active: #3498ff;
  --rs-bg-backdrop: rgba(39, 44, 54, 0.3);
  --rs-state-hover-bg: #f2faff;
  --rs-color-focus-ring: rgba(52, 152, 255, 0.25);
  --rs-state-focus-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  --rs-state-focus-outline: 3px solid rgba(52, 152, 255, 0.25);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: #f7f7fa;
  --rs-btn-default-text: #575757;
  --rs-btn-default-hover-bg: #EAECF0;
  --rs-btn-default-active-bg: #d9d9d9;
  --rs-btn-default-active-text: #272c36;
  --rs-btn-default-disabled-bg: #f7f7fa;
  --rs-btn-default-disabled-text: #c5c6c7;
  --rs-btn-primary-bg: #3498ff;
  --rs-btn-primary-text: #fff;
  --rs-btn-primary-hover-bg: #2589f5;
  --rs-btn-primary-active-bg: #1675e0;
  --rs-btn-subtle-text: #8e8e93;
  --rs-btn-subtle-hover-bg: #f7f7fa;
  --rs-btn-subtle-hover-text: #575757;
  --rs-btn-subtle-active-bg: #EAECF0;
  --rs-btn-subtle-active-text: #272c36;
  --rs-btn-subtle-disabled-text: #c5c6c7;
  --rs-btn-ghost-border: #1675e0;
  --rs-btn-ghost-text: #1675e0;
  --rs-btn-ghost-hover-border: #0a5dc2;
  --rs-btn-ghost-hover-text: #0a5dc2;
  --rs-btn-ghost-active-border: #004299;
  --rs-btn-ghost-active-text: #004299;
  --rs-btn-link-text: #1675e0;
  --rs-btn-link-hover-text: #0a5dc2;
  --rs-btn-link-active-text: #004299;
  --rs-iconbtn-addon: #f2f2f5;
  --rs-iconbtn-activated-addon: #d9d9d9;
  --rs-iconbtn-pressed-addon: #c5c6c7;
  --rs-iconbtn-primary-addon: #2589f5;
  --rs-iconbtn-primary-activated-addon: #1675e0;
  --rs-iconbtn-primary-pressed-addon: #0a5dc2;
  --rs-divider-border: #EAECF0;
  --rs-loader-ring: rgba(247, 247, 250, 0.8);
  --rs-loader-rotor: #a6a6a6;
  --rs-loader-backdrop: hsla(0, 0%, 100%, 0.9);
  --rs-loader-ring-inverse: rgba(247, 247, 250, 0.3);
  --rs-loader-rotor-inverse: #fff;
  --rs-loader-backdrop-inverse: rgba(39, 44, 54, 0.83);
  --rs-message-success-header: var(--rs-text-heading);
  --rs-message-success-text: var(--rs-text-primary);
  --rs-message-success-icon: #4caf50;
  --rs-message-success-bg: #eeffed;
  --rs-message-info-header: var(--rs-text-heading);
  --rs-message-info-text: var(--rs-text-primary);
  --rs-message-info-icon: #2196f3;
  --rs-message-info-bg: #f0f9ff;
  --rs-message-warning-header: var(--rs-text-heading);
  --rs-message-warning-text: var(--rs-text-primary);
  --rs-message-warning-icon: #ffb300;
  --rs-message-warning-bg: #fffaf2;
  --rs-message-error-header: var(--rs-text-heading);
  --rs-message-error-text: var(--rs-text-primary);
  --rs-message-error-icon: #f44336;
  --rs-message-error-bg: #fff2f2;
  --rs-tooltip-bg: #272c36;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #EAECF0;
  --rs-progress-bar: #3498ff;
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #f2f2f5;
  --rs-placeholder-active: #EAECF0;
  --rs-dropdown-divider: #EAECF0;
  --rs-dropdown-item-bg-hover: rgba(204, 233, 255, 0.5);
  --rs-dropdown-item-bg-active: #f2faff;
  --rs-dropdown-item-text-active: #1675e0;
  --rs-dropdown-header-text: #a6a6a6;
  --rs-dropdown-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  --rs-menuitem-active-bg: rgba(204, 233, 255, 0.5);
  --rs-menuitem-active-text: #1675e0;
  --rs-steps-border: #8e8e93;
  --rs-steps-state-finish: #3498ff;
  --rs-steps-border-state-finish: #3498ff;
  --rs-steps-state-wait: #8e8e93;
  --rs-steps-state-process: #3498ff;
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: #3498ff;
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #8e8e93;
  --rs-navs-text-hover: #575757;
  --rs-navs-bg-hover: #EAECF0;
  --rs-navs-text-active: #272c36;
  --rs-navs-bg-active: #EAECF0;
  --rs-navs-tab-border: #d9d9d9;
  --rs-navs-subtle-border: #f7f7fa;
  --rs-navs-selected: #1675e0;
  --rs-navbar-default-bg: #f7f7fa;
  --rs-navbar-default-text: #575757;
  --rs-navbar-default-selected-text: #1675e0;
  --rs-navbar-default-hover-bg: #EAECF0;
  --rs-navbar-default-hover-text: #575757;
  --rs-navbar-inverse-bg: #3498ff;
  --rs-navbar-inverse-text: #fff;
  --rs-navbar-inverse-selected-bg: #1675e0;
  --rs-navbar-inverse-hover-bg: #2589f5;
  --rs-navbar-inverse-hover-text: #fff;
  --rs-navbar-subtle-bg: #fff;
  --rs-navbar-subtle-text: #8e8e93;
  --rs-navbar-subtle-selected-text: #1675e0;
  --rs-navbar-subtle-hover-bg: #f7f7fa;
  --rs-navbar-subtle-hover-text: #575757;
  --rs-sidenav-default-bg: #f7f7fa;
  --rs-sidenav-default-text: #575757;
  --rs-sidenav-default-selected-text: #1675e0;
  --rs-sidenav-default-hover-bg: #EAECF0;
  --rs-sidenav-default-hover-text: #575757;
  --rs-sidenav-default-footer-border: #EAECF0;
  --rs-sidenav-inverse-bg: #3498ff;
  --rs-sidenav-inverse-text: #fff;
  --rs-sidenav-inverse-selected-bg: #1675e0;
  --rs-sidenav-inverse-hover-bg: #2589f5;
  --rs-sidenav-inverse-footer-border: #2589f5;
  --rs-sidenav-subtle-bg: #fff;
  --rs-sidenav-subtle-text: #8e8e93;
  --rs-sidenav-subtle-selected-text: #1675e0;
  --rs-sidenav-subtle-hover-bg: #f7f7fa;
  --rs-sidenav-subtle-hover-text: #575757;
  --rs-sidenav-subtle-footer-border: #EAECF0;
  --rs-input-bg: #fff;
  --rs-input-focus-border: #3498ff;
  --rs-input-disabled-bg: #f7f7fa;
  --rs-listbox-option-hover-bg: rgba(204, 233, 255, 0.5);
  --rs-listbox-option-hover-text: #1675e0;
  --rs-listbox-option-selected-text: #1675e0;
  --rs-listbox-option-selected-bg: #f2faff;
  --rs-listbox-option-disabled-text: #c5c6c7;
  --rs-listbox-option-disabled-selected-text: #a6d7ff;
  --rs-checkbox-icon: #fff;
  --rs-checkbox-border: #d9d9d9;
  --rs-checkbox-checked-bg: #3498ff;
  --rs-checkbox-disabled-bg: #f7f7fa;
  --rs-radio-marker: #fff;
  --rs-radio-border: #d9d9d9;
  --rs-radio-checked-bg: #3498ff;
  --rs-radio-disabled-bg: #f7f7fa;
  --rs-rate-symbol: #8e8e93;
  --rs-rate-symbol-checked: #ffb300;
  --rs-toggle-bg: #d9d9d9;
  --rs-toggle-thumb: #fff;
  --rs-toggle-loader-ring: rgba(247, 247, 250, 0.3);
  --rs-toggle-loader-rotor: #fff;
  --rs-toggle-hover-bg: #c5c6c7;
  --rs-toggle-disabled-bg: #f7f7fa;
  --rs-toggle-disabled-thumb: #fff;
  --rs-toggle-checked-bg: #3498ff;
  --rs-toggle-checked-thumb: #fff;
  --rs-toggle-checked-hover-bg: #2589f5;
  --rs-toggle-checked-disabled-bg: #cce9ff;
  --rs-toggle-checked-disabled-thumb: #fff;
  --rs-slider-bar: #f2f2f5;
  --rs-slider-hover-bar: #EAECF0;
  --rs-slider-thumb-border: #3498ff;
  --rs-slider-thumb-bg: #fff;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
  --rs-slider-progress: #3498ff;
  --rs-uploader-item-bg: #d9d9d9;
  --rs-uploader-item-hover-bg: #f7f7fa;
  --rs-uploader-overlay-bg: hsla(0, 0%, 100%, 0.8);
  --rs-uploader-dnd-bg: #fff;
  --rs-uploader-dnd-border: #EAECF0;
  --rs-uploader-dnd-hover-border: #3498ff;
  --rs-avatar-bg: #d9d9d9;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #f7f7fa;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #8e8e93;
  --rs-carousel-indicator: hsla(0, 0%, 100%, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: #3498ff;
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-list-bg: #fff;
  --rs-list-border: #EAECF0;
  --rs-list-hover-bg: #f2faff;
  --rs-list-placeholder-bg: rgba(242, 250, 255, 0.5);
  --rs-list-placeholder-border: #3498ff;
  --rs-timeline-indicator-bg: #d9d9d9;
  --rs-timeline-indicator-active-bg: #3498ff;
  --rs-table-shadow: rgba(9, 9, 9, 0.08);
  --rs-table-sort: #3498ff;
  --rs-table-resize: #3498ff;
  --rs-table-scrollbar-track: #EAECF0;
  --rs-table-scrollbar-thumb: #575757;
  --rs-table-scrollbar-thumb-active: #272c36;
  --rs-table-scrollbar-vertical-track: rgba(229, 229, 234, 0.4);
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-form-errormessage-text: #f44336;
  --rs-form-errormessage-bg: #fff;
  --rs-form-errormessage-border: #EAECF0;
  --rs-picker-value: #1675e0;
  --rs-picker-count-bg: #3498ff;
  --rs-picker-count-text: #fff;
  --rs-calendar-today-bg: #3498ff;
  --rs-calendar-today-text: #fff;
  --rs-calendar-range-bg: rgba(204, 233, 255, 0.5);
  --rs-calendar-time-unit-bg: #f7f7fa;
  --rs-calendar-date-selected-text: #fff;
  --rs-popover-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
}

.rs-theme-dark {
  --rs-gray-50: #e9ebf0;
  --rs-gray-100: #cbced4;
  --rs-gray-200: #a4a9b3;
  --rs-gray-300: #858b94;
  --rs-gray-400: #6a6f76;
  --rs-gray-500: #5c6066;
  --rs-gray-600: #3c3f43;
  --rs-gray-700: #292d33;
  --rs-gray-800: #1a1d24;
  --rs-gray-900: #0f131a;
  --rs-primary-50: #f2fcff;
  --rs-primary-100: #ccf3ff;
  --rs-primary-200: #a6e9ff;
  --rs-primary-300: #80ddff;
  --rs-primary-400: #59d0ff;
  --rs-primary-500: #34c3ff;
  --rs-primary-600: #25b3f5;
  --rs-primary-700: #169de0;
  --rs-primary-800: #0a81c2;
  --rs-primary-900: #006199;
  --rs-red-50: #ffeded;
  --rs-red-100: #fccaca;
  --rs-red-200: #faa9a7;
  --rs-red-300: #f58884;
  --rs-red-400: #f26a63;
  --rs-red-500: #f04f43;
  --rs-red-600: #e63f30;
  --rs-red-700: #d12f1d;
  --rs-red-800: #b3200c;
  --rs-red-900: #8a1200;
  --rs-orange-50: #fff9f2;
  --rs-orange-100: #ffe2c2;
  --rs-orange-200: #ffce91;
  --rs-orange-300: #ffba61;
  --rs-orange-400: #ffa930;
  --rs-orange-500: #ff9800;
  --rs-orange-600: #f59700;
  --rs-orange-700: #e08e00;
  --rs-orange-800: #c27e00;
  --rs-orange-900: #960;
  --rs-yellow-50: #fff9f0;
  --rs-yellow-100: #ffedd1;
  --rs-yellow-200: #ffe2b3;
  --rs-yellow-300: #ffd894;
  --rs-yellow-400: #ffcf75;
  --rs-yellow-500: #ffc757;
  --rs-yellow-600: #f5bb3d;
  --rs-yellow-700: #e0a824;
  --rs-yellow-800: #c28f0e;
  --rs-yellow-900: #997000;
  --rs-green-50: #f3fff2;
  --rs-green-100: #cff0ce;
  --rs-green-200: #aee0ad;
  --rs-green-300: #8ccf8c;
  --rs-green-400: #71bf72;
  --rs-green-500: #58b15b;
  --rs-green-600: #3fab45;
  --rs-green-700: #27a12f;
  --rs-green-800: #10911b;
  --rs-green-900: #007d0c;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #edf9ff;
  --rs-blue-100: #c0e8fc;
  --rs-blue-200: #93d6fa;
  --rs-blue-300: #67c1f5;
  --rs-blue-400: #3dadf2;
  --rs-blue-500: #1499ef;
  --rs-blue-600: #0e8ce6;
  --rs-blue-700: #087ad1;
  --rs-blue-800: #0464b3;
  --rs-blue-900: #00498a;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #58b15b;
  --rs-state-info: #1499ef;
  --rs-state-warning: #ffc757;
  --rs-state-error: #f04f43;
  --rs-body: #0f131a;
  --rs-text-link: #34c3ff;
  --rs-text-link-hover: #59d0ff;
  --rs-text-link-active: #80ddff;
  --rs-text-primary: #e9ebf0;
  --rs-text-secondary: #a4a9b3;
  --rs-text-tertiary: #858b94;
  --rs-text-heading: #fff;
  --rs-text-inverse: #1a1d24;
  --rs-text-heading-inverse: #0f131a;
  --rs-text-active: #34c3ff;
  --rs-text-disabled: #5c6066;
  --rs-border-primary: #3c3f43;
  --rs-border-secondary: #292d33;
  --rs-bg-card: #1a1d24;
  --rs-bg-overlay: #292d33;
  --rs-bg-well: #0f131a;
  --rs-bg-active: #169de0;
  --rs-bg-backdrop: rgba(15, 19, 26, 0.8);
  --rs-state-hover-bg: #3c3f43;
  --rs-color-focus-ring: 0 0 0 3px rgba(52, 195, 255, 0.25);
  --rs-state-focus-shadow: 0 0 0 3px rgba(52, 195, 255, 0.25);
  --rs-state-focus-outline: 3px solid rgba(52, 195, 255, 0.25);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: #3c3f43;
  --rs-btn-default-text: #e9ebf0;
  --rs-btn-default-hover-bg: #5c6066;
  --rs-btn-default-active-bg: #858b94;
  --rs-btn-default-active-text: #fff;
  --rs-btn-default-disabled-bg: #3c3f43;
  --rs-btn-default-disabled-text: #5c6066;
  --rs-btn-primary-bg: #169de0;
  --rs-btn-primary-text: #fff;
  --rs-btn-primary-hover-bg: #25b3f5;
  --rs-btn-primary-active-bg: #59d0ff;
  --rs-btn-subtle-text: #a4a9b3;
  --rs-btn-subtle-hover-bg: #292d33;
  --rs-btn-subtle-hover-text: #e9ebf0;
  --rs-btn-subtle-active-bg: #6a6f76;
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-subtle-disabled-text: #5c6066;
  --rs-btn-ghost-border: #34c3ff;
  --rs-btn-ghost-text: #34c3ff;
  --rs-btn-ghost-hover-border: #59d0ff;
  --rs-btn-ghost-hover-text: #59d0ff;
  --rs-btn-ghost-active-border: #a6e9ff;
  --rs-btn-ghost-active-text: #a6e9ff;
  --rs-btn-link-text: #34c3ff;
  --rs-btn-link-hover-text: #59d0ff;
  --rs-btn-link-active-text: #a6e9ff;
  --rs-iconbtn-addon: #5c6066;
  --rs-iconbtn-activated-addon: #6a6f76;
  --rs-iconbtn-pressed-addon: #a4a9b3;
  --rs-iconbtn-primary-addon: #25b3f5;
  --rs-iconbtn-primary-activated-addon: #34c3ff;
  --rs-iconbtn-primary-pressed-addon: #59d0ff;
  --rs-divider-border: #3c3f43;
  --rs-loader-ring: rgba(233, 235, 240, 0.3);
  --rs-loader-rotor: #fff;
  --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
  --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
  --rs-loader-rotor-inverse: #5c6066;
  --rs-loader-backdrop-inverse: hsla(0, 0%, 100%, 0.9);
  --rs-message-success-header: #fff;
  --rs-message-success-text: #fff;
  --rs-message-success-icon: #fff;
  --rs-message-success-bg: #4caf50;
  --rs-message-info-header: #fff;
  --rs-message-info-text: #fff;
  --rs-message-info-icon: #fff;
  --rs-message-info-bg: #2196f3;
  --rs-message-warning-header: #0f131a;
  --rs-message-warning-text: #0f131a;
  --rs-message-warning-icon: #0f131a;
  --rs-message-warning-bg: #ffb300;
  --rs-message-error-header: #fff;
  --rs-message-error-text: #fff;
  --rs-message-error-icon: #fff;
  --rs-message-error-bg: #f44336;
  --rs-tooltip-bg: #5c6066;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #292d33;
  --rs-progress-bar: #34c3ff;
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #3c3f43;
  --rs-placeholder-active: #484c50;
  --rs-dropdown-divider: #3c3f43;
  --rs-dropdown-item-bg-hover: #3c3f43;
  --rs-dropdown-item-bg-active: rgba(0, 97, 153, 0.2);
  --rs-dropdown-item-text-active: #34c3ff;
  --rs-dropdown-header-text: #5c6066;
  --rs-dropdown-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2), 0 4px 4px 3px rgba(0, 0, 0, 0.24);
  --rs-menuitem-active-bg: #3c3f43;
  --rs-menuitem-active-text: currentColor;
  --rs-steps-border: #a4a9b3;
  --rs-steps-state-finish: #34c3ff;
  --rs-steps-border-state-finish: #34c3ff;
  --rs-steps-state-wait: #a4a9b3;
  --rs-steps-state-process: #169de0;
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: #34c3ff;
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #a4a9b3;
  --rs-navs-text-hover: #cbced4;
  --rs-navs-bg-hover: #6a6f76;
  --rs-navs-text-active: #fff;
  --rs-navs-bg-active: #6a6f76;
  --rs-navs-tab-border: #3c3f43;
  --rs-navs-subtle-border: #3c3f43;
  --rs-navs-selected: #34c3ff;
  --rs-navbar-default-bg: #1a1d24;
  --rs-navbar-default-text: #a4a9b3;
  --rs-navbar-default-selected-text: #34c3ff;
  --rs-navbar-default-hover-bg: #292d33;
  --rs-navbar-default-hover-text: #e9ebf0;
  --rs-navbar-inverse-bg: #169de0;
  --rs-navbar-inverse-text: #fff;
  --rs-navbar-inverse-selected-bg: #59d0ff;
  --rs-navbar-inverse-hover-bg: #25b3f5;
  --rs-navbar-inverse-hover-text: #fff;
  --rs-navbar-subtle-bg: transparent;
  --rs-navbar-subtle-text: #a4a9b3;
  --rs-navbar-subtle-selected-text: #34c3ff;
  --rs-navbar-subtle-hover-bg: #292d33;
  --rs-navbar-subtle-hover-text: #e9ebf0;
  --rs-sidenav-default-bg: #1a1d24;
  --rs-sidenav-default-text: #a4a9b3;
  --rs-sidenav-default-selected-text: #34c3ff;
  --rs-sidenav-default-hover-bg: #292d33;
  --rs-sidenav-default-hover-text: #e9ebf0;
  --rs-sidenav-default-footer-border: #3c3f43;
  --rs-sidenav-inverse-bg: #169de0;
  --rs-sidenav-inverse-text: #fff;
  --rs-sidenav-inverse-selected-bg: #59d0ff;
  --rs-sidenav-inverse-hover-bg: #25b3f5;
  --rs-sidenav-inverse-footer-border: #25b3f5;
  --rs-sidenav-subtle-bg: transparent;
  --rs-sidenav-subtle-text: #a4a9b3;
  --rs-sidenav-subtle-selected-text: #34c3ff;
  --rs-sidenav-subtle-hover-bg: #292d33;
  --rs-sidenav-subtle-hover-text: #e9ebf0;
  --rs-sidenav-subtle-footer-border: #3c3f43;
  --rs-input-bg: #1a1d24;
  --rs-input-focus-border: #34c3ff;
  --rs-input-disabled-bg: #292d33;
  --rs-listbox-option-hover-bg: #3c3f43;
  --rs-listbox-option-hover-text: currentColor;
  --rs-listbox-option-selected-text: #34c3ff;
  --rs-listbox-option-selected-bg: rgba(0, 97, 153, 0.2);
  --rs-listbox-option-disabled-text: #5c6066;
  --rs-listbox-option-disabled-selected-text: #a6e9ff;
  --rs-checkbox-icon: #1a1d24;
  --rs-checkbox-border: #6a6f76;
  --rs-checkbox-checked-bg: #34c3ff;
  --rs-checkbox-disabled-bg: #5c6066;
  --rs-radio-marker: #1a1d24;
  --rs-radio-border: #6a6f76;
  --rs-radio-checked-bg: #34c3ff;
  --rs-radio-disabled-bg: #5c6066;
  --rs-rate-symbol: #3c3f43;
  --rs-rate-symbol-checked: #ffb300;
  --rs-toggle-bg: #6a6f76;
  --rs-toggle-thumb: #fff;
  --rs-toggle-hover-bg: #858b94;
  --rs-toggle-disabled-bg: #3c3f43;
  --rs-toggle-disabled-thumb: #5c6066;
  --rs-toggle-checked-bg: #169de0;
  --rs-toggle-checked-thumb: #fff;
  --rs-toggle-checked-hover-bg: #25b3f5;
  --rs-toggle-checked-disabled-bg: #006199;
  --rs-toggle-checked-disabled-thumb: #858b94;
  --rs-slider-bar: #3c3f43;
  --rs-slider-hover-bar: #3c3f43;
  --rs-slider-thumb-border: #34c3ff;
  --rs-slider-thumb-bg: #292d33;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 195, 255, 0.25);
  --rs-slider-progress: #34c3ff;
  --rs-uploader-item-bg: #858b94;
  --rs-uploader-item-hover-bg: #3c3f43;
  --rs-uploader-overlay-bg: rgba(60, 63, 67, 0.8);
  --rs-uploader-dnd-bg: #292d33;
  --rs-uploader-dnd-border: #a4a9b3;
  --rs-uploader-dnd-hover-border: #34c3ff;
  --rs-avatar-bg: #6a6f76;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #3c3f43;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #3c3f43;
  --rs-carousel-indicator: hsla(0, 0%, 100%, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: #34c3ff;
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-list-bg: #0f131a;
  --rs-list-border: #292d33;
  --rs-list-hover-bg: #3c3f43;
  --rs-list-placeholder-bg: rgba(0, 97, 153, 0.2);
  --rs-list-placeholder-border: #34c3ff;
  --rs-timeline-indicator-bg: #5c6066;
  --rs-timeline-indicator-active-bg: #34c3ff;
  --rs-table-shadow: rgba(9, 9, 9, 0.99);
  --rs-table-sort: #34c3ff;
  --rs-table-resize: #34c3ff;
  --rs-table-scrollbar-track: #292d33;
  --rs-table-scrollbar-thumb: #a4a9b3;
  --rs-table-scrollbar-thumb-active: #cbced4;
  --rs-table-scrollbar-vertical-track: #292d33;
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-form-errormessage-text: #fff;
  --rs-form-errormessage-bg: #f44336;
  --rs-form-errormessage-border: #f44336;
  --rs-picker-value: #34c3ff;
  --rs-picker-count-bg: #169de0;
  --rs-picker-count-text: #fff;
  --rs-calendar-today-bg: #169de0;
  --rs-calendar-today-text: #fff;
  --rs-calendar-range-bg: rgba(0, 97, 153, 0.5);
  --rs-calendar-time-unit-bg: #3c3f43;
  --rs-calendar-date-selected-text: #fff;
  --rs-popover-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.rs-theme-high-contrast {
  --rs-gray-50: #e9ebf0;
  --rs-gray-100: #cbced4;
  --rs-gray-200: #a4a9b3;
  --rs-gray-300: #858b94;
  --rs-gray-400: #6a6f76;
  --rs-gray-500: #5c6066;
  --rs-gray-600: #3c3f43;
  --rs-gray-700: #292d33;
  --rs-gray-800: #1a1d24;
  --rs-gray-900: #0f131a;
  --rs-primary-50: #fffef2;
  --rs-primary-100: #fffbc2;
  --rs-primary-200: #fffa91;
  --rs-primary-300: #fffa61;
  --rs-primary-400: #fffc30;
  --rs-primary-500: #ff0;
  --rs-primary-600: #f1f500;
  --rs-primary-700: #d9e000;
  --rs-primary-800: #b8c200;
  --rs-primary-900: #8f9900;
  --rs-red-50: #fff2f5;
  --rs-red-100: #f2c2cd;
  --rs-red-200: #e691a3;
  --rs-red-300: #d6637a;
  --rs-red-400: #c93a55;
  --rs-red-500: #bd1732;
  --rs-red-600: #b51029;
  --rs-red-700: #a60a1f;
  --rs-red-800: #8f0414;
  --rs-red-900: #70000b;
  --rs-orange-50: #fff9f2;
  --rs-orange-100: #ffe2c2;
  --rs-orange-200: #ffce91;
  --rs-orange-300: #ffba61;
  --rs-orange-400: #ffa930;
  --rs-orange-500: #ff9800;
  --rs-orange-600: #f59700;
  --rs-orange-700: #e08e00;
  --rs-orange-800: #c27e00;
  --rs-orange-900: #960;
  --rs-yellow-50: #fff9f0;
  --rs-yellow-100: #ffedd1;
  --rs-yellow-200: #ffe2b3;
  --rs-yellow-300: #ffd894;
  --rs-yellow-400: #ffcf75;
  --rs-yellow-500: #ffc757;
  --rs-yellow-600: #f5bb3d;
  --rs-yellow-700: #e0a824;
  --rs-yellow-800: #c28f0e;
  --rs-yellow-900: #997000;
  --rs-green-50: #f2fff5;
  --rs-green-100: #b3e6bd;
  --rs-green-200: #7ccc8e;
  --rs-green-300: #50b567;
  --rs-green-400: #2a9c46;
  --rs-green-500: #0d822c;
  --rs-green-600: #09802b;
  --rs-green-700: #057a28;
  --rs-green-800: #017325;
  --rs-green-900: #006923;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #edf9ff;
  --rs-blue-100: #c0e8fc;
  --rs-blue-200: #93d6fa;
  --rs-blue-300: #67c1f5;
  --rs-blue-400: #3dadf2;
  --rs-blue-500: #1499ef;
  --rs-blue-600: #0e8ce6;
  --rs-blue-700: #087ad1;
  --rs-blue-800: #0464b3;
  --rs-blue-900: #00498a;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #0d822c;
  --rs-state-info: #1499ef;
  --rs-state-warning: #ffc757;
  --rs-state-error: #bd1732;
  --rs-body: #0f131a;
  --rs-text-link: #ff0;
  --rs-text-link-hover: #fffc30;
  --rs-text-link-active: #fffa61;
  --rs-text-primary: #e9ebf0;
  --rs-text-secondary: #a4a9b3;
  --rs-text-tertiary: #858b94;
  --rs-text-heading: #fff;
  --rs-text-inverse: #1a1d24;
  --rs-text-heading-inverse: #0f131a;
  --rs-text-active: #ff0;
  --rs-text-disabled: #5c6066;
  --rs-border-primary: #cbced4;
  --rs-border-secondary: #292d33;
  --rs-bg-card: #1a1d24;
  --rs-bg-overlay: #1a1d24;
  --rs-bg-well: #0f131a;
  --rs-bg-active: #ff0;
  --rs-bg-backdrop: rgba(15, 19, 26, 0.8);
  --rs-state-hover-bg: #3c3f43;
  --rs-color-focus-ring: #fff;
  --rs-state-focus-shadow: 0 0 0 3px #0f131a, 0 0 0 5px #fff;
  --rs-state-focus-shadow-slim: 0 0 0 2px #fff;
  --rs-state-focus-outline: 3px solid rgba(255, 255, 0, 0.25);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: transparent;
  --rs-btn-default-text: #ff0;
  --rs-btn-default-border: 1px solid #ff0;
  --rs-btn-default-hover-bg: transparent;
  --rs-btn-default-hover-text: #fffc30;
  --rs-btn-default-active-bg: transparent;
  --rs-btn-default-active-text: #fffa91;
  --rs-btn-default-disabled-bg: transparent;
  --rs-btn-default-disabled-text: #ff0;
  --rs-btn-primary-bg: #ff0;
  --rs-btn-primary-text: #0f131a;
  --rs-btn-primary-hover-bg: #fffc30;
  --rs-btn-primary-active-bg: #fffa91;
  --rs-btn-subtle-text: #ff0;
  --rs-btn-subtle-hover-bg: transparent;
  --rs-btn-subtle-hover-text: #fffc30;
  --rs-btn-subtle-active-bg: transparent;
  --rs-btn-subtle-active-text: #fffa91;
  --rs-btn-subtle-disabled-text: #5c6066;
  --rs-btn-ghost-border: #ff0;
  --rs-btn-ghost-text: #ff0;
  --rs-btn-ghost-hover-border: #fffc30;
  --rs-btn-ghost-hover-text: #fffc30;
  --rs-btn-ghost-active-border: #fffa91;
  --rs-btn-ghost-active-text: #fffa91;
  --rs-btn-link-text: #ff0;
  --rs-btn-link-hover-text: #fffc30;
  --rs-btn-link-active-text: #fffa91;
  --rs-iconbtn-addon: transparent;
  --rs-iconbtn-activated-addon: transparent;
  --rs-iconbtn-pressed-addon: transparent;
  --rs-iconbtn-primary-addon: #fffc30;
  --rs-iconbtn-primary-activated-addon: #fffa61;
  --rs-iconbtn-primary-pressed-addon: #fffbc2;
  --rs-divider-border: #3c3f43;
  --rs-loader-ring: rgba(233, 235, 240, 0.3);
  --rs-loader-rotor: #fff;
  --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
  --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
  --rs-loader-rotor-inverse: #5c6066;
  --rs-loader-backdrop-inverse: hsla(0, 0%, 100%, 0.9);
  --rs-message-success-header: #fff;
  --rs-message-success-text: #fff;
  --rs-message-success-icon: #fff;
  --rs-message-success-bg: #007d0c;
  --rs-message-success-border: #82cf82;
  --rs-message-info-header: #fff;
  --rs-message-info-text: #fff;
  --rs-message-info-icon: #fff;
  --rs-message-info-bg: #00448c;
  --rs-message-info-border: #2196f3;
  --rs-message-warning-header: #fff;
  --rs-message-warning-text: #fff;
  --rs-message-warning-icon: #fff;
  --rs-message-warning-bg: #997500;
  --rs-message-warning-border: #ffb300;
  --rs-message-error-header: #fff;
  --rs-message-error-text: #fff;
  --rs-message-error-icon: #fff;
  --rs-message-error-bg: #8f1300;
  --rs-message-error-border: #fa8682;
  --rs-tooltip-bg: #1a1d24;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #292d33;
  --rs-progress-bar: #ff0;
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #3c3f43;
  --rs-placeholder-active: #484c50;
  --rs-dropdown-divider: #3c3f43;
  --rs-dropdown-item-bg-hover: #3c3f43;
  --rs-dropdown-item-bg-active: rgba(143, 153, 0, 0.2);
  --rs-dropdown-item-text-active: #ff0;
  --rs-dropdown-header-text: #5c6066;
  --rs-dropdown-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2), 0 4px 4px 3px rgba(0, 0, 0, 0.24);
  --rs-menuitem-active-bg: transparent;
  --rs-menuitem-active-text: #ff0;
  --rs-steps-border: #a4a9b3;
  --rs-steps-state-finish: #ff0;
  --rs-steps-border-state-finish: #ff0;
  --rs-steps-state-wait: #a4a9b3;
  --rs-steps-state-process: #d9e000;
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: #ff0;
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #a4a9b3;
  --rs-navs-text-hover: #ff0;
  --rs-navs-bg-hover: transparent;
  --rs-navs-text-active: #ff0;
  --rs-navs-bg-active: #6a6f76;
  --rs-navs-tab-border: #3c3f43;
  --rs-navs-subtle-border: #3c3f43;
  --rs-navs-selected: #ff0;
  --rs-navbar-default-bg: #1a1d24;
  --rs-navbar-default-text: #e9ebf0;
  --rs-navbar-default-selected-text: #ff0;
  --rs-navbar-default-hover-bg: transparent;
  --rs-navbar-default-hover-text: #ff0;
  --rs-navbar-inverse-bg: #1a1d24;
  --rs-navbar-inverse-text: #e9ebf0;
  --rs-navbar-inverse-selected-bg: transparent;
  --rs-navbar-inverse-selected-text: #ff0;
  --rs-navbar-inverse-hover-bg: transparent;
  --rs-navbar-inverse-hover-text: #ff0;
  --rs-navbar-subtle-bg: #1a1d24;
  --rs-navbar-subtle-text: #e9ebf0;
  --rs-navbar-subtle-selected-text: #ff0;
  --rs-navbar-subtle-hover-bg: transparent;
  --rs-navbar-subtle-hover-text: #ff0;
  --rs-sidenav-default-bg: #1a1d24;
  --rs-sidenav-default-text: #e9ebf0;
  --rs-sidenav-default-selected-text: #ff0;
  --rs-sidenav-default-hover-bg: transparent;
  --rs-sidenav-default-hover-text: #ff0;
  --rs-sidenav-default-footer-border: #e9ebf0;
  --rs-sidenav-inverse-bg: #1a1d24;
  --rs-sidenav-inverse-text: #e9ebf0;
  --rs-sidenav-inverse-selected-bg: transparent;
  --rs-sidenav-inverse-selected-text: #ff0;
  --rs-sidenav-inverse-hover-bg: transparent;
  --rs-sidenav-inverse-footer-border: #e9ebf0;
  --rs-sidenav-subtle-bg: #1a1d24;
  --rs-sidenav-subtle-text: #e9ebf0;
  --rs-sidenav-subtle-selected-text: #ff0;
  --rs-sidenav-subtle-hover-bg: transparent;
  --rs-sidenav-subtle-hover-text: #ff0;
  --rs-sidenav-subtle-footer-border: #e9ebf0;
  --rs-input-bg: #1a1d24;
  --rs-input-focus-border: #ff0;
  --rs-input-disabled-bg: #292d33;
  --rs-listbox-option-hover-bg: transparent;
  --rs-listbox-option-hover-text: #ff0;
  --rs-listbox-option-selected-text: #ff0;
  --rs-listbox-option-selected-bg: transparent;
  --rs-listbox-option-disabled-text: #5c6066;
  --rs-listbox-option-disabled-selected-text: #fffa91;
  --rs-checkbox-icon: #1a1d24;
  --rs-checkbox-border: #cbced4;
  --rs-checkbox-checked-bg: #ff0;
  --rs-checkbox-disabled-bg: #5c6066;
  --rs-radio-marker: #1a1d24;
  --rs-radio-border: #cbced4;
  --rs-radio-checked-bg: #ff0;
  --rs-radio-disabled-bg: #5c6066;
  --rs-rate-symbol: #cbced4;
  --rs-rate-symbol-checked: #ff0;
  --rs-toggle-bg: #1a1d24;
  --rs-toggle-thumb: #cbced4;
  --rs-toggle-hover-bg: #1a1d24;
  --rs-toggle-disabled-bg: #1a1d24;
  --rs-toggle-disabled-thumb: #858b94;
  --rs-toggle-checked-bg: #ff0;
  --rs-toggle-checked-thumb: #1a1d24;
  --rs-toggle-checked-hover-bg: #fffc30;
  --rs-toggle-checked-disabled-bg: #8f9900;
  --rs-toggle-checked-disabled-thumb: #1a1d24;
  --rs-slider-bar: #3c3f43;
  --rs-slider-hover-bar: #3c3f43;
  --rs-slider-thumb-border: #ff0;
  --rs-slider-thumb-bg: #292d33;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(255, 255, 0, 0.25);
  --rs-slider-progress: #ff0;
  --rs-uploader-item-bg: #858b94;
  --rs-uploader-item-hover-bg: #1a1d24;
  --rs-uploader-item-hover-text: #ff0;
  --rs-uploader-overlay-bg: rgba(60, 63, 67, 0.8);
  --rs-uploader-dnd-bg: #292d33;
  --rs-uploader-dnd-border: #a4a9b3;
  --rs-uploader-dnd-hover-border: #ff0;
  --rs-avatar-bg: #6a6f76;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #3c3f43;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #3c3f43;
  --rs-carousel-indicator: hsla(0, 0%, 100%, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: #ff0;
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-pagination-item-text: #e9ebf0;
  --rs-pagination-item-current-text: #ff0;
  --rs-list-bg: transparent;
  --rs-list-border: #292d33;
  --rs-list-hover-bg: #3c3f43;
  --rs-list-placeholder-bg: rgba(143, 153, 0, 0.2);
  --rs-list-placeholder-border: #ff0;
  --rs-timeline-indicator-bg: #5c6066;
  --rs-timeline-indicator-active-bg: #ff0;
  --rs-table-shadow: rgba(9, 9, 9, 0.99);
  --rs-table-sort: #ff0;
  --rs-table-resize: #ff0;
  --rs-table-scrollbar-track: #292d33;
  --rs-table-scrollbar-thumb: #a4a9b3;
  --rs-table-scrollbar-thumb-active: #cbced4;
  --rs-table-scrollbar-vertical-track: #292d33;
  --rs-drawer-bg: #292d33;
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-border: 1px solid #cbced4;
  --rs-form-errormessage-text: #fff;
  --rs-form-errormessage-bg: #f44336;
  --rs-form-errormessage-border: #f44336;
  --rs-picker-value: #ff0;
  --rs-picker-count-bg: #ff0;
  --rs-picker-count-text: #0f131a;
  --rs-calendar-today-bg: #ff0;
  --rs-calendar-today-text: #0f131a;
  --rs-calendar-range-bg: rgba(143, 153, 0, 0.5);
  --rs-calendar-time-unit-bg: #0f131a;
  --rs-calendar-date-selected-text: #0f131a;
  --rs-popover-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[hidden],
template {
  display: none !important;
}

a {
  background-color: transparent;

  &:active,
  &:hover {
    outline: 0;
  }
}

b,
strong {
  font-weight: 700;
}

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
  border-radius: 0;
  text-transform: none;
}

select {
  text-transform: none;
}

button,
html input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input {
  &[type="reset"],
  &[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }
}

button[disabled],
html input[disabled] {
  cursor: not-allowed;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  line-height: normal;

  &[type="checkbox"],
  &[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }

  &[type="number"] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      height: auto;
    }
  }
}

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes slideOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes slideOutRight {
  0% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideOutRight {
  0% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@-webkit-keyframes slideInTop {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideInTop {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes slideOutTop {
  0% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutTop {
  0% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@-webkit-keyframes slideInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes slideOutBottom {
  0% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutBottom {
  0% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.rs-anim-slide-out {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.rs-anim-slide-in {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.rs-anim-right {
  &.rs-anim-slide-in {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
  }

  &.rs-anim-slide-out {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight;
  }
}

.rs-anim-left {
  &.rs-anim-slide-in {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
  }

  &.rs-anim-slide-out {
    -webkit-animation-name: slideOutLeft;
    animation-name: slideOutLeft;
  }
}

.rs-anim-top {
  &.rs-anim-slide-in {
    -webkit-animation-name: slideInTop;
    animation-name: slideInTop;
  }

  &.rs-anim-slide-out {
    -webkit-animation-name: slideOutTop;
    animation-name: slideOutTop;
  }
}

.rs-anim-bottom {
  &.rs-anim-slide-in {
    -webkit-animation-name: slideInBottom;
    animation-name: slideInBottom;
  }

  &.rs-anim-slide-out {
    -webkit-animation-name: slideOutBottom;
    animation-name: slideOutBottom;
  }
}

.rs-anim-bounce-in {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.rs-anim-bounce-out {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes bounceOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@keyframes bounceOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

.rs-anim-fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  pointer-events: none;

  &.rs-anim-in {
    opacity: 1;
    pointer-events: unset;
  }
}

.rs-anim-collapse {
  display: none;

  &.rs-anim-in {
    display: block;
  }
}

tr.rs-anim-collapse.rs-anim-in {
  display: table-row;
}

tbody.rs-anim-collapse.rs-anim-in {
  display: table-row-group;
}

.rs-anim-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease, width 0.35s ease, visibility 0.35s ease;
  transition: height 0.35s ease, width 0.35s ease, visibility 0.35s ease;
}

.rs-auto-complete-menu {
  margin-bottom: 6px;
  overflow-y: auto;
  margin-top: 6px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.rs-auto-complete-item {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  cursor: pointer;
  text-decoration: none;
  width: 100%;
}

.ReactVirtualized__Grid .rs-auto-complete-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rs-auto-complete-item-focus {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
}

.rs-auto-complete-item {
  &:focus,
  &:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
}

.rs-theme-high-contrast {
  .rs-auto-complete-item-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    text-decoration: underline;
  }

  .rs-auto-complete-item {
    &:focus,
    &:hover {
      outline: 3px solid rgba(52, 152, 255, 0.25);
      outline: 3px solid var(--rs-color-focus-ring);
      outline-offset: -3px;
      outline-width: 2px;
      color: #1675e0;
      color: var(--rs-listbox-option-hover-text);
      text-decoration: underline;
    }
  }

  .rs-theme-high-contrast {
    .rs-auto-complete-item-focus {
      outline-offset: 2px;
    }

    .rs-auto-complete-item {
      &:focus,
      &:hover {
        outline-offset: 2px;
      }
    }
  }
}

.rs-auto-complete .rs-input:focus::-webkit-textfield-decoration-container {
  visibility: hidden;
  pointer-events: none;
}

.rs-avatar {
  background-color: #d9d9d9;
  background-color: var(--rs-avatar-bg);
  color: #fff;
  color: var(--rs-avatar-text);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  width: 40px;
  height: 40px;
  font-size: 18px;

  > {
    .rs-icon {
      font-size: 29px;
      height: 29px;
    }

    .rs-avatar-image {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }

    .rs-icon {
      vertical-align: middle;
    }
  }
}

.rs-avatar-image:before {
  content: attr(alt);
  position: absolute;
  width: 100%;
  height: inherit;
  top: 0;
  left: 0;
  background: #d9d9d9;
  background: var(--rs-avatar-bg);
  text-align: center;
  padding: 0 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rs-avatar-circle {
  border-radius: 50%;
}

.rs-avatar-lg {
  width: 60px;
  height: 60px;
  font-size: 26px;

  > {
    .rs-icon {
      font-size: 43px;
      height: 43px;
    }

    .rs-avatar-image {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
  }
}

.rs-avatar-sm {
  width: 30px;
  height: 30px;
  font-size: 14px;

  > {
    .rs-icon {
      font-size: 22px;
      height: 22px;
    }

    .rs-avatar-image {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }
}

.rs-avatar-xs {
  width: 20px;
  height: 20px;
  font-size: 12px;

  > {
    .rs-icon {
      font-size: 16px;
      height: 16px;
    }

    .rs-avatar-image {
      width: 20px;
      height: 20px;
      line-height: 20px;
    }
  }
}

.rs-avatar-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.rs-avatar-group-stack .rs-avatar {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  margin-right: -10px;
  -webkit-transition: margin 0.15s;
  transition: margin 0.15s;

  &:hover,
  &:last-child {
    margin-right: 0;
  }
}

.rs-badge-wrapper {
  position: relative;
}

.rs-badge {
  display: inline-block;
}

.rs-badge-content {
  background-color: #f44336;
  background-color: var(--rs-badge-bg);
  color: #fff;
  color: var(--rs-badge-text);
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  padding: 0 5px;
}

.rs-badge-independent {
  background-color: #f44336;
  background-color: var(--rs-badge-bg);
  color: #fff;
  color: var(--rs-badge-text);
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  padding: 0 5px;

  &.rs-badge-dot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    padding: 0;
  }
}

.rs-badge-wrapper .rs-badge-content {
  &:empty {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    padding: 0;
  }

  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  z-index: 1;
}

.rs-badge-red {
  .rs-badge-content,
  &.rs-badge-independent {
    background-color: #f44336;
    background-color: var(--rs-red-500);
  }
}

.rs-badge-orange {
  .rs-badge-content,
  &.rs-badge-independent {
    background-color: #fa8900;
    background-color: var(--rs-orange-500);
  }
}

.rs-badge-yellow {
  .rs-badge-content,
  &.rs-badge-independent {
    background-color: #ffb300;
    background-color: var(--rs-yellow-500);
  }
}

.rs-badge-green {
  .rs-badge-content,
  &.rs-badge-independent {
    background-color: #4caf50;
    background-color: var(--rs-green-500);
  }
}

.rs-badge-cyan {
  .rs-badge-content,
  &.rs-badge-independent {
    background-color: #00bcd4;
    background-color: var(--rs-cyan-500);
  }
}

.rs-badge-blue {
  .rs-badge-content,
  &.rs-badge-independent {
    background-color: #2196f3;
    background-color: var(--rs-blue-500);
  }
}

.rs-badge-violet {
  .rs-badge-content,
  &.rs-badge-independent {
    background-color: #673ab7;
    background-color: var(--rs-violet-500);
  }
}

.rs-breadcrumb {
  margin-bottom: 20px;
  font-size: 12px;
  color: #8e8e93;
  color: var(--rs-text-secondary);
}

.rs-breadcrumb-item {
  -webkit-transition: color 0.3s linear;
  transition: color 0.3s linear;

  &:focus {
    outline: 0;
  }
}

.rs-breadcrumb-separator {
  margin: 0 4px;
}

.rs-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  -webkit-transition: color 0.2s linear, background-color 0.3s linear;
  transition: color 0.2s linear, background-color 0.3s linear;
  border: none;
  border: var(--rs-btn-default-border, none);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  color: #575757;
  color: var(--rs-btn-default-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-bg);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  overflow: hidden;
  position: relative;
}

.rs-theme-high-contrast .rs-btn {
  -webkit-transition: none;
  transition: none;
}

.rs-btn-ghost.rs-btn {
  padding: 7px 11px;
}

.rs-btn-icon.rs-btn {
  padding: 10px;
  line-height: 16px;

  > .rs-icon {
    font-size: 16px;
  }
}

.rs-btn-icon-with-text.rs-btn {
  line-height: 20px;

  > .rs-icon {
    padding: 10px;
    width: 36px;
    height: 36px;
  }

  &.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-btn:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
}

.rs-theme-high-contrast .rs-btn:focus-visible {
  outline-offset: 2px;
}

.rs-btn {
  &:focus,
  &:hover {
    color: var(--rs-btn-default-hover-text);
    background-color: #EAECF0;
    background-color: var(--rs-btn-default-hover-bg);
    text-decoration: none;
  }

  &.rs-btn-active,
  &:active {
    color: #272c36;
    color: var(--rs-btn-default-active-text);
    background-color: #d9d9d9;
    background-color: var(--rs-btn-default-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    cursor: not-allowed;
    color: #c5c6c7;
    color: var(--rs-btn-default-disabled-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-disabled-bg);
  }
}

.rs-theme-high-contrast .rs-btn {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  .rs-btn {
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
  }
}

.rs-btn-primary {
  color: #fff;
  color: var(--rs-btn-primary-text);
  background-color: #3498ff;
  background-color: var(--rs-btn-primary-bg);
  border: none;

  &:focus,
  &:hover {
    color: #fff;
    color: var(--rs-btn-primary-text);
    background-color: #2589f5;
    background-color: var(--rs-btn-primary-hover-bg);
  }

  &.rs-btn-active,
  &:active {
    color: #fff;
    color: var(--rs-btn-primary-text);
    background-color: #1675e0;
    background-color: var(--rs-btn-primary-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    color: #fff;
    color: var(--rs-btn-primary-text);
    background-color: #3498ff;
    background-color: var(--rs-btn-primary-bg);
    opacity: 0.3;
  }
}

.rs-theme-high-contrast .rs-btn-primary {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.rs-btn-subtle {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;

  &:focus,
  &:hover {
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-subtle-hover-bg);
  }

  &.rs-btn-active,
  &:active {
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
    background-color: #EAECF0;
    background-color: var(--rs-btn-subtle-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;
  }
}

.rs-theme-high-contrast .rs-btn-subtle {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.rs-btn-link {
  color: #1675e0;
  color: var(--rs-btn-link-text);
  background-color: transparent;
  border: none;

  &:focus,
  &:hover {
    color: #0a5dc2;
    color: var(--rs-btn-link-hover-text);
    background-color: transparent;
    text-decoration: underline;
  }

  &.rs-btn-active,
  &:active {
    color: #004299;
    color: var(--rs-btn-link-active-text);
    background-color: transparent;
  }

  &.rs-btn-disabled,
  &:disabled {
    color: #0a5dc2;
    color: var(--rs-btn-link-hover-text);
    background-color: transparent;
    text-decoration: none;
    opacity: 0.3;
  }
}

.rs-theme-high-contrast .rs-btn-link {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.rs-btn-ghost {
  color: #1675e0;
  color: var(--rs-btn-ghost-text);
  background-color: transparent;
  border: 1px solid #1675e0;
  border: 1px solid var(--rs-btn-ghost-border);

  &:focus,
  &:hover {
    color: #0a5dc2;
    color: var(--rs-btn-ghost-hover-text);
    background-color: transparent;
    border-color: #0a5dc2;
    border-color: var(--rs-btn-ghost-hover-border);
  }

  &.rs-btn-active,
  &:active {
    color: #004299;
    color: var(--rs-btn-ghost-active-text);
    background-color: transparent;
    border-color: #004299;
    border-color: var(--rs-btn-ghost-active-border);
  }

  &.rs-btn-disabled,
  &:disabled {
    color: #1675e0;
    color: var(--rs-btn-ghost-text);
    background-color: transparent;
    opacity: 0.3;
  }
}

.rs-theme-high-contrast .rs-btn-ghost {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-500);
  --rs-btn-primary-hover-bg: var(--rs-red-600);
  --rs-btn-primary-active-bg: var(--rs-red-700);
  --rs-btn-subtle-hover-bg: var(--rs-red-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-red-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-red-700);
  --rs-btn-ghost-text: var(--rs-red-700);
  --rs-btn-ghost-hover-border: var(--rs-red-800);
  --rs-btn-ghost-hover-text: var(--rs-red-800);
  --rs-btn-ghost-active-border: var(--rs-red-900);
  --rs-btn-ghost-active-text: var(--rs-red-900);
  --rs-btn-link-text: var(--rs-red-700);
  --rs-btn-link-hover-text: var(--rs-red-800);
  --rs-btn-link-active-text: var(--rs-red-900);
  --rs-iconbtn-primary-addon: var(--rs-red-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-red-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-red-800);
}

.rs-theme-dark .rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-700);
  --rs-btn-primary-hover-bg: var(--rs-red-600);
  --rs-btn-primary-active-bg: var(--rs-red-400);
  --rs-btn-subtle-hover-bg: var(--rs-red-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-red-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-red-500);
  --rs-btn-ghost-text: var(--rs-red-500);
  --rs-btn-ghost-hover-border: var(--rs-red-400);
  --rs-btn-ghost-hover-text: var(--rs-red-400);
  --rs-btn-ghost-active-border: var(--rs-red-200);
  --rs-btn-ghost-active-text: var(--rs-red-200);
  --rs-btn-link-text: var(--rs-red-500);
  --rs-btn-link-hover-text: var(--rs-red-400);
  --rs-btn-link-active-text: var(--rs-red-200);
  --rs-iconbtn-primary-addon: var(--rs-red-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-red-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-red-400);
}

.rs-theme-high-contrast .rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-700);
  --rs-btn-primary-hover-bg: var(--rs-red-600);
  --rs-btn-primary-active-bg: var(--rs-red-400);
  --rs-btn-subtle-hover-bg: var(--rs-red-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-red-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-red-500);
  --rs-btn-ghost-text: var(--rs-red-500);
  --rs-btn-ghost-hover-border: var(--rs-red-400);
  --rs-btn-ghost-hover-text: var(--rs-red-400);
  --rs-btn-ghost-active-border: var(--rs-red-200);
  --rs-btn-ghost-active-text: var(--rs-red-200);
  --rs-btn-link-text: var(--rs-red-500);
  --rs-btn-link-hover-text: var(--rs-red-400);
  --rs-btn-link-active-text: var(--rs-red-200);
}

.rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-500);
  --rs-btn-primary-hover-bg: var(--rs-orange-600);
  --rs-btn-primary-active-bg: var(--rs-orange-700);
  --rs-btn-subtle-hover-bg: var(--rs-orange-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-orange-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-orange-700);
  --rs-btn-ghost-text: var(--rs-orange-700);
  --rs-btn-ghost-hover-border: var(--rs-orange-800);
  --rs-btn-ghost-hover-text: var(--rs-orange-800);
  --rs-btn-ghost-active-border: var(--rs-orange-900);
  --rs-btn-ghost-active-text: var(--rs-orange-900);
  --rs-btn-link-text: var(--rs-orange-700);
  --rs-btn-link-hover-text: var(--rs-orange-800);
  --rs-btn-link-active-text: var(--rs-orange-900);
  --rs-iconbtn-primary-addon: var(--rs-orange-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-orange-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-orange-800);
}

.rs-theme-dark .rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-700);
  --rs-btn-primary-hover-bg: var(--rs-orange-600);
  --rs-btn-primary-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-hover-bg: var(--rs-orange-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-orange-500);
  --rs-btn-ghost-text: var(--rs-orange-500);
  --rs-btn-ghost-hover-border: var(--rs-orange-400);
  --rs-btn-ghost-hover-text: var(--rs-orange-400);
  --rs-btn-ghost-active-border: var(--rs-orange-200);
  --rs-btn-ghost-active-text: var(--rs-orange-200);
  --rs-btn-link-text: var(--rs-orange-500);
  --rs-btn-link-hover-text: var(--rs-orange-400);
  --rs-btn-link-active-text: var(--rs-orange-200);
  --rs-iconbtn-primary-addon: var(--rs-orange-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-orange-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-orange-400);
}

.rs-theme-high-contrast .rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-700);
  --rs-btn-primary-hover-bg: var(--rs-orange-600);
  --rs-btn-primary-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-hover-bg: var(--rs-orange-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-orange-500);
  --rs-btn-ghost-text: var(--rs-orange-500);
  --rs-btn-ghost-hover-border: var(--rs-orange-400);
  --rs-btn-ghost-hover-text: var(--rs-orange-400);
  --rs-btn-ghost-active-border: var(--rs-orange-200);
  --rs-btn-ghost-active-text: var(--rs-orange-200);
  --rs-btn-link-text: var(--rs-orange-500);
  --rs-btn-link-hover-text: var(--rs-orange-400);
  --rs-btn-link-active-text: var(--rs-orange-200);
}

.rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-500);
  --rs-btn-primary-hover-bg: var(--rs-yellow-600);
  --rs-btn-primary-active-bg: var(--rs-yellow-700);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-yellow-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-yellow-700);
  --rs-btn-ghost-text: var(--rs-yellow-700);
  --rs-btn-ghost-hover-border: var(--rs-yellow-800);
  --rs-btn-ghost-hover-text: var(--rs-yellow-800);
  --rs-btn-ghost-active-border: var(--rs-yellow-900);
  --rs-btn-ghost-active-text: var(--rs-yellow-900);
  --rs-btn-link-text: var(--rs-yellow-700);
  --rs-btn-link-hover-text: var(--rs-yellow-800);
  --rs-btn-link-active-text: var(--rs-yellow-900);
  --rs-iconbtn-primary-addon: var(--rs-yellow-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-yellow-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-yellow-800);
}

.rs-theme-dark .rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-700);
  --rs-btn-primary-hover-bg: var(--rs-yellow-600);
  --rs-btn-primary-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-yellow-500);
  --rs-btn-ghost-text: var(--rs-yellow-500);
  --rs-btn-ghost-hover-border: var(--rs-yellow-400);
  --rs-btn-ghost-hover-text: var(--rs-yellow-400);
  --rs-btn-ghost-active-border: var(--rs-yellow-200);
  --rs-btn-ghost-active-text: var(--rs-yellow-200);
  --rs-btn-link-text: var(--rs-yellow-500);
  --rs-btn-link-hover-text: var(--rs-yellow-400);
  --rs-btn-link-active-text: var(--rs-yellow-200);
  --rs-iconbtn-primary-addon: var(--rs-yellow-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-yellow-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-yellow-400);
}

.rs-theme-high-contrast .rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-700);
  --rs-btn-primary-hover-bg: var(--rs-yellow-600);
  --rs-btn-primary-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-yellow-500);
  --rs-btn-ghost-text: var(--rs-yellow-500);
  --rs-btn-ghost-hover-border: var(--rs-yellow-400);
  --rs-btn-ghost-hover-text: var(--rs-yellow-400);
  --rs-btn-ghost-active-border: var(--rs-yellow-200);
  --rs-btn-ghost-active-text: var(--rs-yellow-200);
  --rs-btn-link-text: var(--rs-yellow-500);
  --rs-btn-link-hover-text: var(--rs-yellow-400);
  --rs-btn-link-active-text: var(--rs-yellow-200);
}

.rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-500);
  --rs-btn-primary-hover-bg: var(--rs-green-600);
  --rs-btn-primary-active-bg: var(--rs-green-700);
  --rs-btn-subtle-hover-bg: var(--rs-green-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-green-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-green-700);
  --rs-btn-ghost-text: var(--rs-green-700);
  --rs-btn-ghost-hover-border: var(--rs-green-800);
  --rs-btn-ghost-hover-text: var(--rs-green-800);
  --rs-btn-ghost-active-border: var(--rs-green-900);
  --rs-btn-ghost-active-text: var(--rs-green-900);
  --rs-btn-link-text: var(--rs-green-700);
  --rs-btn-link-hover-text: var(--rs-green-800);
  --rs-btn-link-active-text: var(--rs-green-900);
  --rs-iconbtn-primary-addon: var(--rs-green-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-green-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-green-800);
}

.rs-theme-dark .rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-700);
  --rs-btn-primary-hover-bg: var(--rs-green-600);
  --rs-btn-primary-active-bg: var(--rs-green-400);
  --rs-btn-subtle-hover-bg: var(--rs-green-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-green-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-green-500);
  --rs-btn-ghost-text: var(--rs-green-500);
  --rs-btn-ghost-hover-border: var(--rs-green-400);
  --rs-btn-ghost-hover-text: var(--rs-green-400);
  --rs-btn-ghost-active-border: var(--rs-green-200);
  --rs-btn-ghost-active-text: var(--rs-green-200);
  --rs-btn-link-text: var(--rs-green-500);
  --rs-btn-link-hover-text: var(--rs-green-400);
  --rs-btn-link-active-text: var(--rs-green-200);
  --rs-iconbtn-primary-addon: var(--rs-green-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-green-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-green-400);
}

.rs-theme-high-contrast .rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-700);
  --rs-btn-primary-hover-bg: var(--rs-green-600);
  --rs-btn-primary-active-bg: var(--rs-green-400);
  --rs-btn-subtle-hover-bg: var(--rs-green-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-green-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-green-500);
  --rs-btn-ghost-text: var(--rs-green-500);
  --rs-btn-ghost-hover-border: var(--rs-green-400);
  --rs-btn-ghost-hover-text: var(--rs-green-400);
  --rs-btn-ghost-active-border: var(--rs-green-200);
  --rs-btn-ghost-active-text: var(--rs-green-200);
  --rs-btn-link-text: var(--rs-green-500);
  --rs-btn-link-hover-text: var(--rs-green-400);
  --rs-btn-link-active-text: var(--rs-green-200);
}

.rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-500);
  --rs-btn-primary-hover-bg: var(--rs-cyan-600);
  --rs-btn-primary-active-bg: var(--rs-cyan-700);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-cyan-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-cyan-700);
  --rs-btn-ghost-text: var(--rs-cyan-700);
  --rs-btn-ghost-hover-border: var(--rs-cyan-800);
  --rs-btn-ghost-hover-text: var(--rs-cyan-800);
  --rs-btn-ghost-active-border: var(--rs-cyan-900);
  --rs-btn-ghost-active-text: var(--rs-cyan-900);
  --rs-btn-link-text: var(--rs-cyan-700);
  --rs-btn-link-hover-text: var(--rs-cyan-800);
  --rs-btn-link-active-text: var(--rs-cyan-900);
  --rs-iconbtn-primary-addon: var(--rs-cyan-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-cyan-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-cyan-800);
}

.rs-theme-dark .rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-700);
  --rs-btn-primary-hover-bg: var(--rs-cyan-600);
  --rs-btn-primary-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-cyan-500);
  --rs-btn-ghost-text: var(--rs-cyan-500);
  --rs-btn-ghost-hover-border: var(--rs-cyan-400);
  --rs-btn-ghost-hover-text: var(--rs-cyan-400);
  --rs-btn-ghost-active-border: var(--rs-cyan-200);
  --rs-btn-ghost-active-text: var(--rs-cyan-200);
  --rs-btn-link-text: var(--rs-cyan-500);
  --rs-btn-link-hover-text: var(--rs-cyan-400);
  --rs-btn-link-active-text: var(--rs-cyan-200);
  --rs-iconbtn-primary-addon: var(--rs-cyan-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-cyan-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-cyan-400);
}

.rs-theme-high-contrast .rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-700);
  --rs-btn-primary-hover-bg: var(--rs-cyan-600);
  --rs-btn-primary-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-cyan-500);
  --rs-btn-ghost-text: var(--rs-cyan-500);
  --rs-btn-ghost-hover-border: var(--rs-cyan-400);
  --rs-btn-ghost-hover-text: var(--rs-cyan-400);
  --rs-btn-ghost-active-border: var(--rs-cyan-200);
  --rs-btn-ghost-active-text: var(--rs-cyan-200);
  --rs-btn-link-text: var(--rs-cyan-500);
  --rs-btn-link-hover-text: var(--rs-cyan-400);
  --rs-btn-link-active-text: var(--rs-cyan-200);
}

.rs-btn-blue {
  --rs-btn-primary-bg: var(--rs-blue-500);
  --rs-btn-primary-hover-bg: var(--rs-blue-600);
  --rs-btn-primary-active-bg: var(--rs-blue-700);
  --rs-btn-subtle-hover-bg: var(--rs-blue-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-blue-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-blue-700);
  --rs-btn-ghost-text: var(--rs-blue-700);
  --rs-btn-ghost-hover-border: var(--rs-blue-800);
  --rs-btn-ghost-hover-text: var(--rs-blue-800);
  --rs-btn-ghost-active-border: var(--rs-blue-900);
  --rs-btn-ghost-active-text: var(--rs-blue-900);
  --rs-btn-link-text: var(--rs-blue-700);
  --rs-btn-link-hover-text: var(--rs-blue-800);
  --rs-btn-link-active-text: var(--rs-blue-900);
  --rs-iconbtn-primary-addon: var(--rs-blue-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-blue-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-blue-800);
}

.rs-theme-dark .rs-btn-blue {
  --rs-btn-primary-bg: var(--rs-blue-700);
  --rs-btn-primary-hover-bg: var(--rs-blue-600);
  --rs-btn-primary-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-hover-bg: var(--rs-blue-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-blue-500);
  --rs-btn-ghost-text: var(--rs-blue-500);
  --rs-btn-ghost-hover-border: var(--rs-blue-400);
  --rs-btn-ghost-hover-text: var(--rs-blue-400);
  --rs-btn-ghost-active-border: var(--rs-blue-200);
  --rs-btn-ghost-active-text: var(--rs-blue-200);
  --rs-btn-link-text: var(--rs-blue-500);
  --rs-btn-link-hover-text: var(--rs-blue-400);
  --rs-btn-link-active-text: var(--rs-blue-200);
  --rs-iconbtn-primary-addon: var(--rs-blue-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-blue-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-blue-400);
}

.rs-theme-high-contrast .rs-btn-blue {
  --rs-btn-primary-bg: var(--rs-blue-700);
  --rs-btn-primary-hover-bg: var(--rs-blue-600);
  --rs-btn-primary-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-hover-bg: var(--rs-blue-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-blue-500);
  --rs-btn-ghost-text: var(--rs-blue-500);
  --rs-btn-ghost-hover-border: var(--rs-blue-400);
  --rs-btn-ghost-hover-text: var(--rs-blue-400);
  --rs-btn-ghost-active-border: var(--rs-blue-200);
  --rs-btn-ghost-active-text: var(--rs-blue-200);
  --rs-btn-link-text: var(--rs-blue-500);
  --rs-btn-link-hover-text: var(--rs-blue-400);
  --rs-btn-link-active-text: var(--rs-blue-200);
}

.rs-btn-violet {
  --rs-btn-primary-bg: var(--rs-violet-500);
  --rs-btn-primary-hover-bg: var(--rs-violet-600);
  --rs-btn-primary-active-bg: var(--rs-violet-700);
  --rs-btn-subtle-hover-bg: var(--rs-violet-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-violet-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-violet-700);
  --rs-btn-ghost-text: var(--rs-violet-700);
  --rs-btn-ghost-hover-border: var(--rs-violet-800);
  --rs-btn-ghost-hover-text: var(--rs-violet-800);
  --rs-btn-ghost-active-border: var(--rs-violet-900);
  --rs-btn-ghost-active-text: var(--rs-violet-900);
  --rs-btn-link-text: var(--rs-violet-700);
  --rs-btn-link-hover-text: var(--rs-violet-800);
  --rs-btn-link-active-text: var(--rs-violet-900);
  --rs-iconbtn-primary-addon: var(--rs-violet-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-violet-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-violet-800);
}

.rs-theme-dark .rs-btn-violet {
  --rs-btn-primary-bg: var(--rs-violet-700);
  --rs-btn-primary-hover-bg: var(--rs-violet-600);
  --rs-btn-primary-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-hover-bg: var(--rs-violet-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-violet-500);
  --rs-btn-ghost-text: var(--rs-violet-500);
  --rs-btn-ghost-hover-border: var(--rs-violet-400);
  --rs-btn-ghost-hover-text: var(--rs-violet-400);
  --rs-btn-ghost-active-border: var(--rs-violet-200);
  --rs-btn-ghost-active-text: var(--rs-violet-200);
  --rs-btn-link-text: var(--rs-violet-500);
  --rs-btn-link-hover-text: var(--rs-violet-400);
  --rs-btn-link-active-text: var(--rs-violet-200);
  --rs-iconbtn-primary-addon: var(--rs-violet-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-violet-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-violet-400);
}

.rs-theme-high-contrast .rs-btn-violet {
  --rs-btn-primary-bg: var(--rs-violet-700);
  --rs-btn-primary-hover-bg: var(--rs-violet-600);
  --rs-btn-primary-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-hover-bg: var(--rs-violet-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-violet-500);
  --rs-btn-ghost-text: var(--rs-violet-500);
  --rs-btn-ghost-hover-border: var(--rs-violet-400);
  --rs-btn-ghost-hover-text: var(--rs-violet-400);
  --rs-btn-ghost-active-border: var(--rs-violet-200);
  --rs-btn-ghost-active-text: var(--rs-violet-200);
  --rs-btn-link-text: var(--rs-violet-500);
  --rs-btn-link-hover-text: var(--rs-violet-400);
  --rs-btn-link-active-text: var(--rs-violet-200);
}

.rs-btn-lg {
  font-size: 16px;
  line-height: 22px;
  padding: 10px 16px;
}

.rs-btn-ghost.rs-btn-lg {
  padding: 9px 15px;
}

.rs-btn-icon.rs-btn-lg {
  padding: 11px;
  line-height: 20px;

  > .rs-icon {
    font-size: 20px;
  }
}

.rs-btn-icon-with-text.rs-btn-lg {
  line-height: 22px;

  > .rs-icon {
    padding: 11px;
    width: 42px;
    height: 42px;
  }

  &.rs-btn-icon-placement-left {
    padding: 10px 16px 10px 58px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 10px 58px 10px 16px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-btn-md {
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
}

.rs-btn-ghost.rs-btn-md {
  padding: 7px 11px;
}

.rs-btn-icon.rs-btn-md {
  padding: 10px;
  line-height: 16px;

  > .rs-icon {
    font-size: 16px;
  }
}

.rs-btn-icon-with-text.rs-btn-md {
  line-height: 20px;

  > .rs-icon {
    padding: 10px;
    width: 36px;
    height: 36px;
  }

  &.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-btn-sm {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
}

.rs-btn-ghost.rs-btn-sm {
  padding: 4px 9px;
}

.rs-btn-icon.rs-btn-sm {
  padding: 7px;
  line-height: 16px;

  > .rs-icon {
    font-size: 16px;
  }
}

.rs-btn-icon-with-text.rs-btn-sm {
  line-height: 20px;

  > .rs-icon {
    padding: 7px;
    width: 30px;
    height: 30px;
  }

  &.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-btn-xs {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
}

.rs-btn-ghost.rs-btn-xs {
  padding: 1px 7px;
}

.rs-btn-icon.rs-btn-xs {
  padding: 6px;
  line-height: 12px;

  > .rs-icon {
    font-size: 12px;
  }
}

.rs-btn-icon-with-text.rs-btn-xs {
  line-height: 20px;

  > .rs-icon {
    padding: 6px;
    width: 24px;
    height: 24px;
  }

  &.rs-btn-icon-placement-left {
    padding: 2px 8px 2px 32px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 2px 32px 2px 8px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-btn-block {
  display: block;
  width: 100%;

  + .rs-btn-block {
    margin-top: 5px;
  }
}

.rs-btn-loading {
  color: transparent !important;
  position: relative;
  cursor: default;
  pointer-events: none;

  > .rs-btn-spin {
    &:after,
    &:before {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 50%;
      z-index: 1;
    }
  }
}

.rs-btn-xs.rs-btn-loading > .rs-btn-spin {
  &:after,
  &:before {
    width: 16px;
    height: 16px;
  }
}

.rs-btn-loading > .rs-btn-spin:before {
  border: 3px solid rgba(247, 247, 250, 0.8);
  border: 3px solid var(--rs-loader-ring);
}

.rs-btn-primary.rs-btn-loading > .rs-btn-spin:before {
  border-color: rgba(248, 247, 250, 0.3);
}

.rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin:before {
  border-color: rgba(247, 247, 250, 0.3);
  border-color: var(--rs-loader-ring-inverse);
}

.rs-btn-loading > .rs-btn-spin:after {
  border: 3px solid transparent;
  border-top: 3px solid var(--rs-loader-rotor);
  -webkit-animation: buttonSpin 0.6s linear infinite;
  animation: buttonSpin 0.6s linear infinite;
}

.rs-btn-primary.rs-btn-loading > .rs-btn-spin:after {
  border-top-color: #fff;
}

.rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin:after {
  border-top-color: #fff;
  border-top-color: var(--rs-loader-rotor-inverse);
}

@-webkit-keyframes buttonSpin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes buttonSpin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.rs-btn-group {
  display: inline-block;
  vertical-align: middle;
  position: relative;

  > .rs-btn {
    position: relative;

    &:active,
    &:focus {
      z-index: 2;
    }
  }

  &:not(.rs-btn-group-vertical) > {
    .rs-btn {
      float: left;

      &:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      &:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }

    .rs-btn-ghost + .rs-btn-ghost {
      margin-left: -1px;
    }
  }
}

.rs-btn-group-vertical > {
  .rs-btn {
    display: block;
    width: 100%;
    max-width: 100%;

    &:not(:last-child) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:first-child) {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }

  .rs-btn-ghost + .rs-btn-ghost {
    margin-top: -1px;
  }
}

.rs-btn-group-justified {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;

  > .rs-btn {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 1%;
    flex: 1 1 1%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.rs-btn-toolbar {
  line-height: 0;

  > :not(:first-child):not(.rs-btn-block) {
    margin-left: 10px;
  }
}

.rs-picker-subtle {
  .picker-subtle-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
    cursor: pointer;
  }

  &.rs-picker-disabled .picker-subtle-toggle {
    cursor: not-allowed;

    &:active,
    &:focus,
    &:hover {
      background: none;
    }

    &:active:after,
    &:focus:after,
    &:hover:after {
      display: none;
    }
  }
}

.rs-calendar {
  padding-top: 12px;
  position: relative;
  min-height: 266px;
  overflow: hidden;
}

.rs-calendar-bordered {
  .rs-calendar-table {
    border: 1px solid #EAECF0;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
  }

  .rs-calendar-table-header-row .rs-calendar-table-cell,
  .rs-calendar-table-row:not(:last-child) .rs-calendar-table-cell {
    border-bottom: 1px solid #f2f2f5;
    border-bottom: 1px solid var(--rs-border-secondary);
  }

  .rs-calendar-month-dropdown {
    border: 1px solid #EAECF0;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
  }
}

.rs-calendar-panel {
  .rs-calendar-header {
    width: 100%;
    padding-bottom: 6px;

    &:after,
    &:before {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    .rs-calendar-btn-today {
      float: right;
    }
  }

  .rs-calendar-header-backward,
  .rs-calendar-header-forward {
    float: none !important;
  }

  &.rs-calendar-month-view {
    .rs-calendar-header-backward,
    .rs-calendar-header-forward {
      display: inline-block;
      visibility: hidden;
      pointer-events: none;
    }
  }

  .rs-calendar-header-month-toolbar {
    width: auto !important;
  }

  .rs-calendar-month-view,
  &.rs-calendar-month-view .rs-calendar-header-month-toolbar {
    padding-left: 0;
    padding-right: 0;
  }

  .rs-calendar-table-header-row .rs-calendar-table-cell-content {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .rs-calendar-table-cell-is-today {
    .rs-calendar-table-cell-day {
      color: #fff;
      color: var(--rs-calendar-today-text);
      width: 20px;
      height: 20px;
      background-color: #3498ff;
      background-color: var(--rs-calendar-today-bg);
      border-radius: 50%;
      margin-left: auto;
      margin-right: auto;
    }

    .rs-calendar-table-cell-content {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: inherit;
    color: inherit;
    -webkit-box-shadow: inset 0 0 0 1px #3498ff;
    -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
    box-shadow: inset 0 0 0 1px #3498ff;
    box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
  }

  .rs-calendar-table-cell-day {
    margin-top: 3px;
    display: block;
    text-align: center;
  }

  .rs-calendar-month-dropdown {
    margin-top: -2px;
    margin-left: 12px;
    margin-right: 12px;
    top: 54px;
    width: calc(100% - 24px);
  }

  &:not(.rs-calendar-compact) .rs-calendar-month-dropdown-scroll {
    height: 644px;
  }

  &.rs-calendar-compact .rs-calendar-month-dropdown-scroll {
    height: 344px;
  }

  &:not(.rs-calendar-compact) {
    .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
      width: 100%;
      height: 100px;
      overflow: hidden;
    }

    .rs-calendar-table-cell {
      vertical-align: top;
    }
  }

  .rs-calendar-table-cell {
    padding: 0 1px;
    line-height: 0;
  }

  .rs-calendar-table-cell-content {
    width: 100%;
    border-radius: 0;
  }

  .rs-calendar-table-row:last-child {
    :first-child .rs-calendar-table-cell-content {
      border-bottom-left-radius: 6px;
    }

    :last-child .rs-calendar-table-cell-content {
      border-bottom-right-radius: 6px;
    }
  }

  &.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
    height: 50px;
  }
}

.rs-calendar-btn-close {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 14px;
  padding: 0;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #fff;
  background: var(--rs-bg-overlay);
  z-index: 1;
}

.rs-calendar-panel .rs-calendar-btn-close {
  bottom: 11px;
  margin: 0 20px;
}

.rs-calendar-month-view {
  .rs-calendar-header-month-toolbar {
    padding-left: 24px;
    padding-right: 24px;
  }

  .rs-calendar-header-backward,
  .rs-calendar-header-forward {
    display: none;
  }

  .rs-calendar-header-title-date {
    color: #1675e0;
    color: var(--rs-text-active);
    background: transparent;

    &.rs-calendar-header-error {
      color: #f44336;
      color: var(--rs-text-error);

      &:focus,
      &:hover:active {
        color: #fff !important;
      }
    }
  }
}

.rs-calendar-time-view .rs-calendar-header-title-time {
  color: #1675e0;
  color: var(--rs-text-active);
  background: transparent;

  &.rs-calendar-header-error {
    color: #f44336;
    color: var(--rs-text-error);

    &:hover:active {
      color: #fff;
    }
  }
}

.rs-calendar-table-cell-in-range,
.rs-calendar-table-cell-selected {
  position: relative;
}

.rs-calendar-table-cell-in-range:before,
.rs-calendar-table-cell-selected:before {
  content: "";
  display: block;
  width: 100%;
  margin-top: 4px;
  height: 24px;
  position: absolute;
  z-index: 0;
  top: 0;
}

.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  z-index: 1;
  position: relative;
}

.rs-calendar-table-cell-in-range {
  &:before {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: var(--rs-calendar-range-bg);
  }

  &:hover:before {
    display: none;
  }
}

.rs-calendar-month-dropdown-scroll div:focus {
  outline: none;
}

.rs-calendar-header {
  width: 280px;
  padding-left: 12px;
  padding-right: 12px;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.rs-calendar-header-month-toolbar,
.rs-calendar-header-time-toolbar {
  display: inline-block;
}

.rs-calendar-header-month-toolbar {
  float: left;
}

.rs-calendar-header-time-toolbar {
  float: right;
}

.rs-calendar-header-title {
  margin: 0 4px;
}

.rs-calendar-header-meridian {
  font-size: 12px;
  margin-left: 4px;
}

.rs-calendar-header-error {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  --rs-btn-subtle-text: var(--rs-red-500);
  --rs-btn-subtle-hover-bg: var(--rs-red-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-red-600);
  --rs-btn-subtle-active-text: #fff;

  &:focus,
  &:hover {
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-subtle-hover-bg);
  }

  &.rs-btn-active,
  &:active {
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
    background-color: #EAECF0;
    background-color: var(--rs-btn-subtle-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;
  }
}

.rs-theme-high-contrast .rs-calendar-header-error {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.rs-theme-dark .rs-calendar-header-error {
  --rs-btn-subtle-hover-bg: var(--rs-red-600);
  --rs-btn-subtle-active-bg: var(--rs-red-400);
}

.rs-calendar-header-btn-disabled {
  cursor: not-allowed;
  opacity: 0.3;
  background: none;

  &:hover {
    cursor: not-allowed;
    opacity: 0.3;
    background: none;

    &:focus {
      cursor: not-allowed;
      opacity: 0.3;
      background: none;
    }
  }

  &:after {
    display: none !important;
  }
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;

  .rs-calendar-header-month-toolbar {
    display: block;
    text-align: center;
    width: 100%;

    &:after,
    &:before {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }
  }

  .rs-calendar-header-backward {
    float: left;
  }

  .rs-calendar-header-forward {
    float: right;
  }
}

.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
  margin: 0 auto;
  text-align: center;

  .rs-calendar-header-time-toolbar {
    float: none;
    display: inline-block;
  }
}

.rs-calendar-header-has-month.rs-calendar-header-has-time {
  margin: 0 auto;
}

[dir="rtl"] {
  .rs-calendar-header-backward .rs-icon,
  .rs-calendar-header-forward .rs-icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.rs-calendar-body {
  padding: 4px 12px 12px;
}

.rs-calendar-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.rs-calendar-table-row {
  display: table-row;

  &:nth-child(2) .rs-calendar-table-cell-week-number {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child .rs-calendar-table-cell-week-number {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.rs-calendar-table-cell {
  display: table-cell;
  width: 1%;
  padding: 1px 0;
  text-align: center;
  vertical-align: middle;
}

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content {
  color: #c5c6c7;
  color: var(--rs-text-disabled);

  &:hover {
    color: #c5c6c7;
    color: var(--rs-text-disabled);
  }
}

.rs-calendar-table-cell-disabled {
  .rs-calendar-table-cell-content {
    background: none;
    text-decoration: line-through;
    cursor: not-allowed;

    &:hover {
      background: none;
      text-decoration: line-through;
      cursor: not-allowed;
    }
  }

  &.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
    background-color: #3498ff;
    background-color: var(--rs-bg-active);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.rs-theme-high-contrast
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content:hover {
  text-decoration: underline;
}

.rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  opacity: 0.3;
}

.rs-calendar-table-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;

  &:hover {
    background-color: #f2faff;
    background-color: var(--rs-state-hover-bg);
  }
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  -webkit-box-shadow: inset 0 0 0 1px #3498ff;
  -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
  box-shadow: inset 0 0 0 1px #3498ff;
  box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #fff;
  color: var(--rs-calendar-date-selected-text);
  background-color: #3498ff;
  background-color: var(--rs-bg-active);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rs-theme-high-contrast .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  text-decoration: underline;
}

.rs-calendar-table-header-row .rs-calendar-table-cell-content {
  color: #8e8e93;
  color: var(--rs-text-secondary);
  font-size: 12px;
  line-height: 1.66666667;
  padding-top: 2px;
  padding-bottom: 2px;
  background: none;
  cursor: auto;

  &:focus {
    background: none;
    cursor: auto;
  }

  &:hover {
    background: none;
    cursor: auto;

    &:focus {
      background: none;
      cursor: auto;
    }
  }
}

.rs-calendar-table-cell-week-number {
  display: table-cell;
  width: 1%;
  padding: 1px;
  text-align: center;
  vertical-align: middle;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  background-color: #f7f7fa;
  background-color: var(--rs-bg-well);
  font-size: 12px;
}

.rs-calendar-month-dropdown {
  display: none;
  position: absolute;
  top: 44px;
  border-top: 1px solid #EAECF0;
  border-top: 1px solid var(--rs-border-primary);
  margin-top: 0;
  width: 100%;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  overflow: hidden;
  z-index: 1;
}

.rs-calendar-month-view {
  .rs-calendar-month-dropdown {
    display: block;
  }

  .rs-calendar-month-dropdown-content {
    -webkit-animation: slideDown 0.3s linear;
    animation: slideDown 0.3s linear;
  }
}

.rs-calendar-month-dropdown-scroll {
  height: 230px;
}

.rs-calendar-month-dropdown-row {
  position: relative;
  padding: 5px 12px 5px 52px;

  &:not(:last-child) {
    border-bottom: 1px dotted #EAECF0;
    border-bottom: 1px dotted var(--rs-border-primary);
  }
}

.rs-calendar-month-dropdown-year {
  position: absolute;
  top: calc(50% - 0.5em);
  left: 12px;
}

.rs-calendar-month-dropdown-year-active {
  color: #1675e0;
  color: var(--rs-text-active);
}

.rs-calendar-month-dropdown-list {
  display: block;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.rs-calendar-month-dropdown-cell {
  display: inline-block;
  float: left;
  width: calc(16.66667% - 2px);
  margin: 1px;
  text-align: center;
  vertical-align: middle;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-calendar-month-dropdown-cell {
    width: calc(16.66667% - 2.16667px);
  }
}

@supports (-ms-ime-align: auto) {
  .rs-calendar-month-dropdown-cell {
    width: calc(16.66667% - 2.16667px);
  }
}

.rs-calendar-month-dropdown-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;

  &:hover {
    background-color: #f2faff;
    background-color: var(--rs-state-hover-bg);
  }
}

.rs-calendar-month-dropdown-cell:not(.rs-calendar-month-dropdown-cell-active).disabled .rs-calendar-month-dropdown-cell-content {
  color: #c5c6c7;
  color: var(--rs-text-disabled);
  background: none;
  text-decoration: line-through;
  cursor: not-allowed;
}

.rs-calendar-month-dropdown-cell-active {
  &.disabled .rs-calendar-month-dropdown-cell-content {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .rs-calendar-month-dropdown-cell-content {
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
    background-color: #3498ff;
    background-color: var(--rs-bg-active);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.rs-theme-high-contrast .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  text-decoration: underline;
}

.rs-calendar-time-dropdown {
  display: none;
  position: absolute;
  top: 44px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  color: #575757;
  color: var(--rs-text-primary);
  overflow: hidden;
  z-index: 1;
}

.rs-calendar-time-view {
  .rs-calendar-time-dropdown {
    display: block;
  }

  .rs-calendar-time-dropdown-content {
    -webkit-animation: slideDown 0.3s linear;
    animation: slideDown 0.3s linear;
  }
}

.rs-calendar-time-dropdown-row {
  display: table;
  width: 100%;
}

.rs-calendar-time-dropdown-column {
  display: table-cell;
  width: 1%;

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    height: 230px;
    overflow-y: auto;
    padding-bottom: 200px;
  }
}

.rs-calendar-time-dropdown-column-title {
  width: 100%;
  background-color: #f7f7fa;
  background-color: var(--rs-calendar-time-unit-bg);
  color: #8e8e93;
  color: var(--rs-text-secondary);
  text-align: center;
  font-size: 12px;
  line-height: 1.66666667;
  padding-top: 2px;
  padding-bottom: 2px;
}

.rs-calendar-time-dropdown-cell {
  font-size: 14px;
  line-height: 20px;
  color: inherit;
  padding: 5px;
  display: block;
  text-align: center;
  cursor: pointer;
  text-decoration: none;

  &:focus {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
    background-color: rgba(204, 233, 255, 0.5);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
}

.rs-theme-high-contrast {
  .rs-calendar-time-dropdown-cell:hover {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    text-decoration: underline;
  }

  .rs-theme-high-contrast .rs-calendar-time-dropdown-cell:hover {
    outline-offset: 2px;
  }
}

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
  background-color: #3498ff;
  background-color: var(--rs-bg-active);
  color: #fff;
  color: var(--rs-calendar-date-selected-text);
}

.rs-theme-high-contrast {
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }

  .rs-theme-high-contrast {
    .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
      outline: 3px solid rgba(52, 152, 255, 0.25);
      outline: 3px solid var(--rs-color-focus-ring);
      outline-offset: -3px;
      outline-width: 2px;
      color: #1675e0;
      color: var(--rs-listbox-option-hover-text);
      text-decoration: underline;
    }

    .rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
      outline-offset: 2px;
    }
  }
}

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-disabled {
  color: #c5c6c7;
  color: var(--rs-text-disabled);
  background: none;
  cursor: not-allowed;
  text-decoration: line-through;
}

.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell-disabled.rs-calendar-time-dropdown-cell {
  opacity: 0.3;
  cursor: not-allowed;
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.rs-carousel {
  position: relative;
  height: 400px;
  overflow: hidden;
  background-color: #8e8e93;
  background-color: var(--rs-carousel-bg);
}

.rs-carousel-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.rs-carousel-slider {
  position: relative;
  left: 0;
  height: 100%;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  will-change: transform;
}

.rs-carousel-slider-item {
  float: left;
}

.rs-carousel-slider-after,
.rs-carousel-slider-item {
  background-color: #8e8e93;
  background-color: var(--rs-carousel-bg);
  height: 100%;
  width: 100%;
}

.rs-carousel-slider-after {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: moveLeftHalf 0.3s ease forwards;
  animation: moveLeftHalf 0.3s ease forwards;
}

.rs-carousel-slider-after-vertical {
  -webkit-animation: moveLeftHalf-vertical 0.3s ease forwards;
  animation: moveLeftHalf-vertical 0.3s ease forwards;
}

.rs-carousel-toolbar {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.rs-carousel-label-wrapper {
  margin: 3px;

  input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;

    &:checked ~ label {
      background-color: #3498ff;
      background-color: var(--rs-carousel-indicator-active);
    }
  }
}

.rs-carousel-label {
  cursor: pointer;
  display: block;
  background-color: hsla(0, 0%, 100%, 0.4);
  background-color: var(--rs-carousel-indicator);
  -webkit-transition: background 0.3s linear;
  transition: background 0.3s linear;
  -webkit-transition-property: background, width, height;
  transition-property: background, width, height;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
  }

  &:hover {
    background-color: #fff;
    background-color: var(--rs-carousel-indicator-hover);
  }
}

.rs-carousel-shape-dot .rs-carousel-label {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.rs-carousel-shape-bar .rs-carousel-label {
  border-radius: 2px;
}

.rs-carousel-placement-bottom.rs-carousel-shape-bar .rs-carousel-label,
.rs-carousel-placement-top.rs-carousel-shape-bar .rs-carousel-label {
  width: 18px;
  height: 4px;
}

.rs-carousel-placement-bottom.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label,
.rs-carousel-placement-top.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label {
  width: 28px;
}

.rs-carousel-placement-left.rs-carousel-shape-bar .rs-carousel-label,
.rs-carousel-placement-right.rs-carousel-shape-bar .rs-carousel-label {
  width: 4px;
  height: 18px;
}

.rs-carousel-placement-left.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label,
.rs-carousel-placement-right.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label {
  height: 28px;
}

.rs-carousel-placement-bottom .rs-carousel-toolbar,
.rs-carousel-placement-top .rs-carousel-toolbar {
  left: 0;
  width: 100%;
}

.rs-carousel-placement-bottom .rs-carousel-toolbar > ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rs-carousel-placement-top .rs-carousel-toolbar {
  > ul {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  top: 17px;
}

.rs-carousel-placement-bottom .rs-carousel-toolbar {
  bottom: 17px;
}

.rs-carousel-placement-left .rs-carousel-toolbar,
.rs-carousel-placement-right .rs-carousel-toolbar {
  top: 0;
  width: 1.2vw;
  height: 100%;
}

.rs-carousel-placement-left .rs-carousel-toolbar > ul,
.rs-carousel-placement-right .rs-carousel-toolbar > ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rs-carousel-placement-left .rs-carousel-toolbar {
  left: 17px;
}

.rs-carousel-placement-right .rs-carousel-toolbar {
  right: 17px;
}

@-webkit-keyframes moveLeftHalf {
  0% {
    -webkit-transform: none;
    transform: none;
  }

  to {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@keyframes moveLeftHalf {
  0% {
    -webkit-transform: none;
    transform: none;
  }

  to {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@-webkit-keyframes moveLeftHalf-vertical {
  0% {
    -webkit-transform: none;
    transform: none;
  }

  to {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@keyframes moveLeftHalf-vertical {
  0% {
    -webkit-transform: none;
    transform: none;
  }

  to {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.rs-picker-cascader-menu-items > div {
  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.rs-picker-cascader-search-panel {
  max-height: 300px;
  overflow: auto;
}

.rs-picker-cascader-row {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  cursor: pointer;
  text-decoration: none;
  width: 100%;
}

.ReactVirtualized__Grid .rs-picker-cascader-row {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rs-picker-cascader-row:hover {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
}

.rs-theme-high-contrast {
  .rs-picker-cascader-row:hover {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    text-decoration: underline;
  }

  .rs-theme-high-contrast .rs-picker-cascader-row:hover {
    outline-offset: 2px;
  }
}

.rs-picker-cascader-row.rs-picker-cascader-row-focus {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
}

.rs-theme-high-contrast {
  .rs-picker-cascader-row.rs-picker-cascader-row-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    text-decoration: underline;
  }

  .rs-theme-high-contrast .rs-picker-cascader-row.rs-picker-cascader-row-focus {
    outline-offset: 2px;
  }
}

.rs-picker-cascader-row {
  &.rs-picker-cascader-row-disabled,
  &:disabled {
    color: #c5c6c7;
    color: var(--rs-listbox-option-disabled-text);
    background: none;
    cursor: not-allowed;
  }

  &.rs-picker-cascader-row-disabled .rs-picker-cascader-search-match,
  &:disabled .rs-picker-cascader-search-match {
    opacity: 0.7;
    font-weight: 400;
  }
}

.rs-picker-cascader-col {
  display: inline-block;

  &:after {
    content: "\00a0/\00a0";
  }

  &:last-child:after {
    display: none;
  }
}

.rs-picker-cascader-search-match {
  color: #3498ff;
  color: var(--rs-primary-500);
  font-weight: 700;
}

.rs-picker-cascader-menu-column {
  float: left;
  overflow-y: auto;
}

.rs-picker-cascader-menu-column-loading {
  padding: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  .rs-icon {
    margin: 4px;
  }
}

.rs-picker-cascader-menu-items {
  padding: 6px 0;

  li,
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.rs-picker-cascader-menu-item {
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  position: relative;
  padding: 8px 28px 8px 12px;
  word-break: break-word;
}

.ReactVirtualized__Grid .rs-picker-cascader-menu-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rs-picker-cascader-menu-items-has-children .rs-picker-cascader-menu-item {
  padding-right: 32px;
}

.rs-picker-cascader-menu-item {
  &.rs-picker-cascader-menu-item-focus,
  &.rs-picker-select-menu-item-focus,
  &:focus,
  &:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
}

.rs-theme-high-contrast {
  .rs-picker-cascader-menu-item {
    &.rs-picker-cascader-menu-item-focus,
    &.rs-picker-select-menu-item-focus,
    &:focus,
    &:hover {
      outline: 3px solid rgba(52, 152, 255, 0.25);
      outline: 3px solid var(--rs-color-focus-ring);
      outline-offset: -3px;
      outline-width: 2px;
      color: #1675e0;
      color: var(--rs-listbox-option-hover-text);
      text-decoration: underline;
    }
  }

  .rs-theme-high-contrast .rs-picker-cascader-menu-item {
    &.rs-picker-cascader-menu-item-focus,
    &.rs-picker-select-menu-item-focus,
    &:focus,
    &:hover {
      outline-offset: 2px;
    }
  }
}

.rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active {
  background-color: #f2faff;
  background-color: var(--rs-listbox-option-selected-bg);
  font-weight: 700;
  color: #1675e0;
  color: var(--rs-listbox-option-selected-text);
}

.rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active {
  text-decoration: underline;
}

.rs-picker-cascader-menu-item {
  &.rs-picker-cascader-menu-item-active .rs-picker-cascader-menu-caret {
    color: #575757;
    color: var(--rs-text-primary);
  }

  &.rs-picker-cascader-menu-item-disabled,
  &:disabled {
    color: #c5c6c7;
    color: var(--rs-listbox-option-disabled-text);
    background: none;
    cursor: not-allowed;
  }
}

.rs-picker-cascader-menu-caret {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  height: 20px;
  font-size: 12px;
}

.rs-checkbox {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.rs-checkbox-disabled label {
  cursor: not-allowed;
}

.rs-checkbox label {
  line-height: 1.14285714;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.rs-checkbox-inner:before {
  border-color: #3498ff;
  border-color: var(--rs-checkbox-checked-bg);
}

.rs-checkbox.rs-checkbox-disabled label {
  cursor: not-allowed;
}

.rs-checkbox-disabled > .rs-checkbox-checker > label {
  color: #c5c6c7;
  color: var(--rs-text-disabled);
}

.rs-checkbox-inline {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: 400;
  margin-top: 0;
  margin-left: 10px;
}

.rs-plaintext .rs-checkbox-inline:first-child {
  margin-left: 0;
}

.rs-checkbox [type="checkbox"]:focus-visible ~ .rs-checkbox-inner:before {
  -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  -webkit-box-shadow: var(--rs-state-focus-shadow);
  box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  box-shadow: var(--rs-state-focus-shadow);
}

.rs-checkbox-checker {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 36px;
  min-height: 36px;
  line-height: 1;
  position: relative;
}

.rs-checkbox-wrapper {
  .rs-checkbox-inner {
    &:after,
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
    }
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }

  .rs-checkbox-inner:after {
    opacity: 0;
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
    -webkit-transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46),
      -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  }
}

.rs-theme-high-contrast .rs-checkbox-wrapper .rs-checkbox-inner:after {
  -webkit-transition: none;
  transition: none;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:after,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:after {
  border: solid #fff;
  border: solid var(--rs-checkbox-icon);
  width: 6px;
  height: 9px;
  margin-top: 2px;
  margin-left: 5px;
  opacity: 1;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:after {
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
}

.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:after {
  border-width: 0 0 2px;
  -webkit-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  width: 10px;
  margin-top: 0;
  margin-left: 3px;
}

.rs-checkbox-wrapper {
  position: absolute;
  width: 16px;
  height: 16px;
  display: inline-block;
  left: 10px;
  top: 10px;

  [type="checkbox"] {
    width: 0;
    height: 0;
    opacity: 0;
  }

  .rs-checkbox-inner {
    &:after,
    &:before {
      width: 16px;
      height: 16px;
    }
  }

  &:before {
    width: 16px;
    height: 16px;
    border: 1px solid #3498ff;
    border: 1px solid var(--rs-checkbox-checked-bg);
    background-color: transparent;
    border-radius: 3px;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
    visibility: hidden;
    -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear, -webkit-transform 0.2s linear;
  }
}

.rs-theme-high-contrast .rs-checkbox-wrapper:before {
  -webkit-transition: none;
  transition: none;
}

.rs-checkbox-checked .rs-checkbox-wrapper:before {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 0;
  visibility: visible;
}

.rs-checkbox-wrapper {
  &:after {
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }

  .rs-checkbox-inner:before {
    border: 1px solid #d9d9d9;
    border: 1px solid var(--rs-checkbox-border);
    background-color: transparent;
    border-radius: 3px;
    -webkit-transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
  }
}

.rs-theme-high-contrast .rs-checkbox-wrapper .rs-checkbox-inner:before {
  -webkit-transition: none;
  transition: none;
}

label:hover .rs-checkbox-wrapper .rs-checkbox-inner:before {
  border-color: #3498ff;
  border-color: var(--rs-checkbox-checked-bg);
}

.rs-checkbox-disabled:not(.rs-checkbox-checked):not(.rs-checkbox-indeterminate) .rs-checkbox-wrapper .rs-checkbox-inner:before {
  border-color: #f7f7fa;
  border-color: var(--rs-checkbox-disabled-bg);
  background-color: #f7f7fa;
  background-color: var(--rs-checkbox-disabled-bg);
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
  border-color: #3498ff;
  border-color: var(--rs-checkbox-checked-bg);
  background-color: #3498ff;
  background-color: var(--rs-checkbox-checked-bg);
}

.rs-checkbox-disabled {
  &.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
  &.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
    opacity: 0.3;
  }
}

.rs-theme-high-contrast .rs-checkbox-disabled {
  &.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
  &.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
    opacity: 0.5;
  }
}

.rs-checkbox-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rs-checkbox-group-inline {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: -10px;
}

.rs-picker-check-menu-items {
  margin-bottom: 6px;
  overflow-y: auto;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .rs-picker-menu-group:not(:first-child) {
    margin-top: 6px;
    border-top: 1px solid #EAECF0;
    border-top: 1px solid var(--rs-border-primary);
    padding-top: 6px;
  }

  .rs-picker-menu-group-title {
    padding: 8px 32px 8px 12px;
    position: relative;
    cursor: default;
    -webkit-transition: color 0.3s linear, background-color 0.3s linear;
    transition: color 0.3s linear, background-color 0.3s linear;
    color: #272c36;
    color: var(--rs-text-heading);

    &:hover {
      background-color: #f2faff;
      background-color: var(--rs-state-hover-bg);
    }

    .rs-picker-menu-group-caret {
      display: inline-block;
      margin-left: 2px;
      position: absolute;
      top: 8px;
      right: 12px;
      -webkit-transition: -webkit-transform 0.3s linear;
      transition: -webkit-transform 0.3s linear;
      transition: transform 0.3s linear;
      transition: transform 0.3s linear, -webkit-transform 0.3s linear;
      padding: 3px;
    }
  }

  .rs-picker-menu-group.folded .rs-picker-menu-group-caret {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
    font-weight: 700;
  }
}

.rs-check-tree {
  max-height: 360px;
  height: 100%;
  overflow-y: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;

  &.rs-check-tree-virtualized {
    overflow: hidden;

    .ReactVirtualized__Grid.ReactVirtualized__List {
      padding: 0 12px 12px 0;

      &:focus {
        outline: none;
      }
    }
  }

  .rs-check-item .rs-checkbox-checker {
    > label {
      text-align: left;
      position: relative;
      margin: 0;
      padding: 8px 12px 8px 50px;

      &:before {
        content: "";
        position: absolute;
        width: 46px;
        height: 100%;
        top: 0;
        margin-left: -52px;
      }
    }

    .rs-checkbox-wrapper {
      left: 20px;
    }
  }
}

.rs-check-tree-without-children .rs-check-item .rs-checkbox-checker {
  > label {
    padding-left: 32px;

    &:before {
      width: 28px;
      margin-left: -34px;
    }
  }

  .rs-checkbox-wrapper {
    left: 0;
  }
}

.rs-check-tree-node {
  position: relative;
  font-size: 14px;
  line-height: 22px;

  .rs-check-item {
    display: inline-block;
  }
}

.rs-picker-menu .rs-check-tree-node .rs-check-item {
  display: block;
}

.rs-check-tree-node {
  &:focus .rs-check-item .rs-checkbox-checker > label {
    color: #575757;
    color: var(--rs-text-primary);
    background-color: #f2faff;
    background-color: var(--rs-state-hover-bg);
  }

  .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
    font-weight: 700;
  }
}

.rs-check-tree-node-all-uncheckable .rs-check-item .rs-checkbox-checker > label {
  padding-left: 22px;

  &:before {
    width: 14px;
    margin-left: 0;
    left: 0;
  }
}

.rs-check-tree-node-expand-icon-wrapper {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  z-index: 1;

  > {
    .rs-check-tree-node-expand-icon {
      display: inline-block;
      padding: 8px 8px 8px 0;
      height: 36px;
      font-size: 16px;
      line-height: 22px;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      -webkit-transform-origin: 1.5px 14px;
      transform-origin: 1.5px 14px;
      margin-top: 6px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .rs-check-tree-node-expanded {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      margin-left: 0;
      margin-top: 0;
    }
  }
}

.rs-check-tree-open > .rs-check-tree-node .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  margin-left: 0;
  margin-top: 0;
}

.rs-check-tree-node-expand-icon-wrapper > {
  .rs-check-tree-node-custom-icon,
  .rs-check-tree-node-loading-icon {
    width: 14px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
  }
}

.rs-check-tree-node-children > .rs-check-tree-children {
  position: relative;
  display: none;
}

.rs-check-tree-open.rs-check-tree-node-children > .rs-check-tree-children {
  display: block;
}

.rs-check-tree-indent-line {
  height: 100%;
  width: 1px;
  position: absolute;
  top: 0;
  margin-left: -13px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.rs-picker-menu {
  &.rs-check-tree-menu {
    padding-top: 12px;

    .rs-picker-search-bar {
      padding-top: 0;
    }
  }

  .rs-check-tree {
    padding: 0 12px 12px 0;

    &.rs-check-tree-virtualized {
      padding: 0;
    }
  }

  .rs-check-tree-node > .rs-check-tree-node-label .rs-check-tree-node-text-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
}

[dir="rtl"] {
  .rs-check-tree-node-expand-icon-wrapper {
    right: 0;
    padding-right: inherit;
  }

  .ReactVirtualized__Grid__innerScrollContainer
    .rs-check-tree-node
    > .rs-check-tree-node-label
    .rs-check-item
    .rs-checkbox-checker
    > label:before {
    padding-right: inherit;
    right: 46px;
  }
}

.rs-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}

.rs-container-has-sidebar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.rs-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.rs-form-control-label {
  margin-bottom: 4px;
}

.rs-form-plaintext .rs-form-control-label {
  color: #8e8e93;
  color: var(--rs-text-secondary);
}

.rs-picker-date.rs-picker-default {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 7px;
  }
}

.rs-picker-daterange.rs-picker-default {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 7px;
  }
}

.rs-picker-toolbar {
  padding: 12px;
  border-top: 1px solid #EAECF0;
  border-top: 1px solid var(--rs-border-primary);
}

.rs-picker-date-inline {
  height: 299px;

  .rs-calendar {
    height: 286px;
  }
}

.rs-picker-menu {
  .rs-picker-toolbar {
    max-width: 100%;
  }

  .rs-picker-toolbar-ranges {
    max-width: 400px;
  }

  .rs-calendar {
    min-width: 246px;
    display: block;
    margin: 0 auto;
  }

  .rs-calendar-show-week-numbers {
    min-width: 278px;
  }

  .rs-calendar-header {
    width: 100%;
  }

  .rs-calendar-table {
    width: unset;
  }

  .rs-calendar {
    .rs-calendar-month-dropdown-cell-content,
    .rs-calendar-table-cell-content {
      width: 30px;
      height: 30px;
    }

    .rs-calendar-month-dropdown-cell-content:hover {
      background-color: rgba(204, 233, 255, 0.5);
      background-color: var(--rs-listbox-option-hover-bg);
      color: #1675e0;
      color: var(--rs-listbox-option-hover-text);
    }
  }
}

.rs-theme-high-contrast {
  .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    text-decoration: underline;
  }

  .rs-theme-high-contrast .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
    outline-offset: 2px;
  }
}

.rs-picker-menu .rs-calendar {
  .rs-calendar-table-header-row .rs-calendar-table-cell-content {
    height: 24px;
    padding-top: 0;
  }

  .rs-calendar-table-cell-content {
    padding-left: 0;
    padding-right: 0;
    display: inline-block;
  }

  .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
}

.rs-theme-high-contrast {
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    text-decoration: underline;
  }

  .rs-theme-high-contrast .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    outline-offset: 2px;
  }
}

.rs-picker-menu .rs-calendar {
  .rs-calendar-month-dropdown-scroll {
    height: 227px;
  }

  .rs-calendar-time-dropdown-column > ul {
    height: 214px;
    padding-bottom: 184px;
  }
}

.rs-picker-daterange-menu {
  .rs-calendar {
    display: inline-block;
    height: 274px;
    padding-bottom: 12px;

    &:first-child {
      border-right: 1px solid #EAECF0;
      border-right: 1px solid var(--rs-border-primary);
    }
  }

  .rs-calendar-header {
    width: 100%;
    text-align: center;
  }

  .rs-calendar-header-month-toolbar {
    float: none;
  }

  .rs-calendar-month-dropdown {
    z-index: 1;
  }

  .rs-calendar-month-dropdown-list {
    width: 185px;
  }

  .rs-picker-daterange-panel-show-one-calendar {
    .rs-picker-toolbar {
      max-width: 255px;
    }

    .rs-picker-toolbar-ranges {
      width: 190px;
    }
  }

  .rs-picker-daterange-calendar-single .rs-calendar {
    border: 0;
    display: block;
    margin: auto;
  }
}

.rs-picker-daterange-header {
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-bottom: 1px solid #EAECF0;
  border-bottom: 1px solid var(--rs-border-primary);
}

.rs-picker-daterange-calendar-group {
  height: 274px;
  min-width: 492px;
}

.rs-picker-daterange-predefined {
  height: 366px;
  border-right: 1px solid #EAECF0;
  border-right: 1px solid var(--rs-border-primary);
  padding: 4px 0;

  .rs-btn {
    display: block;
  }
}

.rs-divider {
  background-color: #EAECF0;
  background-color: var(--rs-divider-border);
}

.rs-divider-vertical {
  display: inline-block;
  height: 1em;
  width: 1px;
  vertical-align: middle;
  margin: 0 12px;
}

.rs-divider-horizontal {
  height: 1px;
  margin: 24px 0;

  &.rs-divider-with-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: transparent;
    margin: 30px 0;

    &:after,
    &:before {
      content: "";
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      border-top: 1px solid #EAECF0;
      border-top: 1px solid var(--rs-divider-border);
    }
  }
}

.rs-divider-inner-text {
  padding: 0 12px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

@-webkit-keyframes shakeHead {
  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  25%,
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  50% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shakeHead {
  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  25%,
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  50% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.rs-drawer-wrapper {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rs-drawer {
  display: none;
  position: fixed;
  z-index: 1050;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--rs-drawer-shadow);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: var(--rs-drawer-shadow);
  outline: 0;
  overflow: hidden;
}

.rs-drawer-open.rs-drawer-has-backdrop {
  overflow: hidden;
}

.rs-drawer-left,
.rs-drawer-right {
  top: 0;
  height: 100%;
}

.rs-drawer-left.rs-drawer-lg,
.rs-drawer-right.rs-drawer-lg {
  width: 968px;
}

.rs-drawer-left.rs-drawer-md,
.rs-drawer-right.rs-drawer-md {
  width: 800px;
}

.rs-drawer-left.rs-drawer-sm,
.rs-drawer-right.rs-drawer-sm {
  width: 600px;
}

.rs-drawer-left.rs-drawer-xs,
.rs-drawer-right.rs-drawer-xs {
  width: 400px;
}

.rs-drawer-bottom,
.rs-drawer-top {
  width: 100%;
}

.rs-drawer-bottom.rs-drawer-lg,
.rs-drawer-top.rs-drawer-lg {
  height: 568px;
}

.rs-drawer-bottom.rs-drawer-md,
.rs-drawer-top.rs-drawer-md {
  height: 480px;
}

.rs-drawer-bottom.rs-drawer-sm,
.rs-drawer-top.rs-drawer-sm {
  height: 400px;
}

.rs-drawer-bottom.rs-drawer-xs,
.rs-drawer-top.rs-drawer-xs {
  height: 290px;
}

.rs-drawer-full {
  &.rs-drawer-bottom,
  &.rs-drawer-top {
    height: calc(100% - 60px);
  }

  &.rs-drawer-bottom .rs-drawer-content,
  &.rs-drawer-top .rs-drawer-content {
    height: 100%;
  }

  &.rs-drawer-left,
  &.rs-drawer-right {
    width: calc(100% - 60px);
  }
}

.rs-drawer-right {
  right: 0;
}

.rs-drawer-left {
  left: 0;
}

.rs-drawer-top {
  top: 0;
}

.rs-drawer-bottom {
  bottom: 0;
}

.rs-drawer-open .rs-drawer {
  overflow: visible;
}

.rs-drawer-dialog {
  position: relative;
  width: 100%;
  height: 100%;
}

.rs-drawer-shake .rs-drawer-dialog {
  -webkit-animation: shakeHead 0.3s linear;
  animation: shakeHead 0.3s linear;
}

.rs-drawer-content {
  position: absolute;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  outline: 0;
  width: 100%;
  height: 100%;
}

.rs-theme-high-contrast .rs-drawer-content {
  background-color: var(--rs-drawer-bg);
}

.rs-drawer-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: rgba(39, 44, 54, 0.3);
  background-color: var(--rs-bg-backdrop);

  &.rs-anim-fade {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
  }

  &.rs-anim-in {
    opacity: 1;
  }
}

.rs-drawer-header {
  position: relative;
  padding: 20px 40px 20px 60px;
  border-bottom: 1px solid #EAECF0;
  border-bottom: 1px solid var(--rs-border-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  .rs-drawer-header-close {
    position: absolute;
    left: 15px;
    top: 23px;
  }
}

.rs-drawer-title {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  margin: 0;
  color: #272c36;
  color: var(--rs-text-heading);
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rs-drawer-actions {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-align: right;
  border-top: none;
  margin-left: auto;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.rs-drawer-title ~ .rs-drawer-actions {
  margin-left: 10px;
}

.rs-drawer-actions {
  .rs-btn + .rs-btn {
    margin-left: 10px;
    margin-bottom: 0;
  }

  .rs-btn-group .rs-btn + .rs-btn {
    margin-left: -1px;
  }

  .rs-btn-block + .rs-btn-block {
    margin-left: 0;
  }
}

.rs-drawer-body {
  position: relative;
  padding: 30px 60px;
  height: 100%;
  overflow: auto;
}

.rs-drawer-header + .rs-drawer-body {
  height: calc(100% - 76px);
}

.rs-drawer-body-close {
  position: absolute;
  left: 15px;
  top: 25px;
}

.rs-drawer-header ~ .rs-drawer-body .rs-drawer-body-close {
  display: none;
}

.rs-drawer-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.rs-drawer-footer {
  text-align: right;
  border-top: none;
  margin: 0 20px 20px;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  .rs-btn + .rs-btn {
    margin-left: 10px;
    margin-bottom: 0;
  }

  .rs-btn-group .rs-btn + .rs-btn {
    margin-left: -1px;
  }

  .rs-btn-block + .rs-btn-block {
    margin-left: 0;
  }
}

.rs-dropdown-toggle-caret {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  height: 20px;
  font-size: 12px;
}

.rs-dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  .rs-btn > {
    .rs-dropdown-toggle-icon {
      margin-right: 6px;
    }

    .rs-dropdown-toggle-caret {
      margin-left: 4px;
    }
  }
}

.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn {
  cursor: not-allowed !important;
  -webkit-box-shadow: none;
  box-shadow: none;

  &.rs-btn-subtle {
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;

    &:active,
    &:focus {
      color: #c5c6c7;
      color: var(--rs-btn-subtle-disabled-text);
      background: none;
    }
  }

  &.rs-btn-primary {
    opacity: 0.3;
    background-color: #3498ff;
    background-color: var(--rs-btn-primary-bg);

    &:active,
    &:focus {
      opacity: 0.3;
      background-color: #3498ff;
      background-color: var(--rs-btn-primary-bg);
    }
  }

  &.rs-btn-link {
    opacity: 0.3;
    text-decoration: none;

    &:active,
    &:focus {
      opacity: 0.3;
      text-decoration: none;
    }
  }

  &.rs-btn-ghost {
    opacity: 0.3;

    &:active,
    &:focus {
      opacity: 0.3;
    }
  }

  .rs-ripple-pond {
    display: none !important;
  }
}

.rs-dropdown-toggle {
  &.rs-btn-lg {
    padding-right: 36px !important;

    .rs-dropdown-toggle-caret {
      top: 10px;
      right: 16px;
      font-size: 14px;
    }
  }

  &.rs-btn-sm {
    padding-right: 30px !important;

    .rs-dropdown-toggle-caret {
      top: 5px;
      right: 10px;
      font-size: 10px;
    }
  }

  &.rs-btn-xs {
    padding-right: 28px !important;

    .rs-dropdown-toggle-caret {
      top: 2px;
      right: 8px;
      font-size: 8px;
    }
  }

  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  cursor: pointer;

  &.rs-btn {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
    cursor: pointer;
  }

  &.rs-dropdown-toggle-no-caret {
    padding-right: 12px;
  }
}

.rs-dropdown-menu {
  margin: 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  border-radius: 6px;
  padding: 6px 0;
  outline: 0;

  &:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
  }
}


.rs-theme-high-contrast .rs-dropdown-menu:focus-visible {
  outline-offset: 2px;
}

.rs-dropdown-menu {
  position: absolute;
  z-index: 99;
  float: left;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: var(--rs-dropdown-shadow);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--rs-dropdown-shadow);
  outline: none;
}

.rs-theme-high-contrast .rs-dropdown .rs-dropdown-menu {
  border: 1px solid #EAECF0;
  border: 1px solid var(--rs-border-primary);
}

.rs-dropdown-menu[hidden] {
  display: none;
}

.rs-dropdown-item {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }

  > .rs-icon {
    width: 14px;
    text-align: center;
    margin-right: 6px;
  }

  &.rs-dropdown-item-active {
    outline: 0;
    font-weight: 700;
    color: #1675e0;
    color: var(--rs-dropdown-item-text-active);
    background-color: #f2faff;
    background-color: var(--rs-dropdown-item-bg-active);

    &:focus,
    &:hover {
      color: #1675e0;
      color: var(--rs-dropdown-item-text-active);
      background-color: #f2faff;
      background-color: var(--rs-dropdown-item-bg-active);
    }
  }

  &.rs-dropdown-item-focus,
  &:focus-visible {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: var(--rs-menuitem-active-bg);
    color: #1675e0;
    color: var(--rs-menuitem-active-text);
  }
}

.rs-theme-high-contrast .rs-dropdown-item {
  &.rs-dropdown-item-focus,
  &:focus-visible {
    text-decoration: underline;
    -webkit-box-shadow: inset 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff;
  }
}

.rs-dropdown-item:focus {
  outline: 0;
}

.rs-dropdown-item-disabled {
  color: #c5c6c7;
  color: var(--rs-text-disabled);
  cursor: not-allowed;
  pointer-events: none;

  &:focus,
  &:hover {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
  }
}

.rs-dropdown-item-divider {
  height: 1px;
  margin: 6px 0;
  overflow: hidden;
  background-color: #EAECF0;
  background-color: var(--rs-dropdown-divider);
}

.rs-dropdown-item-submenu {
  position: relative;

  &:hover > .rs-dropdown-item-toggle {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: var(--rs-menuitem-active-bg);
    color: #1675e0;
    color: var(--rs-menuitem-active-text);
  }
}

.rs-theme-high-contrast .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle {
  text-decoration: underline;
  -webkit-box-shadow: inset 0 0 0 2px #fff;
  box-shadow: inset 0 0 0 2px #fff;
}

.rs-dropdown-item-submenu {
  &.rs-dropdown-item {
    position: relative;

    > {
      .rs-dropdown-item-toggle .rs-dropdown-menu-toggle-icon {
        position: absolute;
        top: 11px;
        margin: 0;
      }

      .rs-dropdown-menu {
        position: absolute;
        top: -6px;
      }
    }

    padding: 0;

    .rs-dropdown-item-toggle {
      padding: 8px 27px 8px 12px;
    }

    .rs-dropdown-menu-toggle-icon {
      right: 9px;
    }
  }

  .rs-dropdown-menu {
    left: 100%;

    &[data-direction="start"] {
      left: unset;
      right: 100%;
    }
  }

  &.rs-dropdown-item-open > .rs-dropdown-menu {
    display: table;
    -webkit-animation-name: slideUpIn;
    animation-name: slideUpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
}

.rs-dropdown-menu > .rs-dropdown-menu-item-focus {
  outline: 0;
  background-color: #f2faff;
  background-color: var(--rs-dropdown-item-bg-active);

  &:focus,
  &:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: var(--rs-menuitem-active-bg);
    color: #1675e0;
    color: var(--rs-menuitem-active-text);
  }
}

.rs-theme-high-contrast .rs-dropdown-menu > .rs-dropdown-menu-item-focus {
  &:focus,
  &:hover {
    text-decoration: underline;
    -webkit-box-shadow: inset 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff;
  }
}

.rs-dropdown-open {
  > .rs-dropdown-menu {
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }

  &.rs-dropdown-placement-top-end > .rs-dropdown-menu,
  &.rs-dropdown-placement-top-start > .rs-dropdown-menu {
    bottom: 100%;
  }

  &.rs-dropdown-placement-left-end > .rs-dropdown-menu,
  &.rs-dropdown-placement-left-start > .rs-dropdown-menu {
    right: 100%;
  }

  &.rs-dropdown-placement-right-end > .rs-dropdown-menu,
  &.rs-dropdown-placement-right-start > .rs-dropdown-menu {
    left: 100%;
  }

  &.rs-dropdown-placement-bottom-end > .rs-dropdown-menu {
    top: 100%;
  }

  &.rs-dropdown-placement-bottom-start > .rs-dropdown-menu {
    top: 100%;
    left: 0;
  }

  &.rs-dropdown-placement-top-start > .rs-dropdown-menu {
    left: 0;
  }

  &.rs-dropdown-placement-bottom-end > .rs-dropdown-menu,
  &.rs-dropdown-placement-top-end > .rs-dropdown-menu {
    right: 0;
  }

  &.rs-dropdown-placement-left-start > .rs-dropdown-menu,
  &.rs-dropdown-placement-right-start > .rs-dropdown-menu {
    top: 0;
  }

  &.rs-dropdown-placement-left-end > .rs-dropdown-menu,
  &.rs-dropdown-placement-right-end > .rs-dropdown-menu {
    bottom: 0;
  }

  > a {
    outline: 0;
  }
}

.rs-dropdown-header {
  display: block;
  padding: 8px 12px;
  line-height: 1.42857143;
  color: #a6a6a6;
  color: var(--rs-dropdown-header-text);
  border-bottom: 1px solid #EAECF0;
  border-bottom: 1px solid var(--rs-border-primary);
  white-space: nowrap;
}

@-webkit-keyframes slideUpIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes slideUpIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

.rs-flex-box-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.rs-flex-box-grid-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.rs-flex-box-grid-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.rs-flex-box-grid-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rs-flex-box-grid-bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.rs-flex-box-grid-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.rs-flex-box-grid-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rs-flex-box-grid-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.rs-flex-box-grid-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.rs-flex-box-grid-space-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.rs-flex-box-grid-item-1 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 4.16666667%;
}

.rs-flex-box-grid-item-order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.rs-flex-box-grid-item-2 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 8.33333333%;
}

.rs-flex-box-grid-item-order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.rs-flex-box-grid-item-3 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 12.5%;
}

.rs-flex-box-grid-item-order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.rs-flex-box-grid-item-4 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 16.66666667%;
}

.rs-flex-box-grid-item-order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.rs-flex-box-grid-item-5 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 20.83333333%;
}

.rs-flex-box-grid-item-order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.rs-flex-box-grid-item-6 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 25%;
}

.rs-flex-box-grid-item-order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.rs-flex-box-grid-item-7 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 29.16666667%;
}

.rs-flex-box-grid-item-order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.rs-flex-box-grid-item-8 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 33.33333333%;
}

.rs-flex-box-grid-item-order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.rs-flex-box-grid-item-9 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 37.5%;
}

.rs-flex-box-grid-item-order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.rs-flex-box-grid-item-10 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 41.66666667%;
}

.rs-flex-box-grid-item-order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.rs-flex-box-grid-item-11 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 45.83333333%;
}

.rs-flex-box-grid-item-order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.rs-flex-box-grid-item-12 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 50%;
}

.rs-flex-box-grid-item-order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.rs-flex-box-grid-item-13 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 54.16666667%;
}

.rs-flex-box-grid-item-order-13 {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.rs-flex-box-grid-item-14 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 58.33333333%;
}

.rs-flex-box-grid-item-order-14 {
  -webkit-box-ordinal-group: 15;
  -ms-flex-order: 14;
  order: 14;
}

.rs-flex-box-grid-item-15 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 62.5%;
}

.rs-flex-box-grid-item-order-15 {
  -webkit-box-ordinal-group: 16;
  -ms-flex-order: 15;
  order: 15;
}

.rs-flex-box-grid-item-16 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 66.66666667%;
}

.rs-flex-box-grid-item-order-16 {
  -webkit-box-ordinal-group: 17;
  -ms-flex-order: 16;
  order: 16;
}

.rs-flex-box-grid-item-17 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 70.83333333%;
}

.rs-flex-box-grid-item-order-17 {
  -webkit-box-ordinal-group: 18;
  -ms-flex-order: 17;
  order: 17;
}

.rs-flex-box-grid-item-18 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 75%;
}

.rs-flex-box-grid-item-order-18 {
  -webkit-box-ordinal-group: 19;
  -ms-flex-order: 18;
  order: 18;
}

.rs-flex-box-grid-item-19 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 79.16666667%;
}

.rs-flex-box-grid-item-order-19 {
  -webkit-box-ordinal-group: 20;
  -ms-flex-order: 19;
  order: 19;
}

.rs-flex-box-grid-item-20 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 83.33333333%;
}

.rs-flex-box-grid-item-order-20 {
  -webkit-box-ordinal-group: 21;
  -ms-flex-order: 20;
  order: 20;
}

.rs-flex-box-grid-item-21 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 87.5%;
}

.rs-flex-box-grid-item-order-21 {
  -webkit-box-ordinal-group: 22;
  -ms-flex-order: 21;
  order: 21;
}

.rs-flex-box-grid-item-22 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 91.66666667%;
}

.rs-flex-box-grid-item-order-22 {
  -webkit-box-ordinal-group: 23;
  -ms-flex-order: 22;
  order: 22;
}

.rs-flex-box-grid-item-23 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 95.83333333%;
}

.rs-flex-box-grid-item-order-23 {
  -webkit-box-ordinal-group: 24;
  -ms-flex-order: 23;
  order: 23;
}

.rs-flex-box-grid-item-24 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 100%;
}

.rs-flex-box-grid-item-order-24 {
  -webkit-box-ordinal-group: 25;
  -ms-flex-order: 24;
  order: 24;
}

.rs-footer {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.rs-form-inline {
  > {
    *,
    .rs-btn {
      margin-right: 20px;
      margin-bottom: 24px;
      vertical-align: top;
    }
  }

  .rs-form-control-label {
    vertical-align: top;
    margin-bottom: auto;
    margin-right: 12px;
    margin-top: 8px;
    display: inline-block;
  }
}

.rs-form-fluid {
  .rs-form-control-wrapper {
    width: 100%;

    > {
      .rs-input,
      .rs-input-number {
        width: 100%;
      }
    }
  }

  &.rs-form-vertical .rs-form-group .rs-input-group {
    width: 100%;
  }
}

.rs-form-control-wrapper {
  position: relative;

  > {
    .rs-input,
    .rs-input-number {
      width: 300px;
    }
  }

  &.read-only {
    pointer-events: none;
    -ms-touch-action: none;
    touch-action: none;
  }
}

.rs-form-vertical .rs-form-group {
  .rs-input-group {
    width: 300px;
  }

  .rs-form-control-wrapper {
    display: inline-block;
    max-width: 100%;
  }
}

.rs-form-horizontal .rs-form-group .rs-form-control-wrapper {
  float: left;

  + .rs-form-help-text {
    clear: both;

    &:not(.rs-form-help-text-tooltip) {
      margin-left: 182px;
    }
  }
}

.rs-form-inline .rs-form-group {
  .rs-form-control-wrapper {
    display: inline-block;
  }

  .rs-sr-only + .rs-form-control-wrapper {
    margin-left: 0;
  }
}

.rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child) {
  margin-bottom: 24px;
}

.rs-form-group {
  .rs-input {
    display: inline-block;
  }

  textarea.rs-input {
    vertical-align: bottom;
  }
}

.rs-form-vertical .rs-form-group {
  .rs-form-control-label {
    display: block;
  }

  .rs-form-help-text:not(.rs-form-help-text-tooltip) {
    padding-top: 4px;
  }
}

.rs-form-horizontal .rs-form-group {
  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  .rs-form-control-label {
    float: left;
    display: inline-block;
    min-height: 20px;
    line-height: 1.42857143;
    font-size: 14px;
    padding: 8px 0;
    width: 170px;
    margin-right: 12px;
    text-align: right;
  }

  .rs-btn-toolbar {
    padding-left: 182px;
  }
}

.rs-form-inline .rs-form-group {
  display: inline-block;
}

@-webkit-keyframes errorMessageSlideUpIn {
  0% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes errorMessageSlideUpIn {
  0% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes errorMessageSlideDownIn {
  0% {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes errorMessageSlideDownIn {
  0% {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes errorMessageSlideLeftIn {
  0% {
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes errorMessageSlideLeftIn {
  0% {
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes errorMessageSlideRightIn {
  0% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes errorMessageSlideRightIn {
  0% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.rs-form-error-message {
  position: absolute;
  padding: 3px 7px;
  background-color: #fff;
  background-color: var(--rs-form-errormessage-bg);
  border: 1px solid #EAECF0;
  border: 1px solid var(--rs-form-errormessage-border);
  border-radius: 6px;
  -webkit-filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  z-index: 5;
  color: #f44336;
  color: var(--rs-form-errormessage-text);
  font-size: 12px;
  line-height: 20px;
  display: none;
  white-space: nowrap;
}

.rs-form-error-message-wrapper {
  position: absolute;
}

.rs-form-error-message-show {
  display: block;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.rs-form-error-message-arrow {
  &:after,
  &:before {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid;
  }
}

.rs-form-error-message-placement-bottom-end,
.rs-form-error-message-placement-bottom-start {
  bottom: 0;
}

.rs-form-error-message-placement-bottom-end .rs-form-error-message,
.rs-form-error-message-placement-bottom-start .rs-form-error-message {
  top: 0;
}

.rs-form-error-message-placement-bottom-end .rs-form-error-message-show,
.rs-form-error-message-placement-bottom-start .rs-form-error-message-show {
  -webkit-animation-name: errorMessageSlideDownIn;
  animation-name: errorMessageSlideDownIn;
}

.rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow {
  &:after,
  &:before {
    border-width: 0 6px 6px;
    border-bottom-color: #EAECF0;
    border-bottom-color: var(--rs-form-errormessage-border);
    top: -6px;
  }
}

.rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow {
  &:after,
  &:before {
    border-width: 0 6px 6px;
    border-bottom-color: #EAECF0;
    border-bottom-color: var(--rs-form-errormessage-border);
    top: -6px;
  }
}

.rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow:after,
.rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow:after {
  top: -5px;
  border-bottom-color: #fff;
  border-bottom-color: var(--rs-form-errormessage-bg);
}

.rs-form-error-message-placement-top-end,
.rs-form-error-message-placement-top-start {
  top: 0;
}

.rs-form-error-message-placement-top-end .rs-form-error-message,
.rs-form-error-message-placement-top-start .rs-form-error-message {
  bottom: 0;
}

.rs-form-error-message-placement-top-end .rs-form-error-message-show,
.rs-form-error-message-placement-top-start .rs-form-error-message-show {
  -webkit-animation-name: errorMessageSlideUpIn;
  animation-name: errorMessageSlideUpIn;
}

.rs-form-error-message-placement-top-end .rs-form-error-message-arrow {
  &:after,
  &:before {
    border-width: 6px 6px 0;
    border-top-color: #EAECF0;
    border-top-color: var(--rs-form-errormessage-border);
    bottom: -6px;
  }
}

.rs-form-error-message-placement-top-start .rs-form-error-message-arrow {
  &:after,
  &:before {
    border-width: 6px 6px 0;
    border-top-color: #EAECF0;
    border-top-color: var(--rs-form-errormessage-border);
    bottom: -6px;
  }
}

.rs-form-error-message-placement-top-end .rs-form-error-message-arrow:after,
.rs-form-error-message-placement-top-start .rs-form-error-message-arrow:after {
  bottom: -5px;
  border-top-color: #fff;
  border-top-color: var(--rs-form-errormessage-bg);
}

.rs-form-error-message-placement-bottom-start,
.rs-form-error-message-placement-top-start {
  left: 0;

  .rs-form-error-message {
    left: 0;
  }
}

.rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow {
  &:after,
  &:before {
    left: 10px;
  }
}

.rs-form-error-message-placement-top-start .rs-form-error-message-arrow {
  &:after,
  &:before {
    left: 10px;
  }
}

.rs-form-error-message-placement-bottom-end,
.rs-form-error-message-placement-top-end {
  right: 0;

  .rs-form-error-message {
    right: 0;
  }
}

.rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow {
  &:after,
  &:before {
    right: 10px;
  }
}

.rs-form-error-message-placement-top-end .rs-form-error-message-arrow {
  &:after,
  &:before {
    right: 10px;
  }
}

.rs-form-error-message-placement-left-end,
.rs-form-error-message-placement-left-start {
  left: 0;
}

.rs-form-error-message-placement-left-end .rs-form-error-message,
.rs-form-error-message-placement-left-start .rs-form-error-message {
  right: 0;
}

.rs-form-error-message-placement-left-end .rs-form-error-message-show,
.rs-form-error-message-placement-left-start .rs-form-error-message-show {
  -webkit-animation-name: errorMessageSlideRightIn;
  animation-name: errorMessageSlideRightIn;
}

.rs-form-error-message-placement-left-end .rs-form-error-message-arrow {
  &:after,
  &:before {
    border-width: 6px 0 6px 6px;
    border-left-color: #EAECF0;
    border-left-color: var(--rs-form-errormessage-border);
    right: -6px;
  }
}

.rs-form-error-message-placement-left-start .rs-form-error-message-arrow {
  &:after,
  &:before {
    border-width: 6px 0 6px 6px;
    border-left-color: #EAECF0;
    border-left-color: var(--rs-form-errormessage-border);
    right: -6px;
  }
}

.rs-form-error-message-placement-left-end .rs-form-error-message-arrow:after,
.rs-form-error-message-placement-left-start .rs-form-error-message-arrow:after {
  right: -5px;
  border-left-color: #fff;
  border-left-color: var(--rs-form-errormessage-bg);
}

.rs-form-error-message-placement-right-end,
.rs-form-error-message-placement-right-start {
  right: 0;
}

.rs-form-error-message-placement-right-end .rs-form-error-message,
.rs-form-error-message-placement-right-start .rs-form-error-message {
  left: 0;
}

.rs-form-error-message-placement-right-end .rs-form-error-message-show,
.rs-form-error-message-placement-right-start .rs-form-error-message-show {
  -webkit-animation-name: errorMessageSlideLeftIn;
  animation-name: errorMessageSlideLeftIn;
}

.rs-form-error-message-placement-right-end .rs-form-error-message-arrow {
  &:after,
  &:before {
    border-width: 6px 6px 6px 0;
    border-right-color: #EAECF0;
    border-right-color: var(--rs-form-errormessage-border);
    left: -6px;
  }
}

.rs-form-error-message-placement-right-start .rs-form-error-message-arrow {
  &:after,
  &:before {
    border-width: 6px 6px 6px 0;
    border-right-color: #EAECF0;
    border-right-color: var(--rs-form-errormessage-border);
    left: -6px;
  }
}

.rs-form-error-message-placement-right-end .rs-form-error-message-arrow:after,
.rs-form-error-message-placement-right-start .rs-form-error-message-arrow:after {
  left: -5px;
  border-right-color: #fff;
  border-right-color: var(--rs-form-errormessage-bg);
}

.rs-form-error-message-placement-left-start,
.rs-form-error-message-placement-right-start {
  top: 0;

  .rs-form-error-message {
    top: 0;
  }
}

.rs-form-error-message-placement-left-start .rs-form-error-message-arrow {
  &:after,
  &:before {
    top: 4px;
  }
}

.rs-form-error-message-placement-right-start .rs-form-error-message-arrow {
  &:after,
  &:before {
    top: 4px;
  }
}

.rs-form-error-message-placement-left-end,
.rs-form-error-message-placement-right-end {
  bottom: 0;

  .rs-form-error-message {
    bottom: 0;
  }
}

.rs-form-error-message-placement-left-end .rs-form-error-message-arrow {
  &:after,
  &:before {
    bottom: 4px;
  }
}

.rs-form-error-message-placement-right-end .rs-form-error-message-arrow {
  &:after,
  &:before {
    bottom: 4px;
  }
}

.rs-grid-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@media (min-width: 576px) {
  .rs-grid-container {
    width: 550px;
  }
}

@media (min-width: 768px) {
  .rs-grid-container {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .rs-grid-container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .rs-grid-container {
    width: 970px;
  }
}

@media (min-width: 1400px) {
  .rs-grid-container {
    width: 970px;
  }
}

.rs-grid-container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.rs-col {
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 5px;
  padding-right: 5px;
}

.rs-col-xs-1 {
  width: 4.16666667%;
}

.rs-col-xs-pull-1 {
  right: 4.16666667%;
}

.rs-col-xs-push-1 {
  left: 4.16666667%;
}

.rs-col-xs-offset-1 {
  margin-left: 4.16666667%;
}

.rs-col-xs-2 {
  width: 8.33333333%;
}

.rs-col-xs-pull-2 {
  right: 8.33333333%;
}

.rs-col-xs-push-2 {
  left: 8.33333333%;
}

.rs-col-xs-offset-2 {
  margin-left: 8.33333333%;
}

.rs-col-xs-3 {
  width: 12.5%;
}

.rs-col-xs-pull-3 {
  right: 12.5%;
}

.rs-col-xs-push-3 {
  left: 12.5%;
}

.rs-col-xs-offset-3 {
  margin-left: 12.5%;
}

.rs-col-xs-4 {
  width: 16.66666667%;
}

.rs-col-xs-pull-4 {
  right: 16.66666667%;
}

.rs-col-xs-push-4 {
  left: 16.66666667%;
}

.rs-col-xs-offset-4 {
  margin-left: 16.66666667%;
}

.rs-col-xs-5 {
  width: 20.83333333%;
}

.rs-col-xs-pull-5 {
  right: 20.83333333%;
}

.rs-col-xs-push-5 {
  left: 20.83333333%;
}

.rs-col-xs-offset-5 {
  margin-left: 20.83333333%;
}

.rs-col-xs-6 {
  width: 25%;
}

.rs-col-xs-pull-6 {
  right: 25%;
}

.rs-col-xs-push-6 {
  left: 25%;
}

.rs-col-xs-offset-6 {
  margin-left: 25%;
}

.rs-col-xs-7 {
  width: 29.16666667%;
}

.rs-col-xs-pull-7 {
  right: 29.16666667%;
}

.rs-col-xs-push-7 {
  left: 29.16666667%;
}

.rs-col-xs-offset-7 {
  margin-left: 29.16666667%;
}

.rs-col-xs-8 {
  width: 33.33333333%;
}

.rs-col-xs-pull-8 {
  right: 33.33333333%;
}

.rs-col-xs-push-8 {
  left: 33.33333333%;
}

.rs-col-xs-offset-8 {
  margin-left: 33.33333333%;
}

.rs-col-xs-9 {
  width: 37.5%;
}

.rs-col-xs-pull-9 {
  right: 37.5%;
}

.rs-col-xs-push-9 {
  left: 37.5%;
}

.rs-col-xs-offset-9 {
  margin-left: 37.5%;
}

.rs-col-xs-10 {
  width: 41.66666667%;
}

.rs-col-xs-pull-10 {
  right: 41.66666667%;
}

.rs-col-xs-push-10 {
  left: 41.66666667%;
}

.rs-col-xs-offset-10 {
  margin-left: 41.66666667%;
}

.rs-col-xs-11 {
  width: 45.83333333%;
}

.rs-col-xs-pull-11 {
  right: 45.83333333%;
}

.rs-col-xs-push-11 {
  left: 45.83333333%;
}

.rs-col-xs-offset-11 {
  margin-left: 45.83333333%;
}

.rs-col-xs-12 {
  width: 50%;
}

.rs-col-xs-pull-12 {
  right: 50%;
}

.rs-col-xs-push-12 {
  left: 50%;
}

.rs-col-xs-offset-12 {
  margin-left: 50%;
}

.rs-col-xs-13 {
  width: 54.16666667%;
}

.rs-col-xs-pull-13 {
  right: 54.16666667%;
}

.rs-col-xs-push-13 {
  left: 54.16666667%;
}

.rs-col-xs-offset-13 {
  margin-left: 54.16666667%;
}

.rs-col-xs-14 {
  width: 58.33333333%;
}

.rs-col-xs-pull-14 {
  right: 58.33333333%;
}

.rs-col-xs-push-14 {
  left: 58.33333333%;
}

.rs-col-xs-offset-14 {
  margin-left: 58.33333333%;
}

.rs-col-xs-15 {
  width: 62.5%;
}

.rs-col-xs-pull-15 {
  right: 62.5%;
}

.rs-col-xs-push-15 {
  left: 62.5%;
}

.rs-col-xs-offset-15 {
  margin-left: 62.5%;
}

.rs-col-xs-16 {
  width: 66.66666667%;
}

.rs-col-xs-pull-16 {
  right: 66.66666667%;
}

.rs-col-xs-push-16 {
  left: 66.66666667%;
}

.rs-col-xs-offset-16 {
  margin-left: 66.66666667%;
}

.rs-col-xs-17 {
  width: 70.83333333%;
}

.rs-col-xs-pull-17 {
  right: 70.83333333%;
}

.rs-col-xs-push-17 {
  left: 70.83333333%;
}

.rs-col-xs-offset-17 {
  margin-left: 70.83333333%;
}

.rs-col-xs-18 {
  width: 75%;
}

.rs-col-xs-pull-18 {
  right: 75%;
}

.rs-col-xs-push-18 {
  left: 75%;
}

.rs-col-xs-offset-18 {
  margin-left: 75%;
}

.rs-col-xs-19 {
  width: 79.16666667%;
}

.rs-col-xs-pull-19 {
  right: 79.16666667%;
}

.rs-col-xs-push-19 {
  left: 79.16666667%;
}

.rs-col-xs-offset-19 {
  margin-left: 79.16666667%;
}

.rs-col-xs-20 {
  width: 83.33333333%;
}

.rs-col-xs-pull-20 {
  right: 83.33333333%;
}

.rs-col-xs-push-20 {
  left: 83.33333333%;
}

.rs-col-xs-offset-20 {
  margin-left: 83.33333333%;
}

.rs-col-xs-21 {
  width: 87.5%;
}

.rs-col-xs-pull-21 {
  right: 87.5%;
}

.rs-col-xs-push-21 {
  left: 87.5%;
}

.rs-col-xs-offset-21 {
  margin-left: 87.5%;
}

.rs-col-xs-22 {
  width: 91.66666667%;
}

.rs-col-xs-pull-22 {
  right: 91.66666667%;
}

.rs-col-xs-push-22 {
  left: 91.66666667%;
}

.rs-col-xs-offset-22 {
  margin-left: 91.66666667%;
}

.rs-col-xs-23 {
  width: 95.83333333%;
}

.rs-col-xs-pull-23 {
  right: 95.83333333%;
}

.rs-col-xs-push-23 {
  left: 95.83333333%;
}

.rs-col-xs-offset-23 {
  margin-left: 95.83333333%;
}

.rs-col-xs-24 {
  width: 100%;
}

.rs-col-xs-pull-24 {
  right: 100%;
}

.rs-col-xs-push-24 {
  left: 100%;
}

.rs-col-xs-offset-24 {
  margin-left: 100%;
}

.rs-col-xs-pull-0 {
  right: auto;
}

.rs-col-xs-push-0 {
  left: auto;
}

@media (min-width: 576px) {
  .rs-col-sm-1 {
    width: 4.16666667%;
  }

  .rs-col-sm-pull-1 {
    right: 4.16666667%;
  }

  .rs-col-sm-push-1 {
    left: 4.16666667%;
  }

  .rs-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }

  .rs-col-sm-2 {
    width: 8.33333333%;
  }

  .rs-col-sm-pull-2 {
    right: 8.33333333%;
  }

  .rs-col-sm-push-2 {
    left: 8.33333333%;
  }

  .rs-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }

  .rs-col-sm-3 {
    width: 12.5%;
  }

  .rs-col-sm-pull-3 {
    right: 12.5%;
  }

  .rs-col-sm-push-3 {
    left: 12.5%;
  }

  .rs-col-sm-offset-3 {
    margin-left: 12.5%;
  }

  .rs-col-sm-4 {
    width: 16.66666667%;
  }

  .rs-col-sm-pull-4 {
    right: 16.66666667%;
  }

  .rs-col-sm-push-4 {
    left: 16.66666667%;
  }

  .rs-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }

  .rs-col-sm-5 {
    width: 20.83333333%;
  }

  .rs-col-sm-pull-5 {
    right: 20.83333333%;
  }

  .rs-col-sm-push-5 {
    left: 20.83333333%;
  }

  .rs-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }

  .rs-col-sm-6 {
    width: 25%;
  }

  .rs-col-sm-pull-6 {
    right: 25%;
  }

  .rs-col-sm-push-6 {
    left: 25%;
  }

  .rs-col-sm-offset-6 {
    margin-left: 25%;
  }

  .rs-col-sm-7 {
    width: 29.16666667%;
  }

  .rs-col-sm-pull-7 {
    right: 29.16666667%;
  }

  .rs-col-sm-push-7 {
    left: 29.16666667%;
  }

  .rs-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }

  .rs-col-sm-8 {
    width: 33.33333333%;
  }

  .rs-col-sm-pull-8 {
    right: 33.33333333%;
  }

  .rs-col-sm-push-8 {
    left: 33.33333333%;
  }

  .rs-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }

  .rs-col-sm-9 {
    width: 37.5%;
  }

  .rs-col-sm-pull-9 {
    right: 37.5%;
  }

  .rs-col-sm-push-9 {
    left: 37.5%;
  }

  .rs-col-sm-offset-9 {
    margin-left: 37.5%;
  }

  .rs-col-sm-10 {
    width: 41.66666667%;
  }

  .rs-col-sm-pull-10 {
    right: 41.66666667%;
  }

  .rs-col-sm-push-10 {
    left: 41.66666667%;
  }

  .rs-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }

  .rs-col-sm-11 {
    width: 45.83333333%;
  }

  .rs-col-sm-pull-11 {
    right: 45.83333333%;
  }

  .rs-col-sm-push-11 {
    left: 45.83333333%;
  }

  .rs-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }

  .rs-col-sm-12 {
    width: 50%;
  }

  .rs-col-sm-pull-12 {
    right: 50%;
  }

  .rs-col-sm-push-12 {
    left: 50%;
  }

  .rs-col-sm-offset-12 {
    margin-left: 50%;
  }

  .rs-col-sm-13 {
    width: 54.16666667%;
  }

  .rs-col-sm-pull-13 {
    right: 54.16666667%;
  }

  .rs-col-sm-push-13 {
    left: 54.16666667%;
  }

  .rs-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }

  .rs-col-sm-14 {
    width: 58.33333333%;
  }

  .rs-col-sm-pull-14 {
    right: 58.33333333%;
  }

  .rs-col-sm-push-14 {
    left: 58.33333333%;
  }

  .rs-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }

  .rs-col-sm-15 {
    width: 62.5%;
  }

  .rs-col-sm-pull-15 {
    right: 62.5%;
  }

  .rs-col-sm-push-15 {
    left: 62.5%;
  }

  .rs-col-sm-offset-15 {
    margin-left: 62.5%;
  }

  .rs-col-sm-16 {
    width: 66.66666667%;
  }

  .rs-col-sm-pull-16 {
    right: 66.66666667%;
  }

  .rs-col-sm-push-16 {
    left: 66.66666667%;
  }

  .rs-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }

  .rs-col-sm-17 {
    width: 70.83333333%;
  }

  .rs-col-sm-pull-17 {
    right: 70.83333333%;
  }

  .rs-col-sm-push-17 {
    left: 70.83333333%;
  }

  .rs-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }

  .rs-col-sm-18 {
    width: 75%;
  }

  .rs-col-sm-pull-18 {
    right: 75%;
  }

  .rs-col-sm-push-18 {
    left: 75%;
  }

  .rs-col-sm-offset-18 {
    margin-left: 75%;
  }

  .rs-col-sm-19 {
    width: 79.16666667%;
  }

  .rs-col-sm-pull-19 {
    right: 79.16666667%;
  }

  .rs-col-sm-push-19 {
    left: 79.16666667%;
  }

  .rs-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }

  .rs-col-sm-20 {
    width: 83.33333333%;
  }

  .rs-col-sm-pull-20 {
    right: 83.33333333%;
  }

  .rs-col-sm-push-20 {
    left: 83.33333333%;
  }

  .rs-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }

  .rs-col-sm-21 {
    width: 87.5%;
  }

  .rs-col-sm-pull-21 {
    right: 87.5%;
  }

  .rs-col-sm-push-21 {
    left: 87.5%;
  }

  .rs-col-sm-offset-21 {
    margin-left: 87.5%;
  }

  .rs-col-sm-22 {
    width: 91.66666667%;
  }

  .rs-col-sm-pull-22 {
    right: 91.66666667%;
  }

  .rs-col-sm-push-22 {
    left: 91.66666667%;
  }

  .rs-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }

  .rs-col-sm-23 {
    width: 95.83333333%;
  }

  .rs-col-sm-pull-23 {
    right: 95.83333333%;
  }

  .rs-col-sm-push-23 {
    left: 95.83333333%;
  }

  .rs-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }

  .rs-col-sm-24 {
    width: 100%;
  }

  .rs-col-sm-pull-24 {
    right: 100%;
  }

  .rs-col-sm-push-24 {
    left: 100%;
  }

  .rs-col-sm-offset-24 {
    margin-left: 100%;
  }

  .rs-col-sm-pull-0 {
    right: auto;
  }

  .rs-col-sm-push-0 {
    left: auto;
  }
}

@media (min-width: 768px) {
  .rs-col-md-1 {
    width: 4.16666667%;
  }

  .rs-col-md-pull-1 {
    right: 4.16666667%;
  }

  .rs-col-md-push-1 {
    left: 4.16666667%;
  }

  .rs-col-md-offset-1 {
    margin-left: 4.16666667%;
  }

  .rs-col-md-2 {
    width: 8.33333333%;
  }

  .rs-col-md-pull-2 {
    right: 8.33333333%;
  }

  .rs-col-md-push-2 {
    left: 8.33333333%;
  }

  .rs-col-md-offset-2 {
    margin-left: 8.33333333%;
  }

  .rs-col-md-3 {
    width: 12.5%;
  }

  .rs-col-md-pull-3 {
    right: 12.5%;
  }

  .rs-col-md-push-3 {
    left: 12.5%;
  }

  .rs-col-md-offset-3 {
    margin-left: 12.5%;
  }

  .rs-col-md-4 {
    width: 16.66666667%;
  }

  .rs-col-md-pull-4 {
    right: 16.66666667%;
  }

  .rs-col-md-push-4 {
    left: 16.66666667%;
  }

  .rs-col-md-offset-4 {
    margin-left: 16.66666667%;
  }

  .rs-col-md-5 {
    width: 20.83333333%;
  }

  .rs-col-md-pull-5 {
    right: 20.83333333%;
  }

  .rs-col-md-push-5 {
    left: 20.83333333%;
  }

  .rs-col-md-offset-5 {
    margin-left: 20.83333333%;
  }

  .rs-col-md-6 {
    width: 25%;
  }

  .rs-col-md-pull-6 {
    right: 25%;
  }

  .rs-col-md-push-6 {
    left: 25%;
  }

  .rs-col-md-offset-6 {
    margin-left: 25%;
  }

  .rs-col-md-7 {
    width: 29.16666667%;
  }

  .rs-col-md-pull-7 {
    right: 29.16666667%;
  }

  .rs-col-md-push-7 {
    left: 29.16666667%;
  }

  .rs-col-md-offset-7 {
    margin-left: 29.16666667%;
  }

  .rs-col-md-8 {
    width: 33.33333333%;
  }

  .rs-col-md-pull-8 {
    right: 33.33333333%;
  }

  .rs-col-md-push-8 {
    left: 33.33333333%;
  }

  .rs-col-md-offset-8 {
    margin-left: 33.33333333%;
  }

  .rs-col-md-9 {
    width: 37.5%;
  }

  .rs-col-md-pull-9 {
    right: 37.5%;
  }

  .rs-col-md-push-9 {
    left: 37.5%;
  }

  .rs-col-md-offset-9 {
    margin-left: 37.5%;
  }

  .rs-col-md-10 {
    width: 41.66666667%;
  }

  .rs-col-md-pull-10 {
    right: 41.66666667%;
  }

  .rs-col-md-push-10 {
    left: 41.66666667%;
  }

  .rs-col-md-offset-10 {
    margin-left: 41.66666667%;
  }

  .rs-col-md-11 {
    width: 45.83333333%;
  }

  .rs-col-md-pull-11 {
    right: 45.83333333%;
  }

  .rs-col-md-push-11 {
    left: 45.83333333%;
  }

  .rs-col-md-offset-11 {
    margin-left: 45.83333333%;
  }

  .rs-col-md-12 {
    width: 50%;
  }

  .rs-col-md-pull-12 {
    right: 50%;
  }

  .rs-col-md-push-12 {
    left: 50%;
  }

  .rs-col-md-offset-12 {
    margin-left: 50%;
  }

  .rs-col-md-13 {
    width: 54.16666667%;
  }

  .rs-col-md-pull-13 {
    right: 54.16666667%;
  }

  .rs-col-md-push-13 {
    left: 54.16666667%;
  }

  .rs-col-md-offset-13 {
    margin-left: 54.16666667%;
  }

  .rs-col-md-14 {
    width: 58.33333333%;
  }

  .rs-col-md-pull-14 {
    right: 58.33333333%;
  }

  .rs-col-md-push-14 {
    left: 58.33333333%;
  }

  .rs-col-md-offset-14 {
    margin-left: 58.33333333%;
  }

  .rs-col-md-15 {
    width: 62.5%;
  }

  .rs-col-md-pull-15 {
    right: 62.5%;
  }

  .rs-col-md-push-15 {
    left: 62.5%;
  }

  .rs-col-md-offset-15 {
    margin-left: 62.5%;
  }

  .rs-col-md-16 {
    width: 66.66666667%;
  }

  .rs-col-md-pull-16 {
    right: 66.66666667%;
  }

  .rs-col-md-push-16 {
    left: 66.66666667%;
  }

  .rs-col-md-offset-16 {
    margin-left: 66.66666667%;
  }

  .rs-col-md-17 {
    width: 70.83333333%;
  }

  .rs-col-md-pull-17 {
    right: 70.83333333%;
  }

  .rs-col-md-push-17 {
    left: 70.83333333%;
  }

  .rs-col-md-offset-17 {
    margin-left: 70.83333333%;
  }

  .rs-col-md-18 {
    width: 75%;
  }

  .rs-col-md-pull-18 {
    right: 75%;
  }

  .rs-col-md-push-18 {
    left: 75%;
  }

  .rs-col-md-offset-18 {
    margin-left: 75%;
  }

  .rs-col-md-19 {
    width: 79.16666667%;
  }

  .rs-col-md-pull-19 {
    right: 79.16666667%;
  }

  .rs-col-md-push-19 {
    left: 79.16666667%;
  }

  .rs-col-md-offset-19 {
    margin-left: 79.16666667%;
  }

  .rs-col-md-20 {
    width: 83.33333333%;
  }

  .rs-col-md-pull-20 {
    right: 83.33333333%;
  }

  .rs-col-md-push-20 {
    left: 83.33333333%;
  }

  .rs-col-md-offset-20 {
    margin-left: 83.33333333%;
  }

  .rs-col-md-21 {
    width: 87.5%;
  }

  .rs-col-md-pull-21 {
    right: 87.5%;
  }

  .rs-col-md-push-21 {
    left: 87.5%;
  }

  .rs-col-md-offset-21 {
    margin-left: 87.5%;
  }

  .rs-col-md-22 {
    width: 91.66666667%;
  }

  .rs-col-md-pull-22 {
    right: 91.66666667%;
  }

  .rs-col-md-push-22 {
    left: 91.66666667%;
  }

  .rs-col-md-offset-22 {
    margin-left: 91.66666667%;
  }

  .rs-col-md-23 {
    width: 95.83333333%;
  }

  .rs-col-md-pull-23 {
    right: 95.83333333%;
  }

  .rs-col-md-push-23 {
    left: 95.83333333%;
  }

  .rs-col-md-offset-23 {
    margin-left: 95.83333333%;
  }

  .rs-col-md-24 {
    width: 100%;
  }

  .rs-col-md-pull-24 {
    right: 100%;
  }

  .rs-col-md-push-24 {
    left: 100%;
  }

  .rs-col-md-offset-24 {
    margin-left: 100%;
  }

  .rs-col-md-pull-0 {
    right: auto;
  }

  .rs-col-md-push-0 {
    left: auto;
  }
}

@media (min-width: 992px) {
  .rs-col-lg-1 {
    width: 4.16666667%;
  }

  .rs-col-lg-pull-1 {
    right: 4.16666667%;
  }

  .rs-col-lg-push-1 {
    left: 4.16666667%;
  }

  .rs-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }

  .rs-col-lg-2 {
    width: 8.33333333%;
  }

  .rs-col-lg-pull-2 {
    right: 8.33333333%;
  }

  .rs-col-lg-push-2 {
    left: 8.33333333%;
  }

  .rs-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }

  .rs-col-lg-3 {
    width: 12.5%;
  }

  .rs-col-lg-pull-3 {
    right: 12.5%;
  }

  .rs-col-lg-push-3 {
    left: 12.5%;
  }

  .rs-col-lg-offset-3 {
    margin-left: 12.5%;
  }

  .rs-col-lg-4 {
    width: 16.66666667%;
  }

  .rs-col-lg-pull-4 {
    right: 16.66666667%;
  }

  .rs-col-lg-push-4 {
    left: 16.66666667%;
  }

  .rs-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }

  .rs-col-lg-5 {
    width: 20.83333333%;
  }

  .rs-col-lg-pull-5 {
    right: 20.83333333%;
  }

  .rs-col-lg-push-5 {
    left: 20.83333333%;
  }

  .rs-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }

  .rs-col-lg-6 {
    width: 25%;
  }

  .rs-col-lg-pull-6 {
    right: 25%;
  }

  .rs-col-lg-push-6 {
    left: 25%;
  }

  .rs-col-lg-offset-6 {
    margin-left: 25%;
  }

  .rs-col-lg-7 {
    width: 29.16666667%;
  }

  .rs-col-lg-pull-7 {
    right: 29.16666667%;
  }

  .rs-col-lg-push-7 {
    left: 29.16666667%;
  }

  .rs-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }

  .rs-col-lg-8 {
    width: 33.33333333%;
  }

  .rs-col-lg-pull-8 {
    right: 33.33333333%;
  }

  .rs-col-lg-push-8 {
    left: 33.33333333%;
  }

  .rs-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }

  .rs-col-lg-9 {
    width: 37.5%;
  }

  .rs-col-lg-pull-9 {
    right: 37.5%;
  }

  .rs-col-lg-push-9 {
    left: 37.5%;
  }

  .rs-col-lg-offset-9 {
    margin-left: 37.5%;
  }

  .rs-col-lg-10 {
    width: 41.66666667%;
  }

  .rs-col-lg-pull-10 {
    right: 41.66666667%;
  }

  .rs-col-lg-push-10 {
    left: 41.66666667%;
  }

  .rs-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }

  .rs-col-lg-11 {
    width: 45.83333333%;
  }

  .rs-col-lg-pull-11 {
    right: 45.83333333%;
  }

  .rs-col-lg-push-11 {
    left: 45.83333333%;
  }

  .rs-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }

  .rs-col-lg-12 {
    width: 50%;
  }

  .rs-col-lg-pull-12 {
    right: 50%;
  }

  .rs-col-lg-push-12 {
    left: 50%;
  }

  .rs-col-lg-offset-12 {
    margin-left: 50%;
  }

  .rs-col-lg-13 {
    width: 54.16666667%;
  }

  .rs-col-lg-pull-13 {
    right: 54.16666667%;
  }

  .rs-col-lg-push-13 {
    left: 54.16666667%;
  }

  .rs-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }

  .rs-col-lg-14 {
    width: 58.33333333%;
  }

  .rs-col-lg-pull-14 {
    right: 58.33333333%;
  }

  .rs-col-lg-push-14 {
    left: 58.33333333%;
  }

  .rs-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }

  .rs-col-lg-15 {
    width: 62.5%;
  }

  .rs-col-lg-pull-15 {
    right: 62.5%;
  }

  .rs-col-lg-push-15 {
    left: 62.5%;
  }

  .rs-col-lg-offset-15 {
    margin-left: 62.5%;
  }

  .rs-col-lg-16 {
    width: 66.66666667%;
  }

  .rs-col-lg-pull-16 {
    right: 66.66666667%;
  }

  .rs-col-lg-push-16 {
    left: 66.66666667%;
  }

  .rs-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }

  .rs-col-lg-17 {
    width: 70.83333333%;
  }

  .rs-col-lg-pull-17 {
    right: 70.83333333%;
  }

  .rs-col-lg-push-17 {
    left: 70.83333333%;
  }

  .rs-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }

  .rs-col-lg-18 {
    width: 75%;
  }

  .rs-col-lg-pull-18 {
    right: 75%;
  }

  .rs-col-lg-push-18 {
    left: 75%;
  }

  .rs-col-lg-offset-18 {
    margin-left: 75%;
  }

  .rs-col-lg-19 {
    width: 79.16666667%;
  }

  .rs-col-lg-pull-19 {
    right: 79.16666667%;
  }

  .rs-col-lg-push-19 {
    left: 79.16666667%;
  }

  .rs-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }

  .rs-col-lg-20 {
    width: 83.33333333%;
  }

  .rs-col-lg-pull-20 {
    right: 83.33333333%;
  }

  .rs-col-lg-push-20 {
    left: 83.33333333%;
  }

  .rs-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }

  .rs-col-lg-21 {
    width: 87.5%;
  }

  .rs-col-lg-pull-21 {
    right: 87.5%;
  }

  .rs-col-lg-push-21 {
    left: 87.5%;
  }

  .rs-col-lg-offset-21 {
    margin-left: 87.5%;
  }

  .rs-col-lg-22 {
    width: 91.66666667%;
  }

  .rs-col-lg-pull-22 {
    right: 91.66666667%;
  }

  .rs-col-lg-push-22 {
    left: 91.66666667%;
  }

  .rs-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }

  .rs-col-lg-23 {
    width: 95.83333333%;
  }

  .rs-col-lg-pull-23 {
    right: 95.83333333%;
  }

  .rs-col-lg-push-23 {
    left: 95.83333333%;
  }

  .rs-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }

  .rs-col-lg-24 {
    width: 100%;
  }

  .rs-col-lg-pull-24 {
    right: 100%;
  }

  .rs-col-lg-push-24 {
    left: 100%;
  }

  .rs-col-lg-offset-24 {
    margin-left: 100%;
  }

  .rs-col-lg-pull-0 {
    right: auto;
  }

  .rs-col-lg-push-0 {
    left: auto;
  }
}

@media (min-width: 1200px) {
  .rs-col-xl-1 {
    width: 4.16666667%;
  }

  .rs-col-xl-pull-1 {
    right: 4.16666667%;
  }

  .rs-col-xl-push-1 {
    left: 4.16666667%;
  }

  .rs-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }

  .rs-col-xl-2 {
    width: 8.33333333%;
  }

  .rs-col-xl-pull-2 {
    right: 8.33333333%;
  }

  .rs-col-xl-push-2 {
    left: 8.33333333%;
  }

  .rs-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }

  .rs-col-xl-3 {
    width: 12.5%;
  }

  .rs-col-xl-pull-3 {
    right: 12.5%;
  }

  .rs-col-xl-push-3 {
    left: 12.5%;
  }

  .rs-col-xl-offset-3 {
    margin-left: 12.5%;
  }

  .rs-col-xl-4 {
    width: 16.66666667%;
  }

  .rs-col-xl-pull-4 {
    right: 16.66666667%;
  }

  .rs-col-xl-push-4 {
    left: 16.66666667%;
  }

  .rs-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }

  .rs-col-xl-5 {
    width: 20.83333333%;
  }

  .rs-col-xl-pull-5 {
    right: 20.83333333%;
  }

  .rs-col-xl-push-5 {
    left: 20.83333333%;
  }

  .rs-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }

  .rs-col-xl-6 {
    width: 25%;
  }

  .rs-col-xl-pull-6 {
    right: 25%;
  }

  .rs-col-xl-push-6 {
    left: 25%;
  }

  .rs-col-xl-offset-6 {
    margin-left: 25%;
  }

  .rs-col-xl-7 {
    width: 29.16666667%;
  }

  .rs-col-xl-pull-7 {
    right: 29.16666667%;
  }

  .rs-col-xl-push-7 {
    left: 29.16666667%;
  }

  .rs-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }

  .rs-col-xl-8 {
    width: 33.33333333%;
  }

  .rs-col-xl-pull-8 {
    right: 33.33333333%;
  }

  .rs-col-xl-push-8 {
    left: 33.33333333%;
  }

  .rs-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }

  .rs-col-xl-9 {
    width: 37.5%;
  }

  .rs-col-xl-pull-9 {
    right: 37.5%;
  }

  .rs-col-xl-push-9 {
    left: 37.5%;
  }

  .rs-col-xl-offset-9 {
    margin-left: 37.5%;
  }

  .rs-col-xl-10 {
    width: 41.66666667%;
  }

  .rs-col-xl-pull-10 {
    right: 41.66666667%;
  }

  .rs-col-xl-push-10 {
    left: 41.66666667%;
  }

  .rs-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }

  .rs-col-xl-11 {
    width: 45.83333333%;
  }

  .rs-col-xl-pull-11 {
    right: 45.83333333%;
  }

  .rs-col-xl-push-11 {
    left: 45.83333333%;
  }

  .rs-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }

  .rs-col-xl-12 {
    width: 50%;
  }

  .rs-col-xl-pull-12 {
    right: 50%;
  }

  .rs-col-xl-push-12 {
    left: 50%;
  }

  .rs-col-xl-offset-12 {
    margin-left: 50%;
  }

  .rs-col-xl-13 {
    width: 54.16666667%;
  }

  .rs-col-xl-pull-13 {
    right: 54.16666667%;
  }

  .rs-col-xl-push-13 {
    left: 54.16666667%;
  }

  .rs-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }

  .rs-col-xl-14 {
    width: 58.33333333%;
  }

  .rs-col-xl-pull-14 {
    right: 58.33333333%;
  }

  .rs-col-xl-push-14 {
    left: 58.33333333%;
  }

  .rs-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }

  .rs-col-xl-15 {
    width: 62.5%;
  }

  .rs-col-xl-pull-15 {
    right: 62.5%;
  }

  .rs-col-xl-push-15 {
    left: 62.5%;
  }

  .rs-col-xl-offset-15 {
    margin-left: 62.5%;
  }

  .rs-col-xl-16 {
    width: 66.66666667%;
  }

  .rs-col-xl-pull-16 {
    right: 66.66666667%;
  }

  .rs-col-xl-push-16 {
    left: 66.66666667%;
  }

  .rs-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }

  .rs-col-xl-17 {
    width: 70.83333333%;
  }

  .rs-col-xl-pull-17 {
    right: 70.83333333%;
  }

  .rs-col-xl-push-17 {
    left: 70.83333333%;
  }

  .rs-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }

  .rs-col-xl-18 {
    width: 75%;
  }

  .rs-col-xl-pull-18 {
    right: 75%;
  }

  .rs-col-xl-push-18 {
    left: 75%;
  }

  .rs-col-xl-offset-18 {
    margin-left: 75%;
  }

  .rs-col-xl-19 {
    width: 79.16666667%;
  }

  .rs-col-xl-pull-19 {
    right: 79.16666667%;
  }

  .rs-col-xl-push-19 {
    left: 79.16666667%;
  }

  .rs-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }

  .rs-col-xl-20 {
    width: 83.33333333%;
  }

  .rs-col-xl-pull-20 {
    right: 83.33333333%;
  }

  .rs-col-xl-push-20 {
    left: 83.33333333%;
  }

  .rs-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }

  .rs-col-xl-21 {
    width: 87.5%;
  }

  .rs-col-xl-pull-21 {
    right: 87.5%;
  }

  .rs-col-xl-push-21 {
    left: 87.5%;
  }

  .rs-col-xl-offset-21 {
    margin-left: 87.5%;
  }

  .rs-col-xl-22 {
    width: 91.66666667%;
  }

  .rs-col-xl-pull-22 {
    right: 91.66666667%;
  }

  .rs-col-xl-push-22 {
    left: 91.66666667%;
  }

  .rs-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }

  .rs-col-xl-23 {
    width: 95.83333333%;
  }

  .rs-col-xl-pull-23 {
    right: 95.83333333%;
  }

  .rs-col-xl-push-23 {
    left: 95.83333333%;
  }

  .rs-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }

  .rs-col-xl-24 {
    width: 100%;
  }

  .rs-col-xl-pull-24 {
    right: 100%;
  }

  .rs-col-xl-push-24 {
    left: 100%;
  }

  .rs-col-xl-offset-24 {
    margin-left: 100%;
  }

  .rs-col-xl-pull-0 {
    right: auto;
  }

  .rs-col-xl-push-0 {
    left: auto;
  }
}

@media (min-width: 1400px) {
  .rs-col-xxl-1 {
    width: 4.16666667%;
  }

  .rs-col-xxl-pull-1 {
    right: 4.16666667%;
  }

  .rs-col-xxl-push-1 {
    left: 4.16666667%;
  }

  .rs-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }

  .rs-col-xxl-2 {
    width: 8.33333333%;
  }

  .rs-col-xxl-pull-2 {
    right: 8.33333333%;
  }

  .rs-col-xxl-push-2 {
    left: 8.33333333%;
  }

  .rs-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }

  .rs-col-xxl-3 {
    width: 12.5%;
  }

  .rs-col-xxl-pull-3 {
    right: 12.5%;
  }

  .rs-col-xxl-push-3 {
    left: 12.5%;
  }

  .rs-col-xxl-offset-3 {
    margin-left: 12.5%;
  }

  .rs-col-xxl-4 {
    width: 16.66666667%;
  }

  .rs-col-xxl-pull-4 {
    right: 16.66666667%;
  }

  .rs-col-xxl-push-4 {
    left: 16.66666667%;
  }

  .rs-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }

  .rs-col-xxl-5 {
    width: 20.83333333%;
  }

  .rs-col-xxl-pull-5 {
    right: 20.83333333%;
  }

  .rs-col-xxl-push-5 {
    left: 20.83333333%;
  }

  .rs-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }

  .rs-col-xxl-6 {
    width: 25%;
  }

  .rs-col-xxl-pull-6 {
    right: 25%;
  }

  .rs-col-xxl-push-6 {
    left: 25%;
  }

  .rs-col-xxl-offset-6 {
    margin-left: 25%;
  }

  .rs-col-xxl-7 {
    width: 29.16666667%;
  }

  .rs-col-xxl-pull-7 {
    right: 29.16666667%;
  }

  .rs-col-xxl-push-7 {
    left: 29.16666667%;
  }

  .rs-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }

  .rs-col-xxl-8 {
    width: 33.33333333%;
  }

  .rs-col-xxl-pull-8 {
    right: 33.33333333%;
  }

  .rs-col-xxl-push-8 {
    left: 33.33333333%;
  }

  .rs-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }

  .rs-col-xxl-9 {
    width: 37.5%;
  }

  .rs-col-xxl-pull-9 {
    right: 37.5%;
  }

  .rs-col-xxl-push-9 {
    left: 37.5%;
  }

  .rs-col-xxl-offset-9 {
    margin-left: 37.5%;
  }

  .rs-col-xxl-10 {
    width: 41.66666667%;
  }

  .rs-col-xxl-pull-10 {
    right: 41.66666667%;
  }

  .rs-col-xxl-push-10 {
    left: 41.66666667%;
  }

  .rs-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }

  .rs-col-xxl-11 {
    width: 45.83333333%;
  }

  .rs-col-xxl-pull-11 {
    right: 45.83333333%;
  }

  .rs-col-xxl-push-11 {
    left: 45.83333333%;
  }

  .rs-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }

  .rs-col-xxl-12 {
    width: 50%;
  }

  .rs-col-xxl-pull-12 {
    right: 50%;
  }

  .rs-col-xxl-push-12 {
    left: 50%;
  }

  .rs-col-xxl-offset-12 {
    margin-left: 50%;
  }

  .rs-col-xxl-13 {
    width: 54.16666667%;
  }

  .rs-col-xxl-pull-13 {
    right: 54.16666667%;
  }

  .rs-col-xxl-push-13 {
    left: 54.16666667%;
  }

  .rs-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }

  .rs-col-xxl-14 {
    width: 58.33333333%;
  }

  .rs-col-xxl-pull-14 {
    right: 58.33333333%;
  }

  .rs-col-xxl-push-14 {
    left: 58.33333333%;
  }

  .rs-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }

  .rs-col-xxl-15 {
    width: 62.5%;
  }

  .rs-col-xxl-pull-15 {
    right: 62.5%;
  }

  .rs-col-xxl-push-15 {
    left: 62.5%;
  }

  .rs-col-xxl-offset-15 {
    margin-left: 62.5%;
  }

  .rs-col-xxl-16 {
    width: 66.66666667%;
  }

  .rs-col-xxl-pull-16 {
    right: 66.66666667%;
  }

  .rs-col-xxl-push-16 {
    left: 66.66666667%;
  }

  .rs-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }

  .rs-col-xxl-17 {
    width: 70.83333333%;
  }

  .rs-col-xxl-pull-17 {
    right: 70.83333333%;
  }

  .rs-col-xxl-push-17 {
    left: 70.83333333%;
  }

  .rs-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }

  .rs-col-xxl-18 {
    width: 75%;
  }

  .rs-col-xxl-pull-18 {
    right: 75%;
  }

  .rs-col-xxl-push-18 {
    left: 75%;
  }

  .rs-col-xxl-offset-18 {
    margin-left: 75%;
  }

  .rs-col-xxl-19 {
    width: 79.16666667%;
  }

  .rs-col-xxl-pull-19 {
    right: 79.16666667%;
  }

  .rs-col-xxl-push-19 {
    left: 79.16666667%;
  }

  .rs-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }

  .rs-col-xxl-20 {
    width: 83.33333333%;
  }

  .rs-col-xxl-pull-20 {
    right: 83.33333333%;
  }

  .rs-col-xxl-push-20 {
    left: 83.33333333%;
  }

  .rs-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }

  .rs-col-xxl-21 {
    width: 87.5%;
  }

  .rs-col-xxl-pull-21 {
    right: 87.5%;
  }

  .rs-col-xxl-push-21 {
    left: 87.5%;
  }

  .rs-col-xxl-offset-21 {
    margin-left: 87.5%;
  }

  .rs-col-xxl-22 {
    width: 91.66666667%;
  }

  .rs-col-xxl-pull-22 {
    right: 91.66666667%;
  }

  .rs-col-xxl-push-22 {
    left: 91.66666667%;
  }

  .rs-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }

  .rs-col-xxl-23 {
    width: 95.83333333%;
  }

  .rs-col-xxl-pull-23 {
    right: 95.83333333%;
  }

  .rs-col-xxl-push-23 {
    left: 95.83333333%;
  }

  .rs-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }

  .rs-col-xxl-24 {
    width: 100%;
  }

  .rs-col-xxl-pull-24 {
    right: 100%;
  }

  .rs-col-xxl-push-24 {
    left: 100%;
  }

  .rs-col-xxl-offset-24 {
    margin-left: 100%;
  }

  .rs-col-xxl-pull-0 {
    right: auto;
  }

  .rs-col-xxl-push-0 {
    left: auto;
  }
}

@media (max-width: 575px) {
  .rs-hidden-xs {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .rs-hidden-sm {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .rs-hidden-md {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .rs-hidden-lg {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .rs-hidden-xl {
    display: none;
  }
}

@media (min-width: 1400px) {
  .rs-hidden-xxl {
    display: none;
  }
}

.rs-header {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.rs-form-help-text {
  display: block;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  min-height: 20px;
  line-height: 1.42857143;
  font-size: 14px;
}

.rs-form-help-text-tooltip {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 8px;
}

.rs-btn-icon > .rs-icon {
  vertical-align: bottom;
}

.rs-btn-icon-with-text {
  > .rs-icon {
    position: absolute;
    top: 0;
    display: block;
    background-color: #f2f2f5;
    background-color: var(--rs-iconbtn-addon);
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
  }

  &:focus > .rs-icon,
  &:hover > .rs-icon {
    background-color: #d9d9d9;
    background-color: var(--rs-iconbtn-activated-addon);
  }

  &.rs-btn-active > .rs-icon,
  &:active > .rs-icon {
    background-color: #c5c6c7;
    background-color: var(--rs-iconbtn-pressed-addon);
  }

  &.rs-btn-disabled > .rs-icon,
  &:disabled > .rs-icon {
    background-color: #f2f2f5;
    background-color: var(--rs-iconbtn-addon);
  }
}

.rs-theme-high-contrast .rs-btn-icon-with-text {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.rs-btn-icon-with-text.rs-btn-primary {
  > .rs-icon {
    background-color: #2589f5;
    background-color: var(--rs-iconbtn-primary-addon);
  }

  &:focus > .rs-icon,
  &:hover > .rs-icon {
    background-color: #1675e0;
    background-color: var(--rs-iconbtn-primary-activated-addon);
  }

  &.rs-btn-active > .rs-icon,
  &:active > .rs-icon {
    background-color: #0a5dc2;
    background-color: var(--rs-iconbtn-primary-pressed-addon);
  }

  &.rs-btn-disabled > .rs-icon,
  &:disabled > .rs-icon {
    background-color: #2589f5;
    background-color: var(--rs-iconbtn-primary-addon);
  }
}

.rs-theme-high-contrast .rs-btn-icon-with-text.rs-btn-primary {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.rs-btn-icon-circle {
  border-radius: 50% !important;
}

.rs-input {
  display: block;
  width: 100%;
  color: #575757;
  color: var(--rs-text-primary);
  background-color: #fff;
  background-color: var(--rs-input-bg);
  background-image: none;
  border: 1px solid #EAECF0;
  border: 1px solid var(--rs-border-primary);
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border-radius: 6px;
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input {
    height: 36px;
  }
}

textarea.rs-input {
  height: auto;
}

.rs-theme-high-contrast .rs-input {
  -webkit-transition: none;
  transition: none;
}

.rs-input {
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }

  &:focus,
  &:hover:not(:disabled) {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }

  &:focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
  }
}

.rs-theme-high-contrast .rs-input:focus {
  outline-offset: 2px;
}

.rs-input:disabled {
  background-color: #f7f7fa;
  background-color: var(--rs-input-disabled-bg);
  color: #c5c6c7;
  color: var(--rs-text-disabled);
}

textarea.rs-input {
  overflow: auto;
  resize: vertical;
}

.rs-input-xs {
  padding: 1px 11px;
  font-size: 12px;
  line-height: 1.66666667;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-xs {
    height: 24px;
  }
}

textarea.rs-input-xs {
  height: auto;
}

.rs-input-lg {
  padding: 9px 11px;
  font-size: 16px;
  line-height: 1.375;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-lg {
    height: 42px;
  }
}

textarea.rs-input-lg {
  height: auto;
}

.rs-input-sm {
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.42857143;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-sm {
    height: 30px;
  }
}

textarea.rs-input-sm {
  height: auto;
}

.rs-input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 6px;
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  width: 100%;
  cursor: text;
  color: #575757;
  color: var(--rs-text-primary);
  border: 1px solid #EAECF0;
  border: 1px solid var(--rs-border-primary);
}

.rs-theme-high-contrast .rs-input-group {
  -webkit-transition: none;
  transition: none;
}

.rs-input-group {
  &:focus-within {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }

  &:not(.rs-input-group-disabled) {
    &.rs-input-group-focus,
    &:hover {
      border-color: #3498ff;
      border-color: var(--rs-input-focus-border);
    }
  }

  &:focus-within,
  &:not(.rs-input-group-disabled).rs-input-group-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
  }
}

.rs-theme-high-contrast .rs-input-group {
  &:focus-within,
  &:not(.rs-input-group-disabled).rs-input-group-focus {
    outline-offset: 2px;
  }
}

.rs-input-group {
  .rs-input-group {
    outline: none !important;
  }

  .rs-input-group-addon,
  .rs-input-group-btn,
  .rs-input-number,
  .rs-picker-date .rs-picker-toggle {
    border: none;
    border-radius: 0;
    outline: none;
  }

  &:not(.rs-input-group-inside) {
    .rs-input {
      border: none;
      border-radius: 0;
      outline: none;
    }

    .rs-input-number {
      &:not(:last-child) {
        .rs-input-number-btn-group-vertical {
          border-radius: 0;
        }

        .rs-input-number-touchspin-up {
          border-top-right-radius: 0;
        }

        .rs-input-number-touchspin-down {
          border-bottom-right-radius: 0;
        }
      }

      &:not(:first-child) .rs-input {
        border-radius: 0 !important;
      }
    }

    .rs-auto-complete:first-child .rs-input,
    .rs-form-control-wrapper:first-child > .rs-input,
    .rs-picker-date:first-child .rs-picker-toggle,
    > :first-child {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }

    .rs-auto-complete:last-child .rs-input,
    .rs-form-control-wrapper:last-child > .rs-input,
    .rs-picker-date:last-child .rs-picker-toggle,
    > :last-child {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }
  }

  > {
    .rs-auto-complete,
    .rs-form-control-wrapper,
    .rs-input {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
    }
  }

  .rs-form-control-wrapper {
    > .rs-input {
      width: 100%;
    }

    ~ .rs-input-group-addon {
      border-left: none;
      left: auto;
      right: 0;
    }
  }

  > {
    .rs-auto-complete {
      position: relative;
      z-index: 2;
    }

    .rs-input {
      position: relative;
      z-index: 2;

      &:focus {
        z-index: 3;
      }
    }
  }

  input.rs-input ~ .rs-input-group-addon {
    border-left: none;
    left: auto;
    right: 0;
  }

  .rs-form-control-wrapper ~ .rs-input-group-addon:not(:last-child),
  input.rs-input ~ .rs-input-group-addon:not(:last-child) {
    border-right: 0;
  }

  &.rs-input-group-inside {
    width: 100%;

    .rs-input {
      display: block;
      width: 100%;
      border: none;
      padding-right: 36px;
    }

    .rs-input-group-addon {
      position: absolute;
      z-index: 4;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }

    .rs-input-group-btn {
      position: absolute;
      z-index: 4;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      color: #8e8e93;
      color: var(--rs-btn-subtle-text);
      background-color: transparent;
      border: none;
      color: inherit;
      position: absolute;
      height: 34px;
      padding: 7px 11px;
      border-radius: 0 6px 6px 0;

      &:focus,
      &:hover {
        color: #575757;
        color: var(--rs-btn-subtle-hover-text);
        background-color: #f7f7fa;
        background-color: var(--rs-btn-subtle-hover-bg);
      }

      &.rs-btn-active,
      &:active {
        color: #272c36;
        color: var(--rs-btn-subtle-active-text);
        background-color: #EAECF0;
        background-color: var(--rs-btn-subtle-active-bg);
      }

      &.rs-btn-disabled,
      &:disabled {
        color: #c5c6c7;
        color: var(--rs-btn-subtle-disabled-text);
        background: none;
      }
    }
  }
}

.rs-theme-high-contrast .rs-input-group.rs-input-group-inside .rs-input-group-btn {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.rs-input-group.rs-input-group-inside {
  .rs-input-group-btn {
    > .rs-icon {
      font-size: 14px;
      line-height: 1.42857143;
    }

    &:focus {
      outline: none;
    }

    &:first-child {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }

    &:last-child {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }
  }

  .rs-input-group-addon {
    top: 0;
    background: none;
    border: none;
    padding: 10px 13px;

    &.rs-input-group-btn {
      padding: 8px 13px;
    }

    ~ {
      .rs-auto-complete > input.rs-input,
      .rs-form-control-wrapper > input.rs-input,
      input.rs-input {
        padding-left: 36px;
        padding-right: 12px;
      }
    }
  }

  .rs-input-group-btn ~ {
    .rs-auto-complete > input.rs-input,
    .rs-form-control-wrapper > input.rs-input,
    input.rs-input {
      padding-left: 36px;
      padding-right: 12px;
    }
  }

  .rs-auto-complete ~ .rs-input-group-addon {
    left: inherit;
    right: 0;
  }

  .rs-form-control-wrapper ~ {
    .rs-input-group-addon,
    .rs-input-group-btn {
      left: inherit;
      right: 0;
    }
  }

  input.rs-input ~ .rs-input-group-btn {
    left: inherit;
    right: 0;
  }

  .rs-auto-complete ~ .rs-input-group-btn,
  .rs-form-control-wrapper ~ .rs-input-group-btn {
    left: inherit;
    right: 1px;
  }
}

.rs-input-group-disabled {
  background-color: #f7f7fa;
  background-color: var(--rs-input-disabled-bg);
  color: #c5c6c7;
  color: var(--rs-text-disabled);
  cursor: not-allowed;

  .rs-input,
  .rs-input-group-addon,
  .rs-input-group-btn {
    color: inherit;
  }
}

.rs-input-group-lg > .rs-input {
  padding: 9px 11px;
  font-size: 16px;
  line-height: 1.375;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-group-lg > .rs-input {
    height: 42px;
  }
}

textarea.rs-input-group-lg > .rs-input {
  height: auto;
}

.rs-input-group-lg {
  &.rs-input-group-inside > .rs-input {
    padding-right: 46px;
  }

  &.rs-input-group {
    > {
      .rs-input {
        height: 40px;
      }

      .rs-input-group-addon {
        height: 40px;

        > .rs-icon {
          font-size: inherit;
        }
      }
    }

    &:not(.rs-input-group-inside) > .rs-input-group-addon {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

.rs-input-group-md > .rs-input {
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-group-md > .rs-input {
    height: 36px;
  }
}

textarea.rs-input-group-md > .rs-input {
  height: auto;
}

.rs-input-group-md {
  &.rs-input-group-inside > .rs-input {
    padding-right: 36px;
  }

  &.rs-input-group {
    > {
      .rs-input {
        height: 34px;
      }

      .rs-input-group-addon {
        height: 34px;

        > .rs-icon {
          font-size: inherit;
        }
      }
    }

    &:not(.rs-input-group-inside) > .rs-input-group-addon {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
}

.rs-input-group-sm > .rs-input {
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.42857143;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-group-sm > .rs-input {
    height: 30px;
  }
}

textarea.rs-input-group-sm > .rs-input {
  height: auto;
}

.rs-input-group-sm {
  &.rs-input-group-inside > .rs-input {
    padding-right: 30px;
  }

  &.rs-input-group {
    > {
      .rs-input {
        height: 28px;
      }

      .rs-input-group-addon {
        height: 28px;

        > .rs-icon {
          font-size: inherit;
        }
      }
    }

    &:not(.rs-input-group-inside) > .rs-input-group-addon {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
}

.rs-input-group-xs > .rs-input {
  padding: 1px 11px;
  font-size: 12px;
  line-height: 1.66666667;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-group-xs > .rs-input {
    height: 24px;
  }
}

textarea.rs-input-group-xs > .rs-input {
  height: auto;
}

.rs-input-group-xs {
  &.rs-input-group-inside > .rs-input {
    padding-right: 26px;
  }

  &.rs-input-group > {
    .rs-input {
      height: 22px;
    }

    .rs-input-group-addon {
      height: 22px;

      > .rs-icon {
        font-size: inherit;
      }
    }
  }
}

.rs-input-group.rs-input-group-lg > .rs-input-group-addon {
  font-size: 16px;
  line-height: 1.375;
  min-width: 44px;
  padding: 9px 15px;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-group.rs-input-group-lg > .rs-input-group-addon {
    height: 42px;
  }
}

textarea.rs-input-group.rs-input-group-lg > .rs-input-group-addon {
  height: auto;
}

.rs-input-group.rs-input-group-md > .rs-input-group-addon {
  font-size: 14px;
  line-height: 1.42857143;
  min-width: 36px;
  padding: 7px 11px;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-group.rs-input-group-md > .rs-input-group-addon {
    height: 36px;
  }
}

textarea.rs-input-group.rs-input-group-md > .rs-input-group-addon {
  height: auto;
}

.rs-input-group.rs-input-group-sm > .rs-input-group-addon {
  font-size: 14px;
  line-height: 1.42857143;
  min-width: 32px;
  padding: 4px 9px;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-group.rs-input-group-sm > .rs-input-group-addon {
    height: 30px;
  }
}

textarea.rs-input-group.rs-input-group-sm > .rs-input-group-addon {
  height: auto;
}

.rs-input-group.rs-input-group-xs > .rs-input-group-addon {
  font-size: 12px;
  line-height: 1.66666667;
  min-width: 28px;
  padding: 1px 7px;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-group.rs-input-group-xs > .rs-input-group-addon {
    height: 24px;
  }
}

textarea.rs-input-group.rs-input-group-xs > .rs-input-group-addon {
  height: auto;
}

.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
  font-size: 16px;
  line-height: 1.375;
  height: 40px;
  padding: 9px 15px;
  border-radius: 0 6px 6px 0;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
    height: 42px;
  }
}

textarea.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
  height: auto;
}

.rs-input-group-inside {
  &.rs-input-group-lg > .rs-input-group-btn > .rs-icon {
    font-size: 16px;
    line-height: 1.375;
  }

  &.rs-input-group-md > .rs-input-group-btn {
    font-size: 14px;
    line-height: 1.42857143;
    height: 34px;
    padding: 7px 11px;
    border-radius: 0 6px 6px 0;
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
    height: 36px;
  }
}

textarea.rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
  height: auto;
}

.rs-input-group-inside {
  &.rs-input-group-md > .rs-input-group-btn > .rs-icon {
    font-size: 14px;
    line-height: 1.42857143;
  }

  &.rs-input-group-sm > .rs-input-group-btn {
    font-size: 14px;
    line-height: 1.42857143;
    height: 28px;
    padding: 4px 9px;
    border-radius: 0 6px 6px 0;
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
    height: 30px;
  }
}

textarea.rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
  height: auto;
}

.rs-input-group-inside {
  &.rs-input-group-sm > .rs-input-group-btn > .rs-icon {
    font-size: 12px;
    line-height: 1.66666667;
  }

  &.rs-input-group-xs > .rs-input-group-btn {
    font-size: 12px;
    line-height: 1.66666667;
    height: 22px;
    padding: 1px 7px;
    border-radius: 0 6px 6px 0;
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
    height: 24px;
  }
}

textarea.rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
  height: auto;
}

.rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn > .rs-icon {
  font-size: 12px;
  line-height: 1.66666667;
}

.rs-input-group-addon:not(:first-child):not(:last-child),
.rs-input-group-btn:not(:first-child):not(:last-child),
.rs-input-group:not(.rs-input-group-inside) .rs-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.rs-input-group-addon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  vertical-align: middle;
  color: #575757;
  color: var(--rs-text-primary);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-bg);
  border: 1px solid #EAECF0;
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px;

  &.rs-input-sm {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 6px;
  }

  &.rs-input-xs {
    padding: 8px;
    font-size: 12px;
    border-radius: 6px;
  }

  &.rs-input-lg {
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 6px;
  }

  input {
    &[type="checkbox"],
    &[type="radio"] {
      margin-top: 0;
    }
  }
}

.rs-input-group-btn {
  position: relative;
  line-height: 1;
  white-space: nowrap;
  border-radius: 0;

  > .rs-btn {
    position: relative;

    + .rs-btn {
      margin-left: -1px;
    }

    &:active,
    &:focus,
    &:hover {
      z-index: 2;
    }
  }

  &:first-child > {
    .rs-btn,
    .rs-btn-group {
      margin-right: -1px;
      border-right: none;
    }
  }

  &:last-child > {
    .rs-btn,
    .rs-btn-group {
      z-index: 2;
      margin-left: -1px;
    }
  }
}

.rs-input-number {
  background-color: #fff;
  background-color: var(--rs-input-bg);
}

.rs-input-number-btn-group-vertical {
  position: relative;
  width: 20px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
  display: block;

  > .rs-btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    position: relative;
    border: none;
  }
}

.rs-input-number-touchspin-down,
.rs-input-number-touchspin-up {
  position: relative;
  border-radius: 0;
  padding: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  height: 17px;
}

.rs-input-number-touchspin-down > .rs-icon,
.rs-input-number-touchspin-up > .rs-icon {
  height: 17px;
}

.rs-input-group-lg {
  .rs-input-number-touchspin-down,
  .rs-input-number-touchspin-up {
    height: 20px;

    > .rs-icon {
      height: 20px;
    }
  }
}

.rs-input-group-sm {
  .rs-input-number-touchspin-down,
  .rs-input-number-touchspin-up {
    height: 14px;

    > .rs-icon {
      height: 14px;
    }
  }
}

.rs-input-group-xs {
  .rs-input-number-touchspin-down,
  .rs-input-number-touchspin-up {
    height: 11px;

    > .rs-icon {
      height: 11px;
    }
  }
}

.rs-input-number-touchspin-down > .rs-icon,
.rs-input-number-touchspin-up > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rs-input-number-btn-group-vertical:last-child {
  .rs-input-number-touchspin-up {
    border-top-right-radius: 6px;
  }

  .rs-input-number-touchspin-down {
    border-bottom-right-radius: 6px;
  }
}

.rs-input-number {
  > .rs-input {
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;

    &:disabled + .rs-input-number-btn-group-vertical {
      .rs-input-number-touchspin-down,
      .rs-input-number-touchspin-up {
        background-color: #f7f7fa;
        background-color: var(--rs-input-disabled-bg);
      }
    }
  }

  input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}

.rs-picker-input {
  position: relative;
  border: 1px solid #EAECF0;
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px;
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  background-color: #fff;
  background-color: var(--rs-input-bg);

  .rs-picker-toggle {
    border: none !important;
    background: transparent !important;
    height: 34px;
    cursor: text;
  }

  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 7px;
  }

  .rs-picker-toggle .rs-ripple-pond {
    display: none;
  }

  &:not(.rs-picker-disabled) .rs-picker-toggle {
    position: absolute !important;
  }

  .rs-picker-toggle {
    &.rs-btn-lg {
      height: 40px;
    }

    &.rs-btn-md {
      height: 34px;
    }

    &.rs-btn-sm {
      height: 28px;
    }

    &.rs-btn-xs {
      height: 22px;
    }
  }
}

.rs-picker-tag-wrapper {
  margin-right: 32px;
  min-height: 34px;
}

.rs-picker-toggle {
  &.rs-btn-lg ~ .rs-picker-tag-wrapper {
    min-height: 40px;
  }

  &.rs-btn-sm ~ .rs-picker-tag-wrapper {
    min-height: 28px;
  }

  &.rs-btn-xs ~ .rs-picker-tag-wrapper {
    min-height: 22px;
  }
}

.rs-picker-cleanable .rs-picker-tag-wrapper {
  margin-right: 44px;
}

.rs-picker-search {
  border: none;
  width: 100%;
}

.rs-picker-search-input {
  font-size: 14px;
  line-height: 1.42857143;
  background: none;
  outline: none;
  border: none;
  width: 100%;
  position: relative;
  padding: 7px 0 7px 11px;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-picker-search-input {
    height: 36px;
  }
}

textarea.rs-picker-search-input {
  height: auto;
}

.rs-picker-tag .rs-picker-search-input {
  font-size: 14px;
  line-height: 1.42857143;
  padding: 2px 12px 2px 11px;

  > input {
    background: none;
    outline: none;
    border: none;
    width: 100%;
  }
}

.rs-picker-focused .rs-picker-search-input {
  z-index: 6;
}

.rs-list {
  position: relative;
  -webkit-box-shadow: 0 1px 0 #EAECF0, 0 -1px 0 #EAECF0;
  -webkit-box-shadow: 0 1px 0 var(--rs-list-border), 0 -1px 0 var(--rs-list-border);
  box-shadow: 0 1px 0 #EAECF0, 0 -1px 0 #EAECF0;
  box-shadow: 0 1px 0 var(--rs-list-border), 0 -1px 0 var(--rs-list-border);
  overflow-x: hidden;
  overflow-y: auto;
}

.rs-list-bordered {
  border-radius: 6px;
  -webkit-box-shadow: 0 0 0 1px #EAECF0;
  -webkit-box-shadow: 0 0 0 1px var(--rs-list-border);
  box-shadow: 0 0 0 1px #EAECF0;
  box-shadow: 0 0 0 1px var(--rs-list-border);
}

.rs-list-hover .rs-list-item:hover {
  background-color: #f2faff;
  background-color: var(--rs-list-hover-bg);
}

.rs-list-sortable {
  .rs-list-item {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .rs-list-item-disabled {
    cursor: not-allowed;

    &:active {
      cursor: not-allowed;
    }
  }
}

.rs-list-item {
  position: relative;
}

.rs-list-item-bordered {
  padding-left: 20px;
  padding-right: 20px;
}

.rs-list-item-lg {
  padding-top: 20px;
  padding-bottom: 20px;
}

.rs-list-item-md {
  padding-top: 13px;
  padding-bottom: 13px;
}

.rs-list-item-sm {
  padding-top: 8px;
  padding-bottom: 8px;
}

.rs-list-item {
  -webkit-box-shadow: 0 -1px 0 #EAECF0, 0 1px 0 #EAECF0;
  -webkit-box-shadow: 0 -1px 0 var(--rs-list-border), 0 1px 0 var(--rs-list-border);
  box-shadow: 0 -1px 0 #EAECF0, 0 1px 0 #EAECF0;
  box-shadow: 0 -1px 0 var(--rs-list-border), 0 1px 0 var(--rs-list-border);
  background-color: #fff;
  background-color: var(--rs-list-bg);
}

.rs-list-item-helper {
  background-color: #fff;
  background-color: var(--rs-list-bg);
  position: absolute;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  width: calc(100% - 2px);
  border-radius: 6px;
  top: 0;
  left: 0;
  z-index: 99;
  border: none;
  cursor: move;
}

.rs-drawer-open .rs-list-item-helper,
.rs-modal-open .rs-list-item-helper {
  z-index: 1149;
}

.rs-list-item-holder {
  background-color: rgba(242, 250, 255, 0.5);
  background-color: var(--rs-list-placeholder-bg);
  visibility: hidden;

  &:after {
    content: "";
    position: absolute;
    visibility: visible;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border: 1px dashed #3498ff;
    border: 1px dashed var(--rs-list-placeholder-border);
  }
}

.rs-loader {
  display: inline-block;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  .rs-loader-spin {
    width: 18px;
    height: 18px;

    &:after,
    &:before {
      width: 18px;
      height: 18px;
    }
  }

  .rs-loader-content {
    font-size: 12px;
    line-height: 1.5;
  }

  .rs-loader-vertical .rs-loader {
    .rs-loader {
      height: 48px;
    }

    .rs-loader-content {
      line-height: 1.66666667;
    }
  }
}

.rs-loader-spin {
  position: relative;
  display: inline-block;
  float: left;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
    border: 3px solid rgba(247, 247, 250, 0.8);
    border: 3px solid var(--rs-loader-ring);
  }

  &:after {
    border: 3px solid transparent;
    border-top: 3px solid var(--rs-loader-rotor);
    -webkit-animation: loaderSpin 0.6s linear infinite;
    animation: loaderSpin 0.6s linear infinite;
  }
}

.rs-loader-content {
  float: left;
  display: inline-block;
}

.rs-loader-spin + .rs-loader-content {
  margin-left: 12px;
}

.rs-loader-xs .rs-loader-spin + .rs-loader-content {
  margin-left: 10px;
}

.rs-loader-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 100%, 0.9);
  background: var(--rs-loader-backdrop);
}

.rs-loader-vertical {
  width: auto;
  display: inline-block;

  .rs-loader {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rs-loader-spin {
    margin: 0 auto;
  }

  .rs-loader-content {
    line-height: 1.66666667;
    text-align: center;
    display: block;
    float: none;
  }

  .rs-loader-spin {
    display: block;
    float: none;

    + .rs-loader-content {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

.rs-loader-inverse {
  .rs-loader-content {
    color: #f7f7fa;
    color: var(--rs-text-inverse);
  }

  .rs-loader-backdrop {
    background: rgba(39, 44, 54, 0.83);
    background: var(--rs-loader-backdrop-inverse);
  }

  .rs-loader-spin {
    &:before {
      border-color: rgba(247, 247, 250, 0.3);
      border-color: var(--rs-loader-ring-inverse);
    }

    &:after {
      border-top-color: #fff;
      border-top-color: var(--rs-loader-rotor-inverse);
    }
  }
}

.rs-loader-speed-fast .rs-loader-spin:after {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}

.rs-loader-speed-normal .rs-loader-spin:after {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
}

.rs-loader-speed-slow .rs-loader-spin:after {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
}

.rs-loader-backdrop-wrapper,
.rs-loader-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

.rs-loader-backdrop-wrapper .rs-loader,
.rs-loader-center .rs-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.rs-loader-backdrop-wrapper:not(.rs-loader-vertical) .rs-loader,
.rs-loader-center:not(.rs-loader-vertical) .rs-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rs-loader-backdrop-wrapper.rs-loader-vertical .rs-loader,
.rs-loader-center.rs-loader-vertical .rs-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.rs-loader-backdrop-wrapper .rs-loader-content,
.rs-loader-center .rs-loader-content {
  z-index: 1;
}

.rs-loader-lg {
  .rs-loader-spin {
    width: 64px;
    height: 64px;

    &:after,
    &:before {
      width: 64px;
      height: 64px;
    }
  }

  .rs-loader-content {
    font-size: 16px;
    line-height: 4;
  }

  .rs-loader-vertical .rs-loader-lg {
    .rs-loader {
      height: 94px;
    }

    .rs-loader-content {
      line-height: 1.25;
    }
  }
}

.rs-loader-md {
  .rs-loader-spin {
    width: 36px;
    height: 36px;

    &:after,
    &:before {
      width: 36px;
      height: 36px;
    }
  }

  .rs-loader-content {
    font-size: 14px;
    line-height: 2.57142857;
  }

  .rs-loader-vertical .rs-loader-md {
    .rs-loader {
      height: 66px;
    }

    .rs-loader-content {
      line-height: 1.42857143;
    }
  }
}

.rs-loader-sm {
  .rs-loader-spin {
    width: 18px;
    height: 18px;

    &:after,
    &:before {
      width: 18px;
      height: 18px;
    }
  }

  .rs-loader-content {
    font-size: 14px;
    line-height: 1.28571429;
  }

  .rs-loader-vertical .rs-loader-sm {
    .rs-loader {
      height: 48px;
    }

    .rs-loader-content {
      line-height: 1.42857143;
    }
  }
}

.rs-loader-xs {
  .rs-loader-spin {
    width: 16px;
    height: 16px;

    &:after,
    &:before {
      width: 16px;
      height: 16px;
    }
  }

  .rs-loader-content {
    font-size: 12px;
    line-height: 1.33333333;
  }

  .rs-loader-vertical .rs-loader-xs {
    .rs-loader {
      height: 46px;
    }

    .rs-loader-content {
      line-height: 1.66666667;
    }
  }
}

.rs-loader-wrapper {
  display: inline-block;
  width: auto;
  line-height: 0;
}

@-webkit-keyframes loaderSpin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes loaderSpin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes notificationMoveIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes notificationMoveIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@-webkit-keyframes notificationMoveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes notificationMoveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes notificationMoveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes notificationMoveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes notificationMoveOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    max-height: 100px;
  }

  to {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}

@keyframes notificationMoveOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    max-height: 100px;
  }

  to {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}

.rs-message {
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.42857143;
  position: relative;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.rs-message-container {
  padding: 20px 28px 20px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rs-message-icon-wrapper {
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 0;
  margin-right: 10px;

  .rs-icon {
    font-size: 12px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.rs-message-content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.rs-message-header {
  color: #272c36;
  color: var(--rs-text-heading);
  line-height: 1.71428571;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  + .rs-message-body {
    margin-top: 4px;
  }
}

.rs-message-body {
  color: #575757;
  color: var(--rs-text-primary);
}

.rs-message {
  &.rs-message-has-title .rs-message-icon-wrapper {
    -ms-flex-item-align: start;
    align-self: flex-start;

    .rs-icon {
      font-size: 24px;
      line-height: 1;
    }
  }

  &:not(.rs-message-hiding) + .rs-message {
    margin-top: 10px;

    &.rs-message-hiding:last-child {
      -webkit-transition: margin-top 0.1s linear 0.3s;
      transition: margin-top 0.1s linear 0.3s;
      margin-top: 0;
    }
  }

  &.rs-message-hiding {
    -webkit-animation: notificationMoveOut 0.3s ease-in forwards;
    animation: notificationMoveOut 0.3s ease-in forwards;
  }
}

.rs-theme-dark .rs-message {
  .rs-btn-link,
  a {
    color: inherit;
    text-decoration: underline;
  }
}

.rs-message-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
}

.rs-message-success {
  background-color: #eeffed;
  background-color: var(--rs-message-success-bg);

  .rs-message-header {
    color: #272c36;
    color: var(--rs-message-success-header);
  }

  .rs-message-body {
    color: #575757;
    color: var(--rs-message-success-text);
  }

  .rs-btn-close,
  .rs-message-icon-wrapper > .rs-icon {
    color: #4caf50;
    color: var(--rs-message-success-icon);
  }
}

.rs-theme-high-contrast .rs-message-success {
  border: 1px solid var(--rs-message-success-border);
}

.rs-message-info {
  background-color: #f0f9ff;
  background-color: var(--rs-message-info-bg);

  .rs-message-header {
    color: #272c36;
    color: var(--rs-message-info-header);
  }

  .rs-message-body {
    color: #575757;
    color: var(--rs-message-info-text);
  }

  .rs-btn-close,
  .rs-message-icon-wrapper > .rs-icon {
    color: #2196f3;
    color: var(--rs-message-info-icon);
  }
}

.rs-theme-high-contrast .rs-message-info {
  border: 1px solid var(--rs-message-info-border);
}

.rs-message-warning {
  background-color: #fffaf2;
  background-color: var(--rs-message-warning-bg);

  .rs-message-header {
    color: #272c36;
    color: var(--rs-message-warning-header);
  }

  .rs-message-body {
    color: #575757;
    color: var(--rs-message-warning-text);
  }

  .rs-btn-close,
  .rs-message-icon-wrapper > .rs-icon {
    color: #ffb300;
    color: var(--rs-message-warning-icon);
  }
}

.rs-theme-high-contrast .rs-message-warning {
  border: 1px solid var(--rs-message-warning-border);
}

.rs-message-error {
  background-color: #fff2f2;
  background-color: var(--rs-message-error-bg);

  .rs-message-header {
    color: #272c36;
    color: var(--rs-message-error-header);
  }

  .rs-message-body {
    color: #575757;
    color: var(--rs-message-error-text);
  }

  .rs-btn-close,
  .rs-message-icon-wrapper > .rs-icon {
    color: #f44336;
    color: var(--rs-message-error-icon);
  }
}

.rs-theme-high-contrast .rs-message-error {
  border: 1px solid var(--rs-message-error-border);
}

.rs-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1049;
  background-color: rgba(39, 44, 54, 0.3);
  background-color: var(--rs-bg-backdrop);

  &.rs-anim-fade {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
  }

  &.rs-anim-in {
    opacity: 1;
  }
}

.rs-modal-open {
  overflow: hidden;
}

.rs-modal-wrapper {
  position: fixed;
  overflow: auto;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rs-modal {
  display: none;
  overflow: visible;
  outline: 0;
  margin: 30px auto 0;
  position: relative;
  width: auto;
  z-index: 1050;
}

.rs-modal-lg {
  width: 968px;
  max-width: calc(100% - 10px);
}

.rs-modal-md {
  max-width: calc(100% - 10px);
  width: 800px;
}

.rs-modal-sm {
  width: 600px;
  max-width: calc(100% - 10px);
}

.rs-modal-xs {
  max-width: calc(100% - 10px);
  width: 400px;
}

.rs-modal-full {
  width: calc(100% - 120px);
  max-width: calc(100% - 10px);
}

.rs-modal-shake .rs-modal-dialog {
  -webkit-animation: shakeHead 0.3s linear;
  animation: shakeHead 0.3s linear;
}

.rs-modal-content {
  position: relative;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  border: none;
  border: var(--rs-modal-border, none);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--rs-modal-shadow);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: var(--rs-modal-shadow);
  padding: 20px;
}

.rs-modal-header {
  padding-right: 20px;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  .rs-modal-header-close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 12px;
    line-height: 1.66666667;
    color: #575757;
    color: var(--rs-text-primary);
    width: 20px;
    padding: 0 4px;
  }
}

.rs-modal-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  display: block;
  color: #272c36;
  color: var(--rs-text-heading);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.rs-modal-body {
  position: relative;
  margin-top: 20px;
  padding-bottom: 20px;
}

.rs-modal-footer {
  text-align: right;
  border-top: none;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  .rs-btn + .rs-btn {
    margin-left: 10px;
    margin-bottom: 0;
  }

  .rs-btn-group .rs-btn + .rs-btn {
    margin-left: -1px;
  }

  .rs-btn-block + .rs-btn-block {
    margin-left: 0;
  }
}

.rs-modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.rs-picker-cascader-menu .rs-check-item {
  .rs-checkbox-checker > label {
    padding-right: 26px;
  }

  &.rs-check-item-focus .rs-checkbox-checker > label,
  &.rs-checkbox-checked .rs-checkbox-checker > label {
    font-weight: 700;
  }
}

.rs-picker-cascader-menu-column.rs-picker-cascader-menu-column-uncheckable .rs-check-item .rs-checkbox-checker > label {
  padding-left: 12px;
}

.rs-picker-multi-cascader-menu {
  .rs-picker-cascader-col {
    padding: 0;
  }

  .rs-picker-cascader-row {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.rs-nav {
  position: relative;
  outline: 0;

  .rs-dropdown > .rs-dropdown-toggle {
    height: 36px;
    vertical-align: bottom;
  }
}

.rs-nav-default .rs-nav-item,
.rs-nav-tabs .rs-nav-item {
  overflow: hidden;
  position: relative;
  -webkit-transition: color 0.3s linear, background-color 0.3s linear;
  transition: color 0.3s linear, background-color 0.3s linear;
}

@media not all and (min-resolution: 0.001dpcm) {
  .rs-nav-default .rs-nav-item,
  .rs-nav-tabs .rs-nav-item {
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
  }
}

.rs-nav-item {
  padding: 8px 12px;
  cursor: pointer;
  color: #8e8e93;
  color: var(--rs-navs-text);
  -webkit-transition: color 0.3s linear, background-color 0.3s linear;
  transition: color 0.3s linear, background-color 0.3s linear;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  .rs-nav-item {
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
  }
}

.rs-theme-high-contrast .rs-nav-item {
  -webkit-transition: none;
  transition: none;
}

.rs-nav-item {
  &.rs-nav-item-focus,
  &:focus,
  &:hover {
    color: #575757;
    color: var(--rs-navs-text-hover);
  }
}

.rs-theme-high-contrast .rs-nav-item {
  &.rs-nav-item-focus,
  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

.rs-nav-item:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-width: 2px;
  outline-offset: 0;
  z-index: 2;
}

.rs-theme-high-contrast .rs-nav-item:focus-visible {
  outline-offset: 2px;
}

.rs-nav-item:active {
  color: #272c36;
  color: var(--rs-navs-text-active);
}

.rs-theme-high-contrast .rs-nav-item:active {
  text-decoration: underline;
}

.rs-nav-item {
  &.rs-nav-item-disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: #c5c6c7;
    color: var(--rs-text-disabled);
  }

  &.rs-nav-item-active {
    position: relative;
    color: #1675e0;
    color: var(--rs-navs-selected);
    z-index: 1;
  }
}

.rs-theme-high-contrast .rs-nav-item.rs-nav-item-active {
  text-decoration: underline;
}

.rs-nav-item-icon {
  margin-right: 6px;
}

.rs-nav-item-caret {
  font-size: 16px;
  vertical-align: text-bottom;
  margin-left: 6px;
}

.rs-nav-horizontal {
  white-space: nowrap;

  .rs-nav-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.rs-nav-reversed.rs-nav-horizontal .rs-nav-bar {
  bottom: auto;
  top: 0;
}

.rs-nav-horizontal > {
  .rs-dropdown,
  .rs-nav-item {
    display: inline-block;
    vertical-align: top;
  }
}

.rs-nav-vertical {
  > {
    .rs-dropdown,
    .rs-nav-item {
      display: block;
    }

    .rs-dropdown {
      width: 100%;

      > .rs-dropdown-toggle {
        width: 100%;
        text-align: left;
        z-index: 0;
      }
    }
  }

  .rs-nav-bar {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

.rs-nav-reversed.rs-nav-vertical .rs-nav-bar {
  right: auto;
}

.rs-nav-default .rs-nav-item {
  border-radius: 6px;

  &:focus,
  &:hover {
    background: #EAECF0;
    background: var(--rs-navs-bg-hover);
  }
}

.rs-nav-tabs {
  .rs-nav-item {
    &:focus,
    &:hover {
      background: #EAECF0;
      background: var(--rs-navs-bg-hover);
    }

    &.rs-nav-item-active {
      border: 1px solid #d9d9d9;
      border: 1px solid var(--rs-navs-tab-border);
      background-color: #fff;
      background-color: var(--rs-bg-card);
      z-index: 1;
    }
  }

  &.rs-nav-horizontal > {
    .rs-dropdown .rs-dropdown-toggle,
    .rs-nav-item {
      border-radius: 6px 6px 0 0;
    }
  }
}

.rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal > {
  .rs-dropdown .rs-dropdown-toggle,
  .rs-nav-item {
    border-radius: 0 0 6px 6px;
  }
}

.rs-nav-tabs.rs-nav-horizontal {
  .rs-nav-bar {
    border-top: 1px solid #d9d9d9;
    border-top: 1px solid var(--rs-navs-tab-border);
  }

  .rs-nav-item.rs-nav-item-active {
    border-bottom-width: 0;
  }
}

.rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
  border-bottom-width: 1px;
  border-top-width: 0;
}

.rs-nav-tabs.rs-nav-vertical > {
  .rs-dropdown .rs-dropdown-toggle,
  .rs-nav-item {
    border-radius: 6px 0 0 6px;
  }
}

.rs-nav-reversed.rs-nav-tabs.rs-nav-vertical > {
  .rs-dropdown .rs-dropdown-toggle,
  .rs-nav-item {
    border-radius: 0 6px 6px 0;
  }
}

.rs-nav-tabs.rs-nav-vertical {
  .rs-nav-bar {
    width: 1px;
    background: #d9d9d9;
    background: var(--rs-navs-tab-border);
  }

  .rs-nav-item.rs-nav-item-active {
    border-right-width: 0;
  }
}

.rs-nav-reversed.rs-nav-tabs.rs-nav-vertical .rs-nav-item.rs-nav-item-active {
  border-right-width: 1px;
  border-left-width: 0;
}

.rs-nav-subtle {
  .rs-nav-item {
    position: relative;

    &:focus,
    &:hover {
      color: #1675e0;
      color: var(--rs-navs-selected);
    }

    &.rs-nav-item-active:before {
      content: "";
      position: absolute;
      background-color: #1675e0;
      background-color: var(--rs-navs-selected);
      display: block;
      z-index: 1;
    }
  }

  &.rs-nav-horizontal {
    .rs-nav-bar {
      border-top: 2px solid #f7f7fa;
      border-top: 2px solid var(--rs-navs-subtle-border);
    }

    .rs-nav-item.rs-nav-item-active:before {
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
    }
  }
}

.rs-nav-reversed.rs-nav-subtle.rs-nav-horizontal .rs-nav-item.rs-nav-item-active:before {
  bottom: auto;
  top: 0;
}

.rs-nav-subtle.rs-nav-vertical {
  .rs-nav-bar {
    width: 2px;
    background: #f7f7fa;
    background: var(--rs-navs-subtle-border);
  }

  .rs-nav-item.rs-nav-item-active:before {
    right: 0;
    top: 0;
    bottom: 0;
    width: 2px;
  }
}

.rs-nav-reversed.rs-nav-subtle.rs-nav-vertical .rs-nav-item.rs-nav-item-active:before {
  right: auto;
  left: 0;
}

.rs-nav-justified {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  > {
    .rs-dropdown,
    .rs-nav-item {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 1%;
      flex: 1 1 1%;
    }

    .rs-dropdown .rs-dropdown-toggle {
      width: 100%;
      text-align: left;
    }
  }
}

.rs-navbar {
  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.rs-navbar-brand {
  float: left;
  display: inline-block;
  height: 56px;
  color: inherit;
}

.rs-navbar-header {
  float: left;
  display: inline-block;
  height: 56px;
  color: inherit;

  a {
    color: inherit;
  }
}

.rs-navbar-brand {
  padding: 18px 20px;
}

.rs-navbar-nav {
  float: left;

  &.rs-navbar-right {
    float: right;
  }

  &:focus {
    .rs-dropdown {
      &.rs-dropdown-focus .rs-dropdown-toggle,
      .rs-dropdown-toggle:focus-visible {
        z-index: 1;
        outline: 3px solid rgba(52, 152, 255, 0.25);
        outline: 3px solid var(--rs-color-focus-ring);
      }
    }

    .rs-navbar-item {
      &.rs-navbar-item-focus,
      &:focus-visible {
        z-index: 1;
        outline: 3px solid rgba(52, 152, 255, 0.25);
        outline: 3px solid var(--rs-color-focus-ring);
      }
    }
  }
}

.rs-theme-high-contrast {
  .rs-navbar-nav:focus {
    .rs-dropdown {
      &.rs-dropdown-focus .rs-dropdown-toggle,
      .rs-dropdown-toggle:focus-visible {
        outline-offset: 2px;
        outline: 3px solid rgba(52, 152, 255, 0.25);
        outline: 3px solid var(--rs-color-focus-ring);
        outline-offset: -3px;
        outline-width: 2px;
      }
    }

    .rs-navbar-item {
      &.rs-navbar-item-focus,
      &:focus-visible {
        outline-offset: 2px;
        outline: 3px solid rgba(52, 152, 255, 0.25);
        outline: 3px solid var(--rs-color-focus-ring);
        outline-offset: -3px;
        outline-width: 2px;
      }
    }
  }

  .rs-theme-high-contrast .rs-navbar-nav:focus {
    .rs-dropdown {
      &.rs-dropdown-focus .rs-dropdown-toggle,
      .rs-dropdown-toggle:focus-visible {
        outline-offset: 2px;
      }
    }

    .rs-navbar-item {
      &.rs-navbar-item-focus,
      &:focus-visible {
        outline-offset: 2px;
      }
    }
  }
}

.rs-navbar-item,
.rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
  padding: 18px 16px;
  height: 56px;
  border-radius: 0;
  color: inherit;
  float: left;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rs-navbar-item-icon {
  font-size: 16px;
  margin-right: 5px;
}

.rs-navbar-item-caret {
  font-size: 16px;
  margin-left: 6px;
}

.rs-navbar-brand {
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.rs-navbar-item {
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.rs-navbar-nav > {
  .rs-dropdown-item {
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  .rs-dropdown .rs-dropdown-toggle {
    text-decoration: none;
  }
}

.rs-navbar-brand:focus-visible,
.rs-navbar-item:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  z-index: 2;
}

.rs-navbar-nav > {
  .rs-dropdown-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    z-index: 2;
  }

  .rs-dropdown .rs-dropdown-toggle {
    &:focus,
    &:focus-visible {
      outline: 3px solid rgba(52, 152, 255, 0.25);
      outline: 3px solid var(--rs-color-focus-ring);
      outline-offset: -3px;
      z-index: 2;
    }
  }
}

.rs-theme-high-contrast {
  .rs-navbar-brand:focus-visible,
  .rs-navbar-item:focus-visible,
  .rs-navbar-nav > .rs-dropdown-item:focus-visible {
    outline-offset: 2px;
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
  }

  .rs-theme-high-contrast {
    .rs-navbar-brand:focus-visible,
    .rs-navbar-item:focus-visible,
    .rs-navbar-nav > .rs-dropdown-item:focus-visible {
      outline-offset: 2px;
    }
  }
}

.rs-navbar-item {
  margin: 0 !important;
}

.rs-navbar-nav > .rs-dropdown {
  margin: 0 !important;

  .rs-dropdown-toggle {
    margin: 0 !important;
  }
}

.rs-theme-high-contrast .rs-navbar-item.rs-navbar-item-active {
  text-decoration: underline;
}

.rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
  padding-right: 36px;
}

.rs-theme-high-contrast {
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
    border: none;

    &:focus-visible {
      outline: 3px solid rgba(52, 152, 255, 0.25);
      outline: 3px solid var(--rs-color-focus-ring);
      outline-offset: -3px;
      outline-width: 2px;
    }
  }

  .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible {
    outline-offset: 2px;
  }
}

.rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle .rs-dropdown-toggle-caret {
  top: 18px;
  right: 16px;
}

.rs-navbar .rs-dropdown-item:hover {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-menuitem-active-bg);
  color: #1675e0;
  color: var(--rs-menuitem-active-text);
}

.rs-theme-high-contrast .rs-navbar .rs-dropdown-item:hover {
  text-decoration: underline;
  -webkit-box-shadow: inset 0 0 0 2px #fff;
  box-shadow: inset 0 0 0 2px #fff;
}

.rs-navbar-default {
  background-color: #f7f7fa;
  background-color: var(--rs-navbar-default-bg);
  color: #575757;
  color: var(--rs-navbar-default-text);

  .rs-dropdown {
    &.rs-dropdown-open .rs-dropdown-toggle,
    .rs-dropdown-toggle:hover {
      background-color: #EAECF0;
      background-color: var(--rs-navbar-default-hover-bg);
      color: #575757;
      color: var(--rs-navbar-default-hover-text);
    }
  }

  .rs-navbar-item:hover {
    background-color: #EAECF0;
    background-color: var(--rs-navbar-default-hover-bg);
    color: #575757;
    color: var(--rs-navbar-default-hover-text);
  }

  .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-navbar-item.rs-navbar-item-active {
    color: #1675e0;
    color: var(--rs-navbar-default-selected-text);
  }
}

.rs-navbar-inverse {
  background-color: #3498ff;
  background-color: var(--rs-navbar-inverse-bg);
  color: #fff;
  color: var(--rs-navbar-inverse-text);

  .rs-dropdown .rs-dropdown-toggle {
    &:focus,
    &:hover {
      background-color: #2589f5;
      background-color: var(--rs-navbar-inverse-hover-bg);
      color: #fff;
      color: var(--rs-navbar-inverse-hover-text);
    }
  }

  .rs-navbar-item {
    &:focus,
    &:hover {
      background-color: #2589f5;
      background-color: var(--rs-navbar-inverse-hover-bg);
      color: #fff;
      color: var(--rs-navbar-inverse-hover-text);
    }
  }

  .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-navbar-item.rs-navbar-item-active {
    background-color: #1675e0;
    background-color: var(--rs-navbar-inverse-selected-bg);
  }
}

.rs-theme-high-contrast .rs-navbar-inverse {
  .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-navbar-item.rs-navbar-item-active {
    color: var(--rs-navbar-inverse-selected-text);
  }
}

.rs-navbar-subtle {
  background-color: transparent;
  color: #8e8e93;
  color: var(--rs-navbar-subtle-text);

  .rs-dropdown .rs-dropdown-toggle {
    &:focus,
    &:hover {
      background-color: transparent;
      color: #575757;
      color: var(--rs-navbar-subtle-hover-text);
    }
  }

  .rs-navbar-item {
    &:focus,
    &:hover {
      background-color: transparent;
      color: #575757;
      color: var(--rs-navbar-subtle-hover-text);
    }
  }

  .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-navbar-item.rs-navbar-item-active {
    color: #1675e0;
    color: var(--rs-navbar-subtle-selected-text);
  }
}

.rs-notification {
  pointer-events: auto;
  position: relative;
  display: inline-block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  border-radius: 6px;
  background: #fff;
  background: var(--rs-bg-overlay);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  overflow: hidden;

  &:not(.rs-toast-fade-exited) ~ .rs-notification {
    margin-top: 10px;
  }
}

.rs-theme-high-contrast .rs-notification {
  border: 1px solid #EAECF0;
  border: 1px solid var(--rs-border-primary);
}

.rs-notification-content {
  padding: 20px;
  max-width: 400px;
}

.rs-notification-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  color: #272c36;
  color: var(--rs-text-heading);
  font-size: 16px;
  line-height: 1.5;

  > p {
    margin: 0;
  }

  .rs-icon {
    font-size: 24px;
    width: 24px;
    line-height: 1;
    margin-right: 10px;
  }
}

.rs-notification-title-with-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.rs-notification-description {
  color: #575757;
  color: var(--rs-text-primary);
}

.rs-notification-title + .rs-notification-description {
  margin-top: 8px;
}

.rs-notification.rs-notification-hiding {
  -webkit-animation: notificationMoveOut 0.3s ease-in forwards;
  animation: notificationMoveOut 0.3s ease-in forwards;

  ~ .rs-notification {
    margin-top: 0;
  }
}

.rs-notification-success .rs-notification-content {
  .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }

  .rs-icon {
    color: #4caf50;
    color: var(--rs-state-success);

    &:before {
      vertical-align: middle;
    }
  }
}

.rs-notification-info .rs-notification-content {
  .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }

  .rs-icon {
    color: #2196f3;
    color: var(--rs-state-info);

    &:before {
      vertical-align: middle;
    }
  }
}

.rs-notification-warning .rs-notification-content {
  .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }

  .rs-icon {
    color: #ffb300;
    color: var(--rs-state-warning);

    &:before {
      vertical-align: middle;
    }
  }
}

.rs-notification-error .rs-notification-content {
  .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }

  .rs-icon {
    color: #f44336;
    color: var(--rs-state-error);

    &:before {
      vertical-align: middle;
    }
  }
}

.rs-pagination-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rs-pagination-group-lg {
  font-size: 16px;
}

.rs-pagination-group-md,
.rs-pagination-group-sm {
  font-size: 14px;
}

.rs-pagination-group-xs {
  font-size: 12px;
}

.rs-pagination-group-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.rs-pagination-group .rs-pagination {
  vertical-align: middle;
}

.rs-pagination-group-limit + .rs-pagination-group-total {
  margin-left: 18px;
}

.rs-pagination-group-skip {
  vertical-align: middle;
  display: inline-block;
  margin-left: 10px;

  .rs-input {
    width: 46px;
    margin: 0 5px;
    display: inline-block;
  }
}

.rs-pagination {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0;
}

.rs-pagination-lg .rs-pagination-btn {
  font-size: 16px;
  line-height: 22px;
  padding: 10px 16px;
  min-width: 42px;
}

.rs-btn-ghost.rs-pagination-lg .rs-pagination-btn {
  padding: 9px 15px;
}

.rs-btn-icon.rs-pagination-lg .rs-pagination-btn {
  padding: 11px;
  line-height: 20px;

  > .rs-icon {
    font-size: 20px;
  }
}

.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn {
  line-height: 22px;

  > .rs-icon {
    padding: 11px;
    width: 42px;
    height: 42px;
  }

  &.rs-btn-icon-placement-left {
    padding: 10px 16px 10px 58px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 10px 58px 10px 16px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-pagination-lg {
  .rs-pagination-btn .rs-pagination-symbol {
    height: 22px;
  }

  .rs-pagination-btn-active {
    padding: 9px 15px;
  }
}

.rs-pagination-md .rs-pagination-btn {
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  min-width: 36px;
}

.rs-btn-ghost.rs-pagination-md .rs-pagination-btn {
  padding: 7px 11px;
}

.rs-btn-icon.rs-pagination-md .rs-pagination-btn {
  padding: 10px;
  line-height: 16px;

  > .rs-icon {
    font-size: 16px;
  }
}

.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn {
  line-height: 20px;

  > .rs-icon {
    padding: 10px;
    width: 36px;
    height: 36px;
  }

  &.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-pagination-md .rs-pagination-btn-active {
  padding: 7px 11px;
}

.rs-pagination-sm .rs-pagination-btn {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
  min-width: 30px;
}

.rs-btn-ghost.rs-pagination-sm .rs-pagination-btn {
  padding: 4px 9px;
}

.rs-btn-icon.rs-pagination-sm .rs-pagination-btn {
  padding: 7px;
  line-height: 16px;

  > .rs-icon {
    font-size: 16px;
  }
}

.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn {
  line-height: 20px;

  > .rs-icon {
    padding: 7px;
    width: 30px;
    height: 30px;
  }

  &.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-pagination-sm .rs-pagination-btn-active {
  padding: 4px 9px;
}

.rs-pagination-xs .rs-pagination-btn {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
  min-width: 24px;
}

.rs-btn-ghost.rs-pagination-xs .rs-pagination-btn {
  padding: 1px 7px;
}

.rs-btn-icon.rs-pagination-xs .rs-pagination-btn {
  padding: 6px;
  line-height: 12px;

  > .rs-icon {
    font-size: 12px;
  }
}

.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn {
  line-height: 20px;

  > .rs-icon {
    padding: 6px;
    width: 24px;
    height: 24px;
  }

  &.rs-btn-icon-placement-left {
    padding: 2px 8px 2px 32px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 2px 32px 2px 8px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-pagination-xs .rs-pagination-btn-active {
  padding: 1px 7px;
}

.rs-pagination-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-transition: color 0.2s linear, background-color 0.3s linear;
  transition: color 0.2s linear, background-color 0.3s linear;
  color: #575757;
  color: var(--rs-btn-default-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-bg);
  border-radius: 6px;
  overflow: hidden;
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
  margin: 0 2px;
  position: relative;
  float: left;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rs-btn-ghost.rs-pagination-btn {
  padding: 7px 11px;
}

.rs-btn-icon.rs-pagination-btn {
  padding: 10px;
}

.rs-btn-icon-with-text.rs-pagination-btn {
  > .rs-icon {
    padding: 10px;
    width: 36px;
    height: 36px;
  }

  &.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
  }

  &.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
  }
}

.rs-pagination-btn:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
}

.rs-theme-high-contrast .rs-pagination-btn:focus-visible {
  outline-offset: 2px;
}

.rs-pagination-btn {
  &:focus,
  &:hover {
    color: var(--rs-btn-default-hover-text);
    background-color: #EAECF0;
    background-color: var(--rs-btn-default-hover-bg);
    text-decoration: none;
  }

  &.rs-btn-active,
  &:active {
    color: var(--rs-btn-default-active-text);
    background-color: #d9d9d9;
    background-color: var(--rs-btn-default-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    cursor: not-allowed;
    color: var(--rs-btn-default-disabled-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-disabled-bg);
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  .rs-pagination-btn {
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
  }
}

.rs-picker-default .rs-pagination-btn {
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
}

.rs-theme-high-contrast .rs-picker-default .rs-pagination-btn {
  -webkit-transition: none;
  transition: none;
}

.rs-picker-default:not(.rs-picker-disabled) {
  .rs-pagination-btn-active {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }

  .rs-pagination-btn {
    &:focus,
    &:hover {
      border-color: #3498ff;
      border-color: var(--rs-input-focus-border);
    }
  }
}

.rs-picker-subtle .rs-pagination-btn {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  -webkit-transition: none;
  transition: none;

  &:focus,
  &:hover {
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-subtle-hover-bg);
  }

  &.rs-btn-active,
  &:active {
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
    background-color: #EAECF0;
    background-color: var(--rs-btn-subtle-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;
  }
}

.rs-theme-high-contrast .rs-picker-subtle .rs-pagination-btn {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.rs-pagination-btn {
  &:focus,
  &:hover {
    background-color: #f7f7fa;
    background-color: var(--rs-btn-subtle-hover-bg);
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
  }
}

.rs-picker-subtle .rs-pagination-btn-active {
  background-color: #f7f7fa;
  background-color: var(--rs-btn-subtle-hover-bg);
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
}

.rs-pagination-btn {
  &.rs-btn-active,
  &:active {
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
    background-color: #EAECF0;
    background-color: var(--rs-btn-subtle-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;
  }
}

.rs-theme-high-contrast .rs-pagination-btn {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.rs-btn-ghost.rs-pagination-btn {
  padding: 4px 9px;
}

.rs-btn-icon.rs-pagination-btn {
  padding: 7px;
  line-height: 16px;

  > .rs-icon {
    font-size: 16px;
  }
}

.rs-btn-icon-with-text.rs-pagination-btn {
  line-height: 20px;

  > .rs-icon {
    padding: 7px;
    width: 30px;
    height: 30px;
  }

  &.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-pagination-btn .rs-pagination-symbol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 20px;
}

.rs-theme-high-contrast .rs-pagination-btn {
  -webkit-transition: none;
  transition: none;
  color: var(--rs-pagination-item-text);

  &:focus-visible,
  &:hover {
    color: var(--rs-pagination-item-current-text);
    text-decoration: underline;
  }
}

.rs-pagination-btn.rs-pagination-btn-active {
  color: #1675e0;
  color: var(--rs-btn-ghost-text);
  background-color: transparent;
  border: 1px solid #1675e0;
  border: 1px solid var(--rs-btn-ghost-border);

  &:focus,
  &:hover {
    color: #0a5dc2;
    color: var(--rs-btn-ghost-hover-text);
    background-color: transparent;
    border-color: #0a5dc2;
    border-color: var(--rs-btn-ghost-hover-border);
  }

  &.rs-btn-active,
  &:active {
    color: #004299;
    color: var(--rs-btn-ghost-active-text);
    background-color: transparent;
    border-color: #004299;
    border-color: var(--rs-btn-ghost-active-border);
  }

  &.rs-btn-disabled,
  &:disabled {
    color: #1675e0;
    color: var(--rs-btn-ghost-text);
    background-color: transparent;
    opacity: 0.3;
  }
}

.rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }

  color: var(--rs-pagination-item-current-text);
  text-decoration: underline;
}

[dir="rtl"] .rs-pagination-btn [class*="rs-icon-page"] {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rs-panel {
  border-radius: 6px;
  overflow: hidden;
}

.rs-panel-bordered {
  border: 1px solid #EAECF0;
  border: 1px solid var(--rs-border-primary);
}

.rs-panel-shaded {
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--rs-panel-shadow);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: var(--rs-panel-shadow);
}

.rs-panel-body,
.rs-panel-header {
  padding: 16px 12px;
}

.rs-panel-body-fill {
  padding: 0 !important;
}

.rs-panel-header {
  color: #272c36;
  color: var(--rs-text-heading);
  font-size: 16px;
  line-height: 1.25;
}

.rs-panel-title {
  margin: 0;

  a {
    color: inherit;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

.rs-panel-header + {
  .rs-panel-body,
  .rs-panel-collapse .rs-panel-body {
    padding-top: 0;
  }
}

.rs-panel-collapsible > .rs-panel-header {
  cursor: pointer;
  -webkit-transition: background-color 0.3s linear, border-radius 0.3s linear;
  transition: background-color 0.3s linear, border-radius 0.3s linear;
  position: relative;

  > .rs-icon {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    position: absolute;
    top: 18px;
    right: 12px;
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  }
}

.rs-panel-group {
  border-radius: 8px;
  overflow: hidden;
}

.rs-panel-group-bordered {
  border: 1px solid #EAECF0;
  border: 1px solid var(--rs-border-primary);
}

.rs-panel-group > .rs-panel {
  border: none;

  + .rs-panel {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      border-top: 1px solid #EAECF0;
      border-top: 1px solid var(--rs-border-primary);
      left: 20px;
      right: 20px;
    }
  }
}

.rs-picker-toggle-wrapper {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}

.rs-picker-toggle.rs-btn .rs-ripple-pond {
  display: none !important;
}

.rs-picker-block {
  display: block;
}

.rs-picker-disabled {
  opacity: 0.3;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary);
}

.rs-picker-has-value {
  .rs-btn .rs-picker-toggle-value,
  .rs-picker-toggle .rs-picker-toggle-value {
    color: #1675e0;
    color: var(--rs-picker-value);
  }
}

.rs-picker-none {
  padding: 6px 12px 12px;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  cursor: default;
}

.rs-picker-countable .rs-picker-toggle-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rs-picker-value-list {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-picker-value-list {
    max-width: 100%;
  }
}

.rs-picker-value-count {
  margin: 0 4px;
  background-color: #3498ff;
  background-color: var(--rs-picker-count-bg);
  color: #fff;
  color: var(--rs-picker-count-text);
  border-radius: 10px;
  padding: 0 8px;
  line-height: 20px;
}

.rs-picker-value-separator {
  margin: 0 4px 0 0;
}

.rs-picker {
  &.rs-picker-focused,
  &:not(.rs-picker-disabled):hover {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  -webkit-box-shadow: var(--rs-state-focus-shadow);
  box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  box-shadow: var(--rs-state-focus-shadow);
}

.rs-picker-toggle {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-transition: color 0.2s linear, background-color 0.3s linear;
  transition: color 0.2s linear, background-color 0.3s linear;
  border: none;
  border: var(--rs-btn-default-border, none);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  color: #575757;
  color: var(--rs-btn-default-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-bg);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  position: relative;
}

.rs-theme-high-contrast .rs-picker-toggle {
  -webkit-transition: none;
  transition: none;
}

.rs-btn-ghost.rs-picker-toggle {
  padding: 7px 11px;
}

.rs-btn-icon.rs-picker-toggle {
  padding: 10px;
  line-height: 16px;

  > .rs-icon {
    font-size: 16px;
  }
}

.rs-btn-icon-with-text.rs-picker-toggle {
  line-height: 20px;

  > .rs-icon {
    padding: 10px;
    width: 36px;
    height: 36px;
  }

  &.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-picker-toggle:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
}

.rs-theme-high-contrast .rs-picker-toggle:focus-visible {
  outline-offset: 2px;
}

.rs-picker-toggle {
  &:focus,
  &:hover {
    color: var(--rs-btn-default-hover-text);
    background-color: #EAECF0;
    background-color: var(--rs-btn-default-hover-bg);
    text-decoration: none;
  }

  &.rs-btn-active,
  &:active {
    color: #272c36;
    color: var(--rs-btn-default-active-text);
    background-color: #d9d9d9;
    background-color: var(--rs-btn-default-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    cursor: not-allowed;
    color: #c5c6c7;
    color: var(--rs-btn-default-disabled-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-disabled-bg);
  }
}

.rs-theme-high-contrast .rs-picker-toggle {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  .rs-picker-toggle {
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
  }
}

.rs-picker-default .rs-picker-toggle {
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
}

.rs-theme-high-contrast .rs-picker-default .rs-picker-toggle {
  -webkit-transition: none;
  transition: none;
}

.rs-picker-default:not(.rs-picker-disabled) {
  .rs-picker-toggle-active {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }

  .rs-picker-toggle {
    &:focus,
    &:hover {
      border-color: #3498ff;
      border-color: var(--rs-input-focus-border);
    }
  }
}

.rs-picker-subtle .rs-picker-toggle {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  -webkit-transition: none;
  transition: none;

  &:focus,
  &:hover {
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-subtle-hover-bg);
  }

  &.rs-btn-active,
  &:active {
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
    background-color: #EAECF0;
    background-color: var(--rs-btn-subtle-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;
  }
}

.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.rs-picker-subtle .rs-picker-toggle-active {
  background-color: #f7f7fa;
  background-color: var(--rs-btn-subtle-hover-bg);
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
}

.rs-picker-default .rs-picker-toggle {
  padding: 7px 11px;
}

.rs-picker-toggle-textbox {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 1px solid transparent;
  padding-left: 10px;
  padding-right: 32px;
  color: #575757;
  color: var(--rs-text-primary);
  background-color: #fff;
  background-color: var(--rs-input-bg);
  outline: none;
}

.rs-picker-toggle {
  &.rs-btn-lg .rs-picker-toggle-textbox {
    padding-left: 14px;
  }

  &.rs-btn-sm .rs-picker-toggle-textbox {
    padding-left: 8px;
  }

  &.rs-btn-xs .rs-picker-toggle-textbox {
    padding-left: 6px;
  }
}

.rs-picker-toggle-read-only {
  opacity: 0;
}

.rs-picker-default {
  .rs-btn,
  .rs-picker-toggle {
    -webkit-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;
  }
}

.rs-theme-high-contrast .rs-picker-default {
  .rs-btn,
  .rs-picker-toggle {
    -webkit-transition: none;
    transition: none;
  }
}

.rs-picker-default:not(.rs-picker-disabled) {
  .rs-btn-active {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }

  .rs-btn {
    &:focus,
    &:hover {
      border-color: #3498ff;
      border-color: var(--rs-input-focus-border);
    }
  }

  .rs-picker-toggle-active {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }

  .rs-picker-toggle {
    &:focus,
    &:hover {
      border-color: #3498ff;
      border-color: var(--rs-input-focus-border);
    }
  }
}

.rs-picker-subtle {
  .rs-btn,
  .rs-picker-toggle {
    color: #8e8e93;
    color: var(--rs-btn-subtle-text);
    background-color: transparent;
    border: none;
    -webkit-transition: none;
    transition: none;
  }

  .rs-btn {
    &:focus,
    &:hover {
      color: #575757;
      color: var(--rs-btn-subtle-hover-text);
      background-color: #f7f7fa;
      background-color: var(--rs-btn-subtle-hover-bg);
    }
  }

  .rs-picker-toggle {
    &:focus,
    &:hover {
      color: #575757;
      color: var(--rs-btn-subtle-hover-text);
      background-color: #f7f7fa;
      background-color: var(--rs-btn-subtle-hover-bg);
    }
  }

  .rs-btn {
    &.rs-btn-active,
    &:active {
      color: #272c36;
      color: var(--rs-btn-subtle-active-text);
      background-color: #EAECF0;
      background-color: var(--rs-btn-subtle-active-bg);
    }
  }

  .rs-picker-toggle {
    &.rs-btn-active,
    &:active {
      color: #272c36;
      color: var(--rs-btn-subtle-active-text);
      background-color: #EAECF0;
      background-color: var(--rs-btn-subtle-active-bg);
    }
  }

  .rs-btn {
    &.rs-btn-disabled,
    &:disabled {
      color: #c5c6c7;
      color: var(--rs-btn-subtle-disabled-text);
      background: none;
    }
  }

  .rs-picker-toggle {
    &.rs-btn-disabled,
    &:disabled {
      color: #c5c6c7;
      color: var(--rs-btn-subtle-disabled-text);
      background: none;
    }
  }
}

.rs-theme-high-contrast .rs-picker-subtle {
  .rs-btn {
    &.rs-btn-disabled,
    &:disabled {
      opacity: 0.5;
    }
  }

  .rs-picker-toggle {
    &.rs-btn-disabled,
    &:disabled {
      opacity: 0.5;
    }
  }
}

.rs-picker-subtle {
  .rs-btn-active,
  .rs-picker-toggle-active {
    background-color: #f7f7fa;
    background-color: var(--rs-btn-subtle-hover-bg);
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
  }
}

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
  padding-right: 32px;
}

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn {
  padding-right: 44px;
}

.rs-picker-toggle.rs-btn {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 8px;
  }
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 7px;
  }
}

.rs-picker-toggle.rs-btn {
  .rs-picker-toggle-caret {
    right: 12px;
  }

  .rs-picker-toggle-clean {
    right: 30px;
  }
}

.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 7px 12px;
  font-size: 14px;
  line-height: 1.42857143;
}

.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper {
  padding-bottom: 5px;

  .rs-tag {
    margin-top: 5px;
  }

  .rs-picker-search-input {
    padding-left: 12px;
    margin-top: 5px;
    font-size: 14px;
  }

  input {
    height: 18px;
  }
}

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding-right: 36px;
}

.rs-picker-date .rs-picker-toggle.rs-btn {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 8px;
  }
}

.rs-picker-daterange .rs-picker-toggle.rs-btn {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 8px;
  }
}

.rs-picker-subtle {
  .rs-picker-date .rs-picker-toggle.rs-btn {
    .rs-picker-toggle-caret,
    .rs-picker-toggle-clean {
      top: 7px;
    }
  }

  .rs-picker-daterange .rs-picker-toggle.rs-btn {
    .rs-picker-toggle-caret,
    .rs-picker-toggle-clean {
      top: 7px;
    }
  }
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 12px;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 30px;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
}

.rs-picker-toggle.rs-btn-lg {
  font-size: 16px;
  line-height: 22px;
  padding: 10px 16px;
}

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-lg {
  padding-right: 36px;
}

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-lg {
  padding-right: 50px;
}

.rs-picker-toggle.rs-btn-lg {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 10px;
  }
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 9px;
  }
}

.rs-picker-toggle.rs-btn-lg {
  .rs-picker-toggle-caret {
    right: 16px;
  }

  .rs-picker-toggle-clean {
    right: 32px;
  }
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-left: 15px;
}

.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 8px 16px;
  font-size: 16px;
  line-height: 1.375;
}

.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
  padding-bottom: 6px;

  .rs-tag {
    margin-top: 6px;
  }

  .rs-picker-search-input {
    padding-left: 16px;
    margin-top: 6px;
    font-size: 16px;
  }

  input {
    height: 20px;
  }
}

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg {
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rs-picker-date .rs-picker-toggle.rs-btn-lg,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
  padding-right: 42px;
}

.rs-picker-date .rs-picker-toggle.rs-btn-lg {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 10px;
  }
}

.rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 10px;
  }
}

.rs-picker-subtle {
  .rs-picker-date .rs-picker-toggle.rs-btn-lg {
    .rs-picker-toggle-caret,
    .rs-picker-toggle-clean {
      top: 9px;
    }
  }

  .rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
    .rs-picker-toggle-caret,
    .rs-picker-toggle-clean {
      top: 9px;
    }
  }
}

.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  right: 16px;
}

.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  right: 36px;
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-top: 9px;
  padding-bottom: 9px;
}

.rs-btn-ghost.rs-picker-toggle.rs-btn-lg {
  padding: 9px 15px;
}

.rs-btn-icon.rs-picker-toggle.rs-btn-lg {
  padding: 11px;
  line-height: 20px;

  > .rs-icon {
    font-size: 20px;
  }
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg {
  line-height: 22px;

  > .rs-icon {
    padding: 11px;
    width: 42px;
    height: 42px;
  }

  &.rs-btn-icon-placement-left {
    padding: 10px 16px 10px 58px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 10px 58px 10px 16px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-picker-toggle.rs-btn-lg .rs-picker-value-count {
  line-height: 22px;
}

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-md {
  padding-right: 32px;
}

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-md {
  padding-right: 44px;
}

.rs-picker-toggle.rs-btn-md {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 8px;
  }
}

.rs-picker-default .rs-picker-toggle.rs-btn-md {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 7px;
  }
}

.rs-picker-toggle.rs-btn-md {
  .rs-picker-toggle-caret {
    right: 12px;
  }

  .rs-picker-toggle-clean {
    right: 30px;
  }
}

.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-left: 11px;
}

.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 7px 12px;
  font-size: 14px;
  line-height: 1.42857143;
}

.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper {
  padding-bottom: 5px;

  .rs-tag {
    margin-top: 5px;
  }

  .rs-picker-search-input {
    padding-left: 12px;
    margin-top: 5px;
    font-size: 14px;
  }

  input {
    height: 18px;
  }
}

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md {
  padding-top: 7px;
  padding-bottom: 7px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rs-picker-date .rs-picker-toggle.rs-btn-md,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md {
  padding-right: 36px;
}

.rs-picker-date .rs-picker-toggle.rs-btn-md {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 8px;
  }
}

.rs-picker-daterange .rs-picker-toggle.rs-btn-md {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 8px;
  }
}

.rs-picker-subtle {
  .rs-picker-date .rs-picker-toggle.rs-btn-md {
    .rs-picker-toggle-caret,
    .rs-picker-toggle-clean {
      top: 7px;
    }
  }

  .rs-picker-daterange .rs-picker-toggle.rs-btn-md {
    .rs-picker-toggle-caret,
    .rs-picker-toggle-clean {
      top: 7px;
    }
  }
}

.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  right: 12px;
}

.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  right: 30px;
}

.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-top: 7px;
  padding-bottom: 7px;
}

.rs-picker-toggle.rs-btn-sm {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
}

.rs-btn-ghost.rs-picker-toggle.rs-btn-sm {
  padding: 4px 9px;
}

.rs-btn-icon.rs-picker-toggle.rs-btn-sm {
  padding: 7px;
  line-height: 16px;

  > .rs-icon {
    font-size: 16px;
  }
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm {
  line-height: 20px;

  > .rs-icon {
    padding: 7px;
    width: 30px;
    height: 30px;
  }

  &.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-sm {
  padding-right: 30px;
}

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-sm {
  padding-right: 42px;
}

.rs-picker-toggle.rs-btn-sm {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 5px;
  }
}

.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 4px;
  }
}

.rs-picker-toggle.rs-btn-sm {
  .rs-picker-toggle-caret {
    right: 10px;
  }

  .rs-picker-toggle-clean {
    right: 28px;
  }
}

.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-left: 9px;
}

.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 4px 10px;
  font-size: 14px;
  line-height: 1.42857143;
}

.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper {
  padding-bottom: 2px;

  .rs-tag {
    margin-top: 2px;
  }

  .rs-picker-search-input {
    padding-left: 10px;
    margin-top: 2px;
    font-size: 14px;
  }

  input {
    height: 18px;
  }
}

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm {
  padding-top: 4px;
  padding-bottom: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rs-picker-date .rs-picker-toggle.rs-btn-sm,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
  padding-right: 34px;
}

.rs-picker-date .rs-picker-toggle.rs-btn-sm {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 4px;
  }
}

.rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 4px;
  }
}

.rs-picker-subtle {
  .rs-picker-date .rs-picker-toggle.rs-btn-sm {
    .rs-picker-toggle-caret,
    .rs-picker-toggle-clean {
      top: 3px;
    }
  }

  .rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
    .rs-picker-toggle-caret,
    .rs-picker-toggle-clean {
      top: 3px;
    }
  }
}

.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  right: 10px;
}

.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  right: 28px;
}

.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-top: 4px;
  padding-bottom: 4px;
}

.rs-picker-toggle.rs-btn-xs {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
}

.rs-btn-ghost.rs-picker-toggle.rs-btn-xs {
  padding: 1px 7px;
}

.rs-btn-icon.rs-picker-toggle.rs-btn-xs {
  padding: 6px;
  line-height: 12px;

  > .rs-icon {
    font-size: 12px;
  }
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs {
  line-height: 20px;

  > .rs-icon {
    padding: 6px;
    width: 24px;
    height: 24px;
  }

  &.rs-btn-icon-placement-left {
    padding: 2px 8px 2px 32px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 2px 32px 2px 8px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-xs {
  padding-right: 28px;
}

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-xs {
  padding-right: 40px;
}

.rs-picker-toggle.rs-btn-xs {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 2px;
  }
}

.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 1px;
  }
}

.rs-picker-toggle.rs-btn-xs {
  .rs-picker-toggle-caret {
    right: 8px;
  }

  .rs-picker-toggle-clean {
    right: 26px;
  }
}

.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  padding-left: 7px;
}

.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 1px 8px;
  font-size: 12px;
  line-height: 1.66666667;
}

.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
  padding-bottom: -1px;

  .rs-tag {
    margin-top: -1px;
  }

  .rs-picker-search-input {
    padding-left: 8px;
    margin-top: -1px;
    font-size: 12px;
  }

  input {
    height: 18px;
  }
}

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs {
  padding-top: 1px;
  padding-bottom: 1px;
  -webkit-box-shadow: none;
  box-shadow: none;

  ~ .rs-picker-tag-wrapper {
    padding-bottom: 1px;

    .rs-tag {
      margin-top: 1px;
    }

    .rs-picker-search-input {
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }
}

.rs-picker-date .rs-picker-toggle.rs-btn-xs,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
  padding-right: 30px;
}

.rs-picker-date .rs-picker-toggle.rs-btn-xs {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 2px;
  }
}

.rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 2px;
  }
}

.rs-picker-subtle {
  .rs-picker-date .rs-picker-toggle.rs-btn-xs {
    .rs-picker-toggle-caret,
    .rs-picker-toggle-clean {
      top: 1px;
    }
  }

  .rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
    .rs-picker-toggle-caret,
    .rs-picker-toggle-clean {
      top: 1px;
    }
  }
}

.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  right: 8px;
}

.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  right: 24px;
}

.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  padding-top: 1px;
  padding-bottom: 1px;
}

.rs-picker-toggle {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  cursor: pointer;
  color: #575757;
  color: var(--rs-text-primary);
  border: 1px solid #EAECF0;
  border: 1px solid var(--rs-border-primary);
}

.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed;
}

.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled) {
  &:focus,
  &:hover {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }
}

.rs-picker-subtle {
  .rs-picker-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
    cursor: pointer;
  }

  &.rs-picker-disabled .rs-picker-toggle {
    cursor: not-allowed;

    &:active,
    &:focus,
    &:hover {
      background: none;
    }

    &:active:after,
    &:focus:after,
    &:hover:after {
      display: none;
    }
  }
}

.rs-picker-toggle-label {
  color: #575757;
  color: var(--rs-text-primary);

  &:after {
    content: ":";
    margin: 0 4px 0 2px;
  }
}

.rs-picker-toggle-value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 44px;
}

.rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle,
.rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 52px;
}

.rs-picker-toggle-clean {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 30px;
  height: 20px;
  font-size: 12px;
  background: inherit;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
  cursor: pointer;

  &.rs-btn-close {
    padding: 4px 0;
  }

  &:hover {
    color: #f44336;
    color: var(--rs-state-error);
  }
}

.rs-picker-date .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle-clean {
  right: 38px;
}

.rs-picker-toggle-caret {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 2px;
  top: 8px;
  right: 12px;
  height: 20px;
  font-size: 12px;
  color: #8e8e93;
  color: var(--rs-text-secondary);
}

.rs-picker-menu,
.rs-picker-toggle-caret {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
}

.rs-picker-menu {
  text-align: left;
  z-index: 12;
  border-radius: 6px;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--rs-shadow-overlay);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: var(--rs-shadow-overlay);
  overflow: hidden;
  -webkit-transition: none;
  transition: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rs-theme-high-contrast .rs-picker-menu {
  border: 1px solid #EAECF0;
  border: 1px solid var(--rs-border-primary);
}

.rs-drawer-open .rs-picker-menu,
.rs-modal-open .rs-picker-menu {
  z-index: 1055;
}

.rs-picker-menu {
  &.rs-picker-check-menu,
  &.rs-picker-select-menu {
    padding-top: 6px;
  }

  .rs-picker-search-bar {
    position: relative;
    padding: 6px 12px 12px;

    .rs-picker-search-bar-input {
      display: block;
      width: 100%;
      color: #575757;
      color: var(--rs-text-primary);
      background-color: #fff;
      background-color: var(--rs-input-bg);
      background-image: none;
      border: 1px solid #EAECF0;
      border: 1px solid var(--rs-border-primary);
      -webkit-transition: border-color 0.3s ease-in-out;
      transition: border-color 0.3s ease-in-out;
      border-radius: 6px;
      font-size: 14px;
      line-height: 1.42857143;
      min-width: 200px;
      padding: 7px 36px 7px 11px;
    }
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    height: 36px;
  }
}

textarea.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  height: auto;
}

.rs-theme-high-contrast .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  -webkit-transition: none;
  transition: none;
}

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }

  &:focus,
  &:hover:not(:disabled) {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }

  &:focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
  }
}

.rs-theme-high-contrast .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus {
  outline-offset: 2px;
}

.rs-picker-menu .rs-picker-search-bar {
  .rs-picker-search-bar-input:disabled {
    background-color: #f7f7fa;
    background-color: var(--rs-input-disabled-bg);
    color: #c5c6c7;
    color: var(--rs-text-disabled);
  }

  .rs-picker-search-bar-search-icon {
    position: absolute;
    width: 14px;
    color: #8e8e93;
    color: var(--rs-text-secondary);
    font-size: 14px;
    height: 20px;
    top: 14px;
    right: 24px;
  }
}

.rs-check-tree-menu.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-search-icon,
.rs-tree-menu.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-search-icon {
  top: 7px;
}

.rs-picker-menu {
  .ReactVirtualized__Grid.ReactVirtualized__List:focus {
    outline: none;
  }

  &.rs-picker-inline {
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.rs-picker-default {
  .rs-btn,
  .rs-picker-toggle {
    background-color: #fff !important;
    background-color: var(--rs-input-bg) !important;
  }
}

.rs-picker-input {
  .rs-btn,
  .rs-picker-toggle {
    background-color: #fff !important;
    background-color: var(--rs-input-bg) !important;
  }
}

.rs-picker-default.rs-picker-disabled {
  .rs-btn,
  .rs-picker-toggle {
    background-color: #f7f7fa !important;
    background-color: var(--rs-input-disabled-bg) !important;
  }
}

.rs-picker-input.rs-picker-disabled {
  .rs-btn,
  .rs-picker-toggle {
    background-color: #f7f7fa !important;
    background-color: var(--rs-input-disabled-bg) !important;
  }
}

.rs-check-item {
  display: block;

  &.rs-check-item-focus,
  &:focus,
  &:not(.rs-checkbox-disabled):hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: var(--rs-listbox-option-hover-bg);
  }
}

.rs-theme-high-contrast {
  .rs-check-item {
    &.rs-check-item-focus,
    &:focus,
    &:not(.rs-checkbox-disabled):hover {
      outline: 3px solid rgba(52, 152, 255, 0.25);
      outline: 3px solid var(--rs-color-focus-ring);
      outline-offset: -3px;
      outline-width: 2px;
      color: #1675e0;
      color: var(--rs-listbox-option-hover-text);
      text-decoration: underline;
    }
  }

  .rs-theme-high-contrast .rs-check-item {
    &.rs-check-item-focus,
    &:focus,
    &:not(.rs-checkbox-disabled):hover {
      outline-offset: 2px;
    }
  }

  .rs-check-item {
    &.rs-check-item-focus .rs-check-tree-node-text-wrapper,
    &:focus .rs-check-tree-node-text-wrapper,
    &:not(.rs-checkbox-disabled):hover .rs-check-tree-node-text-wrapper {
      text-decoration: underline;
    }
  }
}

.rs-check-item .rs-checkbox-checker {
  padding: 0;

  > label {
    position: relative;
    cursor: pointer;
    display: block;
    padding: 8px 12px 8px 38px;
    -webkit-transition: color 0.3s linear, background-color 0.3s linear;
    transition: color 0.3s linear, background-color 0.3s linear;
    line-height: 20px;
  }
}

.rs-theme-high-contrast .rs-check-item .rs-checkbox-checker > label {
  -webkit-transition: none;
  transition: none;
}

.rs-check-item .rs-checkbox-checker > label:before {
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
}

.rs-theme-high-contrast .rs-check-item .rs-checkbox-checker > label:before {
  background: none;
}

.ReactVirtualized__Grid .rs-check-item .rs-checkbox-checker > label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rs-checkbox-disabled.rs-check-item .rs-checkbox-checker > label {
  cursor: not-allowed;
}

.rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
  left: 12px;
}

.grouped .rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper,
.rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
  left: 26px;
}

.grouped .rs-check-item .rs-checkbox-checker > label,
.rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker > label {
  padding-left: 52px;
}

.rs-placeholder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rs-placeholder-paragraph {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.rs-placeholder-paragraph-graph {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 2px;
  margin-right: 20px;
  background-color: #f2f2f5;
  background-color: var(--rs-placeholder);
}

.rs-placeholder-paragraph-graph-circle {
  border-radius: 50%;
}

.rs-placeholder-paragraph-graph-image {
  width: 80px;
  height: 60px;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    width: 0;
    height: 0;
    left: 10px;
    bottom: 10px;
    position: absolute;
    border-bottom: 36px solid #fff;
    border-bottom: 36px solid var(--rs-bg-card);
    border-left: 20px solid transparent;
    border-right: 30px solid transparent;
  }

  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 40px;
    bottom: 10px;
    position: absolute;
    border-bottom: 22px solid #fff;
    border-bottom: 22px solid var(--rs-bg-card);
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
  }

  .rs-placeholder-paragraph-graph-inner {
    width: 12px;
    height: 12px;
    right: 18px;
    top: 10px;
    border-radius: 50%;
    background: #fff;
    background: var(--rs-bg-card);
    position: absolute;
  }
}

.rs-placeholder-paragraph-rows {
  width: 100%;

  > p {
    background-color: #f2f2f5;
    background-color: var(--rs-placeholder);
  }
}

.rs-placeholder-grid-col {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;

  > p {
    background-color: #f2f2f5;
    background-color: var(--rs-placeholder);
  }

  &:first-child {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.rs-placeholder-graph {
  display: inline-block;
  width: 100%;
  background-color: #f2f2f5;
  background-color: var(--rs-placeholder);
}

.rs-placeholder-active {
  &.rs-placeholder-graph,
  .rs-placeholder-grid-col > p,
  .rs-placeholder-paragraph-graph-image,
  .rs-placeholder-paragraph-rows > p {
    background: linear-gradient(-45deg, #f2f2f5 25%, #EAECF0 37%, #f2f2f5 63%);
    background: linear-gradient(-45deg, var(--rs-placeholder) 25%, var(--rs-placeholder-active) 37%, var(--rs-placeholder) 63%);
    background-size: 400% 100% !important;
    -webkit-animation: placeholder-active 3s ease infinite;
    animation: placeholder-active 3s ease infinite;
  }
}

@-webkit-keyframes placeholder-active {
  0% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0 50%;
  }
}

@keyframes placeholder-active {
  0% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0 50%;
  }
}

.rs-popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  padding: 12px;
  font-size: 14px;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  background-clip: padding-box;
  border-radius: 4px;
  opacity: 0;
  -webkit-filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12));
  -webkit-filter: drop-shadow(var(--rs-popover-shadow));
  filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12));
  filter: drop-shadow(var(--rs-popover-shadow));
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: var(--rs-popover-shadow);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  box-shadow: var(--rs-popover-shadow);

  &.rs-anim-fade {
    -webkit-transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  }

  &.rs-anim-in {
    opacity: 1;
    -webkit-transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
  }
}

.rs-theme-high-contrast .rs-popover {
  border: 1px solid #EAECF0;
  border: 1px solid var(--rs-border-primary);
}

.rs-popover > .rs-popover-arrow {
  border-width: 6px;
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;

  &:after,
  &:before {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  &:after {
    border-width: 6px;
    content: "";
  }

  &:before {
    border-width: 6px;
    content: "";
    display: none;
  }
}

.rs-theme-high-contrast .rs-popover > .rs-popover-arrow:before {
  display: block;
}

.rs-popover-title {
  margin: 0;
  font-size: 14px;
  line-height: 1.42857143;
}

.rs-popover-content {
  font-size: 12px;
  line-height: 1.66666667;
}

.rs-popover-title ~ .rs-popover-content {
  margin-top: 8px;
}

.rs-popover[class*="placement-top"] {
  margin-top: -8px;
  -webkit-transform: translate(0);
  transform: translate(0);

  &.rs-anim-in {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  > .rs-popover-arrow {
    margin-left: -6px;
    bottom: -6px;

    &:after {
      bottom: -6px;
      margin-left: -6px;
      border-width: 6px 6px 0;
      border-top-color: #fff;
      border-top-color: var(--rs-bg-overlay);
    }
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-popover[class*="placement-top"] > .rs-popover-arrow:after {
    bottom: -7px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-popover[class*="placement-top"] > .rs-popover-arrow:after {
    bottom: -7px;
  }
}

.rs-theme-high-contrast .rs-popover[class*="placement-top"] > .rs-popover-arrow:before {
  bottom: -7px;
  margin-left: -7px;
  border-width: 7px 7px 0;
  border-top-color: #EAECF0;
  border-top-color: var(--rs-border-primary);
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-theme-high-contrast .rs-popover[class*="placement-top"] > .rs-popover-arrow:before {
    bottom: -8px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-popover[class*="placement-top"] > .rs-popover-arrow:before {
    bottom: -8px;
  }
}

.rs-popover[class*="placement-right"] {
  margin-left: 8px;
  -webkit-transform: translate(0);
  transform: translate(0);

  &.rs-anim-in {
    -webkit-transform: translate(2px);
    transform: translate(2px);
  }

  > .rs-popover-arrow {
    left: -6px;
    margin-top: -6px;

    &:after {
      left: -6px;
      margin-top: -6px;
      border-width: 6px 6px 6px 0;
      border-right-color: #fff;
      border-right-color: var(--rs-bg-overlay);
    }
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-popover[class*="placement-right"] > .rs-popover-arrow:after {
    left: -7px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-popover[class*="placement-right"] > .rs-popover-arrow:after {
    left: -7px;
  }
}

.rs-theme-high-contrast .rs-popover[class*="placement-right"] > .rs-popover-arrow:before {
  left: -7px;
  margin-top: -7px;
  border-width: 7px 7px 7px 0;
  border-right-color: #EAECF0;
  border-right-color: var(--rs-border-primary);
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-theme-high-contrast .rs-popover[class*="placement-right"] > .rs-popover-arrow:before {
    left: -8px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-popover[class*="placement-right"] > .rs-popover-arrow:before {
    left: -8px;
  }
}

.rs-popover[class*="placement-left"] {
  margin-left: -8px;
  -webkit-transform: translate(0);
  transform: translate(0);

  &.rs-anim-in {
    -webkit-transform: translate(-2px);
    transform: translate(-2px);
  }

  > .rs-popover-arrow {
    right: -6px;
    margin-top: -6px;

    &:after {
      right: -6px;
      margin-top: -6px;
      border-width: 6px 0 6px 6px;
      border-left-color: #fff;
      border-left-color: var(--rs-bg-overlay);
    }
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-popover[class*="placement-left"] > .rs-popover-arrow:after {
    right: -7px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-popover[class*="placement-left"] > .rs-popover-arrow:after {
    right: -7px;
  }
}

.rs-theme-high-contrast .rs-popover[class*="placement-left"] > .rs-popover-arrow:before {
  right: -7px;
  margin-top: -7px;
  border-width: 7px 0 7px 7px;
  border-left-color: #EAECF0;
  border-left-color: var(--rs-border-primary);
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-theme-high-contrast .rs-popover[class*="placement-left"] > .rs-popover-arrow:before {
    right: -8px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-popover[class*="placement-left"] > .rs-popover-arrow:before {
    right: -8px;
  }
}

.rs-popover[class*="placement-bottom"] {
  margin-top: 8px;
  -webkit-transform: translate(0);
  transform: translate(0);

  &.rs-anim-in {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  > .rs-popover-arrow {
    margin-left: -6px;
    top: -6px;

    &:after {
      top: -6px;
      margin-left: -6px;
      border-width: 0 6px 6px;
      border-bottom-color: #fff;
      border-bottom-color: var(--rs-bg-overlay);
    }
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-popover[class*="placement-bottom"] > .rs-popover-arrow:after {
    top: -7px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-popover[class*="placement-bottom"] > .rs-popover-arrow:after {
    top: -7px;
  }
}

.rs-theme-high-contrast .rs-popover[class*="placement-bottom"] > .rs-popover-arrow:before {
  top: -7px;
  margin-left: -7px;
  border-width: 0 7px 7px;
  border-bottom-color: #EAECF0;
  border-bottom-color: var(--rs-border-primary);
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-theme-high-contrast .rs-popover[class*="placement-bottom"] > .rs-popover-arrow:before {
    top: -8px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-popover[class*="placement-bottom"] > .rs-popover-arrow:before {
    top: -8px;
  }
}

.rs-popover {
  &.placement-bottom > .rs-popover-arrow,
  &.placement-top > .rs-popover-arrow {
    left: 50%;
  }

  &.placement-bottom-end > .rs-popover-arrow,
  &.placement-top-end > .rs-popover-arrow {
    right: 4px;
  }

  &.placement-left > .rs-popover-arrow,
  &.placement-right > .rs-popover-arrow {
    top: 50%;
  }

  &.placement-left-end > .rs-popover-arrow,
  &.placement-right-end > .rs-popover-arrow {
    bottom: 4px;
  }
}

.rs-popover-full {
  padding: 0;

  .rs-popover-content {
    margin-top: 0;
  }
}

.rs-popover {
  &.placement-bottom-start.rs-popover-full > .rs-popover-arrow,
  &.placement-top-start.rs-popover-full > .rs-popover-arrow {
    margin-left: 6px;
  }

  &.placement-left-start.rs-popover-full > .rs-popover-arrow,
  &.placement-right-start.rs-popover-full > .rs-popover-arrow {
    margin-top: 6px;
  }
}

@-webkit-keyframes progress-active {
  0% {
    opacity: 0.1;
    width: 0;
  }

  20% {
    opacity: 0.5;
    width: 0;
  }

  to {
    opacity: 0;
    width: 100%;
  }
}

@keyframes progress-active {
  0% {
    opacity: 0.1;
    width: 0;
  }

  20% {
    opacity: 0.5;
    width: 0;
  }

  to {
    opacity: 0;
    width: 100%;
  }
}

@-webkit-keyframes progress-active-vertical {
  0% {
    opacity: 0.1;
    height: 0;
  }

  20% {
    opacity: 0.5;
    height: 0;
  }

  to {
    opacity: 0;
    height: 100%;
  }
}

@keyframes progress-active-vertical {
  0% {
    opacity: 0.1;
    height: 0;
  }

  20% {
    opacity: 0.5;
    height: 0;
  }

  to {
    opacity: 0;
    height: 100%;
  }
}

.rs-progress-info {
  font-size: 0;
}

.rs-progress,
.rs-progress-icon-fail,
.rs-progress-icon-success,
.rs-progress-info-status {
  font-size: 16px;
  line-height: 1.25;
}

.rs-progress-circle {
  position: relative;
  font-size: 0;
}

.rs-progress-circle-info {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 16px;
  line-height: 2.25;
  height: 36px;
  color: #8e8e93;
  color: var(--rs-text-secondary);
}

.rs-progress-circle {
  .rs-progress-trail {
    stroke: #EAECF0;
    stroke: var(--rs-progress-bg);
  }

  .rs-progress-stroke {
    stroke: #3498ff;
    stroke: var(--rs-progress-bar);
    -webkit-transition: stroke-dashoffset 0.3s ease, stroke-dasharray 0.2s ease-in-out, stroke 0.3s;
    transition: stroke-dashoffset 0.3s ease, stroke-dasharray 0.2s ease-in-out, stroke 0.3s;
  }
}

.rs-progress-circle-success {
  .rs-progress-circle-info {
    color: #4caf50;
    color: var(--rs-progress-bar-success);
  }

  .rs-progress-stroke {
    stroke: #4caf50;
    stroke: var(--rs-progress-bar-success);
  }
}

.rs-progress-circle-fail {
  .rs-progress-circle-info {
    color: #f44336;
    color: var(--rs-progress-bar-fail);
  }

  .rs-progress-stroke {
    stroke: #f44336;
    stroke: var(--rs-progress-bar-fail);
  }
}

.rs-progress-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 8px 12px;
}

.rs-progress-line-active .rs-progress-line-bg {
  position: relative;

  &:before {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border-radius: 10px;
    -webkit-animation: progress-active 2s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    animation: progress-active 2s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  }
}

.rs-progress-line .rs-progress-info {
  width: 50px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
  height: 20px;
  padding-left: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rs-progress-line-outer {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border-radius: 5px;
  overflow: hidden;
  vertical-align: middle;
}

.rs-progress-line-inner {
  background: #EAECF0;
  background: var(--rs-progress-bg);
  border-radius: 5px;
}

.rs-progress-line-bg {
  background-color: #3498ff;
  background-color: var(--rs-progress-bar);
  border-radius: 5px;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  -webkit-transition-property: width, background-color;
  transition-property: width, background-color;
  height: 10px;
}

.rs-progress-line-inner {
  height: 10px;
}

.rs-progress-line-success {
  color: #4caf50;
  color: var(--rs-progress-bar-success);

  .rs-progress-line-bg {
    background-color: #4caf50;
    background-color: var(--rs-progress-bar-success);
  }
}

.rs-progress-line-fail {
  color: #f44336;
  color: var(--rs-progress-bar-fail);

  .rs-progress-line-bg {
    background-color: #f44336;
    background-color: var(--rs-progress-bar-fail);
  }
}

.rs-progress-line.rs-progress-line-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  width: auto;

  .rs-progress-line-outer {
    width: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .rs-progress-line-inner {
    min-height: 100px;
    width: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    height: 100%;
  }

  .rs-progress-info {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    padding-left: 0;
    width: auto;
  }

  .rs-progress-line-bg {
    width: 100%;
    -webkit-transition-property: height, background-color;
    transition-property: height, background-color;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    position: absolute;
    bottom: 0;
  }

  &.rs-progress-line-active .rs-progress-line-bg:before {
    -webkit-animation-name: progress-active-vertical;
    animation-name: progress-active-vertical;
  }
}

.rs-progress-icon-fail,
.rs-progress-icon-success {
  display: inline-block;
}

.rs-progress-icon-fail svg,
.rs-progress-icon-success svg {
  stroke: currentColor;
  stroke-width: 0.5px;
  stroke-linecap: round;
}

.rs-progress-line {
  .rs-progress-icon-fail,
  .rs-progress-icon-success {
    line-height: 0;
    font-size: 14px;
  }
}

.rs-progress-circle {
  .rs-progress-icon-fail,
  .rs-progress-icon-success {
    line-height: 0;
    font-size: 36px;
  }
}

.rs-radio {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.rs-radio-disabled label {
  cursor: not-allowed;
}

.rs-radio label {
  line-height: 1.14285714;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.rs-radio-inner:before {
  border-color: #3498ff;
  border-color: var(--rs-checkbox-checked-bg);
}

.rs-radio.rs-radio-disabled label {
  cursor: not-allowed;
}

.rs-radio-disabled > .rs-radio-checker > label {
  color: #c5c6c7;
  color: var(--rs-text-disabled);
}

.rs-radio-inline {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: 400;
  margin-top: 0;
  margin-left: 10px;
}

.rs-plaintext .rs-radio-inline:first-child {
  margin-left: 0;
}

.rs-radio [type="radio"]:focus ~ .rs-radio-inner:before {
  -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  -webkit-box-shadow: var(--rs-state-focus-shadow);
  box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  box-shadow: var(--rs-state-focus-shadow);
}

.rs-radio-checker {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 36px;
  min-height: 36px;
  line-height: 1;
  position: relative;
}

.rs-radio-wrapper {
  position: absolute;
  width: 16px;
  height: 16px;
  display: inline-block;
  left: 10px;
  top: 10px;

  [type="radio"] {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .rs-radio-inner {
    &:after,
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
    }
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }

  .rs-radio-inner:before {
    width: 16px;
    height: 16px;
  }

  &:before {
    width: 16px;
    height: 16px;
    border: 1px solid #3498ff;
    border: 1px solid var(--rs-radio-checked-bg);
    background-color: transparent;
    border-radius: 50%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
    visibility: hidden;
    -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear, -webkit-transform 0.2s linear;
  }
}

.rs-theme-high-contrast .rs-radio-wrapper:before {
  -webkit-transition: none;
  transition: none;
}

.rs-radio-checked .rs-radio-wrapper:before {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 0;
  visibility: visible;
}

.rs-radio-wrapper:after {
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}

.rs-radio-inner:before {
  border: 1px solid #d9d9d9;
  border: 1px solid var(--rs-radio-border);
  background-color: transparent;
  border-radius: 50%;
  -webkit-transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
  transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
  transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
  transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
}

.rs-theme-high-contrast .rs-radio-inner:before {
  -webkit-transition: none;
  transition: none;
}

.rs-radio {
  &:hover .rs-radio-inner:before {
    border-color: #3498ff;
    border-color: var(--rs-radio-checked-bg);
  }

  &.rs-radio-disabled .rs-radio-inner:before {
    border-color: #f7f7fa;
    border-color: var(--rs-radio-disabled-bg);
    background-color: #f7f7fa;
    background-color: var(--rs-radio-disabled-bg);
  }

  &.rs-radio-checked .rs-radio-inner:before {
    border-color: #3498ff;
    border-color: var(--rs-radio-checked-bg);
    background-color: #3498ff;
    background-color: var(--rs-radio-checked-bg);
  }

  &.rs-radio-disabled.rs-radio-checked .rs-radio-inner:before {
    opacity: 0.3;
  }
}

.rs-theme-high-contrast .rs-radio.rs-radio-disabled.rs-radio-checked .rs-radio-inner:before {
  opacity: 0.5;
}

.rs-radio-inner:after {
  width: 6px;
  height: 6px;
  background-color: #fff;
  background-color: var(--rs-radio-marker);
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 50%;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46),
    -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.rs-theme-high-contrast .rs-radio-inner:after {
  -webkit-transition: none;
  transition: none;
}

.rs-radio-checked .rs-radio-inner:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.rs-radio-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rs-radio-group-inline {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: -10px;
}

.rs-radio-group-picker {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #575757;
  color: var(--rs-text-primary);
  border: 1px solid #EAECF0;
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px;
  margin-left: 0;

  .rs-radio-inline {
    margin-left: 0;
  }

  .rs-radio-checker {
    padding: 0;
    min-height: auto;
    border-bottom: 1px solid transparent;
    margin: 0 10px;
  }

  .rs-radio-wrapper {
    display: none;
  }

  .rs-radio-checker > label {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    color: #575757;
    color: var(--rs-btn-default-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-bg);
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    position: relative;
    color: var(--rs-btn-subtle-text);
    background-color: transparent;
    border: none;
    color: #8e8e93;
    color: var(--rs-text-secondary);
    background: none;
    -webkit-transition: color 0.3s linear;
    transition: color 0.3s linear;
    padding: 7px 0;
  }
}

.rs-btn-ghost.rs-radio-group-picker .rs-radio-checker > label {
  padding: 7px 11px;
}

.rs-btn-icon.rs-radio-group-picker .rs-radio-checker > label {
  padding: 10px;
  line-height: 16px;

  > .rs-icon {
    font-size: 16px;
  }
}

.rs-btn-icon-with-text.rs-radio-group-picker .rs-radio-checker > label {
  line-height: 20px;

  > .rs-icon {
    padding: 10px;
    width: 36px;
    height: 36px;
  }

  &.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-radio-group-picker .rs-radio-checker > label:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
}

.rs-theme-high-contrast .rs-radio-group-picker .rs-radio-checker > label:focus-visible {
  outline-offset: 2px;
}

.rs-radio-group-picker .rs-radio-checker > label {
  &:focus,
  &:hover {
    color: var(--rs-btn-default-hover-text);
    background-color: #EAECF0;
    background-color: var(--rs-btn-default-hover-bg);
    text-decoration: none;
  }

  &.rs-btn-active,
  &:active {
    color: var(--rs-btn-default-active-text);
    background-color: #d9d9d9;
    background-color: var(--rs-btn-default-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    cursor: not-allowed;
    color: var(--rs-btn-default-disabled-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-disabled-bg);
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  .rs-radio-group-picker .rs-radio-checker > label {
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
  }
}

.rs-picker-default .rs-radio-group-picker .rs-radio-checker > label {
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
}

.rs-theme-high-contrast .rs-picker-default .rs-radio-group-picker .rs-radio-checker > label {
  -webkit-transition: none;
  transition: none;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-radio-group-picker .rs-radio-checker > {
  label-active {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }

  label {
    &:focus,
    &:hover {
      border-color: #3498ff;
      border-color: var(--rs-input-focus-border);
    }
  }
}

.rs-picker-subtle .rs-radio-group-picker .rs-radio-checker > label {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  -webkit-transition: none;
  transition: none;

  &:focus,
  &:hover {
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-subtle-hover-bg);
  }

  &.rs-btn-active,
  &:active {
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
    background-color: #EAECF0;
    background-color: var(--rs-btn-subtle-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;
  }
}

.rs-theme-high-contrast .rs-picker-subtle .rs-radio-group-picker .rs-radio-checker > label {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.rs-picker-subtle .rs-radio-group-picker .rs-radio-checker > label-active {
  background-color: #f7f7fa;
  background-color: var(--rs-btn-subtle-hover-bg);
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
}

.rs-radio-group-picker .rs-radio-checker > label {
  &:focus,
  &:hover {
    background-color: #f7f7fa;
    background-color: var(--rs-btn-subtle-hover-bg);
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
  }

  &.rs-btn-active,
  &:active {
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
    background-color: #EAECF0;
    background-color: var(--rs-btn-subtle-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;
  }
}

.rs-theme-high-contrast .rs-radio-group-picker .rs-radio-checker > label {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }

  -webkit-transition: none;
  transition: none;
}

.rs-radio-group-picker {
  .rs-radio-checker > label {
    &:active,
    &:hover {
      color: #1675e0;
      color: var(--rs-text-active);
      background: none;
    }
  }

  .rs-radio-checked .rs-radio-checker {
    border-bottom: 2px solid #1675e0;
    border-bottom: 2px solid var(--rs-text-active);

    > label {
      color: #1675e0;
      color: var(--rs-text-active);
    }
  }

  .rs-radio-disabled {
    .rs-radio-checker {
      opacity: 0.3;
      cursor: not-allowed;
    }

    &:not(.rs-radio-checked) .rs-radio-checker > label {
      color: #8e8e93;
      color: var(--rs-text-secondary);
    }
  }
}

.rs-ripple {
  position: absolute;
  display: block;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0s;
  transition: 0s;
}

.rs-ripple-rippling {
  -webkit-transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 0;
}

.rs-ripple-pond {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.rs-theme-high-contrast .rs-ripple-pond {
  display: none !important;
}

.rs-row {
  margin-left: -5px;
  margin-right: -5px;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.rs-picker-select-menu-items {
  margin-bottom: 6px;
  overflow-y: auto;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .rs-picker-menu-group:not(:first-child) {
    margin-top: 6px;
    border-top: 1px solid #EAECF0;
    border-top: 1px solid var(--rs-border-primary);
    padding-top: 6px;
  }

  .rs-picker-menu-group-title {
    padding: 8px 32px 8px 12px;
    position: relative;
    cursor: default;
    -webkit-transition: color 0.3s linear, background-color 0.3s linear;
    transition: color 0.3s linear, background-color 0.3s linear;
    color: #272c36;
    color: var(--rs-text-heading);

    &:hover {
      background-color: #f2faff;
      background-color: var(--rs-state-hover-bg);
    }

    .rs-picker-menu-group-caret {
      display: inline-block;
      margin-left: 2px;
      position: absolute;
      top: 8px;
      right: 12px;
      -webkit-transition: -webkit-transform 0.3s linear;
      transition: -webkit-transform 0.3s linear;
      transition: transform 0.3s linear;
      transition: transform 0.3s linear, -webkit-transform 0.3s linear;
      padding: 3px;
    }
  }

  .rs-picker-menu-group.folded .rs-picker-menu-group-caret {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.rs-picker-select-menu-item {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  cursor: pointer;
  text-decoration: none;
  width: 100%;
}

.ReactVirtualized__Grid .rs-picker-select-menu-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rs-picker-select-menu-item {
  &.rs-picker-select-menu-item-focus,
  &:focus,
  &:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
}

.rs-theme-high-contrast {
  .rs-picker-select-menu-item {
    &.rs-picker-select-menu-item-focus,
    &:focus,
    &:hover {
      outline: 3px solid rgba(52, 152, 255, 0.25);
      outline: 3px solid var(--rs-color-focus-ring);
      outline-offset: -3px;
      outline-width: 2px;
      color: #1675e0;
      color: var(--rs-listbox-option-hover-text);
      text-decoration: underline;
    }
  }

  .rs-theme-high-contrast .rs-picker-select-menu-item {
    &.rs-picker-select-menu-item-focus,
    &:focus,
    &:hover {
      outline-offset: 2px;
    }
  }
}

.rs-picker-select-menu-item {
  &.rs-picker-select-menu-item-active {
    font-weight: 700;
    color: #1675e0;
    color: var(--rs-listbox-option-selected-text);
    background-color: #f2faff;
    background-color: var(--rs-listbox-option-selected-bg);
  }

  &.rs-picker-select-menu-item-disabled {
    color: #c5c6c7;
    color: var(--rs-listbox-option-disabled-text);
    background: none;
    cursor: not-allowed;
  }
}

.grouped .rs-picker-select-menu-item,
.rs-picker-select-menu-group-children .rs-picker-select-menu-item {
  padding-left: 26px;
}

.rs-sidebar-collapse {
  -webkit-transition: width 0.2s ease-in, -webkit-box-flex 0.2s ease-in;
  transition: width 0.2s ease-in, -webkit-box-flex 0.2s ease-in;
  transition: flex 0.2s ease-in, width 0.2s ease-in;
  transition: flex 0.2s ease-in, width 0.2s ease-in, -webkit-box-flex 0.2s ease-in, -ms-flex 0.2s ease-in;
  min-width: 0;
}

.rs-sidenav {
  -webkit-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
  width: 56px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rs-sidenav-nav {
  list-style: none;
  padding: 0;

  .rs-dropdown-item,
  .rs-dropdown-item-toggle,
  .rs-dropdown-toggle,
  .rs-sidenav-item {
    padding: 15px 20px;
    -webkit-transition: color 0.3s linear, background-color 0.3s linear;
    transition: color 0.3s linear, background-color 0.3s linear;
    border-radius: 0;
    color: inherit;
  }
}

.rs-theme-high-contrast .rs-sidenav-nav {
  .rs-dropdown-item,
  .rs-dropdown-item-toggle,
  .rs-dropdown-toggle,
  .rs-sidenav-item {
    -webkit-transition: none;
    transition: none;
  }
}

.rs-sidenav-nav > {
  .rs-dropdown,
  .rs-sidenav-item {
    margin: 0 !important;
  }

  .rs-dropdown .rs-dropdown-item-toggle {
    display: block;
    width: 100%;
    text-align: start;
    background: none;
    padding-right: 40px;
    position: relative;
  }
}

.rs-sidenav-collapse-in .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle {
  padding-left: 56px;
}

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle {
  &:focus {
    outline: 0;
  }

  &:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    z-index: 1;
  }
}

.rs-theme-high-contrast {
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus-visible {
    outline-offset: 2px;
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
  }

  .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus-visible {
    outline-offset: 2px;
  }
}

.rs-sidenav-nav > .rs-dropdown {
  .rs-dropdown-item-toggle-icon {
    font-size: 16px;
    position: absolute;
    right: 20px;
    top: 11px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .rs-dropdown-item-expand .rs-dropdown-item-toggle-icon {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

.rs-theme-high-contrast .rs-sidenav-nav {
  .rs-dropdown-menu,
  .rs-dropdown-toggle {
    border: none;
  }
}

.rs-sidenav-nav .rs-sidenav-item:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  z-index: 1;
}

.rs-theme-high-contrast {
  .rs-sidenav-nav .rs-sidenav-item:focus-visible {
    outline-offset: 2px;
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
  }

  .rs-theme-high-contrast .rs-sidenav-nav .rs-sidenav-item:focus-visible {
    outline-offset: 2px;
  }
}

.rs-sidenav-nav > {
  .rs-dropdown {
    .rs-dropdown-item-active,
    .rs-dropdown-menu-item-focus {
      font-weight: 400;
      background: transparent;
    }

    .rs-dropdown-menu {
      -webkit-transition: height 0.2s ease-in;
      transition: height 0.2s ease-in;
    }

    .rs-dropdown-toggle {
      height: auto;
      padding-right: 40px;

      .rs-dropdown-toggle-caret {
        font-size: 16px;
        right: 20px;
        top: 15px;

        &[aria-label="angle-right"] {
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }

      font-size: 16px;
      line-height: 1.25;
      padding-left: 56px;
      width: 100%;
      white-space: normal;
    }
  }

  .rs-sidenav-item {
    font-size: 16px;
    line-height: 1.25;
    padding-left: 56px;
    width: 100%;
    white-space: normal;
  }

  .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-sidenav-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
  }
}

.rs-theme-high-contrast .rs-sidenav-nav > {
  .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-sidenav-item:focus-visible {
    outline-offset: 2px;
  }
}

.rs-sidenav-nav {
  > {
    .rs-dropdown .rs-dropdown-toggle > .rs-icon:not(.rs-dropdown-toggle-caret),
    .rs-sidenav-item > .rs-icon:not(.rs-dropdown-toggle-caret) {
      font-size: 16px;
      margin-right: 20px;
      position: absolute;
      left: 20px;
      top: 15px;
      line-height: 1.25;
      height: 16px;
    }
  }

  .rs-dropdown-toggle:focus-visible,
  > .rs-dropdown-focus .rs-dropdown-toggle {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    z-index: 2;
  }
}

.rs-theme-high-contrast {
  .rs-sidenav-nav {
    .rs-dropdown-toggle:focus-visible,
    > .rs-dropdown-focus .rs-dropdown-toggle {
      outline-offset: 2px;
      outline: 3px solid rgba(52, 152, 255, 0.25);
      outline: 3px solid var(--rs-color-focus-ring);
      outline-offset: -3px;
      outline-width: 2px;
    }
  }

  .rs-theme-high-contrast .rs-sidenav-nav {
    .rs-dropdown-toggle:focus-visible,
    > .rs-dropdown-focus .rs-dropdown-toggle {
      outline-offset: 2px;
    }
  }
}

.rs-sidenav-item {
  position: relative;
  display: block;
  outline: 0;
  overflow: hidden;

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &.rs-sidenav-item-focus,
  &:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    z-index: 2;
  }
}

.rs-theme-high-contrast .rs-sidenav-item {
  &.rs-sidenav-item-focus,
  &:focus-visible {
    outline-offset: 2px;
  }
}

.rs-sidenav-toggle {
  height: 50px;
  border-top: 1px solid;
  margin-top: auto;
}

.rs-sidenav-toggle-button {
  float: right;
  width: 56px;
  height: 100%;
  border: none;
  border-radius: 0;
  color: inherit;
}

.rs-sidenav-collapse-in {
  width: 100%;

  .rs-sidenav-item {
    border-radius: 0;
  }

  .rs-dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rs-dropdown-item-focus,
  .rs-dropdown-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    background: none;
    z-index: 2;
  }
}

.rs-theme-high-contrast {
  .rs-sidenav-collapse-in {
    .rs-dropdown-item-focus,
    .rs-dropdown-item:focus-visible {
      outline-offset: 2px;
      outline: 3px solid rgba(52, 152, 255, 0.25);
      outline: 3px solid var(--rs-color-focus-ring);
      outline-offset: -3px;
      outline-width: 2px;
    }
  }

  .rs-theme-high-contrast .rs-sidenav-collapse-in {
    .rs-dropdown-item-focus,
    .rs-dropdown-item:focus-visible {
      outline-offset: 2px;
    }
  }
}

.rs-sidenav-collapse-in {
  .rs-dropdown-item-focus.rs-dropdown-item-submenu,
  .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
  .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    z-index: 2;
  }
}

.rs-theme-high-contrast {
  .rs-sidenav-collapse-in {
    .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
    .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
      outline-offset: 2px;
      outline: 3px solid rgba(52, 152, 255, 0.25);
      outline: 3px solid var(--rs-color-focus-ring);
      outline-offset: -3px;
      outline-width: 2px;
    }
  }

  .rs-theme-high-contrast .rs-sidenav-collapse-in {
    .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
    .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
      outline-offset: 2px;
    }
  }
}

.rs-sidenav-collapse-in {
  .rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
  .rs-dropdown-item:not(.rs-dropdown-item-submenu) {
    overflow: hidden;
    position: relative;
  }

  .rs-dropdown-item-disabled .rs-sidenav-collapse-in .rs-dropdown-item:after {
    display: none;
  }

  .rs-dropdown {
    .rs-dropdown-item-toggle > .rs-dropdown-item-toggle-icon:before,
    .rs-dropdown-toggle-caret:before {
      display: none;
    }

    .rs-dropdown-item-toggle > .rs-dropdown-item-toggle-icon,
    .rs-dropdown-toggle-caret {
      -webkit-transition: -webkit-transform 0.3s linear;
      transition: -webkit-transform 0.3s linear;
      transition: transform 0.3s linear;
      transition: transform 0.3s linear, -webkit-transform 0.3s linear;
    }

    &.rs-dropdown-expand .rs-dropdown-toggle-caret {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    .rs-dropdown-menu {
      display: block;
      position: static;
      float: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-animation-name: none;
      animation-name: none;
      padding: 0;
      background-color: transparent;
      border-radius: 0;
    }

    .rs-dropdown-item-submenu {
      padding: 0;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .rs-dropdown-item:not(.rs-dropdown-item-submenu) {
    width: 100%;
    white-space: normal;
  }

  .rs-dropdown-menu {
    .rs-dropdown-menu-toggle {
      width: 100%;
      white-space: normal;
    }

    overflow: hidden;
  }

  .rs-dropdown-item-submenu.rs-dropdown-item-collapse > .rs-dropdown-menu.rs-dropdown-menu-collapse-out,
  .rs-dropdown.rs-dropdown-collapse > .rs-dropdown-menu.rs-dropdown-menu-collapse-out {
    display: none;
  }

  .rs-dropdown-item-submenu.rs-dropdown-menu-collapse-in > .rs-dropdown-menu.rs-dropdown-menu-collapse-in,
  .rs-dropdown.rs-dropdown-menu-collapse-in > .rs-dropdown-menu.dropdown-menu-collapse-in {
    display: block;
  }

  .rs-dropdown-item-submenu > .rs-dropdown-item-menu-icon {
    padding-top: 11px;
    padding-bottom: 11px;
    line-height: 1.42857143;
    width: 14px;
    position: absolute;
    z-index: 1;
  }

  .rs-dropdown-item,
  .rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    padding: 11px 20px 11px 56px;
  }

  .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > {
    .rs-dropdown-item-menu-icon {
      left: 56px;
    }

    .rs-dropdown-menu-toggle {
      padding-left: 76px;
    }
  }

  .rs-dropdown-item-submenu > .rs-dropdown-menu .rs-dropdown-item-toggle,
  .rs-dropdown-item .rs-dropdown-item:not(.rs-dropdown-item-submenu) {
    padding-left: 65px;
  }

  .rs-dropdown-item-submenu > .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > {
    .rs-dropdown-item-menu-icon {
      left: 65px;
    }

    .rs-dropdown-menu-toggle {
      padding-left: 85px;
    }
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  .rs-sidenav-collapse-in {
    .rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
    .rs-dropdown-item:not(.rs-dropdown-item-submenu) {
      -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
    }
  }
}

.rs-sidenav-collapse-out {
  .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav-item {
    padding-right: 0;
    height: 50px;
    padding-left: 56px;
  }

  .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-sidenav-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid var(--rs-color-focus-ring);
  }
}

.rs-theme-high-contrast {
  .rs-sidenav-collapse-out {
    .rs-dropdown .rs-dropdown-toggle:focus-visible,
    .rs-sidenav-item:focus-visible {
      outline-offset: 2px;
      outline: 3px solid rgba(52, 152, 255, 0.25);
      outline: 3px solid var(--rs-color-focus-ring);
      outline-offset: -3px;
      outline-width: 2px;
    }
  }

  .rs-theme-high-contrast .rs-sidenav-collapse-out {
    .rs-dropdown .rs-dropdown-toggle:focus-visible,
    .rs-sidenav-item:focus-visible {
      outline-offset: 2px;
    }
  }
}

.rs-sidenav-collapse-out {
  .rs-dropdown-item {
    padding: 8px 27px 8px 12px;
  }

  .rs-dropdown-item-submenu {
    padding: 0;
  }

  .rs-dropdown-toggle-caret {
    display: none;
  }

  .rs-dropdown-toggle > span,
  .rs-sidenav-item-text {
    -webkit-animation: sideNavFoldedText 0.2s ease-in forwards;
    animation: sideNavFoldedText 0.2s ease-in forwards;
  }
}

.rs-sidenav-collapsing {
  &.rs-sidenav-collapse-in {
    .rs-dropdown-item,
    .rs-dropdown-item-submenu > .rs-dropdown-menu-toggle,
    .rs-dropdown .rs-dropdown-toggle,
    .rs-sidenav-item {
      white-space: nowrap;
      text-overflow: clip;
    }
  }

  &.rs-sidenav-collapse-out {
    .rs-dropdown-item,
    .rs-dropdown-item-submenu > .rs-dropdown-menu-toggle,
    .rs-dropdown .rs-dropdown-toggle,
    .rs-sidenav-item {
      white-space: nowrap;
      text-overflow: clip;
    }
  }

  &.rs-sidenav-collapse-in .rs-dropdown-toggle-caret {
    display: none;
  }

  &.rs-sidenav-collapse-out {
    .rs-dropdown .rs-dropdown-menu {
      padding-left: 0;
    }

    .rs-dropdown-item {
      -webkit-animation: sideNavFoldedText 0.2s ease-in;
      animation: sideNavFoldedText 0.2s ease-in;
    }
  }
}

.rs-sidenav-default {
  color: #575757;
  color: var(--rs-sidenav-default-text);
  background-color: #f7f7fa;
  background-color: var(--rs-sidenav-default-bg);

  .rs-dropdown-toggle,
  .rs-sidenav-item,
  .rs-sidenav-toggle-button {
    background-color: #f7f7fa;
    background-color: var(--rs-sidenav-default-bg);
  }

  .rs-dropdown {
    &.rs-dropdown-open .rs-dropdown-toggle,
    .rs-dropdown-toggle:hover {
      background-color: #EAECF0;
      background-color: var(--rs-sidenav-default-hover-bg);
      color: #575757;
      color: var(--rs-sidenav-default-hover-text);
    }
  }

  .rs-sidenav-item:hover,
  .rs-sidenav-toggle-button:hover {
    background-color: #EAECF0;
    background-color: var(--rs-sidenav-default-hover-bg);
    color: #575757;
    color: var(--rs-sidenav-default-hover-text);
  }

  .rs-dropdown-item.rs-dropdown-item-active,
  .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon,
  .rs-sidenav-item.rs-sidenav-item-active {
    color: #1675e0;
    color: var(--rs-sidenav-default-selected-text);
  }

  .rs-sidenav-toggle {
    border-top-color: #EAECF0;
    border-top-color: var(--rs-sidenav-default-footer-border);
  }

  &.rs-sidenav-collapse-out .rs-dropdown-item.rs-dropdown-item-active {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: var(--rs-menuitem-active-bg);
    color: #1675e0;
    color: var(--rs-menuitem-active-text);
  }
}

.rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown-item.rs-dropdown-item-active {
  text-decoration: underline;
  -webkit-box-shadow: inset 0 0 0 2px #fff;
  box-shadow: inset 0 0 0 2px #fff;
}

.rs-sidenav-default {
  &.rs-sidenav-collapse-in .rs-dropdown-item > {
    .rs-dropdown-item-toggle,
    .rs-dropdown-menu-toggle {
      color: #575757;
      color: var(--rs-sidenav-default-text);
    }
  }

  &.rs-sidenav-collapsing .rs-dropdown-item > {
    .rs-dropdown-item-toggle,
    .rs-dropdown-menu-toggle {
      color: #575757;
      color: var(--rs-sidenav-default-text);
    }
  }

  &.rs-sidenav-collapse-in .rs-dropdown-item > {
    .rs-dropdown-item-toggle {
      &.rs-dropdown-item-focus,
      &:focus,
      &:hover {
        background-color: #EAECF0;
        background-color: var(--rs-sidenav-default-hover-bg);
        color: #575757;
        color: var(--rs-sidenav-default-hover-text);
      }
    }

    .rs-dropdown-menu-toggle {
      &.rs-dropdown-item-focus,
      &:focus,
      &:hover {
        background-color: #EAECF0;
        background-color: var(--rs-sidenav-default-hover-bg);
        color: #575757;
        color: var(--rs-sidenav-default-hover-text);
      }
    }
  }

  &.rs-sidenav-collapsing .rs-dropdown-item > {
    .rs-dropdown-item-toggle {
      &.rs-dropdown-item-focus,
      &:focus,
      &:hover {
        background-color: #EAECF0;
        background-color: var(--rs-sidenav-default-hover-bg);
        color: #575757;
        color: var(--rs-sidenav-default-hover-text);
      }
    }

    .rs-dropdown-menu-toggle {
      &.rs-dropdown-item-focus,
      &:focus,
      &:hover {
        background-color: #EAECF0;
        background-color: var(--rs-sidenav-default-hover-bg);
        color: #575757;
        color: var(--rs-sidenav-default-hover-text);
      }
    }
  }

  &.rs-sidenav-collapse-in {
    .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active,
    .rs-sidenav-item.rs-sidenav-item-active {
      color: #1675e0;
      color: var(--rs-sidenav-default-selected-text);
    }
  }

  &.rs-sidenav-collapsing {
    .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active,
    .rs-sidenav-item.rs-sidenav-item-active {
      color: #1675e0;
      color: var(--rs-sidenav-default-selected-text);
    }
  }
}

.rs-theme-high-contrast .rs-sidenav-default {
  &.rs-sidenav-collapse-in {
    .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active,
    .rs-sidenav-item.rs-sidenav-item-active {
      text-decoration: underline;
    }
  }

  &.rs-sidenav-collapsing {
    .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active,
    .rs-sidenav-item.rs-sidenav-item-active {
      text-decoration: underline;
    }
  }
}

.rs-sidenav-default {
  &.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon,
  &.rs-sidenav-collapse-out .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon,
  &.rs-sidenav-collapsing .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon {
    color: #1675e0;
    color: var(--rs-sidenav-default-selected-text);
  }
}

.rs-sidenav-inverse {
  color: #fff;
  color: var(--rs-sidenav-inverse-text);
  background-color: #3498ff;
  background-color: var(--rs-sidenav-inverse-bg);

  .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle,
  .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav-item,
  .rs-sidenav-toggle-button {
    background-color: #3498ff;
    background-color: var(--rs-sidenav-inverse-bg);
  }

  .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle {
    &:focus,
    &:hover {
      background-color: #2589f5;
      background-color: var(--rs-sidenav-inverse-hover-bg);
    }
  }

  .rs-dropdown .rs-dropdown-toggle {
    &:focus,
    &:hover {
      background-color: #2589f5;
      background-color: var(--rs-sidenav-inverse-hover-bg);
    }
  }

  .rs-sidenav-item {
    &:focus,
    &:hover {
      background-color: #2589f5;
      background-color: var(--rs-sidenav-inverse-hover-bg);
    }
  }

  .rs-sidenav-toggle-button {
    &:focus,
    &:hover {
      background-color: #2589f5;
      background-color: var(--rs-sidenav-inverse-hover-bg);
    }
  }

  .rs-dropdown-item {
    color: #fff;
    color: var(--rs-sidenav-inverse-text);

    > {
      .rs-dropdown-item-toggle,
      .rs-dropdown-menu-toggle {
        color: #fff;
        color: var(--rs-sidenav-inverse-text);
      }
    }
  }

  .rs-sidenav-toggle-button {
    color: #fff;
    color: var(--rs-sidenav-inverse-text);
  }

  .rs-dropdown-item {
    &.rs-dropdown-item-focus,
    &:focus,
    &:hover {
      background-color: #2589f5;
      background-color: var(--rs-sidenav-inverse-hover-bg);
      color: #fff;
      color: var(--rs-sidenav-inverse-text);
    }

    > {
      .rs-dropdown-item-toggle {
        &.rs-dropdown-item-focus,
        &:focus,
        &:hover {
          background-color: #2589f5;
          background-color: var(--rs-sidenav-inverse-hover-bg);
          color: #fff;
          color: var(--rs-sidenav-inverse-text);
        }
      }

      .rs-dropdown-menu-toggle {
        &.rs-dropdown-item-focus,
        &:focus,
        &:hover {
          background-color: #2589f5;
          background-color: var(--rs-sidenav-inverse-hover-bg);
          color: #fff;
          color: var(--rs-sidenav-inverse-text);
        }
      }
    }
  }

  .rs-sidenav-toggle-button {
    &.rs-dropdown-item-focus,
    &:focus,
    &:hover {
      background-color: #2589f5;
      background-color: var(--rs-sidenav-inverse-hover-bg);
      color: #fff;
      color: var(--rs-sidenav-inverse-text);
    }
  }
}

.rs-theme-high-contrast .rs-sidenav-inverse {
  .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle,
  .rs-sidenav-item.rs-sidenav-item-active {
    color: var(--rs-sidenav-inverse-selected-text);
  }
}

.rs-sidenav-inverse {
  .rs-dropdown {
    &.rs-dropdown-selected-within .rs-dropdown-toggle {
      color: #fff;
      color: var(--rs-sidenav-inverse-text);

      &.rs-dropdown-item-focus,
      &:focus,
      &:hover {
        color: #fff;
        color: var(--rs-sidenav-inverse-text);
      }
    }

    .rs-dropdown-toggle {
      color: #fff;
      color: var(--rs-sidenav-inverse-text);

      &.rs-dropdown-item-focus,
      &:focus,
      &:hover {
        color: #fff;
        color: var(--rs-sidenav-inverse-text);
      }
    }
  }

  .rs-sidenav-item {
    color: #fff;
    color: var(--rs-sidenav-inverse-text);

    &.rs-dropdown-item-focus,
    &:focus,
    &:hover {
      color: #fff;
      color: var(--rs-sidenav-inverse-text);
    }
  }

  .rs-dropdown-item.rs-dropdown-item-active,
  .rs-sidenav-item.rs-sidenav-item-active {
    background-color: #1675e0;
    background-color: var(--rs-sidenav-inverse-selected-bg);
  }

  .rs-sidenav-toggle {
    border-top-color: #2589f5;
    border-top-color: var(--rs-sidenav-inverse-footer-border);
  }
}

.rs-sidenav-subtle {
  background-color: transparent;

  .rs-dropdown-item,
  .rs-dropdown-toggle,
  .rs-sidenav-item,
  .rs-sidenav-toggle-button {
    background-color: transparent;
    color: #8e8e93;
    color: var(--rs-sidenav-subtle-text);
  }

  .rs-dropdown-item {
    &:focus-visible,
    &:hover {
      background-color: #f7f7fa;
      background-color: var(--rs-sidenav-subtle-hover-bg);
      color: #575757;
      color: var(--rs-sidenav-subtle-hover-text);
    }
  }

  .rs-dropdown-toggle {
    &:focus-visible,
    &:hover {
      background-color: #f7f7fa;
      background-color: var(--rs-sidenav-subtle-hover-bg);
      color: #575757;
      color: var(--rs-sidenav-subtle-hover-text);
    }
  }

  .rs-sidenav-item {
    &:focus-visible,
    &:hover {
      background-color: #f7f7fa;
      background-color: var(--rs-sidenav-subtle-hover-bg);
      color: #575757;
      color: var(--rs-sidenav-subtle-hover-text);
    }
  }

  .rs-sidenav-toggle-button {
    &:focus-visible,
    &:hover {
      background-color: #f7f7fa;
      background-color: var(--rs-sidenav-subtle-hover-bg);
      color: #575757;
      color: var(--rs-sidenav-subtle-hover-text);
    }
  }

  .rs-dropdown-item.rs-dropdown-item-active,
  .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon,
  .rs-sidenav-item.rs-sidenav-item-active {
    color: #1675e0;
    color: var(--rs-sidenav-subtle-selected-text);
  }

  .rs-dropdown-open > .rs-dropdown-toggle.btn {
    background-color: transparent;

    &:focus,
    &:hover {
      background-color: #f7f7fa;
      background-color: var(--rs-sidenav-subtle-hover-bg);
    }
  }

  .rs-sidenav-toggle {
    border-top-color: #EAECF0;
    border-top-color: var(--rs-sidenav-subtle-footer-border);
  }

  &.rs-sidenav-collapse-in .rs-dropdown-item > {
    .rs-dropdown-item-toggle,
    .rs-dropdown-menu-toggle {
      color: #8e8e93;
      color: var(--rs-sidenav-subtle-text);
    }
  }

  &.rs-sidenav-collapsing .rs-dropdown-item > {
    .rs-dropdown-item-toggle,
    .rs-dropdown-menu-toggle {
      color: #8e8e93;
      color: var(--rs-sidenav-subtle-text);
    }
  }

  &.rs-sidenav-collapse-in .rs-dropdown-item > {
    .rs-dropdown-item-toggle {
      &:focus-visible,
      &:hover {
        background-color: #f7f7fa;
        background-color: var(--rs-sidenav-subtle-hover-bg);
        color: #575757;
        color: var(--rs-sidenav-subtle-hover-text);
      }
    }

    .rs-dropdown-menu-toggle {
      &:focus-visible,
      &:hover {
        background-color: #f7f7fa;
        background-color: var(--rs-sidenav-subtle-hover-bg);
        color: #575757;
        color: var(--rs-sidenav-subtle-hover-text);
      }
    }
  }

  &.rs-sidenav-collapsing .rs-dropdown-item > {
    .rs-dropdown-item-toggle {
      &:focus-visible,
      &:hover {
        background-color: #f7f7fa;
        background-color: var(--rs-sidenav-subtle-hover-bg);
        color: #575757;
        color: var(--rs-sidenav-subtle-hover-text);
      }
    }

    .rs-dropdown-menu-toggle {
      &:focus-visible,
      &:hover {
        background-color: #f7f7fa;
        background-color: var(--rs-sidenav-subtle-hover-bg);
        color: #575757;
        color: var(--rs-sidenav-subtle-hover-text);
      }
    }
  }

  &.rs-sidenav-collapse-in .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active,
  &.rs-sidenav-collapsing .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active {
    color: #1675e0;
    color: var(--rs-sidenav-subtle-selected-text);
  }

  &.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover,
  &.rs-sidenav-collapse-out .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover,
  &.rs-sidenav-collapsing .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover {
    background-color: #f7f7fa;
    background-color: var(--rs-sidenav-subtle-hover-bg);
  }

  &.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle-icon,
  &.rs-sidenav-collapse-out .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle-icon,
  &.rs-sidenav-collapsing .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle-icon {
    color: #1675e0;
    color: var(--rs-sidenav-subtle-selected-text);
  }
}

@-webkit-keyframes sideNavFoldedText {
  0% {
    max-width: 200px;
    opacity: 0.8;
  }

  to {
    max-width: 0;
    opacity: 0;
  }
}

@keyframes sideNavFoldedText {
  0% {
    max-width: 200px;
    opacity: 0.8;
  }

  to {
    max-width: 0;
    opacity: 0;
  }
}

.rs-slider {
  position: relative;

  .rs-tooltip {
    display: none;

    &.rs-tooltip-placement-top .rs-tooltip:after {
      margin: auto;
      left: 0;
      right: 0;
    }
  }
}

.rs-slider-disabled {
  opacity: 0.3;
  cursor: not-allowed;

  .rs-slider-bar,
  .rs-slider-handle:before {
    cursor: not-allowed;
  }
}

.rs-slider-with-mark:not(.rs-slider-vertical) {
  margin-bottom: 29px;
}

.rs-slider-bar {
  height: 6px;
  border-radius: 3px;
  background-color: #f2f2f5;
  background-color: var(--rs-slider-bar);
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.rs-slider:hover .rs-slider-bar {
  background-color: #EAECF0;
  background-color: var(--rs-slider-hover-bar);
}

.rs-slider-vertical .rs-slider-bar {
  height: 100%;
  width: 6px;
}

.rs-slider-handle {
  position: absolute;
  top: -50%;
  outline: none;

  &:before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #3498ff;
    border: 2px solid var(--rs-slider-thumb-border);
    background-color: #fff;
    background-color: var(--rs-slider-thumb-bg);
    margin-left: -6px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out,
      -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }

  &:focus:before,
  &:hover:before {
    -webkit-box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
    -webkit-box-shadow: var(--rs-slider-thumb-hover-shadow);
    box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
    box-shadow: var(--rs-slider-thumb-hover-shadow);
  }

  &.active:before,
  &:active:before {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.rs-slider-vertical .rs-slider-handle {
  top: unset;

  &:before {
    left: 3px;
    margin-top: -6px;
  }
}

.rs-slider-handle {
  &.active .rs-tooltip,
  &:hover .rs-tooltip {
    display: block;
    opacity: 1;
    top: -30px;
  }
}

.rs-slider-vertical .rs-slider-handle {
  &.active .rs-tooltip,
  &:hover .rs-tooltip {
    top: -33px;
    margin-left: 3px;
  }
}

.rs-slider-mark {
  position: absolute;
  top: 15px;
  left: -2px;
  white-space: nowrap;
}

.rs-slider-mark-content {
  margin-left: -50%;
}

.rs-slider-mark-last {
  left: auto;
  right: -2px;

  .rs-slider-mark-content {
    margin-left: 50%;
  }
}

.rs-slider-graduator {
  width: 100%;

  li,
  ol {
    list-style: none;
  }

  > ol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    width: 100%;

    > li {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 1%;
      flex: 1 1 1%;
      position: relative;

      &:before,
      &:last-child:after {
        content: "";
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #fff;
        background-color: var(--rs-slider-thumb-bg);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 2px solid #f2f2f5;
        border: 2px solid var(--rs-slider-bar);
        margin-left: -4px;
        top: -1px;
      }
    }
  }
}

.rs-slider-vertical .rs-slider-graduator > ol > li {
  &:before,
  &:last-child:after {
    top: unset;
    bottom: -4px;
    margin-left: -1px;
  }
}

.rs-slider-graduator > ol > li:last-child:after {
  right: -4px;
}

.rs-slider-vertical .rs-slider-graduator > ol > li:last-child:after {
  left: 0;
  bottom: unset;
  top: -4px;
}

.rs-slider-graduator > ol > li {
  &.rs-slider-pass:before {
    border-color: #3498ff;
    border-color: var(--rs-slider-progress);
  }

  &.rs-slider-active:before {
    visibility: hidden;
  }
}

.rs-slider-vertical .rs-slider-graduator {
  display: block;
  height: 100%;

  > ol {
    width: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: 100%;
    padding: 0;

    > li {
      display: block;
      padding: 0;
    }
  }
}

.rs-slider-progress-bar {
  position: absolute;
  height: 6px;
  border-radius: 3px 0 0 3px;
  background-color: #3498ff;
  background-color: var(--rs-slider-progress);
}

.rs-slider-vertical {
  .rs-slider-progress-bar {
    width: 6px;
    border-radius: 0 0 3px 3px;
  }

  height: 100%;

  .rs-slider-mark {
    top: unset;
    bottom: -8px;
    left: 15px;
  }

  .rs-slider-mark-content {
    margin-left: auto;
  }

  .rs-slider-mark-last {
    bottom: unset;
    top: -8px;
  }
}

.rs-steps {
  min-height: 30px;
}

.rs-steps-item {
  padding-left: 40px;
  position: relative;
  overflow: hidden;
}

.rs-steps-item-icon-wrapper {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 16px;
  line-height: 1.75;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #8e8e93;
  border: 1px solid var(--rs-steps-border);
  border-radius: 50%;
}

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
  border-color: #3498ff;
  border-color: var(--rs-steps-state-finish);
  color: #3498ff;
  color: var(--rs-steps-state-finish);
}

.rs-steps-item-status-error .rs-steps-item-icon-wrapper {
  border-color: #f44336;
  border-color: var(--rs-steps-state-error);
  color: #f44336;
  color: var(--rs-steps-state-error);
}

.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
  border-color: #3498ff;
  border-color: var(--rs-steps-state-process);
  background-color: #3498ff;
  background-color: var(--rs-steps-state-process);
  color: #f7f7fa;
  color: var(--rs-text-inverse);

  .rs-icon {
    color: #3498ff;
    color: var(--rs-steps-icon-state-process);
  }
}

.rs-steps-item-icon-wrapper {
  &.rs-steps-item-custom-icon {
    border: none;
    background: none;
  }

  > .rs-steps-item-icon {
    width: 100%;
    display: block;
    text-align: center;
    position: relative;

    .rs-icon {
      font-size: 14px;
      vertical-align: middle;
    }
  }
}

.rs-steps-item-content {
  color: #8e8e93;
  color: var(--rs-text-secondary);
  display: inline-block;
  position: relative;
  width: 100%;
}

.rs-steps-item-status-process .rs-steps-item-content {
  color: #575757;
  color: var(--rs-text-primary);
}

.rs-steps-item-status-error .rs-steps-item-content {
  color: #f44336;
  color: var(--rs-text-error);
}

.rs-steps-item-title {
  position: relative;
  font-size: 16px;
  line-height: 1.875;
  min-height: 16px;
  padding-right: 10px;
  display: inline-block;

  &:empty {
    padding-right: 0;
  }
}

.rs-steps-item-description {
  margin-top: 12px;
}

.rs-steps-item-tail,
.rs-steps-item-title:after {
  position: absolute;
}

.rs-steps-item-status-process {
  .rs-steps-item-tail,
  .rs-steps-item-title:after {
    border-color: #8e8e93;
    border-color: var(--rs-steps-border);
  }
}

.rs-steps-item-tail,
.rs-steps-item-title:after {
  border-color: #8e8e93;
  border-color: var(--rs-steps-border);
}

.rs-steps-item-status-finish {
  .rs-steps-item-tail,
  .rs-steps-item-title:after {
    border-color: #3498ff;
    border-color: var(--rs-steps-state-finish);
  }
}

.rs-steps-next-error {
  .rs-steps-item-tail,
  .rs-steps-item-title:after {
    border-color: #f44336;
    border-color: var(--rs-steps-border-state-error);
  }
}

.rs-steps-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  .rs-steps-item {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    min-height: 30px;

    &:last-child {
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;

      .rs-steps-item-title:after {
        display: none;
      }
    }

    &:not(:first-child) {
      padding-left: 50px;

      .rs-steps-item-icon-wrapper {
        left: 10px;
      }
    }
  }

  .rs-steps-item-content {
    height: 30px;
  }

  .rs-steps-item-title:after {
    content: "";
    top: 15px;
    left: 100%;
    width: 9999px;
    border-top-width: 1px;
    border-top-style: solid;
  }
}

.rs-steps-vertical {
  .rs-steps-item {
    padding-bottom: 20px;

    &:not(:first-child) {
      margin-top: 10px;
    }

    &:last-child .rs-steps-item-tail {
      display: none;
    }
  }

  .rs-steps-item-tail {
    top: 40px;
    bottom: 0;
    left: 15px;
    border-left-width: 1px;
    border-left-style: solid;
  }
}

.rs-steps-small {
  min-height: 24px;

  &.rs-steps-horizontal .rs-steps-item {
    min-height: 24px;

    &:not(:first-child) {
      padding-left: 44px;
    }
  }

  &.rs-steps-vertical .rs-steps-item-tail {
    left: 12px;
  }

  &.rs-steps-horizontal .rs-steps-item-title:after {
    top: 12px;
  }

  .rs-steps-item {
    padding-left: 34px;
  }

  .rs-steps-item-icon-wrapper {
    width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 1.57142857;
  }

  .rs-steps-item-title {
    font-size: 14px;
    line-height: 1.71428571;
  }

  .rs-steps-item-description {
    margin-top: 12px;
    font-size: 12px;
    line-height: 1.66666667;
  }
}

.rs-table {
  position: relative;
  overflow: hidden;
}

.rs-table-column-resizing {
  cursor: ew-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rs-table-row {
  overflow: hidden;
  position: absolute;
  height: 36px;
  width: 100%;
  top: 0;

  &.virtualized {
    pointer-events: none;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  .rs-table-row.virtualized {
    visibility: hidden;
  }
}

.rs-table-row-expanded {
  position: absolute;
  height: 46px;
  bottom: 0;
  width: 100%;
  z-index: 4;
  border-top: 1px solid #f2f2f5;
  border-top: 1px solid var(--rs-border-secondary);
  padding: 10px;
}

.rs-table-hover .rs-table-row:hover {
  .rs-table-cell,
  .rs-table-cell-group {
    background-color: #fff;
    background-color: var(--rs-bg-card);
  }
}

.rs-table-row-expanded {
  background-color: #fff;
  background-color: var(--rs-bg-card);
}

.rs-table-row-header {
  background-color: #fff;
  background-color: var(--rs-bg-card);

  .rs-table-cell {
    background-color: #fff;
    background-color: var(--rs-bg-card);
  }

  .rs-table-cell-group-shadow {
    z-index: 5;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.rs-table-cell,
.rs-table-cell-group,
.rs-table-row {
  -webkit-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}

.rs-table-hover {
  .rs-table-row {
    &:hover {
      .rs-table-cell,
      .rs-table-cell-group {
        background-color: #f2faff;
        background-color: var(--rs-state-hover-bg);
      }
    }

    &:not(.rs-table-row-header):hover {
      background-color: #f2faff;
      background-color: var(--rs-state-hover-bg);
    }
  }

  .rs-table-row-header:hover {
    .rs-table-cell,
    .rs-table-cell-group {
      background-color: #fff;
      background-color: var(--rs-bg-card);
    }
  }
}

.rs-table-header-row-wrapper {
  z-index: 2;
  position: relative;
  font-size: 12px;
  color: #8e8e93;
  color: var(--rs-text-secondary);
}

.rs-table-affix-header {
  z-index: 3;
  visibility: hidden;

  &.fixed {
    visibility: visible;
  }
}

.rs-table-body-row-wrapper {
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.rs-table-body-info {
  width: 100%;
  text-align: center;
  top: 50%;
  position: absolute;
  margin-top: -20px;
  line-height: 40px;

  .icon {
    margin: 0 10px;
  }
}

.rs-table-body-info-wheel-area,
.rs-table-body-wheel-area {
  width: 100%;
}

.rs-table-loader-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: hsla(0, 0%, 100%, 0.9);
  background-color: var(--rs-loader-backdrop);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  pointer-events: none;
}

@media not all and (min-resolution: 0.001dpcm) {
  .rs-table-loader-wrapper {
    visibility: hidden;
  }
}

.rs-table-loader {
  width: 100%;
  text-align: center;
  top: 50%;
  position: absolute;
  margin-top: -20px;
  line-height: 40px;
}

.rs-table-loader-icon {
  display: inline-block;
  position: relative;
  margin-right: 12px;
  padding-top: 3px;
  width: 18px;
  height: 18px;

  &:after,
  &:before {
    width: 18px;
    height: 18px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
    border: 3px solid rgba(247, 247, 250, 0.8);
    border: 3px solid var(--rs-loader-ring);
  }

  &:after {
    border: 3px solid transparent;
    border-top: 3px solid var(--rs-loader-rotor);
    -webkit-animation: loaderSpin 0.6s linear infinite;
    animation: loaderSpin 0.6s linear infinite;
  }
}

.rs-table-loading .rs-table-loader-wrapper {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  z-index: 1;
}

.rs-table-cell {
  height: 36px;
  display: block;
  overflow: hidden;
  position: absolute;
  white-space: normal;
  background-color: #fff;
  background-color: var(--rs-bg-card);
  border-bottom: 1px solid #f2f2f5;
  border-bottom: 1px solid var(--rs-border-secondary);

  &.first {
    border-left-width: 0;
  }
}

.rs-table-cell-wrap1 {
  display: table;
}

.rs-table-cell-wrap2 {
  display: table-row;
}

.rs-table-cell-wrap3 {
  display: table-cell;
  vertical-align: middle;
}

.rs-table-cell-content {
  padding: 13px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.rs-table-cell-header .rs-table-cell-content {
  padding: 10px;
  line-height: 1.66666667;
}

.rs-table-cell-header-sort-wrapper {
  cursor: pointer;
  margin-left: 5px;
  display: inline-block;
}

.rs-table-cell-header-icon-sort {
  color: #3498ff;
  color: var(--rs-table-sort);
  font-size: 16px;
  line-height: 1.66666667;
}

.rs-table-cell-rowspan {
  border-bottom: 1px solid #f2f2f5 !important;
  border-bottom: 1px solid var(--rs-border-secondary) !important;
}

.rs-table-cell-header-sortable .rs-table-cell-content {
  cursor: pointer;
}

.rs-table-column-resize-spanner {
  height: 36px;
  width: 3px;
  z-index: 3;
  position: absolute;
  cursor: ew-resize !important;
  outline: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;

  &:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    margin: auto;
    bottom: 0;
    top: 0;
    display: none;
  }

  &:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    margin: auto;
    bottom: 0;
    top: 0;
    display: none;
    border: 3px dashed transparent;
    border-right: 3px solid var(--rs-table-resize);
    right: 4px;
  }

  &:after {
    border: 3px dashed transparent;
    border-left: 3px solid var(--rs-table-resize);
    left: 4px;
  }
}

.rs-table:not(.rs-table-column-resizing) {
  .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner,
  .rs-table-column-resize-spanner:hover {
    background-color: #3498ff;
    background-color: var(--rs-table-resize);
  }

  .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner {
    &:after,
    &:before {
      display: block;
    }
  }

  .rs-table-column-resize-spanner:hover {
    &:after,
    &:before {
      display: block;
    }
  }
}

.rs-table-cell-group-fixed-left,
.rs-table-cell-group-fixed-right {
  position: absolute;
  z-index: 4;
  background-color: #fff;
  background-color: var(--rs-bg-card);
}

.rs-table-cell-group-left-shadow,
.rs-table-cell-group-shadow {
  -webkit-box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08);
  -webkit-box-shadow: 3px 0 5px var(--rs-table-shadow);
  box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08);
  box-shadow: 3px 0 5px var(--rs-table-shadow);
}

.rs-table-cell-group-right-shadow {
  -webkit-box-shadow: (-3px) 0 5px rgba(9, 9, 9, 0.08);
  -webkit-box-shadow: (-3px) 0 5px var(--rs-table-shadow);
  box-shadow: (-3px) 0 5px rgba(9, 9, 9, 0.08);
  box-shadow: (-3px) 0 5px var(--rs-table-shadow);
}

.rs-table-mouse-area {
  display: none;
  left: -1px;
  top: 0;
  width: 1px;
  z-index: 6;
  background-color: #3498ff;
  background-color: var(--rs-table-resize);
  position: absolute;

  > span {
    background-color: #3498ff;
    background-color: var(--rs-table-resize);
    position: absolute;
    height: 36px;
    width: 3px;
    z-index: 3;
    cursor: ew-resize !important;
    outline: none;
    left: -1.5px;
  }
}

.rs-table-word-wrap .rs-table-cell-content {
  white-space: normal;
}

.rs-table-bordered {
  border: 1px solid #f2f2f5;
  border: 1px solid var(--rs-border-secondary);
}

.rs-table-cell-bordered .rs-table-cell {
  border-right: 1px solid #f2f2f5;
  border-right: 1px solid var(--rs-border-secondary);
}

.rs-table-column-group {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.rs-table-column-group-header {
  border-bottom: 1px solid #f2f2f5;
  border-bottom: 1px solid var(--rs-border-secondary);
  position: absolute;
  width: 100%;
}

.rs-table-column-group-header-content {
  display: table-cell;
  padding: 10px;
}

.rs-table-column-group-cell {
  position: absolute;
  border-right: 1px solid #f2f2f5;
  border-right: 1px solid var(--rs-border-secondary);
}

.rs-table-cell-expand-wrapper {
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
}

.rs-table-cell-expand-icon {
  cursor: pointer;
  outline: none;
  font-size: 16px;
}

.rs-table-scrollbar {
  background-color: #EAECF0;
  background-color: var(--rs-table-scrollbar-track);
  position: absolute;
  -webkit-transition: background 0.1s linear, -webkit-transform 0.1s linear;
  transition: background 0.1s linear, -webkit-transform 0.1s linear;
  transition: background 0.1s linear, transform 0.1s linear;
  transition: background 0.1s linear, transform 0.1s linear, -webkit-transform 0.1s linear;
  opacity: 0.6;
  z-index: 1;
}

.rs-table-scrollbar-hide {
  display: none;
}

.rs-table-scrollbar-handle {
  position: absolute;
  background-color: #575757;
  background-color: var(--rs-table-scrollbar-thumb);
  border-radius: 4px;
}

.rs-table-scrollbar-pressed {
  z-index: 1;

  .rs-table-scrollbar-handle {
    background-color: #272c36;
    background-color: var(--rs-table-scrollbar-thumb-active);
  }
}

.rs-table-scrollbar-horizontal {
  width: 100%;
  height: 10px;
  bottom: 0;

  &.fixed {
    position: fixed;
  }

  .rs-table-scrollbar-handle {
    height: 8px;
    left: 0;
    top: 1px;
  }

  &:hover {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scaleY(1.3);
    transform: scaleY(1.3);
  }
}

.rs-table-scrollbar-pressed.rs-table-scrollbar-horizontal:hover {
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-transform: scaleY(1.3);
  transform: scaleY(1.3);
}

.rs-table-scrollbar-vertical {
  top: 0;
  right: 0;
  width: 10px;
  bottom: 10px;
  background-color: rgba(229, 229, 234, 0.4);
  background-color: var(--rs-table-scrollbar-vertical-track);

  .rs-table-scrollbar-handle {
    min-height: 20px;
    width: 8px;
    top: 0;
    left: 1px;
  }
}

.rs-table-scrollbar-pressed.rs-table-scrollbar-vertical:hover,
.rs-table-scrollbar-vertical:hover {
  -webkit-transform-origin: right center;
  transform-origin: right center;
  -webkit-transform: scaleX(1.3);
  transform: scaleX(1.3);
}

.rs-tag {
  display: inline-block;
  background-color: #f7f7fa;
  background-color: var(--rs-tag-bg);
  color: #575757;
  color: var(--rs-text-primary);
  border-radius: 6px;
  line-height: 20px;
}

.rs-tag-closable {
  position: relative;
}

.rs-tag-icon-close {
  position: absolute;
  font-size: 12px;
}

.rs-tag-default .rs-tag-icon-close:hover {
  color: #f44336;
  color: var(--rs-tag-close);
}

.rs-tag-group {
  margin: -10px 0 0 -10px;

  > .rs-tag {
    margin-top: 10px;
    margin-left: 10px;
  }
}

.rs-tag-lg {
  padding: 5px 10px;
  font-size: 14px;

  .rs-tag-icon-close {
    padding: 9px 8px;
  }
}

.rs-tag-closable.rs-tag-lg {
  padding-right: 27px;
}

.rs-tag-md {
  padding: 2px 8px;
  font-size: 12px;

  .rs-tag-icon-close {
    padding: 0 8px 0 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.rs-tag-closable.rs-tag-md {
  padding-right: 25px;
}

.rs-tag-sm {
  padding: 0 8px;
  font-size: 12px;

  .rs-tag-icon-close {
    padding: 4px 5px;
  }
}

.rs-tag-closable.rs-tag-sm {
  padding-right: 25px;
}

.rs-tag + .rs-tag {
  //margin-left: 10px;
}

.rs-tag-red {
  background-color: #f44336;
  background-color: var(--rs-red-500);
  color: #fff;
}

.rs-tag-orange {
  background-color: #fa8900;
  background-color: var(--rs-orange-500);
  color: #fff;
}

.rs-tag-yellow {
  background-color: #ffb300;
  background-color: var(--rs-yellow-500);
  color: #fff;
}

.rs-tag-green {
  background-color: #4caf50;
  background-color: var(--rs-green-500);
  color: #fff;
}

.rs-tag-cyan {
  background-color: #00bcd4;
  background-color: var(--rs-cyan-500);
  color: #fff;
}

.rs-tag-blue {
  background-color: #2196f3;
  background-color: var(--rs-blue-500);
  color: #fff;
}

.rs-tag-violet {
  background-color: #673ab7;
  background-color: var(--rs-violet-500);
  color: #fff;
}

.rs-picker-tag {
  .rs-picker-toggle {
    left: 0;
    cursor: text;
  }

  &.rs-picker-disabled {
    cursor: not-allowed;

    .rs-picker-toggle {
      position: absolute;
    }
  }

  .rs-tag {
    max-width: calc(100% - 6px);
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .rs-picker-search-input,
  .rs-tag {
    margin-top: 5px;
  }

  &.rs-picker-has-value .rs-picker-search-input {
    padding-left: 6px;
  }

  .rs-tag {
    margin-left: 6px;

    + .rs-tag {
      margin-left: 6px;
    }
  }

  .rs-picker-search {
    display: inline-block;
    width: auto;
    min-width: 14px;
  }

  .rs-picker-search-input {
    display: inline-block;
    width: auto;
    min-width: 14px;

    > input {
      display: inline-block;
      width: auto;
      min-width: 14px;
      padding: 0;
    }
  }

  .rs-picker-tag-wrapper {
    position: relative;
    z-index: 6;
    padding-bottom: 5px;
  }
}

.rs-plaintext .rs-tag {
  margin-left: 6px;
  margin-bottom: 6px;
}

.rs-picker-disabled-options {
  .rs-picker-toggle {
    padding-right: 32px !important;
  }

  .rs-picker-tag-wrapper {
    margin-right: 32px !important;
  }

  .rs-picker-toggle-clean {
    right: 10px !important;
  }
}

.rs-timeline {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  direction: ltr;
}

.rs-timeline-item {
  position: relative;
  text-align: left;

  &:not(:last-child) .rs-timeline-item-content {
    padding-bottom: 18px;
  }
}

.rs-timeline-item-dot {
  position: absolute;
  top: 5px;

  &:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: #d9d9d9;
    background-color: var(--rs-timeline-indicator-bg);
    border-radius: 50%;
  }
}

.rs-timeline-item-custom-dot {
  text-align: center;

  &:before {
    display: none;
  }
}

.rs-timeline-item-last .rs-timeline-item-dot:before {
  background-color: #3498ff;
  background-color: var(--rs-timeline-indicator-active-bg);
}

.rs-timeline-item-tail {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #d9d9d9;
  background-color: var(--rs-timeline-indicator-bg);
}

.rs-timeline-item {
  &:first-child .rs-timeline-item-tail {
    top: 5px;
  }

  &:last-child .rs-timeline-item-tail {
    bottom: auto;
    height: 15px;
  }
}

.rs-timeline-endless .rs-timeline-item:last-child .rs-timeline-item-tail {
  height: auto;
  min-height: 38px;
}

.rs-timeline-item:only-child .rs-timeline-item-tail {
  display: none;
}

.rs-timeline-align-left {
  .rs-timeline-item-dot {
    left: 0;
  }

  .rs-timeline-item-tail {
    left: 4px;
  }

  .rs-timeline-item {
    padding-left: 22px;
  }
}

.rs-timeline-align-right {
  .rs-timeline-item-dot {
    right: 0;
  }

  .rs-timeline-item-tail {
    right: 4px;
  }

  .rs-timeline-item {
    padding-right: 22px;
  }

  .rs-timeline-item-content {
    text-align: right;
  }
}

.rs-timeline-align-alternate {
  .rs-timeline-item-dot {
    left: calc(50% - 5px);
  }

  .rs-timeline-item-tail {
    left: calc(50% - 1px);
  }

  .rs-timeline-item-content {
    width: 50%;
  }

  .rs-timeline-item {
    &:nth-child(2n) .rs-timeline-item-content {
      text-align: right;
    }

    &:nth-child(odd) {
      text-align: right;

      .rs-timeline-item-content {
        display: inline-block;
        text-align: left;
      }
    }
  }
}

.rs-timeline-with-time {
  .rs-timeline-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
  }

  .rs-timeline-item-dot {
    left: calc(50% - 5px);
  }

  .rs-timeline-item-tail {
    left: calc(50% - 1px);
  }

  .rs-timeline-item-content,
  .rs-timeline-item-time {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 50%;
    flex: 1 0 50%;
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-timeline-with-time {
    .rs-timeline-item-content,
    .rs-timeline-item-time {
      max-width: 50%;
    }
  }
}

.rs-timeline-align-alternate .rs-timeline-item:nth-child(2n) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.rs-timeline-with-time {
  &.rs-timeline-align-right .rs-timeline-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    padding-right: 0;
  }

  &.rs-timeline-align-left .rs-timeline-item-time {
    text-align: right;
  }

  &.rs-timeline-align-right .rs-timeline-item-time {
    text-align: left;
  }
}

.rs-timeline-align-alternate .rs-timeline-item {
  &:nth-child(2n) .rs-timeline-item-content,
  &:nth-child(odd) .rs-timeline-item-time {
    padding-right: 17px;
  }
}

.rs-timeline-with-time {
  &.rs-timeline-align-left .rs-timeline-item-time,
  &.rs-timeline-align-right .rs-timeline-item-content {
    padding-right: 17px;
  }
}

.rs-timeline-align-alternate .rs-timeline-item {
  &:nth-child(2n) .rs-timeline-item-time,
  &:nth-child(odd) .rs-timeline-item-content {
    padding-left: 17px;
  }
}

.rs-timeline-with-time {
  &.rs-timeline-align-left .rs-timeline-item-content,
  &.rs-timeline-align-right .rs-timeline-item-time {
    padding-left: 17px;
  }
}

.rs-toggle {
  position: relative;

  .rs-toggle-loader {
    width: 18px;
    height: 18px;
    left: 3px;
    top: 3px;
  }

  .rs-toggle-presentation {
    height: 24px;
    min-width: 44px;
    border-radius: 12px;

    &:after {
      width: 18px;
      height: 18px;
      left: 3px;
      top: 3px;
      border-radius: 9px;
    }

    &:active:after {
      width: 21.6px;
    }
  }

  .rs-toggle-inner {
    margin-left: 24px;
    margin-right: 8px;
    height: 24px;
    line-height: 2;
    font-size: 12px;

    .rs-icon {
      font-size: 12px;
    }
  }

  &.rs-toggle-checked {
    .rs-toggle-presentation {
      &:after {
        left: 100%;
        margin-left: -21px;
      }

      &:active:after {
        margin-left: -24.6px;
      }

      .rs-toggle-inner {
        margin-right: 24px;
        margin-left: 8px;
      }
    }

    .rs-toggle-loader {
      left: 100%;
      margin-left: -21px;
    }
  }
}

.rs-toggle-input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.rs-toggle-presentation {
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: #d9d9d9;
  background-color: var(--rs-toggle-bg);
  color: #fff;
  color: var(--rs-toggle-thumb);
  cursor: pointer;
  -webkit-transition: background-color 0.2s ease-in-out, width 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, width 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rs-theme-high-contrast .rs-toggle-presentation {
  -webkit-transition: none;
  transition: none;
  -webkit-box-shadow: inset 0 0 0 1px #fff;
  -webkit-box-shadow: inset 0 0 0 1px var(--rs-toggle-thumb);
  box-shadow: inset 0 0 0 1px #fff;
  box-shadow: inset 0 0 0 1px var(--rs-toggle-thumb);
}

.rs-toggle-input:focus-visible + .rs-toggle-presentation {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
}

.rs-theme-high-contrast .rs-toggle-input:focus-visible + .rs-toggle-presentation {
  outline-offset: 2px;
  -webkit-box-shadow: inset 0 0 0 1px #fff, 0 0 0 3px rgba(52, 152, 255, 0.25);
  -webkit-box-shadow: inset 0 0 0 1px var(--rs-toggle-thumb), var(--rs-state-focus-shadow);
  box-shadow: inset 0 0 0 1px #fff, 0 0 0 3px rgba(52, 152, 255, 0.25);
  box-shadow: inset 0 0 0 1px var(--rs-toggle-thumb), var(--rs-state-focus-shadow);
}

.rs-toggle-presentation {
  &:hover {
    background-color: #c5c6c7;
    background-color: var(--rs-toggle-hover-bg);
  }

  &:after {
    content: "";
    cursor: pointer;
    position: absolute;
    background-color: currentColor;
    -webkit-transition: left 0.2s ease-in-out, margin-left 0.2s ease-in-out, width 0.2s ease-in-out;
    transition: left 0.2s ease-in-out, margin-left 0.2s ease-in-out, width 0.2s ease-in-out;
  }
}

.rs-theme-high-contrast .rs-toggle-presentation:after {
  -webkit-transition: none;
  transition: none;
}

.rs-toggle-disabled .rs-toggle-presentation {
  background-color: #f7f7fa;
  background-color: var(--rs-toggle-disabled-bg);
  color: #fff;
  color: var(--rs-toggle-disabled-thumb);
  -webkit-box-shadow: inset 0 0 0 1px #fff;
  -webkit-box-shadow: inset 0 0 0 1px var(--rs-toggle-disabled-thumb);
  box-shadow: inset 0 0 0 1px #fff;
  box-shadow: inset 0 0 0 1px var(--rs-toggle-disabled-thumb);
  cursor: not-allowed;
}

.rs-toggle-checked .rs-toggle-presentation {
  background-color: #3498ff;
  background-color: var(--rs-toggle-checked-bg);
  color: #fff;
  color: var(--rs-toggle-checked-thumb);
  -webkit-box-shadow: none;
  box-shadow: none;

  &:hover {
    background-color: #2589f5;
    background-color: var(--rs-toggle-checked-hover-bg);
  }
}

.rs-toggle-disabled.rs-toggle-checked .rs-toggle-presentation {
  background-color: #cce9ff;
  background-color: var(--rs-toggle-checked-disabled-bg);
  color: #fff;
  color: var(--rs-toggle-checked-disabled-thumb);
}

.rs-toggle-loading .rs-toggle-presentation:after {
  display: none;
}

.rs-toggle-inner {
  display: block;
  -webkit-transition: margin 0.2s ease-in-out;
  transition: margin 0.2s ease-in-out;
}

.rs-theme-high-contrast .rs-toggle-inner {
  -webkit-transition: none;
  transition: none;
}

.rs-toggle-loader {
  position: absolute;
  -webkit-transition: left 0.2s ease-in-out, margin-left 0.2s ease-in-out, width 0.2s ease-in-out;
  transition: left 0.2s ease-in-out, margin-left 0.2s ease-in-out, width 0.2s ease-in-out;

  .rs-loader-spin {
    &:before {
      border-color: rgba(247, 247, 250, 0.3);
      border-color: var(--rs-toggle-loader-ring);
    }

    &:after {
      border-top-color: #fff;
      border-top-color: var(--rs-toggle-loader-rotor);
    }
  }
}

.rs-toggle-sm {
  .rs-toggle-loader {
    width: 10px;
    height: 10px;
    left: 2px;
    top: 2px;
  }

  .rs-toggle-presentation {
    height: 14px;
    min-width: 26px;
    border-radius: 7px;

    &:after {
      width: 10px;
      height: 10px;
      left: 2px;
      top: 2px;
      border-radius: 5px;
    }

    &:active:after {
      width: 12px;
    }
  }

  .rs-toggle-inner {
    margin-left: 14px;
    margin-right: 6px;
    height: 14px;
    line-height: 1.4;
    font-size: 10px;

    .rs-icon {
      font-size: 10px;
    }
  }

  &.rs-toggle-checked {
    .rs-toggle-presentation {
      &:after {
        left: 100%;
        margin-left: -12px;
      }

      &:active:after {
        margin-left: -14px;
      }

      .rs-toggle-inner {
        margin-right: 14px;
        margin-left: 6px;
      }
    }

    .rs-toggle-loader {
      left: 100%;
      margin-left: -12px;
    }
  }
}

.rs-toggle-md {
  .rs-toggle-loader {
    width: 18px;
    height: 18px;
    left: 3px;
    top: 3px;
  }

  .rs-toggle-presentation {
    height: 24px;
    min-width: 44px;
    border-radius: 12px;

    &:after {
      width: 18px;
      height: 18px;
      left: 3px;
      top: 3px;
      border-radius: 9px;
    }

    &:active:after {
      width: 21.6px;
    }
  }

  .rs-toggle-inner {
    margin-left: 24px;
    margin-right: 8px;
    height: 24px;
    line-height: 2;
    font-size: 12px;

    .rs-icon {
      font-size: 12px;
    }
  }

  &.rs-toggle-checked {
    .rs-toggle-presentation {
      &:after {
        left: 100%;
        margin-left: -21px;
      }

      &:active:after {
        margin-left: -24.6px;
      }

      .rs-toggle-inner {
        margin-right: 24px;
        margin-left: 8px;
      }
    }

    .rs-toggle-loader {
      left: 100%;
      margin-left: -21px;
    }
  }
}

.rs-toggle-lg {
  .rs-toggle-loader {
    width: 22px;
    height: 22px;
    left: 4px;
    top: 4px;
  }

  .rs-toggle-presentation {
    height: 30px;
    min-width: 55px;
    border-radius: 15px;

    &:after {
      width: 22px;
      height: 22px;
      left: 4px;
      top: 4px;
      border-radius: 11px;
    }

    &:active:after {
      width: 26.4px;
    }
  }

  .rs-toggle-inner {
    margin-left: 30px;
    margin-right: 10px;
    height: 30px;
    line-height: 2.14285714;
    font-size: 14px;

    .rs-icon {
      font-size: 14px;
    }
  }

  &.rs-toggle-checked {
    .rs-toggle-presentation {
      &:after {
        left: 100%;
        margin-left: -26px;
      }

      &:active:after {
        margin-left: -30.4px;
      }

      .rs-toggle-inner {
        margin-right: 30px;
        margin-left: 10px;
      }
    }

    .rs-toggle-loader {
      left: 100%;
      margin-left: -26px;
    }
  }
}

.rs-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-size: 12px;
  opacity: 0;
  line-height: 1.66666667;
  max-width: 250px;
  padding: 2px 10px;
  color: #fff;
  color: var(--rs-tooltip-text);
  text-align: center;
  background-color: #272c36;
  background-color: var(--rs-tooltip-bg);
  border-radius: 4px;
  overflow-wrap: break-word;

  &.rs-anim-fade {
    -webkit-transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  }

  &.rs-anim-in {
    opacity: 1;
    -webkit-transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
  }
}

.rs-tooltip-arrow {
  &:after {
    content: " ";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  &:before {
    content: " ";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    display: none;
  }
}

.rs-theme-high-contrast {
  .rs-tooltip {
    border: 1px solid #EAECF0;
    border: 1px solid var(--rs-border-primary);
  }

  .rs-tooltip-arrow:before {
    display: block;
  }
}

.rs-tooltip[class*="placement-top"] {
  margin-top: -8px;
  -webkit-transform: translate(0);
  transform: translate(0);

  &.rs-anim-in {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  &:after {
    bottom: -6px;
    margin-left: -6px;
    border-width: 6px 6px 0;
    border-top-color: #272c36;
    border-top-color: var(--rs-tooltip-bg);
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-tooltip[class*="placement-top"]:after {
    bottom: -7px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*="placement-top"]:after {
    bottom: -7px;
  }
}

.rs-theme-high-contrast .rs-tooltip[class*="placement-top"]:before {
  bottom: -7px;
  margin-left: -7px;
  border-width: 7px 7px 0;
  border-top-color: var(--rs-tooltip-border);
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-theme-high-contrast .rs-tooltip[class*="placement-top"]:before {
    bottom: -8px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-tooltip[class*="placement-top"]:before {
    bottom: -8px;
  }
}

.rs-tooltip[class*="placement-bottom"] {
  margin-top: 8px;
  -webkit-transform: translate(0);
  transform: translate(0);

  &.rs-anim-in {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  &:after {
    top: -6px;
    margin-left: -6px;
    border-width: 0 6px 6px;
    border-bottom-color: #272c36;
    border-bottom-color: var(--rs-tooltip-bg);
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-tooltip[class*="placement-bottom"]:after {
    top: -7px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*="placement-bottom"]:after {
    top: -7px;
  }
}

.rs-theme-high-contrast .rs-tooltip[class*="placement-bottom"]:before {
  top: -7px;
  margin-left: -7px;
  border-width: 0 7px 7px;
  border-bottom-color: var(--rs-tooltip-border);
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-theme-high-contrast .rs-tooltip[class*="placement-bottom"]:before {
    top: -8px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-tooltip[class*="placement-bottom"]:before {
    top: -8px;
  }
}

.rs-tooltip[class*="placement-right"] {
  margin-left: 8px;
  -webkit-transform: translate(0);
  transform: translate(0);

  &.rs-anim-in {
    -webkit-transform: translate(2px);
    transform: translate(2px);
  }

  &:after {
    left: -6px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
    border-right-color: #272c36;
    border-right-color: var(--rs-tooltip-bg);
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-tooltip[class*="placement-right"]:after {
    left: -7px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*="placement-right"]:after {
    left: -7px;
  }
}

.rs-theme-high-contrast .rs-tooltip[class*="placement-right"]:before {
  left: -7px;
  margin-top: -7px;
  border-width: 7px 7px 7px 0;
  border-right-color: var(--rs-tooltip-border);
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-theme-high-contrast .rs-tooltip[class*="placement-right"]:before {
    left: -8px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-tooltip[class*="placement-right"]:before {
    left: -8px;
  }
}

.rs-tooltip[class*="placement-left"] {
  margin-left: -8px;
  -webkit-transform: translate(0);
  transform: translate(0);

  &.rs-anim-in {
    -webkit-transform: translate(-2px);
    transform: translate(-2px);
  }

  &:after {
    right: -6px;
    margin-top: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: #272c36;
    border-left-color: var(--rs-tooltip-bg);
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-tooltip[class*="placement-left"]:after {
    right: -7px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*="placement-left"]:after {
    right: -7px;
  }
}

.rs-theme-high-contrast .rs-tooltip[class*="placement-left"]:before {
  right: -7px;
  margin-top: -7px;
  border-width: 7px 0 7px 7px;
  border-left-color: var(--rs-tooltip-border);
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-theme-high-contrast .rs-tooltip[class*="placement-left"]:before {
    right: -8px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-tooltip[class*="placement-left"]:before {
    right: -8px;
  }
}

.rs-tooltip {
  &.placement-bottom {
    &:after,
    &:before {
      left: 50%;
    }
  }

  &.placement-top {
    &:after,
    &:before {
      left: 50%;
    }
  }

  &.placement-bottom-start {
    &:after,
    &:before {
      left: 10px;
    }
  }

  &.placement-top-start {
    &:after,
    &:before {
      left: 10px;
    }
  }

  &.placement-bottom-end:before,
  &.placement-top-end:before {
    right: 3px;
  }

  &.placement-bottom-end:after,
  &.placement-top-end:after {
    right: 4px;
  }

  &.placement-left {
    &:after,
    &:before {
      top: 50%;
    }
  }

  &.placement-right {
    &:after,
    &:before {
      top: 50%;
    }
  }

  &.placement-left-start {
    &:after,
    &:before {
      top: 10px;
    }
  }

  &.placement-right-start {
    &:after,
    &:before {
      top: 10px;
    }
  }

  &.placement-left-end:before,
  &.placement-right-end:before {
    bottom: 3px;
  }

  &.placement-left-end:after,
  &.placement-right-end:after {
    bottom: 4px;
  }
}

.rs-tree {
  max-height: 360px;
  height: 100%;
  overflow-y: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;

  &.rs-tree-virtualized {
    overflow: hidden;

    .ReactVirtualized__Grid.ReactVirtualized__List {
      padding: 0 12px 12px 0;

      &:focus {
        outline: none;
      }
    }
  }
}

.rs-tree-node {
  position: relative;
  font-size: 0;
  text-align: left;
  margin: 0 0 4px;
}

.rs-tree-node-label {
  position: relative;
  margin: 0;
  padding-left: 16px;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
}

.rs-tree-node-label-content {
  padding: 6px 12px 6px 8px;
  display: inline-block;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;

  &.rs-tree-node-label-content-focus,
  &:focus,
  &:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
}

.rs-theme-high-contrast {
  .rs-tree-node-label-content {
    &.rs-tree-node-label-content-focus,
    &:focus,
    &:hover {
      outline: 3px solid rgba(52, 152, 255, 0.25);
      outline: 3px solid var(--rs-color-focus-ring);
      outline-offset: -3px;
      outline-width: 2px;
      color: #1675e0;
      color: var(--rs-listbox-option-hover-text);
      text-decoration: underline;
    }
  }

  .rs-theme-high-contrast .rs-tree-node-label-content {
    &.rs-tree-node-label-content-focus,
    &:focus,
    &:hover {
      outline-offset: 2px;
    }
  }
}

.rs-tree-node-active .rs-tree-node-label-content {
  font-weight: 700;
  color: #1675e0;
  color: var(--rs-text-link);
  background-color: #f2faff;
  background-color: var(--rs-listbox-option-selected-bg);
}

.rs-tree-node-drag-over {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
}

.rs-tree-node-dragging {
  outline: 1px dashed #3498ff;
  outline: 1px dashed var(--rs-primary-500);
}

.rs-tree-node-drag-over-top.rs-tree-node-label-content {
  border-top: 2px solid #1675e0 !important;
  border-top: 2px solid var(--rs-text-link) !important;

  &:after {
    content: "";
    position: absolute;
    top: -2px;
    left: 12px;
    width: 0;
    height: 0;
    border-left: 6px solid #1675e0;
    border-left: 6px solid var(--rs-text-link);
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }
}

.rs-tree-node-drag-over-bottom.rs-tree-node-label-content {
  border-bottom: 2px solid #1675e0 !important;
  border-bottom: 2px solid var(--rs-text-link) !important;

  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 12px;
    width: 0;
    height: 0;
    border-left: 6px solid #1675e0;
    border-left: 6px solid var(--rs-text-link);
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }
}

.rs-tree-node-drag-disabled.rs-tree-node-label-content {
  cursor: not-allowed;
}

.rs-tree-node-disabled {
  color: #c5c6c7;
  color: var(--rs-listbox-option-disabled-text);
  cursor: not-allowed;

  > .rs-tree-node-label > .rs-tree-node-label-content {
    cursor: not-allowed;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  &.rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content {
    color: #a6d7ff;
    color: var(--rs-listbox-option-disabled-selected-text);

    &:hover {
      color: #a6d7ff;
      color: var(--rs-listbox-option-disabled-selected-text);
    }
  }
}

.rs-tree-node:not(.rs-tree-node-disabled) {
  &:focus > .rs-tree-node-label > .rs-tree-node-label-content,
  > .rs-tree-node-label:focus > .rs-tree-node-label-content {
    background-color: #f2faff;
    background-color: var(--rs-listbox-option-selected-bg);
  }
}

.rs-tree-node-expand-icon-wrapper {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  z-index: 1;

  > {
    .rs-tree-node-expand-icon {
      display: inline-block;
      padding: 8px 8px 8px 0;
      height: 36px;
      font-size: 16px;
      line-height: 22px;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      -webkit-transform-origin: 1.5px 14px;
      transform-origin: 1.5px 14px;
      margin-top: 6px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .rs-tree-node-expanded {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      margin-left: 0;
      margin-top: 0;
    }
  }
}

.rs-tree-open > .rs-tree-node .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  margin-left: 0;
  margin-top: 0;
}

.rs-tree-node-expand-icon-wrapper > {
  .rs-tree-node-custom-icon,
  .rs-tree-node-loading-icon {
    width: 14px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
  }
}

.rs-tree-node-children > .rs-tree-children {
  position: relative;
  display: none;
}

.rs-tree-open.rs-tree-node-children > .rs-tree-children {
  display: block;
}

.rs-tree-indent-line {
  height: 100%;
  width: 1px;
  position: absolute;
  top: 0;
  margin-left: -13px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.rs-picker-menu {
  &.rs-tree-menu {
    padding-top: 12px;

    .rs-picker-search-bar {
      padding-top: 0;
    }
  }

  .rs-tree {
    padding: 0 12px 12px 0;

    &.rs-tree-virtualized {
      padding: 0;
    }
  }

  .rs-tree-node {
    margin: 0;

    > .rs-tree-node-label > .rs-tree-node-label-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: block;
      line-height: 20px;
    }
  }
}

[dir="rtl"] {
  .rs-tree-node-expand-icon-wrapper {
    right: 0;
    padding-right: inherit;
  }

  .ReactVirtualized__Grid__innerScrollContainer .rs-tree-node > .rs-tree-node-label:before {
    display: none;
  }
}

.rs-uploader-trigger input[type="file"] {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
}

.rs-uploader-file-item-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.rs-uploader-picture .rs-uploader-file-item-disabled .rs-uploader-file-item-status {
  cursor: not-allowed;
}

.rs-uploader-file-item-disabled .rs-uploader-file-item-btn-remove {
  display: none;
}

.rs-uploader-file-item-icon-reupload {
  cursor: pointer;
  margin-left: 10px;
  color: #575757;
  color: var(--rs-text-primary);
  font-size: 12px;
}

.rs-theme-high-contrast .rs-uploader-file-item:hover .rs-uploader-file-item-title {
  color: var(--rs-uploader-item-hover-text);
  text-decoration: underline;
}

.rs-uploader-text {
  .rs-uploader-file-items {
    margin-top: 10px;
  }

  .rs-uploader-file-item {
    padding: 8px 30px 8px 36px;
    position: relative;
    -webkit-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;

    &:hover {
      background-color: #f7f7fa;
      background-color: var(--rs-uploader-item-hover-bg);
    }
  }

  .rs-uploader-file-item-icon-wrapper {
    position: absolute;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    top: 8px;
    left: 12px;
    width: 20px;
    height: 20px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
    width: 18px;
    height: 18px;

    &:after,
    &:before {
      width: 18px;
      height: 18px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      display: block;
      border-radius: 50%;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      display: block;
      border-radius: 50%;
      border: 3px solid rgba(247, 247, 250, 0.8);
      border: 3px solid var(--rs-loader-ring);
    }

    &:after {
      border: 3px solid transparent;
      border-top: 3px solid var(--rs-loader-rotor);
      -webkit-animation: loaderSpin 0.6s linear infinite;
      animation: loaderSpin 0.6s linear infinite;
    }

    > svg {
      display: none;
    }
  }

  .rs-uploader-file-item-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: #575757;
    color: var(--rs-text-primary);
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }

  .rs-uploader-file-item-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .rs-uploader-file-item-size {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    padding: 0 8px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .rs-uploader-file-item-status {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;

    > a {
      text-decoration: none;

      &:active,
      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
  }

  .rs-uploader-file-item-btn-remove {
    position: absolute;
    font-size: 12px;
    top: 12px;
    right: 12px;
    color: #8e8e93;
    color: var(--rs-text-secondary);
    cursor: pointer;
    padding: 0;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  .rs-uploader-file-item-panel {
    position: relative;
  }

  .rs-uploader-file-item-progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }

  .rs-uploader-file-item-progress-bar {
    height: 2px;
    background-color: #3498ff;
    background-color: var(--rs-primary-500);
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }

  .rs-uploader-file-item-has-error {
    .rs-uploader-file-item-content,
    .rs-uploader-file-item-title {
      color: #f44336;
      color: var(--rs-state-error);
    }
  }
}

.rs-uploader-picture {
  display: inline-block;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  .rs-uploader-trigger-btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    color: #575757;
    color: var(--rs-btn-default-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-bg);
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    position: relative;
    color: #8e8e93;
    color: var(--rs-btn-subtle-text);
    background-color: transparent;
    width: 66px;
    height: 66px;
    padding: 0;
    border: 2px dotted #EAECF0;
    border: 2px dotted var(--rs-border-primary);
    border-radius: 6px;
    margin: 10px 0 0;
  }
}

.rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn {
  -webkit-transition: none;
  transition: none;
}

.rs-btn-ghost.rs-uploader-picture .rs-uploader-trigger-btn {
  padding: 7px 11px;
}

.rs-btn-icon.rs-uploader-picture .rs-uploader-trigger-btn {
  padding: 10px;
  line-height: 16px;

  > .rs-icon {
    font-size: 16px;
  }
}

.rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn {
  line-height: 20px;

  > .rs-icon {
    padding: 10px;
    width: 36px;
    height: 36px;
  }

  &.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;

    > .rs-icon {
      left: 0;
      border-right: none;
      border-right: var(--rs-btn-default-border, none);
    }
  }

  &.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;

    > .rs-icon {
      right: 0;
      border-left: none;
      border-left: var(--rs-btn-default-border, none);
    }
  }
}

.rs-uploader-picture .rs-uploader-trigger-btn:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
}

.rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn:focus-visible {
  outline-offset: 2px;
}

.rs-uploader-picture .rs-uploader-trigger-btn {
  &:focus,
  &:hover {
    color: var(--rs-btn-default-hover-text);
    background-color: #EAECF0;
    background-color: var(--rs-btn-default-hover-bg);
    text-decoration: none;
  }

  &.rs-btn-active,
  &:active {
    color: var(--rs-btn-default-active-text);
    background-color: #d9d9d9;
    background-color: var(--rs-btn-default-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    cursor: not-allowed;
    color: var(--rs-btn-default-disabled-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-disabled-bg);
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  .rs-uploader-picture .rs-uploader-trigger-btn {
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
  }
}

.rs-picker-default .rs-uploader-picture .rs-uploader-trigger-btn {
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
}

.rs-theme-high-contrast .rs-picker-default .rs-uploader-picture .rs-uploader-trigger-btn {
  -webkit-transition: none;
  transition: none;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-uploader-picture {
  .rs-uploader-trigger-btn-active {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }

  .rs-uploader-trigger-btn {
    &:focus,
    &:hover {
      border-color: #3498ff;
      border-color: var(--rs-input-focus-border);
    }
  }
}

.rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  -webkit-transition: none;
  transition: none;

  &:focus,
  &:hover {
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-subtle-hover-bg);
  }

  &.rs-btn-active,
  &:active {
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
    background-color: #EAECF0;
    background-color: var(--rs-btn-subtle-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;
  }
}

.rs-theme-high-contrast .rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn-active {
  background-color: #f7f7fa;
  background-color: var(--rs-btn-subtle-hover-bg);
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
}

.rs-uploader-picture .rs-uploader-trigger-btn {
  &:focus,
  &:hover {
    background-color: #f7f7fa;
    background-color: var(--rs-btn-subtle-hover-bg);
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
  }

  &.rs-btn-active,
  &:active {
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
    background-color: #EAECF0;
    background-color: var(--rs-btn-subtle-active-bg);
  }

  &.rs-btn-disabled,
  &:disabled {
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;
  }
}

.rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn {
  &.rs-btn-disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.rs-uploader-picture {
  .rs-uploader-trigger.rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
    color: #8e8e93 !important;
    color: var(--rs-btn-subtle-text) !important;
    background-color: transparent !important;

    &:after {
      display: none;
    }
  }

  .rs-uploader-file-item,
  .rs-uploader-trigger {
    float: left;
  }

  .rs-uploader-file-items {
    display: inline;
  }

  .rs-uploader-file-item {
    width: 66px;
    height: 66px;
    overflow: hidden;
    background-color: #d9d9d9;
    background-color: var(--rs-uploader-item-bg);
    border-radius: 6px;
    position: relative;
    margin-top: 10px;
    margin-right: 10px;
  }

  .rs-uploader-file-item-preview {
    position: relative;
    height: 66px;
    vertical-align: middle;
    text-align: center;
    z-index: 1;

    > img {
      width: 100%;
      height: auto;
    }

    .rs-uploader-file-item-icon {
      font-size: 22px;
      margin: 22px;
    }
  }

  .rs-uploader-file-item-status {
    position: absolute;
    top: 0;
    left: 0;
    width: 66px;
    height: 66px;
    text-align: center;
    z-index: 3;
    background-color: hsla(0, 0%, 100%, 0.8);
    background-color: var(--rs-uploader-overlay-bg);
    cursor: default;
    color: #f44336;
    color: var(--rs-state-error);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    > {
      span {
        white-space: nowrap;
      }

      a:hover {
        text-decoration: none;
      }
    }
  }

  .rs-uploader-file-item-icon-wrapper {
    display: none;
  }

  .rs-uploader-file-item-btn-remove {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: 3px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background-color: hsla(0, 0%, 100%, 0.8);
    background-color: var(--rs-uploader-overlay-bg);
    border-radius: 50%;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    z-index: 4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 0;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  .rs-uploader-file-item:hover .rs-uploader-file-item-btn-remove {
    opacity: 1;
    visibility: visible;
  }

  .rs-uploader-file-item-icon-loading {
    display: block;
    width: 66px;
    height: 66px;
    line-height: 66px;
    background-color: hsla(0, 0%, 100%, 0.8);
    background-color: var(--rs-uploader-overlay-bg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    text-align: center;

    .rs-uploader-file-item-icon {
      width: 18px;
      height: 18px;
      display: inline-block;
      position: relative;

      &:after {
        width: 18px;
        height: 18px;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        display: block;
        border-radius: 50%;
      }

      &:before {
        width: 18px;
        height: 18px;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        display: block;
        border-radius: 50%;
        border: 3px solid rgba(247, 247, 250, 0.8);
        border: 3px solid var(--rs-loader-ring);
      }

      &:after {
        border: 3px solid transparent;
        border-top: 3px solid var(--rs-loader-rotor);
        -webkit-animation: loaderSpin 0.6s linear infinite;
        animation: loaderSpin 0.6s linear infinite;
      }

      > svg {
        display: none;
      }
    }
  }

  .rs-uploader-file-item-icon-reupload {
    margin-left: 0;
    display: block;
  }
}

@supports (-o-object-fit: cover) or (object-fit: cover) {
  .rs-uploader-picture .rs-uploader-file-item-preview > img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.rs-uploader-picture-text {
  .rs-uploader-file-item {
    position: relative;
    height: 50px;
    padding-left: 50px;
    margin-top: 10px;
    padding-right: 30px;
    border: 1px solid #EAECF0;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
    overflow: hidden;
    -webkit-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;

    &:hover {
      background-color: #f7f7fa;
      background-color: var(--rs-uploader-item-hover-bg);
    }
  }

  .rs-uploader-file-item-icon-wrapper {
    display: none;
  }

  .rs-uploader-file-item-preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    text-align: center;
    background-color: #EAECF0;
    background-color: var(--rs-border-primary);
    overflow: hidden;
    border-radius: 6px 0 0 6px;

    > img {
      width: 100%;
      height: auto;
    }

    .rs-uploader-file-item-icon {
      font-size: 22px;
      margin: 12px;
    }
  }

  .rs-uploader-file-item-content {
    padding: 5px 12px;

    .rs-uploader-file-item-size {
      display: block;
    }

    .rs-uploader-file-item-title {
      display: block;
      color: #575757;
      color: var(--rs-text-primary);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .rs-uploader-file-item-size {
      color: #8e8e93;
      color: var(--rs-text-secondary);
    }
  }

  .rs-uploader-file-item-btn-remove {
    position: absolute;
    top: 0;
    right: 12px;
    color: #8e8e93;
    color: var(--rs-text-secondary);
    cursor: pointer;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  .rs-uploader-file-item-progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding-left: 50px;
  }

  .rs-uploader-file-item-progress-bar {
    height: 2px;
    background-color: #3498ff;
    background-color: var(--rs-primary-500);
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }

  .rs-uploader-file-item-icon-loading {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 50px;
    height: 50px;
    z-index: 1;
    background-color: #EAECF0;
    background-color: var(--rs-border-primary);
    text-align: center;
    line-height: 50px;

    .rs-uploader-file-item-icon {
      width: 18px;
      height: 18px;
      display: inline-block;
      position: relative;

      &:after {
        width: 18px;
        height: 18px;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        display: block;
        border-radius: 50%;
      }

      &:before {
        width: 18px;
        height: 18px;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        display: block;
        border-radius: 50%;
        border: 3px solid rgba(247, 247, 250, 0.8);
        border: 3px solid var(--rs-loader-ring);
      }

      &:after {
        border: 3px solid transparent;
        border-top: 3px solid var(--rs-loader-rotor);
        -webkit-animation: loaderSpin 0.6s linear infinite;
        animation: loaderSpin 0.6s linear infinite;
      }
    }
  }

  .rs-uploader-file-item-has-error .rs-uploader-file-item-size,
  .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon > svg {
    display: none;
  }

  .rs-uploader-file-item-has-error {
    .rs-uploader-file-item-status,
    .rs-uploader-file-item-title {
      display: block;
      color: #f44336;
      color: var(--rs-state-error);
    }
  }
}

@supports (-o-object-fit: cover) or (object-fit: cover) {
  .rs-uploader-picture-text .rs-uploader-file-item-preview > img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.rs-uploader-draggable {
  .rs-uploader-trigger-customize .rs-uploader-trigger-btn {
    display: block;
    background: #fff;
    background: var(--rs-uploader-dnd-bg);
    border: 1px dashed #EAECF0;
    border: 1px dashed var(--rs-uploader-dnd-border);
  }

  .rs-uploader-trigger-drag-over .rs-uploader-trigger-btn.rs-uploader-trigger-customize,
  .rs-uploader-trigger.rs-uploader-trigger-customize:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:hover {
    border-color: #3498ff;
    border-color: var(--rs-uploader-dnd-hover-border);
  }

  .rs-uploader-trigger-disabled {
    cursor: not-allowed;

    .rs-uploader-trigger-btn {
      pointer-events: none;
    }
  }
}

.rs-rate {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #ffb300;
  color: var(--rs-rate-symbol-checked);
  list-style: none;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

.rs-rate-character {
  position: relative;
  margin-right: 10px;
  padding: 3px 0;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rs-rate-character-before {
  position: absolute;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}

.rs-rate-character-after {
  color: #8e8e93;
  color: var(--rs-rate-symbol);
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.rs-theme-high-contrast .rs-rate-character-after > .rs-icon {
  fill: none;
  stroke: currentColor;
}

.rs-rate-character-after,
.rs-rate-character-before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
}

.rs-rate-character-after > .rs-icon,
.rs-rate-character-before > .rs-icon {
  font-size: inherit;
}

.rs-rate-character-half .rs-rate-character-before {
  opacity: 1;
  -webkit-filter: none;
  filter: none;
}

.rs-rate-character-full .rs-rate-character-after {
  color: inherit;
  -webkit-filter: none;
  filter: none;

  > .rs-icon {
    fill: currentColor;
  }
}

.rs-rate-character-vertical {
  width: 100%;
  height: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  bottom: 3px;
}

.rs-rate-character .rs-icon {
  position: absolute;
  height: 1em;
  width: 1em;
}

.rs-rate:not(.rs-rate-readonly).rs-rate:not(.rs-rate-disabled) .rs-rate-character {
  &:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  &:active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.rs-rate-disabled {
  opacity: 0.3;
  outline: none;

  .rs-rate-character {
    cursor: not-allowed;
  }
}

.rs-rate-readonly .rs-rate-character {
  cursor: default;
  pointer-events: none;
}

.rs-rate-lg {
  font-size: 36px;

  .rs-rate-character {
    width: 36px;
    height: 42px;
  }
}

.rs-rate-md {
  font-size: 30px;

  .rs-rate-character {
    width: 30px;
    height: 36px;
  }
}

.rs-rate-sm {
  font-size: 24px;

  .rs-rate-character {
    width: 24px;
    height: 30px;
  }
}

.rs-rate-xs {
  font-size: 18px;

  .rs-rate-character {
    width: 18px;
    height: 24px;
  }
}

.rs-rate-red {
  color: #f44336;
  color: var(--rs-red-500);
}

.rs-rate-orange {
  color: #fa8900;
  color: var(--rs-orange-500);
}

.rs-rate-yellow {
  color: #ffb300;
  color: var(--rs-yellow-500);
}

.rs-rate-green {
  color: #4caf50;
  color: var(--rs-green-500);
}

.rs-rate-cyan {
  color: #00bcd4;
  color: var(--rs-cyan-500);
}

.rs-rate-blue {
  color: #2196f3;
  color: var(--rs-blue-500);
}

.rs-rate-violet {
  color: #673ab7;
  color: var(--rs-violet-500);
}

.rs-toast {
  pointer-events: auto;
}

.rs-toast-container {
  position: fixed;
  z-index: 1080;
  pointer-events: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .rs-toast:not(.rs-notification) {
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: var(--rs-shadow-overlay);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    box-shadow: var(--rs-shadow-overlay);
  }

  .rs-toast-fade-entered,
  .rs-toast-fade-exiting {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .rs-toast-fade-entered {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-timing-function: cubic-bezier(0.99, 0.44, 0.44, 1.35);
    animation-timing-function: cubic-bezier(0.99, 0.44, 0.44, 1.35);
  }

  .rs-toast-fade-entering {
    opacity: 0;
  }

  .rs-toast-fade-exited {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}

.rs-toast-container-bottom-center,
.rs-toast-container-top-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.rs-toast-container-bottom-center .rs-toast-fade-entered,
.rs-toast-container-top-center .rs-toast-fade-entered {
  -webkit-animation-name: notificationMoveIn;
  animation-name: notificationMoveIn;
}

.rs-toast-container-bottom-start,
.rs-toast-container-top-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  left: 24px;
}

.rs-toast-container-bottom-start .rs-toast-fade-entered,
.rs-toast-container-top-start .rs-toast-fade-entered {
  margin-left: auto;
  -webkit-animation-name: notificationMoveInLeft;
  animation-name: notificationMoveInLeft;
}

.rs-toast-container-bottom-end,
.rs-toast-container-top-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  right: 24px;
}

.rs-toast-container-bottom-end .rs-toast-fade-entered,
.rs-toast-container-top-end .rs-toast-fade-entered {
  margin-left: auto;
  -webkit-animation-name: notificationMoveInRight;
  animation-name: notificationMoveInRight;
}

.rs-toast-container-top-center,
.rs-toast-container-top-end,
.rs-toast-container-top-start {
  top: 24px;
}

.rs-toast-container-bottom-center,
.rs-toast-container-bottom-end,
.rs-toast-container-bottom-start {
  bottom: 24px;
}

.rs-toast-container .rs-toast-fade-exiting {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
  animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
  -webkit-animation-name: notificationMoveOut;
  animation-name: notificationMoveOut;
}

.rs-btn-close {
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 8px 0;
  font-size: 12px;
  line-height: 1;
  border: none;
  outline: none !important;
  cursor: pointer;

  .rs-icon {
    vertical-align: bottom;
  }
}

.rs-stack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}