.react-datepicker {
  border: 0;
  border-radius: 8px;
  box-shadow: 0px 24px 24px rgba(1, 19, 61, 0.1);
  margin-top: 16px;
  padding: 16px 16px 4px;
  position: absolute;
  z-index: 1000;

  &__triangle {
    display: none;
  }

  &__children-container {
    width: 100%;
  }

  &__navigation {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6 4L10 8L6 12' stroke='%231D2939' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E") !important;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    border: none;
    top: 16px;

    span::before {
      border: 0;
    }

    &--previous {
      left: 16px;
      transform: rotate(180deg);
    }

    &--next {
      right: 16px;
      transform: rotate(0deg);
    }
  }

  &__month-container {
    margin-bottom: 12px;
    padding: 0;
  }

  &__header {
    background-color: #fff;
    border: 0;
    padding: 0;
  }

  &__current-month {
    color: #344054;
    font-weight: 500;
    height: 24px;
    line-height: 24px;
  }

  &__day-names {
    margin-bottom: 4px;
    margin-top: 16px;
  }

  &__day-name {
    color: #344054;
    height: 24px;
    line-height: 24px;
    margin: 3px;
    width: 32px;
  }

  &__month {
    margin: 0;
  }

  &__day {
    border: 1px solid transparent;
    border-radius: 15px;
    color: #344054;
    font-size: 14px;
    margin: 8px;
    line-height: 24px;
    transition: all 0.05s;
    width: 26px;

    &--selected {
      background-color: #2e90fa;
      color: white;
    }

    &:not(.react-datepicker__day--disabled, .react-datepicker__day--range-start, .react-datepicker__day--range-end, .react-datepicker__day--selecting-range-start):hover {
      background-color: #fff;
      color: inherit;
    }

    &--outside-month {
      border: 0;
    }

    &--today {
      color: #344054;
      font-weight: 400;
    }

    &--in-range {
      background-color: rgba(var(--c-primary), 0.05);
    }

    &--range-start,
    &--range-end,
    &--selecting-range-start {
      background-color: #344054 !important;
      color: #fff;
    }

    &--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range, .react-datepicker__day--selecting-range-start) {
      background-color: var(--c-pearl);
    }

    &--disabled {
      color: var(--c-heather);

      div[class*="tooltip"] {
        pointer-events: none;

        span {
          transition: 0s;
        }
      }

      &:hover div[class*="tooltip"] span {
        bottom: -4px;
        opacity: 1;
      }
    }

    span,
    span > * {
      display: block !important;
    }
  }

  &__total-days {
    float: left;
    margin-bottom: 16px;
    padding-top: 16px;
    position: relative;
    width: 100%;

    &::before {
      content: "";
      clear: both;
      display: table;
    }

    &::after {
      background-color: #eaecf0;
      content: "";
      height: 1px;
      left: -16px;
      position: absolute;
      width: 100%;
      top: 0;

      @media (min-width: 768px) {
        width: calc(100% + 32px);
      }
    }

    span {
      color: #98a2b3;
      font-size: 12px;
      font-weight: 600;
      line-height: 36px;
      float: left;
    }
  }
}
