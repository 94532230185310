@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ::selection {
    @apply bg-blue-500 text-white;
  }

  html {
    font-family: "Inter", sans-serif;
    @apply h-full;
  }

  body {
    @apply h-full;
  }
  #root {
    @apply flex flex-col;
  }
  main {
    @apply relative flex justify-start;
    height: calc(100vh - 72px);
  }

  main > * {
    @apply w-full min-h-full;
  }

  aside {
    @apply fixed top-[72px] w-[320px];
    height: calc(100vh - 72px);
  }

  aside > nav a {
    @apply flex gap-[18px] rounded-lg text-gray-500 font-medium text-base transition-all p-4 mb-1;
  }

  aside > nav a.active {
    @apply text-blue-500 bg-gray-50;
  }
  aside ~ main {
    margin-left: 320px;
  }
  aside ~ main,
  aside + section {
    @apply bg-gray-50 overflow-auto;
    width: calc(100% - 320px);
  }
  main aside {
    @apply sticky;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="tel"],
  textarea {
    @apply caret-primary-500 appearance-none h-11 border border-gray-200 text-gray-500 rounded-lg outline-none text-base px-3.5 transition hover:text-gray-900 focus:text-gray-900 focus:border-blue-200 focus:shadow-input;
  }

  input[type="range"]::-webkit-slider-thumb {
    @apply bg-white w-4 appearance-none h-4 rounded-3xl shadow-[-405px_0px_0px_400px_rgba(46,144,250,1)];
  }

  textarea {
    @apply h-16 w-full pt-3 text-gray-500 text-sm;
  }

  input[readonly],
  textarea[readonly] {
    @apply pointer-events-none bg-gray-50 resize-none;
  }

  input[disabled] {
    @apply pointer-events-none;
  }

  input[type="checkbox"],
  input[type="radio"] {
    @apply appearance-none w-4 h-4 min-w-[16px] min-h-[16px] text-blue-500 rounded border border-gray-300 bg-white bg-no-repeat bg-center;
  }

  input[type="radio"] {
    @apply rounded-full;
  }

  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    @apply bg-gray-50 border-blue-500;
  }

  input[type="checkbox"]:checked {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDNMNC41IDguNUwyIDYiIHN0cm9rZT0iIzJFOTBGQSIgc3Ryb2tlLXdpZHRoPSIxLjY2NjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
  }

  input[type="radio"]:checked {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgNyA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSIzLjMzMzI1IiBjeT0iMyIgcj0iMyIgZmlsbD0iIzJFOTBGQSIvPgo8L3N2Zz4K");
  }

  progress {
    @apply w-full absolute bottom-0 h-1;
  }

  progress::-webkit-progress-bar {
    background-color: #eff8ff;
    border-radius: 7px;
  }
  progress::-webkit-progress-value {
    background: #2e90fa;
    border-radius: 0px 4px 4px 0px;
  }

  ::-webkit-scrollbar {
    @apply w-1 h-1;
  }

  ::-webkit-scrollbar-track {
    @apply bg-gray-200 rounded-md;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-blue-500 rounded-md;
  }

  ::-webkit-scrollbar-thumb:hover {
    @apply bg-blue-400;
  }
}

@layer components {
  .colorInput {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwLjcxNDQgNi4wMjA3NUw5LjcxNDM2IDE3LjAyMDhMNC43MTQzNiAxMi4wMjA4IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
  }

  .btn {
    @apply inline-flex items-center justify-center gap-2 bg-blue-500 border border-blue-500 text-white rounded-lg px-[18px] whitespace-nowrap shadow-buttonSm no-underline cursor-pointer h-11 text-base font-medium transition select-none hover:bg-blue-400 hover:border-blue-400;
  }
  .btn.xs {
    @apply px-[14px] h-9 text-sm;
  }
  .btn.sm {
    @apply px-4 h-10 text-sm;
  }
  .btn.md {
    @apply px-[18px] h-11 text-base;
  }
  .btn.lg {
    @apply px-5 h-12;
  }
  .btn.xl {
    @apply px-7 h-[60px] gap-3 text-lg;
  }
  .btn.secondary {
    @apply bg-blue-50 border-blue-50 text-blue-500 hover:bg-blue-100 hover:border-blue-100;
  }
  .btn.danger {
    @apply bg-error-50 border-error-50 text-error-500 hover:bg-error-100 hover:border-error-100;
  }
  .btn.danger-text {
    @apply bg-transparent border-0 shadow-none text-error-500 hover:bg-error-100;
  }
  .btn.white {
    @apply bg-white text-gray-500 border-gray-300 hover:bg-gray-100 hover:border-gray-400;
  }
  .btn.white.active {
    @apply text-blue-500 shadow-buttonActive border-gray-300 hover:bg-gray-100 hover:border-gray-400;
  }
  .btn.outline {
    @apply outline-0 bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hover:text-gray-800;
  }
  .btn.ghost {
    @apply bg-transparent text-blue-500 border-0 hover:bg-blue-50;
  }
  .btn.alternate {
    @apply bg-blue-50 text-blue-500 border-0 hover:bg-blue-50;
  }
  .btn.text {
    @apply bg-transparent text-blue-500 border-0 hover:text-blue-800;
  }
  .btn.disabled,
  .btn[disabled] {
    @apply bg-gray-200 border-gray-200 text-white pointer-events-none;
  }
  .btn.square {
    @apply w-11 px-0;
  }
  .btn.xs.square {
    @apply w-9;
  }
  .btn.sm.square {
    @apply w-10;
  }
  .btn.lg.square {
    @apply w-12;
  }
  .btn.xl.square {
    @apply w-14 h-14;
  }
  .btn.outline.disabled {
    @apply bg-white border-gray-200 text-gray-300;
  }
  .btn.outline[disabled] {
    @apply bg-white border-gray-200 text-gray-300;
  }
  .btn.ghost.disabled,
  .btn.text.disabled,
  .btn.ghost[disabled],
  .btn.text[disabled] {
    @apply bg-white border-transparent text-gray-300;
  }

  .box {
    @apply border border-gray-200 rounded-lg p-6;
  }

  textarea.disabled,
  textarea[disabled] {
    @apply text-gray-400;
  }

  .input-group {
    @apply flex flex-wrap flex-col gap-[6px];
  }

  .input-group small {
    @apply text-gray-500 text-sm;
  }

  .input-group.error input {
    @apply border-error-300;
  }

  .input-group.error small {
    @apply text-error-500;
  }

  .input-group.icon {
    @apply relative;
  }

  .input-group.icon span {
    @apply absolute z-10 top-0 left-4 h-11 flex items-center;
  }

  .input-group.icon input {
    @apply pl-11;
  }

  .input-group.error textarea {
    @apply focus:shadow-inputError focus:border-red-200;
  }
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    @apply appearance-none m-0 text-center;
  }
}

.ck.ck-icon,
.ck.ck-icon * {
  @apply !text-blue-500;
}

.ql-container {
  font-size: inherit;
}

.ql-toolbar {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.ql-toolbar .ql-stroke {
  fill: none;
  stroke: #98a2b3;
}

.ql-toolbar .ql-fill {
  fill: #fff;
  stroke: #98a2b3;
}

.ql-toolbar .ql-picker {
  color: #98a2b3;
}

.ql-editor {
  /*height: 45vh;*/
  height: 100%;
  min-height: 250px;
  /*max-height: calc(100% - 45vh);*/
  overflow: auto;
}

.ql-snow .ql-picker {
  height: 44px;
}

.ql-snow .ql-picker.ql-header {
  border: 1px solid #eaecf0;
  line-height: 44px;
  width: 340px;
}

.ql-toolbar.ql-snow {
  padding: 24px;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-color: #eaecf0;
}

.ql-snow .ql-editor h1 {
  font-size: 32px;
  font-weight: 700;
}

.ql-snow .ql-editor h2 {
  font-size: 24px;
  font-weight: 700;
}

.ql-snow .ql-editor h3 {
  font-size: 18.72px;
  font-weight: 700;
}

.ql-snow .ql-editor h4 {
  font-size: 16px;
  font-weight: 700;
}

.ql-snow .ql-editor h5 {
  font-size: 13px;
  font-weight: 700;
}

.ql-snow .ql-editor h6 {
  font-size: 10px;
  font-weight: 700;
}

.ql-snow .ql-editor a {
  color: rgb(0, 0, 238);
  text-decoration: underline;
}

.ql-snow .ql-editor img {
  display: initial;
}

.custom-reset-html h1 {
  font-size: 32px;
  font-weight: 700;
}

.custom-reset-html h2 {
  font-size: 24px;
  font-weight: 700;
}

.custom-reset-html h3 {
  font-size: 18.72px;
  font-weight: 700;
}

.custom-reset-html h4 {
  font-size: 16px;
  font-weight: 700;
}

.custom-reset-html h5 {
  font-size: 13px;
  font-weight: 700;
}

.custom-reset-html h6 {
  font-size: 10px;
  font-weight: 700;
}

.custom-reset-html a {
  color: rgb(0, 0, 238);
  text-decoration: underline;
}

.custom-reset-html ul,
.custom-reset-html li,
.custom-reset-html ol {
  list-style: unset;
  padding: unset;
  margin: unset;
}

.custom-reset-html ul {
  list-style-type: disc;
  padding-left: 24px;
}

.custom-reset-html ol {
  list-style-type: decimal;
  padding-left: 24px;
}
.custom-reset-html blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

.prose p {
  @apply my-0;
}

.ReactModalPortal {
  @apply relative z-50;
}

.ReactModal__Overlay {
  @apply !bg-gray-500/[0.6];
}

.ReactModal__Content {
  /*@apply !max-w-[90%] w-[950px] -translate-x-2/4 !left-2/4 !top-20 h-[calc(100%-160px)] min-h-[240px] border !border-gray-200 !rounded-lg*/

  @apply !max-w-[90%] w-[950px] border !border-gray-200 !rounded-lg;
}

/*forms*/
.form-wrapper {
  @apply relative;
}
.inner-sidebar-wrapper {
  @apply absolute w-full h-full left-0 top-0 px-5 pt-5 bg-white transition-all duration-150 -translate-x-20 opacity-0 pointer-events-none
}
.sidebar-inner.active > .inner-sidebar-wrapper, .inner-sidebar-wrapper.active {
  @apply translate-x-0 opacity-100 pointer-events-auto duration-700 z-50
}


.uploaded-image-area {
  background-image: url("assets/img/page-manager/image-upload-bg-pattern.jpg");
  min-height: 220px;
}